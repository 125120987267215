import { Select, Row, Col, Input, Button, Modal, Divider, Checkbox, Spin } from "antd"
import DatePicker from "react-datepicker"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"
import { updateCaretakerAction } from "../../redux/actions/caretakerActions"

import dayjs from "dayjs"
import { PatternFormat } from "react-number-format"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { useState } from "react"

const schema = yup
  .object({
    middleName: yup
      .string()
      .max(100)
      .matches(/^[a-zA-Z\s]*$/, "Middle Name should only contain alphabets"),
    dob: yup.string().required("Invalid DOB"),
    gender: yup.string().required("Invalid Gender"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid email").required("Invalid Email"),
    userName: yup.string().min(1).max(100).matches(/^[a-zA-Z0-9]*$/, "Only alphabets and numbers are allowed").required("Invalid Username"),
    mobileNumber: yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Mobile Number Must Be 10 Digits")
    .required("Invalid Mobile Number"),
    lastName: yup
    .string()
    .max(100)
    .required("Enter Last Name")
    .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    firstName: yup
      .string()
      .max(100)
      .required("Enter First Name")
      .matches(/^[a-zA-Z\s]+$/, "First Name should only contain alphabets"),
  })
  .required()

// permission constants
const CAN_ADD_PATIENTS = "CAN_ADD_PATIENTS"
const CAN_ADD_MANUAL_TIME = "CAN_ADD_MANUALTIME"
const ENROLLMENT_SPECIALIST = "ENROLLMENT_SPECIALIST"

const permissionCheckboxOptions = [
  {
    label: "Can Add Patient",
    value: CAN_ADD_PATIENTS
  },
  {
    label: "Can Add Manual Time",
    value: CAN_ADD_MANUAL_TIME
  }
]

const enrollSplCheckboxOption = [
  {
    label: "Enrollment Specialist",
    value: ENROLLMENT_SPECIALIST
  }
]

// grant permission profile type
const getProfileType = permissions => {
  const canAddPatients = permissions.includes(CAN_ADD_PATIENTS)
  const canAddManualTime = permissions.includes(CAN_ADD_MANUAL_TIME)
  const enrollSpl = permissions.includes(ENROLLMENT_SPECIALIST)

  if (canAddPatients && canAddManualTime) return "caretaker_all"
  else if (!canAddPatients && canAddManualTime) return "caretaker_manual_log"
  else if (!canAddManualTime && canAddPatients) return "caretaker_register_patient"
  else if(enrollSpl) return "enrollment_specialist"
  else return "caretaker"
}

const grantTypeToPermissions = {
  caretaker: [],
  caretaker_all: [CAN_ADD_PATIENTS, CAN_ADD_MANUAL_TIME],
  caretaker_manual_log: [CAN_ADD_MANUAL_TIME],
  caretaker_register_patient: [CAN_ADD_PATIENTS],
  enrollment_specialist: [ENROLLMENT_SPECIALIST]
}

const UpdateCaretaker = props => {
  const { singleCaretaker, updateCaretakerShow, setUpdateCaretakerShow } = props
  const [isFocused, setIsFocused] = useState(false);

  const { error: updateError, loading } = useSelector(store => store.updateCaretaker)

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: singleCaretaker?.email || "",
      gender: singleCaretaker?.gender || "",
      userName: singleCaretaker?.userName || "",
      lastName: singleCaretaker?.lastName || "",
      firstName: singleCaretaker?.firstName || "",
      middleName: singleCaretaker?.middleName || "",
      mobileNumber: formatPhoneNumber(singleCaretaker?.mobileNumber) || "",
      dob: new Date(singleCaretaker?.dob) || "",
      grantPermissions: grantTypeToPermissions[singleCaretaker?.grantType]
    }
  })

  const onSubmit = async data => {
    const profileTypes = getProfileType(data?.grantPermissions)
    delete data?.grantPermissions
    const result = await dispatch(
      updateCaretakerAction(singleCaretaker?.id, {
        ...data,
        mobileNumber:  data?.mobileNumber?.replace(/[^\d]/g, ''),
        dob: dayjs(data.dob).format("YYYY-MM-DD"),
        profileTypes
      })
    )
    if (result?.status === 200) setUpdateCaretakerShow(false)
  }
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };

  return (
    <Modal
      width={800}
      footer={null}
      open={updateCaretakerShow}
      onCancel={() => setUpdateCaretakerShow(false)}
      title={<h4 className="text-xl">Update Caretaker</h4>}>
      <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
        <Spin spinning={loading === true}>
          <Row gutter={16}>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-firstName">
                First Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    className="w-100"
                    status={errors.firstName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.firstName ? (
                <small className="text-danger">{errors.firstName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-middleName">
                Middle Name
              </label>
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => (
                  <Input
                    className="w-100"
                    status={errors.middleName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.middleName ? (
                <small className="text-danger">{errors.middleName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-lastName">
                Last Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    className="w-100"
                    status={errors.lastName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.lastName ? (
                <small className="text-danger">{errors.lastName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-userName">
                Username <span className="text-danger">*</span>
              </label>
              <Controller
                name="userName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    className="w-100"
                    status={errors.userName ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.userName ? (
                <small className="text-danger">{errors.userName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-mobileNumber">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <Controller
                name="mobileNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const {ref, ...rest} = field
                return (
                    <PatternFormat
                      format="(###) ###-####"
                      className={`formatted-mobile-input text-sm leading-[1.35rem] flex px-[11px] py-[4px]  w-full  border border-${
                        errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                      }  rounded-lg focus:outline-none`}
                      id="input-mobileNumber"
                      {...rest}
                      onChange={e => rest.onChange(handleMobileChange(e))}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                )}
               }
              />
              {errors.mobileNumber ? (
                <small className="text-danger">{errors.mobileNumber.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-email">
                Email <span className="text-danger">*</span>
              </label>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    className="w-100"
                    type="email"
                    status={errors.email ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-gender">
              Gender at the time of birth <span className="text-danger">*</span>
              </label>
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    className="w-100"
                    status={errors.gender ? "error" : undefined}
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" }
                    ]}
                    {...field}
                  />
                )}
              />
              {errors.gender ? (
                <small className="text-danger">{errors.gender.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-dob">
                DOB <span className="text-danger">*</span>
              </label>
              <Controller
                name="dob"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={date => field.onChange(date.toISOString())}
                    dateFormatCalendar={"MMM yyyy"}
                    maxDate={Date.now()}
                    showPopperArrow={false}
                    placeholderText="Date of Birth"
                    minDate={dayjs(new Date()).subtract(150, "years")._d}
                    withPortal
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    className={`border p-3 rounded w-100 h-[40px] ${
                      errors.dob ? "border-danger" : ""
                    }`}
                    {...field}
                  />
                )}
              />
              {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
            </Col>

            <Divider orientation="left">Permissions</Divider>
            <Col className="mb-4" xs={24}>
              <Controller
                name="grantPermissions"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <div className="inline-flex flex-wrap gap-3">
                      <Checkbox.Group
                        options={permissionCheckboxOptions}
                        // status={errors.grantPermissions ? "error" : undefined}
                        {...field}
                        />
                    </div>
                    <Checkbox.Group
                      className="ml-2"
                      options={enrollSplCheckboxOption}
                      {...field}
                    />
                  </>
                )}
              />
            </Col>

            {updateError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-medium">{updateError}</p>
              </Col>
            ) : null}
          </Row>
        </Spin>

        <Button className="mt-4" type="primary" htmlType="submit" disabled={loading}>
          {loading ? "Updating..." : "Update"}
        </Button>
      </form>
    </Modal>
  )
}

export default UpdateCaretaker
