import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

// import jsPDF from "jspdf"
import { Button } from "antd"
import html2PDF from "jspdf-html2canvas"

import { useDispatch, useSelector } from "react-redux"
import { getPatientInfo } from "../../redux/actions/patientAction"

import Loading from "../layouts/Loading/Loading"
import PatientDetailedReport from "./PatientDetailedReport"

import "./patient-report.css"

const PatientReportComponent = () => {
  const patientId = useParams()

  // custom states
  const [deviceData] = useState(new Set())
  const [devicesGiven] = useState(new Set())
  const [chronicConcernId] = useState(new Set())
  const [chronicConcernCondition] = useState(new Set())

  const dispatch = useDispatch()

  const { userInfo } = useSelector(state => state.userInfo)
  const { loading, patientInfoDetail, error } = useSelector(state => state.patientInfo)
  const { providerList } = useSelector(state => state.providerList)
  const { manualTimeList } = useSelector(state => state.manualList)
  const { ChronicConcern } = useSelector(state => state.chronicConcern)
  const { loading: reportLoading, getPatientReport } = useSelector(state => state.patientReport)

  const { patientBpReading } = useSelector(state => state.bloodPressureReading)
  const { patientWmReading } = useSelector(state => state.weightMachineReading)
  const { patientOxyReading } = useSelector(state => state.oxygenMeterReading)

  useEffect(() => {
    dispatch(getPatientInfo({ patientId: patientId.id }))
    /* dispatch(getPatinetWmReading({ patientId: patientId.id }));
        dispatch(getPatientBPReading({ patientId: patientId.id }));
        dispatch(getPatientOxyReading({ patientId: patientId.id })); */
  }, [dispatch, patientId])

  const navigate = useNavigate()

  useEffect(() => {
    if (!reportLoading && getPatientReport === undefined) {
      navigate("/patient", { replace: true })
    }
  }, [userInfo, navigate, getPatientReport, reportLoading])

  // get patient monitor type
  useEffect(() => {
    if (patientInfoDetail !== undefined) {
      if (patientInfoDetail?.userData?.moniteringDetails) {
        for (let i = 0; i < patientInfoDetail?.userData?.moniteringDetails?.length; i++) {
          deviceData.add(patientInfoDetail?.userData?.moniteringDetails[i].moniterData.value)
        }
        for (const monitorData of patientInfoDetail?.userData?.moniteringDetails) {
          if (
            monitorData?.moniterData?.value === "RPM" &&
            monitorData?.moniterData?.id === "2001"
          ) {
            for (const monitorData of patientInfoDetail?.userData?.moniteringDetails) {
              monitorData?.deviceData?.map(({ value }) => devicesGiven.add(value))
            }
          }
        }
      }
    }

    ChronicConcern !== undefined &&
      ChronicConcern?.chronicData.forEach(({ chronicId, condition }, value) => {
        chronicConcernCondition.add(condition)
        chronicConcernId.add(chronicId)
      })
  }, [
    ChronicConcern,
    chronicConcernCondition,
    chronicConcernId,
    deviceData,
    devicesGiven,
    patientInfoDetail
  ])

  const pdfDivReference = useRef()

  const handleGenerateReport = async () => {
    const myElement = pdfDivReference.current
    // const pdf = new jsPDF({
    //   orientation: "p",
    //   unit: "px",
    //   format: "a4"
    // })

    await html2PDF(myElement, {
      output: `${patientInfoDetail?.userData.name}`
    })
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <div className="text-danger">Something went wrong while Getting Information.</div>
  ) : (
    <>
      <h3>Patient Detailed Generated Report Card</h3>
      <PatientDetailedReport
        patientInfo={patientInfoDetail?.userData}
        patientInfoDetail={patientInfoDetail}
        deviceData={deviceData}
        providerList={providerList}
        getPatientReport={getPatientReport}
        manualTimeList={manualTimeList}
        chronicConcernId={chronicConcernId}
        pdfDivReference={pdfDivReference}
        chronicConcernCondition={chronicConcernCondition}
        devicesGiven={devicesGiven}
        patientBpReading={patientBpReading}
        patientWmReading={patientWmReading}
        patientOmReading={patientOxyReading}
      />
      <div className="d-flex justify-content-center align-items-center mt-2">
        <Button type="primary" onClick={handleGenerateReport}>
          Generate Report
        </Button>
      </div>
    </>
  )
}

export default PatientReportComponent
