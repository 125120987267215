import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"

import { Card } from "antd"

// import PatientFilters from "./PatientFilters"

import { useDispatch, useSelector } from "react-redux"
import {
  enrollmentPatientCountAction,
  getUserCountStatsAction,
  triageStatsCountAction
} from "../../redux/actions/dashboardAction"
import { getrpmDeviceActions } from "../../redux/actions/rpmAction"
import TriageDashboardChart from "./TriageDashboardChart"
import moment from "moment"

import OrgBackNavigation from "../Organization/OrgBackNavigation"
import EnrolledPatientsChart from "./EnrolledPatientsChart"
// import "./dashboard.css"

const DashboardComponent = () => {
  const [showDashboardChart, setShowDashboardChart] = useState(false)
  const [showEnrolledPatientsChart, setShowEnrolledPatientsChart] = useState(false)
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  const { userInfo } = useSelector(state => state.userInfo)
  const { userCountStats, loading: userCountStatsLoading } = useSelector(
    state => state.getUserCountStats
  )
  const { triageStatsCount } = useSelector(state => state.triageStatsCount)
  const { enrollmentPatientCount } = useSelector(state => state.enrollmentPatientCount)

  const rpmDevices = JSON.parse(localStorage.getItem("monitorTypes"))

  useEffect(() => {
    if (!orgId ) return
    dispatch(getUserCountStatsAction(orgId))
    if (!rpmDevices?.length) dispatch(getrpmDeviceActions())
    dispatch(triageStatsCountAction({
      startDate: moment.utc(new Date()).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS+00:00"),
      endDate: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS+00:00")
    }, orgId))
    dispatch(enrollmentPatientCountAction({
      startDate: moment(new Date()).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
    }, orgId))
  }, [userInfo, dispatch]) //eslint-disable-line

  return (
    <div>
      {/* <PatientFilters
        userInfo={userInfo}
        handleReset={handleReset}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        setSearchQuery={setSearchQuery}
        setDashboardCard={setDashboardCard}
      /> */}
      <OrgBackNavigation />
      <div className="flex flex-wrap gap-4 mt-4 justify-around md:justify-normal">
        <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px]"
          onClick={() => navigate(`/${orgId}/${orgName}/patient`)}>
          <div className="flex items-center flex-col">
            <img alt="icon-patient-group" src="/assets/icon-group.png" width="100px" />
            <p className="my-3">Total Patients</p>
            <p className="text-3xl font-semibold text-current">{userCountStats?.patientCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px] cursor-pointer"
          onClick={() =>  navigate(`/${orgId}/${orgName}/provider`)}>
          <div className="flex items-center flex-col">
            <img
              alt="icon-doctor"
              src="/assets/doctor.png"
              width="85px"
              style={{ marginBottom: "15px" }}
            />
            <p className="my-3">Total Providers</p>
            <p className="text-3xl font-semibold text-current">{userCountStats?.providerCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px] cursor-pointer"
          onClick={() =>  navigate(`/${orgId}/${orgName}/caretaker`)}>
          <div className="flex items-center flex-col">
            <img alt="icon-onboarding" src="/assets/icon-onboarding.png" width="100px" />
            <p className="my-3">Total Caretaker</p>
            <p className="text-3xl font-semibold text-current">{userCountStats?.caretakerCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px] cursor-pointer"
          onClick={() => setShowDashboardChart(!showDashboardChart)}
        >
          <div className="flex items-center flex-col">
            <img alt="icon-triage" src="/assets/triage-icon.png" width="100px" />
            <p className="my-3">No. of Critical Patients</p>
            <p className="text-3xl font-semibold text-current">{triageStatsCount?.receivedCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px]"
          onClick={() => setShowEnrolledPatientsChart(!showEnrolledPatientsChart)}>
          <div className="flex items-center flex-col">
            <img alt="icon-enrolled-patients" src="/assets/enrolled-patients-icon.png"  width="85px" style={{ marginBottom: "12px", paddingTop:'3px' }} />
            <p className="my-3">Enrolled Patients</p>
            <p className="text-3xl font-semibold text-current">
              {enrollmentPatientCount?.enrollmentTrack}
            </p>
          </div>
        </Card>
      </div>

      {showDashboardChart &&
        <div className="mt-4">
          <TriageDashboardChart />
        </div>
      }

      {showEnrolledPatientsChart &&
        <div className="mt-4">
          <EnrolledPatientsChart />
        </div>
      }
    </div>
  )
}

export default DashboardComponent
