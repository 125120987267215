import { applyMiddleware, combineReducers, createStore } from "redux"
import thunk from "redux-thunk"
// import { composeWithDevTools } from "redux-devtools-extension"

import {
  ImageReducer,
  passwordUpdateReducer,
  userLoginReducer,
  emailUpdateReducer,
  phoneUpdateReducer,
  adminUpdateReducer,
  validateLoginDetailsReducer,
  googleLoginReducer
} from "./reducers/userReducer"
import {
  criticalPatientReducer,
  dashboardKPIReducer,
  enrollmentPatientCountReducer,
  enrollmentStatsReducer,
  filterDashboardReducer,
  getUserCountStatsReducer,
  triageStatsAnalysisReducer,
  triageStatsCountReducer
} from "./reducers/dashboardReducer"
import {
  AddPatientReducer,
  addPatientClearErrorsReducer,
  chronicTypeReducer,
  criticalStatusReducer,
  getChronicConditionReducer,
  getManualTimeReducer,
  getPatientReportReducer,
  HealthBpReducer,
  HealthKpiReducer,
  HealthOxyReducer,
  HealthWmReducer,
  HealthGlucoseReducer,
  patientBpReadingReducer,
  PatientInfoReducer,
  patientOxyReadingReducer,
  PatientReadingReducer,
  patientTimelineReducer,
  patientWmReadingReducer,
  postChronicConditionReducer,
  updateLandlineReducer,
  UpdatePatientReducer,
  AddManualTime,
  addConcernReducer,
  getConcernReducer,
  getTriageCriticalPatientsReducer,
  PatientMonitoringReducer,
  PatientDiagnosticReducer,
  PostPatientDiagnosticReducer,
  PatientVitalsReducer,
  postPatientVitalsReducer,
  PatientAddressReducer,
  facilityAddressReducer,
  patientCaretakerReducer,
  postPatientCaretakerReducer,
  patientManualLogTrack,
  patientInsuranceReducer,
  updatePatientInsuranceReducer,
  outpatientAddressReducer,
  postOutpatientAddressReducer,
  advancedFiltersOptionsReducer,
  facilityPatientAddressReducer,
  PatientCriticalDevicesReducer,
  postPatientInsuranceReducer,
  postFacilityPatientAddressReducer,
  patientAdvFilterReducer,
  dateRangeReducer,
  getPatientMenuConfigReducer,
  getPatientOptOutReducer,
  postPatientOptOutReducer,
  patientDetailsValidateReducer,
  setPatientSelectedGroupsReducer,
  getTriageFalseReportReducer,
  postPatientEnrollManualReducer
} from "./reducers/patientReducer"
import {
  addProviderReducer,
  getSingleProviderReducer,
  providerKPIReducer,
  providerPatientReducer,
  providerReducer,
  searchProviderByNPI,
  getProviderNPIReducer,
  updateProviderNPIReducer,
  getProviderTaxonomyReducer,
  registerProviderTaxonomyReducer,
  deleteProviderTaxonomyReducer,
  getProviderSpecializationReducer,
  registerProviderSpecializationReducer,
  deleteProviderSpecializationReducer,
  getProviderAddressReducer,
  updateProviderAddressReducer,
  providerFilterPatientReducer,
  providerPatientAdvFilterReducer
} from "./reducers/providerReducer"
import {
  addCaretakerReducer,
  caretakerCardReducer,
  caretakerListReducer,
  caretakerPatientReducer,
  singleCaretakerReducer,
  getCaretakerAddressReducer,
  getCareTypesReducer,
  updateCaretakerManualTimeReducer,
  updateCaretakerReducer,
  updateCaretakerAddressReducer,
  caretakerFilterPatientReducer,
  caretakerPatientAdvFilterReducer
} from "./reducers/caretakerReducer"
import { monitorTypeReducer } from "./reducers/devicesReducer"
import {
  emailChangeReducer,
  emailVerificationReducer,
  numberChangeReducer,
  pushNotificationReducer,
  setTriageSessionReducer,
  setShortClearReducer,
  filterModalReducer
} from "./reducers/utilReducer"
import {
  getCcmReducer,
  postCCMReducer,
  getCcmCarePlanReducer,
  getFacilityAddressReducer,
  getUserSearchReducer,
  getFilterUsersReducer,
  getUsersForCcmFollowUpReducer,
  getPreviousCareplansListReducer,
  getDashboardFilterUsersReducer,
  getCcmArchiveReducer,
  updateCcmPlanReducer,
  postCcmFollowUpReducer,
  getLatestCcmPlanReducer
} from "./reducers/ccmReducer"
import {
  getPatientStatusReducer,
  getShipmentStatusReducer,
  updatePatientStatusReducer,
  updateShipmentStatusReducer
} from "./reducers/patientStatusReducer"
import {
  getLatestPcmPlanReducer,
  getPcmArchiveReducer,
  getPcmReducer,
  postPCMReducer,
  postPcmFollowUpReducer,
  updatePcmPlanReducer,
} from "./reducers/pcmReducer"
import {
  getRpmReducer,
  postRpmReducer,
  getRpmCarePlanReducer,
  getUsersForRpmFollowUpReducer,
  postRpmTriageReducer,
  getRpmDeviceReducer,
  postRpmClearTriageReducer,
  getRpmTriageDataReducer,
  getTreatmentPlans,
  postCriticalReadingSnoozeReducer
} from "./reducers/rpmReducer"

import { getThemeReducer } from "./reducers/themeReducer"

import {
  getRtmAddictionMedicine,
  getRtmMentalHealth,
  getRtmPainManagement,
  getSingleRtmAddictionMedicine,
  getSingleRtmMentalHealth,
  getSingleRtmPainManagement
} from "./reducers/rtmReducer"
import {
  AddInternalNoteReducer,
  EditInternalNoteReducer,
  getInternalNotesReducer
} from "./reducers/internalNotesReducer"
import { UploadFilesReducer } from "./reducers/uploadFileReducer"
import {
  getPatientBpCustomParameterReducer,
  postPatientBpCustomParameterReducer,
  updatePatientBpCustomParameterReducer,
  deletePatientBpCustomParameterReducer,
  getPatientOxiCustomParameterReducer,
  postPatientOxiCustomParameterReducer,
  updatePatientOxiCustomParameterReducer,
  deletePatientOxiCustomParameterReducer,
  getPatientGlucoCustomParameterReducer,
  postPatientGlucoCustomParameterReducer,
  updatePatientGlucoCustomParameterReducer,
  deletePatientGlucoCustomParameterReducer
} from "./reducers/customParameterReducer";
import {
  AddSpecialInstructionsReducer,
  GetSpecialInstructionsReducer,
  EditSpecialInstructionsReducer,
  DeleteSpecialInstructionsReducer,
  GetSpecialInstructionsHistoryReducer
} from "./reducers/specialInstructionsReducer"
import {
  editEligiblePatientReducer,
  getOrgOnboardPatientStatisticsReducer,
  getSelfOnboardingPendingPatientReducer,
  postBulkMailMessagePatientReducer,
  registerOTPReducer,
  removeBulkCaretakerFromPatientReducer,
  removeSelfOnboardingPatientCaretakerReducer,
  updateBulkCaretakerActivePatientReducer,
  updateSelfOnboardingPatientCaretakerReducer
} from "./reducers/selfOnboardingReducer"
import {
  getPractitionerAddressReducer,
  getPractitionerProvidersReducer,
  getPractitionerSpecializationReducer,
  mapPractitionerToProviderReducer,
  practitionerFilterPatientReducer,
  practitionerListReducer,
  practitionerPatientAdvFilterReducer,
  practitionerPatientReducer,
  singlePractitionerReducer,
  updatePractitionerAddressReducer,
  updatePractitionerReducer
} from "./reducers/practitionerReducer"
import {
  addGroupReducer,
  getAdminCaretakerForGroupReducer,
  getGroupDetailReducer,
  getGroupsReducer,
  updateGroupReducer,
  mapGroupToPatientReducer,
  validateGroupNameReducer,
  getPatientsForGroupReducer,
  groupPatientAdvFilterReducer,
  groupPatientsListAdvFilterReducer,
  getPatientGroupNameReducer
} from "./reducers/groupsReducer"
import { getAllOrgReducer } from "./reducers/orgDetailsReducer"

const reducer = combineReducers({
  googleLogin: googleLoginReducer,
  userInfo: userLoginReducer,
  imageUploader: ImageReducer,
  themeConfig: getThemeReducer,
  adminUpdate: adminUpdateReducer,
  passwordUpdate: passwordUpdateReducer,
  userEmailUpdate: emailUpdateReducer,
  userPhoneUpdate: phoneUpdateReducer,
  validateLoginDetails: validateLoginDetailsReducer,
  // DASHBOARD
  getUserCountStats: getUserCountStatsReducer,
  dashboardKPI: dashboardKPIReducer,
  filterdashboardKPI: filterDashboardReducer,
  criticalPatient: criticalPatientReducer,
  enrollmentStats: enrollmentStatsReducer,
  enrollmentPatientCount: enrollmentPatientCountReducer,
  triageStatsAnalysis: triageStatsAnalysisReducer,
  triageStatsCount: triageStatsCountReducer,
  //
  manualList: getManualTimeReducer,
  chronicConcern: getChronicConditionReducer,
  criticalStatus: criticalStatusReducer,
  // PATINET_DATA
  addSpecialInstructions: AddSpecialInstructionsReducer,
  getSpecialInstructions: GetSpecialInstructionsReducer,
  GetSpecialInstructionsHistory: GetSpecialInstructionsHistoryReducer,
  editSpecialInstructions: EditSpecialInstructionsReducer,
  deleteSpecialInstructions: DeleteSpecialInstructionsReducer,
  addInternalNote: AddInternalNoteReducer,
  editInternalNote: EditInternalNoteReducer,
  patientInfo: PatientInfoReducer,
  patientMonitoringData: PatientMonitoringReducer,
  patientDiagnosticData: PatientDiagnosticReducer,
  postPatientDiagnosticReducer: PostPatientDiagnosticReducer,
  patientVitals: PatientVitalsReducer,
  postPatientVitals: postPatientVitalsReducer,
  patientReport: getPatientReportReducer,
  healthKpi: HealthKpiReducer,
  healthWm: HealthWmReducer,
  healthBp: HealthBpReducer,
  healthOxy: HealthOxyReducer,
  healthGlucose: HealthGlucoseReducer,
  patientReading: PatientReadingReducer,
  patientTimeline: patientTimelineReducer,
  weightMachineReading: patientWmReadingReducer,
  oxygenMeterReading: patientOxyReadingReducer,
  bloodPressureReading: patientBpReadingReducer,
  addPatient: AddPatientReducer,
  addPatientErrors: addPatientClearErrorsReducer,
  updatePatient: UpdatePatientReducer,
  chronicDeviceType: chronicTypeReducer,
  chronicConditionStatus: postChronicConditionReducer,
  addManualTime: AddManualTime,
  updateLandline: updateLandlineReducer,
  patientConcern: addConcernReducer,
  patientConcernData: getConcernReducer,
  triageCriticalPatients: getTriageCriticalPatientsReducer,
  patientAddress: PatientAddressReducer,
  facilityAddress: facilityAddressReducer,
  postFacilityAddress: postFacilityPatientAddressReducer,
  patientCaretaker: patientCaretakerReducer,
  postPatientCaretaker: postPatientCaretakerReducer,
  patientManualLogTrack: patientManualLogTrack,
  patientInsurance: patientInsuranceReducer,
  updatePatientInsurance: updatePatientInsuranceReducer,
  postPatientInsurance: postPatientInsuranceReducer,
  outpatientAddress: outpatientAddressReducer,
  postOutpatientAddress: postOutpatientAddressReducer,
  advancedFiltersOptions: advancedFiltersOptionsReducer,
  facilityPatientAddress: facilityPatientAddressReducer,
  patientCriticalDevices: PatientCriticalDevicesReducer,
  getTriageFalseReport: getTriageFalseReportReducer,
  patientAdvFilter: patientAdvFilterReducer,
  selectedDateRange: dateRangeReducer,
  getPatientMenuConfig: getPatientMenuConfigReducer,
  getPatientOptOut: getPatientOptOutReducer,
  postPatientOptOut: postPatientOptOutReducer,
  patientDetailsValidate: patientDetailsValidateReducer,
  setPatientSelectedGroups: setPatientSelectedGroupsReducer,
  postPatientEnrollManual: postPatientEnrollManualReducer,
  // patient status
  getPatientStatus : getPatientStatusReducer,
  updatePatientStatus : updatePatientStatusReducer,
  getShipmentStatus: getShipmentStatusReducer,
  updateShipmentStatus: updateShipmentStatusReducer,
  // Org details
  orgDetails: getAllOrgReducer,
  // PROVIDER_DETAILS
  providerList: providerReducer,
  providerKPI: providerKPIReducer,
  providerPatient: providerPatientReducer,
  providerFilterPatient: providerFilterPatientReducer,
  addProvider: addProviderReducer,
  providerNPI: searchProviderByNPI,
  singleProvider: getSingleProviderReducer,
  getProviderNPIReducer,
  updateProviderNPIReducer,
  getProviderTaxonomyReducer,
  registerProviderTaxonomyReducer,
  deleteProviderTaxonomyReducer,
  getProviderSpecializationReducer,
  registerProviderSpecializationReducer,
  deleteProviderSpecializationReducer,
  getProviderAddressReducer,
  updateProviderAddressReducer,
  providerPatientAdvFilter: providerPatientAdvFilterReducer,
  // PRACTITIONER_DETAILS
  practitionerList: practitionerListReducer,
  singlePractitioner: singlePractitionerReducer,
  practitionerPatient: practitionerPatientReducer,
  practitionerFilterPatient: practitionerFilterPatientReducer,
  practitionerPatientAdvFilter: practitionerPatientAdvFilterReducer,
  practitionerSpecialization: getPractitionerSpecializationReducer,
  practitionerAddress: getPractitionerAddressReducer,
  updatePractitionerAddress: updatePractitionerAddressReducer,
  updatePractitioner: updatePractitionerReducer,
  practitionerProviders: getPractitionerProvidersReducer,
  mapPractitionerToProvider: mapPractitionerToProviderReducer,
  // CATETAKER_DATA
  caretakerCard: caretakerCardReducer,
  caretakerList: caretakerListReducer,
  singleCaretaker: singleCaretakerReducer,
  getCaretakerAddress: getCaretakerAddressReducer,
  caretakerPatient: caretakerPatientReducer,
  caretakerFilterPatient: caretakerFilterPatientReducer,
  addCaretaker: addCaretakerReducer,
  careTypes: getCareTypesReducer,
  cateTakerManualtime: updateCaretakerManualTimeReducer,
  updateCaretaker: updateCaretakerReducer,
  updateCaretakerAddress: updateCaretakerAddressReducer,
  caretakerPatientAdvFilter: caretakerPatientAdvFilterReducer,
  // devices details
  monitorTypeList: monitorTypeReducer,

  // utils
  emailVerification: emailVerificationReducer,
  emailUpdate: emailChangeReducer,
  numberUpdate: numberChangeReducer,
  pushNotification: pushNotificationReducer,
  sessionTriageData: setTriageSessionReducer,
  shortClearData: setShortClearReducer,
  filterModalValues: filterModalReducer,
  // CCM
  ccmList: getCcmReducer,
  getLatestCareplan: getLatestCcmPlanReducer,
  updateCcmPlan: updateCcmPlanReducer,
  ccmArchivePlans: getCcmArchiveReducer,
  postCCMData: postCCMReducer,
  postCcmFollowUp: postCcmFollowUpReducer,
  ccmPatientCarePlan: getCcmCarePlanReducer,
  // facilityAddress: getFacilityAddressReducer,
  filterSearch: getUserSearchReducer,
  filter: getFilterUsersReducer,
  ccmFollowUpUsers: getUsersForCcmFollowUpReducer,
  previousCareplans: getPreviousCareplansListReducer,
  dashboardFilterUserData: getDashboardFilterUsersReducer,
  // PCM 
  pcmList: getPcmReducer,
  getLatestPcmCareplan: getLatestPcmPlanReducer,
  updatePcmPlan: updatePcmPlanReducer,
  postPCMData: postPCMReducer,
  pcmArchivePlans: getPcmArchiveReducer,
  postPcmFollowUp: postPcmFollowUpReducer,

  // RPM
  rpmList: getRpmReducer,
  postRPMData: postRpmReducer,
  rpmPatientCarePlan: getRpmCarePlanReducer,
  rpmFollowUpUsers: getUsersForRpmFollowUpReducer,
  rpmTriageStore: postRpmTriageReducer,
  rpmDevices: getRpmDeviceReducer,
  rpmClearTriage: postRpmClearTriageReducer,
  rpmTriageData: getRpmTriageDataReducer,
  treatmentPlans: getTreatmentPlans,
  postCriticalReadingSnooze: postCriticalReadingSnoozeReducer,
  //RTM
  getRtmPainManagement: getRtmPainManagement,
  getSingleRtmPainManagement: getSingleRtmPainManagement,
  getRtmMentalHealth: getRtmMentalHealth,
  getSingleRtmMentalHealth: getSingleRtmMentalHealth,
  getRtmAddictionMedicine: getRtmAddictionMedicine,
  getSingleRtmAddictionMedicine: getSingleRtmAddictionMedicine,
  // internal notes
  getInternalNotes: getInternalNotesReducer,
  uploadFiles: UploadFilesReducer,
  // custom parameter
  getPatientBpCustomParameter: getPatientBpCustomParameterReducer,
  postPatientBpCustomParameter: postPatientBpCustomParameterReducer,
  updatePatientBpCustomParameter: updatePatientBpCustomParameterReducer,
  deletePatientBpCustomParameter: deletePatientBpCustomParameterReducer,
  getPatientOxiCustomParameter: getPatientOxiCustomParameterReducer,
  postPatientOxiCustomParameter: postPatientOxiCustomParameterReducer,
  updatePatientOxiCustomParameter: updatePatientOxiCustomParameterReducer,
  deletePatientOxiCustomParameter: deletePatientOxiCustomParameterReducer,
  getPatientGlucoCustomParameter: getPatientGlucoCustomParameterReducer,
  postPatientGlucoCustomParameter: postPatientGlucoCustomParameterReducer,
  updatePatientGlucoCustomParameter: updatePatientGlucoCustomParameterReducer,
  deletePatientGlucoCustomParameter: deletePatientGlucoCustomParameterReducer,
  // groups
  getGroups: getGroupsReducer,
  addGroup: addGroupReducer,
  getGroupDetail: getGroupDetailReducer,
  updateGroup: updateGroupReducer,
  getAdminCaretakerForGroup: getAdminCaretakerForGroupReducer,
  mapGroupToPatient: mapGroupToPatientReducer,
  validateGroupName: validateGroupNameReducer,
  getPatientsForGroup: getPatientsForGroupReducer,
  groupPatientAdvFilter: groupPatientAdvFilterReducer,
  groupPatientsListAdvFilter: groupPatientsListAdvFilterReducer,
  getPatientGroupName: getPatientGroupNameReducer,
  // selfonboarding 
  getOrgOnboardPatientStatistics: getOrgOnboardPatientStatisticsReducer,
  selfOnboardingPendingPatient: getSelfOnboardingPendingPatientReducer,
  editEligiblePatient: editEligiblePatientReducer,
  registerOTP: registerOTPReducer,
  updateBulkCaretaker: updateSelfOnboardingPatientCaretakerReducer,
  updateBulkCaretakerActivePatient: updateBulkCaretakerActivePatientReducer,
  removeBulkCaretakerPatient: removeBulkCaretakerFromPatientReducer,
  removeBulkCaretakerSOB: removeSelfOnboardingPatientCaretakerReducer,
  postBulkMessagePatient: postBulkMailMessagePatientReducer,

})

const middleware = [thunk]

// const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));
const store = createStore(reducer, applyMiddleware(...middleware))

export default store
