import { useState, useEffect } from "react"

import { Row, Col, Input, Button, Modal } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"

import {
  getPractitionerAddressAction,
  updatePractitionerAddressAction
} from "../../redux/actions/practitionerAction"
import Loading from "../layouts/Loading/Loading"

const INVALID_CITY = "Invalid City"
const INVALID_STATE = "Invalid State"
const INVALID_STREET = "Invalid Street"
const INVALID_ZIPCODE = "Invalid Zipcode"

const CITY_ERR_MSG = "City should contain alphabets only"
const STATE_ERR_MSG = "City should contain alphabets only"

const STREET = "street"
const CITY = "city"
const STATE = "state"
const ZIPCODE = "zipcode"

const SUCCESS_MSG = "Update Address Successful!"

const UPDATING = "Updating..."
const UPDATE = "Update"

const alphaRegex = /^[a-zA-Z]+$/

const schema = yup
  .object({
    [STREET]: yup.string().required(INVALID_STREET),
    [CITY]: yup.string().trim().matches(alphaRegex, CITY_ERR_MSG).required(INVALID_CITY),
    [STATE]: yup.string().trim().matches(alphaRegex, STATE_ERR_MSG).required(INVALID_STATE),
    [ZIPCODE]: yup.string().min(5).max(5).required(INVALID_ZIPCODE)
  })
  .required()

const UpdateAddress = props => {
  const { practitionerId, updateAddressShow, setUpdateAddressShow } = props

  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [addressError, setAddressError] = useState("")

  const {
    loading: addressLoading,
    data: address,
    error: addressErrors
  } = useSelector(state => state.practitionerAddress)
  const {
    loading: addressUpdating,
    data: addressUpdatedDated,
    error: addressUpdateError
  } = useSelector(state => state.updatePractitionerAddress)

  useEffect(() => {
    if (!updateAddressShow || !address) return
    setValue(CITY, address?.city ?? "")
    setValue(STATE, address?.state ?? "")
    setValue(STREET, address?.street ?? "")
    setValue(ZIPCODE, address?.zipcode ?? "")
  }, [updateAddressShow, address])

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (updateAddressShow) clearErrors()
  }, [updateAddressShow])

  const onSubmit = async data => {
    setAddressError("")
    const result = await dispatch(updatePractitionerAddressAction(practitionerId, data))
    if (result?.status === 200) {
      addToast(SUCCESS_MSG, {
        appearance: "success",
        autoDismiss: true
      })
      setUpdateAddressShow(false)
      await dispatch(getPractitionerAddressAction(practitionerId))
    } else setAddressError(result)
  }

  return (
    <Modal
      width={800}
      footer={null}
      open={updateAddressShow}
      onCancel={() => setUpdateAddressShow(false)}
      title={<h4 className="text-xl">View/Update Address</h4>}>
      {addressLoading ? (
        <Loading />
      ) : (
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={16}>
            <Col className="mb-4" xs={24} md={16}>
              <label className="mb-1" htmlFor="input-street">
                Street <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name={STREET}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-street"
                    status={errors.street ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors[STREET] ? (
                <small className="text-danger capitalize">{errors[STREET].message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={8}>
              <label className="mb-1" htmlFor="input-zipcode">
                Zipcode <span className="text-danger">*</span>
              </label>
              <Controller
                name={ZIPCODE}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    type="number"
                    id="input-zipcode"
                    status={errors.zipcode ? "error" : undefined}
                    {...field}
                    onChange={e => /^\d{0,5}$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors[ZIPCODE] ? (
                <small className="text-danger capitalize">{errors[ZIPCODE].message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12}>
              <label className="mb-1" htmlFor="input-city">
                City <span className="text-danger">*</span>
              </label>
              <Controller
                name={CITY}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-city"
                    status={errors.city ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors[CITY] ? (
                <small className="text-danger capitalize">{errors[CITY].message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12}>
              <label className="mb-1" htmlFor="input-state">
                State <span className="text-danger">*</span>
              </label>
              <Controller
                name={STATE}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-state"
                    status={errors.state ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors[STATE] ? (
                <small className="text-danger capitalize">{errors[STATE].message}</small>
              ) : null}
            </Col>
            {addressError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-medium">{addressError}</p>
              </Col>
            ) : null}
            <Col xs={24} md={12}>
              <Button type="primary" htmlType="submit" disabled={addressUpdating}>
                {addressUpdating ? UPDATING : UPDATE}
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Modal>
  )
}

export default UpdateAddress
