export const PROVIDER_LIST_REQUEST = "PROVIDER_LIST_REQUEST"
export const PROVIDER_LIST_SUCCESS = "PROVIDER_LIST_SUCCESS"
export const PROVIDER_LIST_FAIL = "PROVIDER_LIST_FAIL"
export const PROVIDER_ADD_REQUEST = "PROVIDER_ADD_REQUEST"
export const PROVIDER_ADD_SUCCESS = "PROVIDER_ADD_SUCCESS"
export const PROVIDER_ADD_FAIL = "PROVIDER_ADD_FAIL"
export const PROVIDER_PATIENT_LIST_REQUEST = "PROVIDER_PATIENT_LIST_REQUEST"
export const PROVIDER_PATIENT_LIST_SUCCESS = "PROVIDER_PATIENT_LIST_SUCCESS"
export const PROVIDER_PATIENT_LIST_FAIL = "PROVIDER_PATIENT_LIST_FAIL"

export const PROVIDER_FILTER_PATIENT_REQUEST = "PROVIDER_FILTER_PATIENT_REQUEST"
export const PROVIDER_FILTER_PATIENT_SUCCESS = "PROVIDER_FILTER_PATIENT_SUCCESS"
export const PROVIDER_FILTER_PATIENT_FAIL = "PROVIDER_FILTER_PATIENT_FAIL"

export const PROVIDER_KPI_REQUEST = "PROVIDER_KPI_REQUEST"
export const PROVIDER_KPI_SUCCESS = "PROVIDER_KPI_SUCCESS"
export const PROVIDER_KPI_FAIL = "PROVIDER_KPI_FAIL"

export const PROVIDER_NPI_REQUEST = "PROVIDER_NPI_REQUEST"
export const PROVIDER_NPI_SUCCESS = "PROVIDER_NPI_SUCCESS"
export const PROVIDER_NPI_FAIL = "PROVIDER_NPI_FAIL"

export const SINGLE_PROVIDER_REQUEST = "SINGLE_PROVIDER_REQUEST"
export const SINGLE_PROVIDER_SUCCESS = "SINGLE_PROVIDER_SUCCESS"
export const SINGLE_PROVIDER_FAIL = "SINGLE_PROVIDER_FAIL"

export const UPDATE_PROVIDER_REQUEST = "UPDATE_PROVIDER_REQUEST"
export const UPDATE_PROVIDER_SUCCESS = "UPDATE_PROVIDER_SUCCESS"
export const UPDATE_PROVIDER_FAIL = "UPDATE_PROVIDER_FAIL"

export const GET_PROVIDER_NPI_REQUEST = "GET_PROVIDER_NPI_REQUEST" 
export const GET_PROVIDER_NPI_SUCCESS = "GET_PROVIDER_NPI_SUCCESS" 
export const GET_PROVIDER_NPI_FAIL = "GET_PROVIDER_NPI_FAIL" 

export const UPDATE_PROVIDER_NPI_REQUEST = "UPDATE_PROVIDER_NPI_REQUEST" 
export const UPDATE_PROVIDER_NPI_SUCCESS = "UPDATE_PROVIDER_NPI_SUCCESS" 
export const UPDATE_PROVIDER_NPI_FAIL = "UPDATE_PROVIDER_NPI_FAIL" 

export const GET_PROVIDER_TAXONOMY_REQUEST = "GET_PROVIDER_TAXONOMY_REQUEST" 
export const GET_PROVIDER_TAXONOMY_SUCCESS = "GET_PROVIDER_TAXONOMY_SUCCESS" 
export const GET_PROVIDER_TAXONOMY_FAIL = "GET_PROVIDER_TAXONOMY_FAIL" 

export const REGISTER_PROVIDER_TAXONOMY_REQUEST = "REGISTER_PROVIDER_TAXONOMY_REQUEST" 
export const REGISTER_PROVIDER_TAXONOMY_SUCCESS = "REGISTER_PROVIDER_TAXONOMY_SUCCESS" 
export const REGISTER_PROVIDER_TAXONOMY_FAIL = "REGISTER_PROVIDER_TAXONOMY_FAIL" 

export const DELETE_PROVIDER_TAXONOMY_REQUEST = "DELETE_PROVIDER_TAXONOMY_REQUEST" 
export const DELETE_PROVIDER_TAXONOMY_SUCCESS = "DELETE_PROVIDER_TAXONOMY_SUCCESS" 
export const DELETE_PROVIDER_TAXONOMY_FAIL = "DELETE_PROVIDER_TAXONOMY_FAIL" 

export const GET_PROVIDER_SPECIALIZATION_REQUEST = "GET_PROVIDER_SPECIALIZATION_REQUEST" 
export const GET_PROVIDER_SPECIALIZATION_SUCCESS = "GET_PROVIDER_SPECIALIZATION_SUCCESS" 
export const GET_PROVIDER_SPECIALIZATION_FAIL = "GET_PROVIDER_SPECIALIZATION_FAIL" 

export const REGISTER_PROVIDER_SPECIALIZATION_REQUEST = "REGISTER_PROVIDER_SPECIALIZATION_REQUEST" 
export const REGISTER_PROVIDER_SPECIALIZATION_SUCCESS = "REGISTER_PROVIDER_SPECIALIZATION_SUCCESS" 
export const REGISTER_PROVIDER_SPECIALIZATION_FAIL = "REGISTER_PROVIDER_SPECIALIZATION_FAIL" 

export const DELETE_PROVIDER_SPECIALIZATION_REQUEST = "DELETE_PROVIDER_SPECIALIZATION_REQUEST" 
export const DELETE_PROVIDER_SPECIALIZATION_SUCCESS = "DELETE_PROVIDER_SPECIALIZATION_SUCCESS" 
export const DELETE_PROVIDER_SPECIALIZATION_FAIL = "DELETE_PROVIDER_SPECIALIZATION_FAIL" 

export const GET_PROVIDER_ADDRESS_REQUEST = "GET_PROVIDER_ADDRESS_REQUEST"
export const GET_PROVIDER_ADDRESS_SUCCESS = "GET_PROVIDER_ADDRESS_SUCCESS"
export const GET_PROVIDER_ADDRESS_FAIL = "GET_PROVIDER_ADDRESS_FAIL"

export const UPDATE_PROVIDER_ADDRESS_REQUEST = "UPDATE_PROVIDER_ADDRESS_REQUEST"
export const UPDATE_PROVIDER_ADDRESS_SUCCESS = "UPDATE_PROVIDER_ADDRESS_SUCCESS"
export const UPDATE_PROVIDER_ADDRESS_FAIL = "UPDATE_PROVIDER_ADDRESS_FAIL"
