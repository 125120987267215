import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate,useLocation, useParams } from "react-router-dom";
// import { useToasts } from "react-toast-notifications";
// import RPMViewNavbar from "../../RPMAdmin/component/RPMViewNavbar/RPMViewNavbar";
import ReactToPrint from 'react-to-print';
import SimpleHeader from "../../layouts/Header/SimpleHeader";
import TriageViewFormInput from "../Triage/weightScaleModals/TriageViewFormInput";
import { getPatientInfo } from "../../../redux/actions/patientAction";
import Loading from "../../layouts/Loading/Loading";
import PatientPlanHeader from "../../PatientDetails/PatientPlanHeader";

const ViewToPrintTriageV2 = () => {
    const {id, type} = useParams()
    const {loading, patientInfoDetail} = useSelector((state) => state.patientInfo);
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const location = useLocation();
    const componentRef = useRef();
    const list = location.state && location.state.list
    useEffect(() => {
        if(!list || list === null){
            navigate('/rpm-critical-patient')
        }
    }, [location.state]);

    useEffect(()=>{
        dispatch(getPatientInfo({patientId: id}));
    },[id])

    const generatePageNum = ()=>{
        //let totalPages = Math.ceil(document.body.scrollHeight / 1123);  //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi, 
        let totalPages = Math.ceil(Object.keys(list ?? {}).length/2)+1
        for (let i = 1; i <= totalPages; i++) {
          let pageNumberDiv = document.createElement("div");
          let pageNumber = document.createTextNode("Page " + i + " of " + totalPages);
          pageNumberDiv.classList.add('page-num-div')
          pageNumberDiv.style.position = "absolute";
          pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - 40px)"; //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
          pageNumberDiv.style.height = "16px";
          pageNumberDiv.appendChild(pageNumber);
          let parentDiv = document.getElementById("view-container");
          let originalDiv = document.getElementById("content");
          parentDiv.insertBefore(pageNumberDiv,originalDiv.nextSibling)
          pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + 100px))";
          pageNumberDiv.style.backgroundColor = "#DBDBDB"
          pageNumberDiv.style.paddingBottom = '24px'
          pageNumberDiv.style.borderRadius = "6px"
        }
}
useEffect(()=>{
    generatePageNum()
},[list])


    
  return (
    <>
           <div className="view-container">
                {/* <SidebarComponent sidebarOpen={sidebarOpen} /> */}
                <div id="content" className="no-hadow">
                    <div
                        className="view-container" id="view-container"
                        ref={componentRef}
                        style={{
                            padding: "20px",
                            overflowY: "auto",
                        }}>
                            {loading ?
                    <Loading/>
                    :
                    <>
                     <PatientPlanHeader patientInfoDetail={patientInfoDetail} id={id} title={`${type} Report`} readingDetails={list?.readingDetails}/>
                            <div className="d-flex justify-content-between w-100 py-2">
                        <h4 className="Ish px-3">Answers</h4>
                        <div className="d-flex noprint">
                            <div>
                            <button className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}>Back</button>
                            </div>
                            <div>
                        <ReactToPrint
                        trigger={() => <button className="btn pdf-button btn-sm mx-2">Print as PDF</button>}
                        content={() => componentRef.current}
                        />
                        </div>
                        </div>
                        </div>  
                        

                        <div className="list-print-div" style={{counterIncrement:'page'}}>
                            {Object.values(list ?? {})?.map((ques,index)=>((
                                //below condition to ignore the reading details to questions
                                !Array.isArray(ques)  ?
                        <div className="bg-light w-100 mb-3 shadow p-4 no-hadow mt-4" key={index}
                        style={{pageBreakAfter: index % 2 == 0 ? 'always' : 'avoid'}}
                        >
                        <h4 className="form_heading fw-bold">{ques?.name}</h4>
                        <TriageViewFormInput ques={ques}/>
                      
                      </div> : null
                            )))}
                        </div>
                        </>
                        }
                    </div>
                </div>
            </div>  
        </>
  )
}

export default ViewToPrintTriageV2