import { useState } from "react"
import { Button, Tabs, Input, Dropdown, Radio, Space, Empty } from "antd"
import { CloseOutlined, DownOutlined } from "@ant-design/icons"
import SortUp from "../../assets/bxSort-up.svg"
import ViewNotesCard from "./ViewNotesCard"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getInternalNotesAction } from "../../redux/actions/internalNotesAction"
import { useParams } from "react-router-dom"
import Loading from "../layouts/Loading/Loading"

const { Search } = Input

const InternalNotesList = ({ handleViewNoteFunc, setEditNote }) => {
  const { patientId } = useParams()
  const [AllNotes, setAllNotes] = useState([])
  const [filteredNotes, setFilteredNotes] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const [filterBy, setFilterBy] = useState("")

  const dispatch = useDispatch()

  //selectors
  const { loading, internalNotes } = useSelector(state => state.getInternalNotes)

  useEffect(() => {
    if (!loading) {
      dispatch(getInternalNotesAction({ patientId }))
    }
  }, [])

  useEffect(() => {
    setAllNotes(internalNotes?.details)
  }, [internalNotes])

  useEffect(() => {
    let filtered = AllNotes && [...AllNotes]
    if (filterBy === "sortByDate") {
      filtered = filtered?.sort((a, b) => new Date(a.date) - new Date(b.date))
    } else {
      filtered = AllNotes && [...AllNotes]?.sort((a, b) => new Date(b.date) - new Date(a.date))
    }

    // search query
    if (searchTerm) {
      filtered = filtered.filter(N => {
        return N.concern.toLowerCase().includes(searchTerm)
      })
    }
    setFilteredNotes(filtered)
  }, [searchTerm, filterBy, AllNotes])

  const handleSearch = term => {
    setSearchTerm(term?.toLowerCase())
  }

  const handleFilter = e => {
    setFilterBy(e.target.value)
    setShowDropdown(false)
  }

  const items = [
    {
      key: 1,
      label: "All",
      children: filteredNotes?.map((note, index) => (
        <ViewNotesCard
          key={index}
          note={note}
          openIndivNote={false}
          handleViewNoteFunc={handleViewNoteFunc}
          setEditNote={setEditNote}
        />
      ))
    },
    {
      key: 2,
      label: "Bookmark",
      children: filteredNotes
        ?.filter(item => item.bookMark)
        ?.map((note, index) => (
          <ViewNotesCard
            key={index}
            note={note}
            openIndivNote={false}
            handleViewNoteFunc={handleViewNoteFunc}
            setEditNote={setEditNote}
          />
        ))
    },
    {
      key: 3,
      label: "Important",
      children: filteredNotes
        ?.filter(item => item.important)
        ?.map((note, index) => (
          <ViewNotesCard
            key={index}
            note={note}
            openIndivNote={false}
            handleViewNoteFunc={handleViewNoteFunc}
            setEditNote={setEditNote}
          />
        ))
    }
  ]

  const operations = (
    <div className="mb-2 flex justify-center items-center gap-3">
      <Search
        size="large"
        placeholder="Search Notes"
        allowClear
        onSearch={value => handleSearch(value)}
        onChange={e => handleSearch(e.target.value)}
        style={{ width: 300 }}
      />
      <div className="flex">
        <Dropdown
          open={showDropdown}
          onOpenChange={() => setShowDropdown(!showDropdown)}
          menu={{ items: [] }}
          placement="bottomRight"
          trigger={["click"]}
          dropdownRender={_ => (
            <div className="bg-[#fafafa] rounded-lg shadow-md">
              <div className="">
                <Radio.Group
                  onChange={e => handleFilter(e)}
                  value={filterBy === "" ? null : filterBy}>
                  <Space direction="vertical" className="gap-0">
                    <Radio.Button className="w-full" value="sortByDate">
                      Sort by date
                    </Radio.Button>
                    {/* <Radio.Button value="sortByPerson" className="w-full">
              Sort by person who created
            </Radio.Button> */}
                  </Space>
                </Radio.Group>
              </div>
            </div>
          )}>
          <Button size="large" className="flex justify-center items-center gap-2">
            <img src={SortUp} alt="up" />
            <DownOutlined />
          </Button>
        </Dropdown>
        {filterBy ? (
          <Button size="large" className="flex justify-center items-center">
            <CloseOutlined onClick={() => setFilterBy("")} />
          </Button>
        ) : null}
      </div>
    </div>
  )

  return loading ? (
    <Loading />
  ) : AllNotes?.length ? (
    <div>
      <Tabs tabBarExtraContent={operations} items={items} />
    </div>
  ) : (
    <p className="text-center text-lg pt-4">This Patient doesn't have any Notes</p>
  )
}

export default InternalNotesList
