import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { singleCaretakerAction, caretakerPatientAction, getCaretakerFilterPatientsAction, saveCaretakerPatientAdvanceFilter } from "../../redux/actions/caretakerActions"

import { sortBy } from "../../utils/sortBy"
import Loading from "../layouts/Loading/Loading"
import CaretakerPatientTable from "./CaretakerPatientTable"
import CaretakerDetailHeader from "./CaretakerDetailHeader"
import CaretakerPatientFilter from "./CaretakerPatientFilter"
import moment from "moment"
import { resetFilterUsersAction } from "../../redux/actions/ccmActions"

const CaretakerDetail = () => {
  const { id: caretakerId } = useParams()
  const [sortColumn, setSortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })
  const [pageSize, setPageSize] = useState(50)
  const [sortedData, setSortedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [tempLoading, setTempLoading] = useState(false)
  const [isFilteredPatient, setIsFilteredPatient] = useState(false)
  const [filteredWithoutSearch, setFilteredWithoutSearch] = useState([])
  const [allPatientFiltered, setAllPatientFiltered] = useState([])
  const [filterUserType, setFilterUserType] = useState([])
  const filter = useSelector(state => state.caretakerFilterPatient)

  const { loading: singleCaretakerLoading, singleCaretaker } = useSelector(
    state => state.singleCaretaker
  )
  const { loading: patientsLoading, caretakerPatient } = useSelector(
    state => state.caretakerPatient
  )
  const { caretakerPatientAdvFilter:patientAdvFilter} = useSelector(state => state.caretakerPatientAdvFilter)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(singleCaretakerAction(caretakerId))
  }, [caretakerId, dispatch])

  useEffect(() => {
    if(!singleCaretaker) return
    if (singleCaretaker?.grantType !== "enrollment_specialist")
      dispatch(caretakerPatientAction(singleCaretaker?.id))
  }, [singleCaretaker])

  const handleSort = (path, order) => {
    setSortColumn({ path, order })
  }

  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }

  const showSearchedData = () => {
    let allPatients = []
    const query = searchQuery?.toLowerCase()
    if (filter?.getFilterUsersData  && patientAdvFilter.userType !== "outpatient") {
      if (searchQuery) {
        allPatients = filter?.getFilterUsersData?.filter(m => {
          const fullName = `${m?.firstName}${m?.middleName || ""}${m?.lastName}`.toLowerCase()
          const reversedFullName = `${m?.lastName}${m?.middleName || ""}${m?.firstName}`.toLowerCase()
          const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
          return (
            m?.firstName?.toLowerCase().includes(query) ||
            m?.middleName?.toLowerCase().includes(query) ||
            m?.lastName?.toLowerCase().includes(query) ||
            m?.email?.toLowerCase().includes(query) ||
            String(m?.mobileNumber)?.toLowerCase().includes(query) ||
            m?.userName?.toLowerCase().includes(query) ||
            m?.id?.toLowerCase().includes(query) ||
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
          )
        })
      } else {
        allPatients = filter?.getFilterUsersData
      }
    } else if (caretakerPatient !== undefined) {
      if (searchQuery) {
        let tempAllPatient = []
        if (sortedData?.length === 0) {
          tempAllPatient = isFilteredPatient ? filteredWithoutSearch : caretakerPatient
        } else {
          tempAllPatient = sortedData
        }
        allPatients = tempAllPatient.filter(m => {
          const fullName = `${m.firstName}${m?.middleName || ""}${m.lastName}`.toLowerCase()
          const reversedFullName = `${m.lastName}${m?.middleName || ""}${m.firstName}`.toLowerCase()
          const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
          return (
            m?.firstName?.toLowerCase().includes(query) ||
            m?.middleName?.toLowerCase().includes(query) ||
            m?.lastName?.toLowerCase().includes(query) ||
            m?.email?.toLowerCase().includes(query) ||
            String(m?.mobileNumber)?.toLowerCase().includes(query) ||
            m?.userName?.toLowerCase().includes(query) ||
            m?.id?.toLowerCase().includes(query) ||
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
          )
        })
      } else if (patientAdvFilter?.userType ) {
        allPatients = (isFilteredPatient ? filteredWithoutSearch : caretakerPatient)?.filter(
          patient => patient?.userType === patientAdvFilter?.userType
        )
      }  else if (isFilteredPatient) {
        allPatients = filteredWithoutSearch
      } else {
        allPatients = caretakerPatient
      }
    }
    allPatients = allPatients.map((patient) => {
      if (patient?.manualLog?.length) {
        let ccmLogCount = 0
        let rpmLogCount = 0
        let manualLogCount = 0
        if (moment(patient?.manualLogActiveTime).month() === moment().month() && moment(patient?.manualLogActiveTime).year() === moment().year()) {
          patient?.manualLog?.map((manual) => {
            if (manual?.careType === 'ccm' && moment(manual?.careDate).month() === moment().month() && moment(manual?.careDate).year() === moment().year()) {
              ccmLogCount += manual?.count
            }
            else if (manual?.careType === 'rpm' && moment(manual?.careDate).month() === moment().month() && moment(manual?.careDate).year() === moment().year()) {
              rpmLogCount += manual?.count
            }
          })
          patient = { ...patient, "rpmManualLogCount": rpmLogCount, "ccmManualLogCount": ccmLogCount }
        } else {
          patient = { ...patient, "rpmManualLogCount": rpmLogCount, "ccmManualLogCount": ccmLogCount, "manualLogCount": manualLogCount }
        }
      }
      if (patient?.rpmActiveDate?.length !== 0 && moment(patient?.rpmActiveDate).month() === moment().month()
        && moment(patient?.rpmActiveDate).year() === moment().year()) {
        return { ...patient, "monthlyLastestTotalReadingRPM": patient?.rpmReadingCount }
      } else {
        return { ...patient, "monthlyLastestTotalReadingRPM": 0 }
      }
    })
    let temp = []
    if (sortColumn.path === sortBy.AtoZ) {
      temp = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    if (sortColumn.path === sortBy.ZtoA) {
      temp = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    setSortedData(temp)
    setAllPatientFiltered(temp)
    if (temp?.length === caretakerPatient?.length)
      setFilterUserType(temp) // this is added to handle the advancce filter condition 
  }

  useEffect(() => {
    showSearchedData()
  }, [filter, caretakerPatient, searchQuery, patientAdvFilter]) //eslint-disable-line

  // useEffect(() => {
  //   let temp = []
  //   if (sortColumn.path === sortBy.AtoZ) {
  //     temp = sortedData?.sort((a, b) =>
  //       a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
  //     )
  //   }

  //   if (sortColumn.path === sortBy.ZtoA) {
  //     temp = sortedData?.sort((a, b) =>
  //       a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
  //     )
  //   }

  //   setSortedData(temp)
  // }, [sortedData, sortColumn])

  // useEffect(() => {
  //   showSearchedData()
  // }, [caretakerPatient, searchQuery]) //eslint-disable-line

  const applyFilters = (type, data) => {
    dispatch(saveCaretakerPatientAdvanceFilter(data))
    setCurrentPage(1)
    if (type === "normal" && data?.facilityId) {
      dispatch(getCaretakerFilterPatientsAction({ caretakerId, formData: data }))
    } else if (type === "normal" && !data?.facilityId) {
      setSortedData(filterUserType?.filter((patient) => patient?.userType === data?.userType))
    } else {
      getOnBoardingPatients()
    }
  }

  const resetFilters = async cb => {
    setIsFilteredPatient(false)
    setAllPatientFiltered([])
    await dispatch(resetFilterUsersAction())
    if (cb) {
      cb()
    }
  }
  const getOnBoardingPatients = async () => {
    await dispatch(resetFilterUsersAction()).then(() => {
      // dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
    })
  }
  const sortReadingCount = val => {
    const tempData = []
    setIsFilteredPatient(true)
    const countExistsArr = allPatientFiltered.filter(
      x => x?.rpmReadingCount !== null && x?.rpmReadingCount !== undefined && x?.rpmActiveDate && moment(x?.rpmActiveDate).month() === moment().month()
        && moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noCountArr = allPatientFiltered.filter(
      x => x?.rpmReadingCount === undefined || x?.rpmReadingCount === null
    )
    const ascCountArr = countExistsArr.sort((a, b) => a?.rpmReadingCount - b?.rpmReadingCount)
    const descCountArr = countExistsArr.sort((a, b) => b?.rpmReadingCount - a?.rpmReadingCount)
    if (val === "asc") {
      const sd = noCountArr.concat(ascCountArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    } else {
      const sd = descCountArr.concat(noCountArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    }
  }
  const sortLatestReading = val => {
    const tempData = []
    setIsFilteredPatient(true)
    const dateExistsArr = allPatientFiltered?.filter(
      x => x?.rpmActiveDate !== null && x?.rpmActiveDate !== undefined && x?.rpmActiveDate !== "" && x?.rpmActiveDate && moment(x?.rpmActiveDate).month() === moment().month()
        && moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noDateArr = allPatientFiltered?.filter(
      x => x?.rpmActiveDate === undefined && x?.rpmActiveDate === null && x?.rpmActiveDate === ""
    )
    const ascDateArr = dateExistsArr.sort(
      (a, b) => new Date(a?.rpmActiveDate) - new Date(b?.rpmActiveDate)
    )
    const descDateArr = dateExistsArr.sort(
      (a, b) => new Date(b?.rpmActiveDate) - new Date(a?.rpmActiveDate)
    )
    if (val === "asc") {
      const sd = noDateArr.concat(ascDateArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    } else {
      const sd = descDateArr.concat(noDateArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    }
  }
  const filterCriticalReading = val => {
    setIsFilteredPatient(true)
    const countExistsArr = allPatientFiltered.filter(
      x => x?.criticalStatus === true
    )
    setTempLoading(true)
    setTimeout(() => {
      setTempLoading(false)
      setFilteredWithoutSearch([...countExistsArr])
      setSortedData([...countExistsArr])
    }, 1500)

  }

  return singleCaretakerLoading ? (
    <Loading />
  ) : (
    caretakerId === singleCaretaker?.id && (
      <>
        <CaretakerDetailHeader singleCaretaker={singleCaretaker} />
        {singleCaretaker?.grantType !== "enrollment_specialist" && (
          <>
            <CaretakerPatientFilter
              caretakerId={caretakerId}
              pageSize={pageSize}
              setPageSize={setPageSize}
              onAscSort={handleSort}
              onDecSort={handleSort}
              setCurrentPage={setCurrentPage}
              // sortedData={sortedData}
              handleSearch={handleSearch}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setSortedData={setSortedData}
              //caretakerPatient={caretakerPatient}
              sortReadingCount={sortReadingCount}
              applyFilters={applyFilters}
              resetFilters={resetFilters}
              // filteredData={filteredData}
              setFilteredData={setFilteredData}
              sortLatestReading={sortLatestReading}
              filterCriticalReading={filterCriticalReading}
            />

            <CaretakerPatientTable
              // {...caretakerPatient}
              // caretakerPatient={caretakerPatient}
              sortedData={sortedData}
              loading={patientsLoading || tempLoading || filter?.loading}
              // sortColumn={sortColumn}
              // pageSize={pageSize}
              // setCurrentPage={setCurrentPage}
              // currentPage={currentPage}
            />
          </>
        )}
      </>
    )
  )
}

export default CaretakerDetail
