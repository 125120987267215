import React, { useState } from "react"

import { Modal, Row, Col, Input, Button, Select, Divider, Checkbox, Spin } from "antd"
import DatePicker from "react-datepicker"

import dayjs from "dayjs"

import { useToasts } from "react-toast-notifications"
// import imageCompression from "browser-image-compression"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"

import { useDispatch, useSelector } from "react-redux"
import { getCaretakerListAction, postCaretakerAction } from "../../redux/actions/caretakerActions"
import { PatternFormat } from "react-number-format"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { useParams } from "react-router-dom"

// import { base64ImageConvertor } from "../layouts/utils/imageConvertor"

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(100)
      .required("Enter First Name")
      .matches(/^[a-zA-Z\s]+$/, "First Name should only contain alphabets"),
      middleName: yup
      .string()
      .max(100)
      .matches(/^[a-zA-Z\s]*$/, "Middle Name should only contain alphabets"),
    lastName: yup
      .string()
      .max(100)
      .required("Enter Last Name")
      .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),

    dob: yup.string().required("Invalid DOB"),
    gender: yup.string().required("Invalid Gender"),

    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Invalid Email"),
    userName: yup.string().min(1).max(100).required("Invalid User Name"),
    mobileNumber: yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Mobile Number Must Be 10 Digits")
    .required("Invalid Phone Number"),

    street: yup.string().required("Invalid Street"),
    city: yup.string().required("Invalid City"),
    state: yup.string().required("Invalid State"),
    zipcode: yup.string().min(5).max(5).required("Invalid Zipcode")
  })
  .required()

const defaultValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  gender: "",
  email: "",
  userName: "",
  mobileNumber: "",
  street: "",
  city: "",
  state: "",
  zipcode: ""
}

// permission constants
const CAN_ADD_PATIENTS = "CAN_ADD_PATIENTS"
const CAN_ADD_MANUAL_TIME = "CAN_ADD_MANUALTIME"
const ENROLLMENT_SPECIALIST = "ENROLLMENT_SPECIALIST"

const permissionCheckboxOptions = [
  {
    label: "Can Add Patient",
    value: CAN_ADD_PATIENTS
  },
  {
    label: "Can Add Manual Time",
    value: CAN_ADD_MANUAL_TIME
  }
]

const enrollSplCheckboxOption = [
  {
    label: "Enrollment Specialist",
    value: ENROLLMENT_SPECIALIST
  }
]

// grant permission profile type
const getProfileType = permissions => {
  const canAddPatients = permissions.includes(CAN_ADD_PATIENTS)
  const canAddManualTime = permissions.includes(CAN_ADD_MANUAL_TIME)
  const enrollSpl = permissions.includes(ENROLLMENT_SPECIALIST)

  if (canAddPatients && canAddManualTime) return "caretaker_all"
  else if (!canAddPatients && canAddManualTime) return "caretaker_manual_log"
  else if (!canAddManualTime && canAddPatients) return "caretaker_register_patient"
  else if(enrollSpl) return "enrollment_specialist"
  else return "caretaker"
}

const AddCaretakerModal = ({ show, handleClose }) => {
  // const [picture, setPicture] = useState(null)
  const [grantPermissions, setGrantPermissions] = useState([])
  const [isFocused, setIsFocused] = useState(false);

  // const { userInfo } = useSelector(state => state.userInfo)
  const { error: caretakerError, loading } = useSelector(state => state.addCaretaker)

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const {orgId} = useParams()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onChangePermission = checkedOptions => {
    setGrantPermissions(checkedOptions)
  }

  // const handlePictureUpload = async e => {
  //   const file = e.target.files[0]
  //   if (file !== undefined) {
  //     const options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 1920,
  //       useWebWorker: true
  //     }
  //     try {
  //       const compressedFile = await imageCompression(file, options)
  //       const base64Image = await base64ImageConvertor(compressedFile)
  //       const profileImage = await base64Image.replace("data:image/png;base64,", "")
  //       setPicture(profileImage)
  //     } catch (error) {
  //       addToast("we are unable to process at this moment.", {
  //         appearance: "error",
  //         autoDismiss: true
  //       })
  //     }
  //   }
  // }

  const handleCancel = () => {
    handleClose()
    reset()
    setGrantPermissions([])
    // setPicture(null)
  }

  const onSubmit = async data => {
    const {
      firstName,
      middleName,
      lastName,
      userName,
      gender,
      dob,
      email,
      mobileNumber,
      street,
      zipcode,
      city,
      state
    } = data

    const addCaretaker = await dispatch(
      postCaretakerAction({
        orgId,
        firstName,
        middleName: middleName || null,
        lastName,
        userName,
        gender,
        dob: dayjs(dob).format("YYYY-MM-DD"),
        email,
        mobileNumber: mobileNumber?.replace(/[^\d]/g, ''),
        addressDetails: {
          street,
          zipcode,
          city,
          state
        },
        profileTypes: getProfileType(grantPermissions)
        // profileImage: picture,
      })
    )
    if (addCaretaker?.status === 200) {
      addToast("Caretaker add successfully", {
        appearance: "success",
        autoDismiss: true
      })
      handleCancel()
      dispatch(getCaretakerListAction(orgId))
    }
    // else {
    //   addToast("Unable to add caretaker, try after sometime", {
    //     appearance: "error",
    //     autoDismiss: true
    //   })
    // }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };
  return (
    <Modal
      open={show}
      width={700}
      footer={null}
      onOk={handleCancel}
      onCancel={handleCancel}
      title={<h4 className="text-2xl">Add Caretaker</h4>}>
      <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
        <Spin spinning={loading === true}>
          <Row gutter={16}>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-firstName">
                First Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-firstName"
                    status={errors.firstName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.firstName ? (
                <small className="text-danger capitalize">{errors.firstName.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-middleName">
                Middle Name
              </label>
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-middleName"
                    status={errors.middleName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.middleName ? (
                <small className="text-danger capitalize">{errors.middleName.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-lastName">
                Last Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-lastName"
                    status={errors.lastName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.lastName ? (
                <small className="text-danger capitalize">{errors.lastName.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-userName">
                Username <span className="text-danger">*</span>
              </label>
              <Controller
                name="userName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => 
                <Input 
                  size="large" 
                  id="input-userName" 
                  {...field}                   
                  onChange={e => /^[a-zA-Z0-9]*$/.test(e.target.value) && field.onChange(e)}
                  status={errors.userName ? "error" : undefined}
                />}
              />
              {errors.userName ? (
                <small className="text-danger capitalize">{errors.userName.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-gender">
              Gender at the time of birth <span className="text-danger">*</span>
              </label>
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="large"
                    className="w-100"
                    status={errors.gender ? "error" : undefined}
                    options={[
                      {
                        value: "male",
                        label: "Male"
                      },
                      {
                        value: "female",
                        label: "Female"
                      }
                    ]}
                    {...field}
                  />
                )}
              />
              {errors.gender ? (
                <small className="text-danger capitalize block">{errors.gender.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-dob">
                DOB <span className="text-danger">*</span>
              </label>
              <Controller
                name="dob"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={date => field.onChange(date.toISOString())}
                    dateFormatCalendar={"MMM yyyy"}
                    maxDate={Date.now()}
                    showPopperArrow={false}
                    placeholderText="Date of Birth"
                    minDate={dayjs(new Date()).subtract(150, "years")._d}
                    withPortal
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    className={`border p-3 rounded w-100 h-[40px] ${
                      errors.dob ? "border-danger" : ""
                    }`}
                    {...field}
                  />
                )}
              />
              {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
            </Col>

            <Col className="mb-4" xs={24} md={12} lg={16}>
              <label className="mb-1" htmlFor="input-email">
                Email <span className="text-danger">*</span>
              </label>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    type="email"
                    id="input-email"
                    status={errors.email ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.email ? (
                <small className="text-danger capitalize">{errors.email.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-mobileNumber">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="mobileNumber"
                rules={{ required: true }}
                render={({ field }) => {
                  const {ref, ...rest} = field
                return (
                    <PatternFormat
                      format="(###) ###-####"
                      className={`formatted-mobile-input flex px-[11px] py-[7px] text-[1rem] w-full  border border-${
                        errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                      }  rounded-lg focus:outline-none`}
                      id="input-mobileNumber"
                      {...rest}
                      onChange={e => rest.onChange(handleMobileChange(e))}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                )}
               }
              />
              {errors.mobileNumber ? (
                <small className="text-danger capitalize">{errors.mobileNumber.message}</small>
              ) : null}
            </Col>

            <Divider orientation="left">Address</Divider>

            <Col className="mb-4" xs={24} md={12} lg={16}>
              <label className="mb-1" htmlFor="input-street">
                Street <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="street"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-street"
                    status={errors.street ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.street ? (
                <small className="text-danger capitalize">{errors.street.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={8}>
              <label className="mb-1" htmlFor="input-zipcode">
                Zipcode <span className="text-danger">*</span>
              </label>
              <Controller
                name="zipcode"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    type="number"
                    id="input-zipcode"
                    status={errors.zipcode ? "error" : undefined}
                    {...field}
                    onChange={e => /^\d{0,5}$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.zipcode ? (
                <small className="text-danger capitalize">{errors.zipcode.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={12}>
              <label className="mb-1" htmlFor="input-city">
                City <span className="text-danger">*</span>
              </label>
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-city"
                    status={errors.city ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.city ? (
                <small className="text-danger capitalize">{errors.city.message}</small>
              ) : null}
            </Col>
            <Col className="mb-4" xs={24} md={12} lg={12}>
              <label className="mb-1" htmlFor="input-state">
                State <span className="text-danger">*</span>
              </label>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-state"
                    status={errors.state ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.state ? (
                <small className="text-danger capitalize">{errors.state.message}</small>
              ) : null}
            </Col>

            <Divider orientation="left">Permissions</Divider>
            <Col className="mb-4" xs={24}>
              <div className="inline-flex flex-wrap gap-3">
                <Checkbox.Group
                  options={permissionCheckboxOptions}
                  value={grantPermissions}
                  onChange={onChangePermission}
                />
              </div>
              <Checkbox.Group
                className="ml-2"
                options={enrollSplCheckboxOption}
                value={grantPermissions}
                onChange={onChangePermission}
              />
            </Col>

            {/* <Col className="mb-4" xs={24}>
            <input onChange={handlePictureUpload} type="file" accept=".jpg,.jpeg,.png" />
          </Col> */}
            {caretakerError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-medium">{caretakerError}</p>
              </Col>
            ) : null}
          </Row>
        </Spin>
        <div className="mt-4 flex flex-wrap gap-3">
          <Button size="large" type="primary" htmlType="submit" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
          <Button size="large" danger onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddCaretakerModal
