import { deviceDetect } from "react-device-detect"
import packageJson from "../../package.json"

const getDeviceModel = () => {
  const info = deviceDetect(window?.navigator?.userAgent)
  if (info?.isMobile) {
    return `${info?.model} ${info?.osVersion}`
  }
  return `${info?.browserName} ${info?.browserMajorVersion}`
}

export const deviceInfo = () => ({
  appVersion: packageJson?.version,
  deviceId: "1234567891",
  deviceModel: getDeviceModel()
})