import React from "react"
import RPMAdminPatient from "./RPMAdminPatient/RPMAdminPatient"

const RPMAdminComponent = () => {
  return (
    <div id="content">
      <RPMAdminPatient isFromAddPatient />
    </div>
  )
}

export default RPMAdminComponent
