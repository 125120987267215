import React, { useEffect, useState } from "react"
import { Button, Checkbox, Input, Space, Tabs } from "antd"
import ProTable from "@ant-design/pro-table"
import { useParams } from "react-router-dom"

import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import { useSelector, useDispatch } from "react-redux"
import CaretakerAssignedDropdown from "./CaretakerAssignedDropdown"
import { CaretakerTypes, OnBoardedStage } from "./Types"

const OnboardedPatient = props => {
  const { columns, selfOnboardedPatients, patientLoading } = props
  console.log(selfOnboardedPatients)
  const { orgId, orgName } = useParams()

  const { isDark } = useSelector(state => state.themeConfig)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchPatients, setSearchPatients] = useState([])
  const [selectedEntireRowData, setSelectedEntireRowData] = useState([])

  const [selectedCaretakers, setSelectedCaretakers] = useState()
  const [caretakerAssignedType, setCaretakerAssignedType] = useState()
  const [onBoardedSearchQuery, setOnBoardedSearchQuery] = useState()
  const [filteredPatients, setFilteredPatients] = useState([])

  const handleOnboardedSearch = e => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setOnBoardedSearchQuery(queryWithoutSpace)
  }

  useEffect(() => {
    // @ts-expect-error
    setSearchPatients(filterPatients(filteredPatients, onBoardedSearchQuery))
  }, [onBoardedSearchQuery])

  useEffect(() => {
    let filtered = selfOnboardedPatients

    if (selectedCaretakers) {
      if (caretakerAssignedType === CaretakerTypes.NoCaretakerAssigned) {
        filtered = filtered.filter(
          patient => !patient.caretakerIds === null || !patient?.caretaker?.length
        )
      } else if (
        caretakerAssignedType === CaretakerTypes.MultipleCaretaker &&
        !selectedCaretakers.length
      ) {
        filtered = filtered.filter(patient => patient?.caretaker?.length)
      } else if (caretakerAssignedType === CaretakerTypes.MultipleCaretaker) {
        filtered = filtered?.filter(
          patient =>
            selectedCaretakers.every(caretakerChecked =>
              patient.caretakerIds?.includes(caretakerChecked.value)
            ) && patient.caretaker?.length >= selectedCaretakers.length
        )
      }
    }
    setFilteredPatients(filtered)
  }, [selectedCaretakers, patientLoading])

  useEffect(() => {
    setFilteredPatients(selfOnboardedPatients)
  }, [patientLoading, selfOnboardedPatients])
  const filterPatients = (patients, query) => {
    if (!query) return patients

    const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()

    return patients.filter(p => {
      const fullName = `${p.firstName}${p.middleName || ""}${p.lastName}`.toLowerCase()
      const reversedFullName = `${p.lastName}${p.middleName || ""}${p.firstName}`.toLowerCase()

      return (
        p.firstName?.toLowerCase().includes(query) ||
        p.middleName?.toLowerCase().includes(query) ||
        p.lastName?.toLowerCase().includes(query) ||
        p.email?.toLowerCase().includes(query) ||
        p.id?.toLowerCase().includes(query) ||
        String(p.phone)
          ?.toLowerCase()
          .includes(query || "") ||
        fullName.includes(trimmedSearchValue || "") ||
        reversedFullName.includes(trimmedSearchValue || "")
      )
    })
  }

  const onCaretakerSelection = (caretakers, caretakerAssigned) => {
    setCaretakerAssignedType(caretakerAssigned)
    setSelectedCaretakers(caretakers)
  }

  const handleSelectAll = selected => {
    // @ts-expect-error
    setSelectedRowKeys(selected ? filteredPatients?.map(r => r.id) : [])
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnTitle: (
      <Checkbox
        checked={selectedRowKeys?.length === selfOnboardedPatients?.length}
        indeterminate={
          selectedRowKeys?.length > 0 && selectedRowKeys.length < selfOnboardedPatients?.length
        }
        onChange={e => handleSelectAll(e.target.checked)}
      />
    )
  }
  const onCaretakerSelectionClear = () => {
    setCaretakerAssignedType("")
    setFilteredPatients(selfOnboardedPatients)
  }


  return (
    <ProTable
      className="onboarded-patients-table"
      // @ts-expect-error
      rowKey={record => record.id}
      columnsState={{
        persistenceKey: "pro-table-onboarded-patient-columns",
        persistenceType: "localStorage"
      }}
      dataSource={!onBoardedSearchQuery ? filteredPatients : searchPatients}
      columns={columns}
      toolbar={{
        actions: [
          <Input
            key="search"
            style={{ width: 241, height: 48 }}
            value={onBoardedSearchQuery}
            onChange={e => handleOnboardedSearch(e)}
            allowClear
            disabled={!selfOnboardedPatients?.length}
            placeholder="Search Patient"
            prefix={<SearchOutlined className="disable" />}
          />
        ]
      }}
      loading={patientLoading}
      options={{
        density: false,
        reload: false,
        fullScreen: false,
        setting: {
          children: (
            <Space.Compact
              className={`card ${isDark ? "dark" : "light"
                } flex md:flex-row max-md:flex-col max-md:mb-20`}
              style={{ borderWidth: 0, height: 48 }}>
              <Button
                style={{ width: 241, height: "inherit" }}
                className="flex place-items-center p-0">
                <span className="secondary text-xs px-4">Filter By</span>
                <div
                  className="border-l flex-1 flex items-center justify-center gap-2"
                  style={{
                    height: "inherit",
                    borderColor: isDark ? "#424242" : "rgba(0, 0, 0, 0.1)"
                  }}>
                  Column Visibility
                  <DownOutlined />
                </div>
              </Button>
              <Space.Compact
                style={{ height: "inherit", display: "flex" }}
                onClick={e => e.stopPropagation()}>
                <CaretakerAssignedDropdown
                  // caretakerAssigned={caretakerAssigned}
                  // setCaretakerAssigned={setCaretakerAssigned}
                  onCaretakerSelection={onCaretakerSelection}
                  onCaretakerSelectionClear={onCaretakerSelectionClear}
                />
              </Space.Compact>
            </Space.Compact>
          )
        }
      }}
      search={false}
      pagination={{
        defaultPageSize: 10,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
      }}
      scroll={{ x: "max-content" }}
    />
  )
}

export default OnboardedPatient
