import React, { useState, useEffect } from "react"

import { Modal, Input, Radio, Row, Col, Button } from "antd"
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"

import moment from "moment"
import DatePicker from "react-datepicker"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  getProviderList,
  providerNPIAction,
  addProviderAction,
  addPractitionerAction,
  clearNpiAction,
  getProviderTaxonomyAction,
  getProviderSpecializationAction
} from "../../../redux/actions/providerAction"

import PrimaryProviderSelect from "./PrimaryProviderSelect"

import { useToasts } from "react-toast-notifications"
import imageCompression from "browser-image-compression"
import { base64ImageConvertor } from "../../layouts/utils/imageConvertor"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"

const alphaRegex = /^[a-zA-Z]+$/

const FIRST_NAME_ERR_MSG = "First Name should contain alphabets only"
const MIDDLE_NAME_ERR_MSG = "Middle Name should contain alphabets only"
const LAST_NAME_ERR_MSG = "Last Name should contain alphabets only"

const MOBILE_MIN_ERR_MSG = "Mobile Number must be at least 10 characters"
const MOBILE_MAX_ERR_MSG = "Mobile Number must be at most 10 characters"

const INVALID_USERNAME = "Invalid User Name"
const INVALID_DOB = "Invalid DOB"
const INVALID_GENDER = "Invalid Gender"
const INVALID_NPI = "Invalid NPI"
const INVALID_CITY = "Invalid City"
const INVALID_STATE = "Invalid State"
const INVALID_STREET = "Invalid Street"
const INVALID_ZIPCODE = "Invalid Zipcode"
const INVALID_EMAIL = "Invalid Email"
const INVALID_SPECIALIZATION = "Invalid Specialization"
const INVALID_PROVIDERTYPE = "Invalid Provider Type"
const INVALID_MOBILENUMBER = "Invalid Mobile Number"
const INVALID_TAXONOMY = "Invalid Taxonomy"
const INVALID_FIRSTNAME = "Invalid First Name"
const INVALID_LASTNAME = "Invalid Last Name"

const schema = yup
  .object({
    username: yup.string().min(1).max(100).required(INVALID_USERNAME),
    // middleName: yup.string(),
    dob: yup.string().required(INVALID_DOB),
    gender: yup.string().required(INVALID_GENDER),
    npi: yup.string().min(10).max(10).required(INVALID_NPI),
    city: yup.string().required(INVALID_CITY),
    state: yup.string().required(INVALID_STATE),
    street: yup.string().required(INVALID_STREET),
    zipcode: yup.string().min(5).max(5).required(INVALID_ZIPCODE),
    //address: yup.string().required("Invalid Address"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, INVALID_EMAIL).required(INVALID_EMAIL),
    //specialty: yup.string().required("Invalid Specialty"),
    specializations: yup.array()
    .of(yup.object().shape({ specialization: yup.string().required(INVALID_SPECIALIZATION) })).min(1),
    //mailingCity: yup.string().required("Invalid Mailing City"),
    providerType: yup.string().required(INVALID_PROVIDERTYPE),
    //mailingState: yup.string().required("Invalid Mailing State"),
    //mailingZipcode: yup.string().min(5).max(5).required("Invalid Mailing Zipcode"),
    //mailingAddress: yup.string().required("Invalid Mailing Address"),
    mobileNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, INVALID_MOBILENUMBER)
      .required(INVALID_MOBILENUMBER),
    taxonomie: yup
      .array()
      .of(yup.object().shape({ taxonomy: yup.string().required(INVALID_TAXONOMY) })).min(1),
    firstName: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .matches(alphaRegex, FIRST_NAME_ERR_MSG)
      .required(INVALID_FIRSTNAME),
    middleName: yup
      .string()
      .trim()
      .test("checkMiddleName", "", (value, { createError, path }) => {
        if (value.length && !alphaRegex.test(value)) {
          return createError({
            path,
            message: MIDDLE_NAME_ERR_MSG
          })
        } else {
          return true
        }
      }),
    lastName: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .matches(alphaRegex, LAST_NAME_ERR_MSG)
      .required(INVALID_LASTNAME),
  })
  .required()

const defaultValues = {
  npi: "",
  email: "",
  gender: "",
  dob:"",
  city: "",
  state: "",
  street:"",
  //address: "",
  zipcode: "",
  username: "",
  lastName: "",
  // specialty: "",
  specializations: [{specialization: ""}],
  firstName: "",
  middleName: "",
  //mailingCity: "",
  //mailingState: "",
  mobileNumber: "",
  //mailingZipcode: "",
  //mailingAddress: "",
  providerType: "provider",
  taxonomie: [{ taxonomy: "" }]
}

const AddProvider = ({ showAddProvider, handleAddPatientClose }) => {
  const providerId = useParams()
  const [showError, setShowError] = useState("")
  const [providerNPI, setProviderNPI] = useState("")
  const [providerIds, setProviderIds] = useState([])
  const [profilePicture, setProfilePicture] = useState(null)
  const [providerType, setProviderType] = useState("provider")
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const {orgId} = useParams()

  const userInfo = useSelector(state => state.userInfo)
  const { error: dataError, loading } = useSelector(state => state.addProvider)
  const { providerNPI: getProviderNPI } = useSelector(state => state.providerNPI)
  const { loading:taxonomyLoading, data:taxonomy, error:taxonomyError } = useSelector(state => state.getProviderTaxonomyReducer)
  const { loading:specializationLoading, data:Specialization, error:SpecializationError } = useSelector(state => state.getProviderSpecializationReducer)

const filterTaxonomyOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
const filterSpecializationOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const {
    reset,
    control,
    handleSubmit,
    setValue: setFormValue,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema)
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "taxonomie",
    rules: { minLength: 1 }
  })

  const { fields:sFields, append:sAppend, remove:sRemove } = useFieldArray({
    control,
    name: "specializations",
    rules: { minLength: 1 }
  })
// console.log('provider,id', providerId)
//   useEffect(()=>{
//     dispatch(getProviderTaxonomyAction(providerId?.id))
//     dispatch(getProviderSpecializationAction(providerId?.id))
//   },[providerId?.id])


  useEffect(() => {
    if (errors?.gender) {
      document
        .getElementById("radio-gender")
        ?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    }
  }, [errors])

  useEffect(() => {
    if (getProviderNPI !== undefined) {
      // debugger
      if (getProviderNPI?.result_count === 0) {
        reset()
        setShowError("No Data Found")
      } else if (
        getProviderNPI?.Errors &&
        getProviderNPI?.Errors?.[0]?.description
      ) {
        setShowError(getProviderNPI?.Errors?.[0]?.description)
      } else {
        // eslint-disable-next-line
        if (!getProviderNPI?.Errors && getProviderNPI?.result_count !== 0) {
          setShowError("")
          if (getProviderNPI?.results?.[0]?.basic?.first_name)
            setFormValue("firstName", getProviderNPI?.results?.[0]?.basic?.first_name)
          if (getProviderNPI?.results?.[0]?.basic?.middle_name)
            setFormValue("middleName", getProviderNPI?.results?.[0]?.basic?.middle_name)
          if (getProviderNPI?.results?.[0]?.basic?.last_name)
            setFormValue("lastName", getProviderNPI?.results?.[0]?.basic?.last_name)
          if (getProviderNPI?.results?.[0]?.basic?.gender) {
            if (getProviderNPI?.results?.[0]?.basic?.gender === "M")
              setFormValue("gender", "male")
            else if (getProviderNPI?.results?.[0]?.basic?.gender === "F")
              setFormValue("gender", "female")
          }
          if (getProviderNPI?.results?.[0]?.number)
            setFormValue("npi", getProviderNPI?.results?.[0]?.number)
          if (getProviderNPI?.results?.[0]?.taxonomies) {
            const list = []
            const specializList = []
            for (let i = 0; i < getProviderNPI?.results?.[0]?.taxonomies.length; i++) {
              list.push({ taxonomy: getProviderNPI?.results?.[0]?.taxonomies?.[i]?.code })
              specializList.push({specialization: getProviderNPI?.results?.[0]?.taxonomies?.[i]?.desc });
            }
            setFormValue("taxonomie", list)
            setFormValue("specializations", specializList);
          }
          if (getProviderNPI?.results?.[0]?.basic?.credential)
            setFormValue("specializations", getProviderNPI?.results?.[0]?.basic?.credential)
          if (getProviderNPI?.results?.[0]?.addresses?.[0]?.address_1)
            setFormValue("street", getProviderNPI?.results?.[0]?.addresses?.[0]?.address_1)
          if (getProviderNPI?.results?.[0]?.addresses?.[0]?.city)
            setFormValue("city", getProviderNPI?.results?.[0]?.addresses?.[0]?.city)
          if (getProviderNPI?.results?.[0]?.addresses?.[0]?.state)
            setFormValue("state", getProviderNPI?.results?.[0]?.addresses?.[0]?.state)
          if (getProviderNPI?.results?.[0]?.addresses?.[0]?.postal_code)
            setFormValue(
              "zipcode",
              getProviderNPI?.results?.[0]?.addresses?.[0]?.postal_code?.slice(0,5)
            )
          // if (getProviderNPI?.results?.[0]?.addresses?.[1]?.address_1)
          //   setFormValue(
          //     "mailingAddress",
          //     getProviderNPI?.results?.[0]?.addresses?.[1]?.address_1
          //   )
          // if (getProviderNPI?.results?.[0]?.addresses?.[1]?.city)
          //   setFormValue("mailingCity", getProviderNPI?.results?.[0]?.addresses?.[1]?.city)
          // if (getProviderNPI?.results?.[0]?.addresses?.[1]?.state)
          //   setFormValue(
          //     "mailingState",
          //     getProviderNPI?.results?.[0]?.addresses?.[1]?.state
          //   )
          // if (getProviderNPI?.results?.[0]?.addresses?.[1]?.postal_code)
          //   setFormValue(
          //     "mailingZipcode",
          //     getProviderNPI?.results?.[0]?.addresses?.[1]?.postal_code
          //   )//
        } else if (getProviderNPI?.result?.Errors.length) {
          setShowError("No Data Found")
          reset()
        }
      }
    }
  }, [getProviderNPI])

  const handleCancel = () => {
    setShowError("")
    handleAddPatientClose()
    setProviderNPI("")
    setProviderType("provider")
    setProviderIds([])
    reset()
  }

  const handleProviderProviderByNPI = e => {
    e.preventDefault()
    // setNpiCall({value:{}, called:true})
    if (providerNPI !== "") {
      dispatch(providerNPIAction(providerNPI))
    }
  }

  const handleProviderRadio = e => setProviderType(e.target.value)

  const handleFileChange = async e => {
    const file = e.target.files[0]
    if (file !== undefined) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(file, options)
        const base64Image = await base64ImageConvertor(compressedFile)
        const profileImage = await base64Image.replace("data:image/png;base64,", "")
        await setProfilePicture(profileImage)
      } catch (error) {
        addToast("we are unable to process at this moment.", {
          appearance: "error",
          autoDismiss: true
        })
      }
    }
  }

  const onSubmit = async data => {
    const values = {
      email: data.email,
      mobileNumber: data?.mobileNumber?.replace(/[^\d]/g, ''),
      userName: data.username || `${data.firstName}${data?.middleName ?? ""}${data.lastName}`,
      firstName: data.firstName,
      middleName: data.middleName || null,
      lastName: data.lastName,
      dob: moment(data.dob).format("YYYY-MM-DD"),
      //profileImage: profilePicture,
      //orgId: userInfo?.userInfo?.orgId,
      gender: data.gender,
      npi: data.npi,
      // specality: data.specialty,
      countryCode: "US",
      taxonomies: data.taxonomie,
      specializations: data.specializations,
      profileTypes: providerType,
      // primaryAddress: [
      //   {
      //     address: `${data.address}, ${data.city}, ${data.state}, ${data.zipcode}`
      //   }
      // ],
      // mailingAddress: [
      //   {
      //     address: `${data.mailingAddress}, ${data.mailingCity}, ${data.mailingState}, ${data.mailingZipcode}`
      //   }
      // ],
      addressDetails: {
        street: data.street,
        city: data.city,
        state: data.state,
        //address: data.address,
        zipcode: data.zipcode
      },
      // providers: providerIds
    }

    if (providerType === "practitioner") {
      // if (!providerIds.length) {
      //   return alert("Primary Provider Is Required")
      // }

      const AddProvider = await dispatch(addPractitionerAction({values:{...values, providerIds}, orgId}))
      if (AddProvider !== undefined && AddProvider?.status === 200) {
        dispatch(getProviderList(orgId))
        addToast("Added Practitioner Successfully", {
          appearance: "success",
          autoDismiss: true
        })
        reset()
        setProviderType("provider")
        setProviderIds([])
        handleCancel()
        // setTimeout(()=>{
        // window.location.reload()
        // },1000)
        dispatch(clearNpiAction())
        
      } else {
        addToast("Error Adding Practitioner", {
          appearance: "error",
          autoDismiss: true
        })
      }
    } else {
      const AddProvider = await dispatch(addProviderAction(values, orgId))
      if (AddProvider !== undefined && AddProvider?.message === "Success") {
        dispatch(getProviderList(orgId))
        addToast("Added Provider Successfully", {
          appearance: "success",
          autoDismiss: true
        })
        reset()
        setProviderType("provider")
        setProviderIds([])
        handleCancel()
        // setTimeout(()=>{
        // window.location.reload()
        // },1000)
        dispatch(clearNpiAction())
        
      } else {
        addToast("Error Adding Provider", {
          appearance: "error",
          autoDismiss: true
        })
      }
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };
  return (
    <>
    <Modal
      width={700}
      footer={null}
      open={showAddProvider}
      onCancel={handleCancel}
      title={<h4 className="text-2xl capitalize">Add {providerType}</h4>}>
      <div className="pt-4">
        <Row gutter={16}>
          <Col xs={24} className="mb-4">
            <form onSubmit={handleProviderProviderByNPI}>
              <label className="mb-2" htmlFor="input-npi-provider">
                NPI Provider
              </label>
              <Input
                allowClear
                size="large"
                value={providerNPI}
                id="input-npi-provider"
                onChange={e => setProviderNPI(e.target.value)}
                addonAfter={
                  <div className="cursor-pointer" onClick={handleProviderProviderByNPI}>
                    Search
                  </div>
                }
              />

              {showError && showError.length && <p className="text-danger">{showError}</p>}
            </form>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[24, 16]}>
            <Col className="mb-4" xs={24} md={12} lg={12}>
              <label className="mb-2 block" htmlFor="radio-provider-type">
                Provider Type
                <span className="text-danger ml-1">*</span>
              </label>
              <Radio.Group value={providerType} onChange={handleProviderRadio}>
                <Radio value="provider">Provider</Radio>
                <Radio value="practitioner">Practitioner</Radio>
              </Radio.Group>
            </Col>

            <Col className="mb-4 flex" xs={24} md={12} lg={12}>
              <div className="ml-auto">
                <label className="mb-2 block" htmlFor="radio-gender">
                  Gender
                  <span className="text-danger ml-1">*</span>
                </label>
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Radio.Group id="radio-gender" {...field}>
                      <Radio value="male">Male</Radio>
                      <Radio value="female">Female</Radio>
                    </Radio.Group>
                  )}
                />
                {errors.gender ? (
                  <small className="text-danger block">{errors.gender.message}</small>
                ) : null}
              </div>
            </Col>
            {providerType === "practitioner" && (
              <Col xs={24} className="mb-4">
                <PrimaryProviderSelect setProviderIds={setProviderIds} />
              </Col>
            )}
            <Col xs={24} md={12} lg={8} className="mb-4">
              <label className="mb-2" htmlFor="input-firstName">
                First Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-firstName"
                    status={errors.firstName ? "error" : undefined}
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.firstName ? (
                <small className="text-danger">{errors.firstName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} lg={8} className="mb-4">
              <label className="mb-2" htmlFor="input-middleName">
                Middle Name
              </label>
              <Controller
                name="middleName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => 
                <Input 
                size="large" 
                id="middleName" 
                status={errors.middleName ? "error" : undefined}
                {...field}
                onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />}
              />
              {errors.middleName ? (
                <small className="text-danger">{errors.middleName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} lg={8} className="mb-4">
              <label className="mb-2" htmlFor="input-lastName">
                Last Name <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.lastName ? "error" : undefined}
                    size="large"
                    id="input-lastName"
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.lastName ? (
                <small className="text-danger">{errors.lastName.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-username">
                Username <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="username"
                rules={{ required: true }}
                render={({ field }) => 
                <Input 
                  size="large" 
                  id="input-username" 
                  {...field}  
                  onChange={e => /^[a-zA-Z0-9]*$/.test(e.target.value) && field.onChange(e)}
                  status={errors.username ? "error" : undefined}/>}
                />
              {errors.username ? (
                <small className="text-danger block">{errors.username.message}</small>
              ) : null}
            </Col>
            {/* <Col xs={24} md={12} lg={8} className="mb-4">
              <label className="mb-2" htmlFor="input-dob">
                Dob <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="dob"
                rules={{ required: true }}
                render={({ field }) => <Input size="large" type="date" id="input-dob" {...field}
                min={moment(new Date()).subtract(150, "years")._d}
                max={`${Date.now()}`}
                />}
              />
              {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
            </Col> */}
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-dob">
              DOB <span className="text-danger">*</span>
            </label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={date => field.onChange(date.toISOString())}
                  dateFormatCalendar={"MMM yyyy"}
                  maxDate={Date.now()}
                  showPopperArrow={false}
                  placeholderText="Date of Birth"
                  minDate={moment(new Date()).subtract(150, "years")._d}
                  withPortal
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  className={`border p-3 rounded w-100 h-[40px] ${
                    errors.dob ? "border-danger" : ""
                  }`}
                  {...field}
                />
              )}
            />
            {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
          </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-email">
                Email <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.email ? "error" : undefined}
                    size="large"
                    type="email"
                    id="input-email"
                    {...field}
                  />
                )}
              />
              {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-mobileNumber">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="mobileNumber"
                rules={{ required: true }}
                render={({ field }) => {
                  const {ref, ...rest} = field
                return (
                    <PatternFormat
                      format="(###) ###-####"
                      className={`formatted-mobile-input flex px-[11px] py-[7px] text-[1rem] w-full  border border-${
                        errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                      }  rounded-lg focus:outline-none`}
                      id="input-mobileNumber"
                      {...rest}
                      onChange={e => rest.onChange(handleMobileChange(e))}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                )}
               }
              />
              {errors.mobileNumber ? (
                <small className="text-danger">{errors.mobileNumber.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={24} className="mb-4">
              <label className="mb-2" htmlFor="input-npi">
                NPI <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="npi"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="number"
                    status={errors.npi ? "error" : undefined}
                    size="large"
                    id="input-npi"
                    {...field}
                  />
                )}
              />
              {errors.npi ? <small className="text-danger">{errors.npi.message}</small> : null}
            </Col>
            {/* <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-specialty">
                Specialty <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="specialty"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.specialty ? "error" : undefined}
                    size="large"
                    id="input-specialty"
                    {...field}
                  />
                )}
              />
              {errors.specialty ? (
                <small className="text-danger">{errors.specialty.message}</small>
              ) : null}
            </Col> */}
            {/* <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-address">
                Address <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="address"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.address ? "error" : undefined}
                    size="large"
                    id="input-address"
                    {...field}
                  />
                )}
              />
              {errors.address ? (
                <small className="text-danger">{errors.address.message}</small>
              ) : null}
            </Col> */}
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-city">
                Street <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="street"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.street ? "error" : undefined}
                    size="large"
                    type="street"
                    id="input-street"
                    {...field}
                  />
                )}
              />
              {errors.street ? <small className="text-danger">{errors.street.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-city">
                City <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="city"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.city ? "error" : undefined}
                    size="large"
                    type="city"
                    id="input-city"
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.city ? <small className="text-danger">{errors.city.message}</small> : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-state">
                State <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="state"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.state ? "error" : undefined}
                    size="large"
                    id="input-state"
                    {...field}
                    onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.state ? <small className="text-danger">{errors.state.message}</small> : null}
            </Col>
            <Col xs={24} md={12} lg={8} className="mb-4">
              <label className="mb-2" htmlFor="input-zipcode">
                Zipcode <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="zipcode"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="number"
                    status={errors.zipcode ? "error" : undefined}
                    size="large"
                    id="input-zipcode"
                    {...field}
                    onChange={e => /^\d{0,5}$/.test(e.target.value) && field.onChange(e)}
                  />
                )}
              />
              {errors.zipcode ? (
                <small className="text-danger">{errors.zipcode.message}</small>
              ) : null}
            </Col>
            {/* <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-mailingAddress">
                Mailing Address <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="mailingAddress"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.mailingAddress ? "error" : undefined}
                    size="large"
                    id="input-mailingAddress"
                    {...field}
                  />
                )}
              />
              {errors.mailingAddress ? (
                <small className="text-danger">{errors.mailingAddress.message}</small>
              ) : null}
            </Col> */}
            {/* <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-mailingCity">
                Mailing City <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="mailingCity"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.mailingCity ? "error" : undefined}
                    size="large"
                    id="input-mailingCity"
                    {...field}
                  />
                )}
              />
              {errors.mailingCity ? (
                <small className="text-danger">{errors.mailingCity.message}</small>
              ) : null}
            </Col> */}
            {/* <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-mailingState">
                Mailing State <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="mailingState"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.mailingState ? "error" : undefined}
                    size="large"
                    id="input-mailingState"
                    {...field}
                  />
                )}
              />
              {errors.mailingState ? (
                <small className="text-danger">{errors.mailingState.message}</small>
              ) : null}
            </Col> */}
            {/* <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-mailingZipcode">
                Mailing Zipcode <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="mailingZipcode"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="number"
                    status={errors.mailingZipcode ? "error" : undefined}
                    size="large"
                    id="input-mailingZipcode"
                    {...field}
                  />
                )}
              />
              {errors.mailingZipcode ? (
                <small className="text-danger">{errors.mailingZipcode.message}</small>
              ) : null}
            </Col> */}
            {/* <Col xs={24} className="mb-4">
              <label className="mb-2 block">Profile Image</label>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
                className="border p-2 rounded w-full"
              />
            </Col> */}
            <Col xs={24} className="mb-4">
              <label className="mb-2 flex items-center justify-between">
                <span className="text-lg mr-2">Taxonomies <span className="text-danger">*</span></span> 
                <Button
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => append({ taxonomy: "" })}
                />
              </label>
              {taxonomy && taxonomy?.length &&
              <>
              <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              // onChange={onChange}
              // onSearch={onSearch}
              filterOption={filterOption}
              options={taxonomy?.map((item, index)=>(
                {value:'', label:''}
              ))}
              />
              </>
              }
              {fields.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className={`flex items-center ${fields.length !== 1 ? "mt-3" : ""}`}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name={`taxonomie.${index}.taxonomy`}
                        render={({ field }) => (
                          <Input
                            size="large"
                            status={
                              errors.taxonomie && errors.taxonomie[index]?.taxonomy
                                ? "error"
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />

                      {fields.length !== 1 && (
                        <Button
                          shape="circle"
                          className="ml-3"
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(index)}
                        />
                      )}
                    </div>
                    {errors.taxonomie && errors.taxonomie[index]?.taxonomy ? (
                      <small className="text-danger">
                        {errors.taxonomie[index]?.taxonomy.message}
                      </small>
                    ) : null}
                  </div>
                )
              })}
            </Col>
            <Col xs={24} className="mb-4">
              <label className="mb-2 flex items-center justify-between">
                <span className="text-lg mr-2">Specializations <span className="text-danger">*</span></span>
                <Button
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => sAppend({ specialization: "" })}
                />
              </label>
              {sFields.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className={`flex items-center ${sFields.length !== 1 ? "mt-3" : ""}`}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name={`specializations.${index}.specialization`}
                        render={({ field }) => (
                          <Input
                            size="large"
                            status={
                              errors.specializations && errors.specializations[index]?.specialization
                                ? "error"
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />

                      {sFields.length !== 1 && (
                        <Button
                          shape="circle"
                          className="ml-3"
                          icon={<MinusCircleOutlined />}
                          onClick={() => sRemove(index)}
                        />
                      )}
                    </div>
                    {errors.specializations && errors.specializations[index]?.specialization ? (
                      <small className="text-danger">
                        {errors.specializations[index]?.specialization.message}
                      </small>
                    ) : null}
                  </div>
                )
              })}
            </Col>

            <Col xs={24}>
            {dataError !== undefined && typeof dataError === "string" && (
              <p className="text-danger text-center font-medium mt-4">{dataError}</p>
            )}

          {dataError !== undefined &&
            typeof dataError === "object" &&
            Object.values(dataError).map((value, index) => (
              <div key={index} className="text-center">
              <p className="text-danger">
                <small>{value}</small>
              </p>
              </div>
            ))}
            </Col>

            <Col xs={24}>
              <div className="flex flex-wrap gap-3">
                <Button size="large" type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
                <Button size="large" danger onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </Modal>
  
    </>
  )
}

export default AddProvider
