import React from "react"
import { useNavigate, NavLink, useLocation } from "react-router-dom"

import { Layout, Menu, Button, Grid } from "antd"
import { CloseOutlined, HomeOutlined, MenuUnfoldOutlined, SettingOutlined } from "@ant-design/icons"

import Logo from "../assets/fitpeo_logo.png"
import { useDispatch, useSelector } from "react-redux"
import { prevUserLogoutAction, userLogoutAction } from "../redux/actions/userActions"
import { RiShutDownLine } from "react-icons/ri"
import { Mixpanel } from "../utils/mixpanel"
import { BsBuilding } from "react-icons/bs"

const { Sider } = Layout
const { useBreakpoint } = Grid

const SidebarOrg = (props) => {
  const { sidebarHidden, sidebarCollapsed, setSidebarCollapsed, setSidebarHidden, style } = props

  const dispatch = useDispatch()
  const { isDark } = useSelector((state) => state.themeConfig)

  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()

  const activeURL = () => {
    const splitURL = location.pathname.split("/")
    if (splitURL.length > 2) {
      return `/${splitURL.filter(u => u.length).join("/")}`
    }
    return [location.pathname]
  }

  const handleToggleSidebar = () => {
    if (screens.lg) {
      setSidebarCollapsed(!sidebarCollapsed)
    } else {
      setSidebarHidden(false)
    }
  }
  const handleLogout = e => {
   // e.preventDefault()
    Mixpanel.track("Logout Clicked")
    Mixpanel.reset()
    dispatch(prevUserLogoutAction())
  }

  const LogoutItem = [
    {
      key: "/logout",
      label: "Logout",
      icon: <RiShutDownLine />
    }
  ]
  return (
    <Sider
      style={{
        ...style,
        zIndex: 100,
        boxShadow: screens.lg ? "none" : "0 0 20px rgba(0,0,0, 0.1)"
      }}
      theme="light"
      breakpoint="lg"
      trigger={null}
      hidden={sidebarHidden}
      collapsible={screens.lg}
      collapsed={sidebarCollapsed}
      collapsedWidth={screens.lg ? "60px" : 0}
      className={sidebarHidden ? "closed" : "open"}
      onCollapse={value => {
        setSidebarCollapsed(value)
      }}>
      <div className="sidebar-header" style={{ height: "64px", maxWidth: "100%" }}>
        {sidebarCollapsed ? (
          <Button shape="circle" icon={<MenuUnfoldOutlined />} onClick={handleToggleSidebar} />
        ) : (
          <NavLink to="/" style={{ margin: "auto" }}>
            <img className="logo img-fluid" src={Logo} alt="logo" />
          </NavLink>
        )}
        {!sidebarHidden && screens.lg === false && (
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            className="sidebar-toggler"
            onClick={() => setSidebarHidden(true)}
          />
        )}
      </div>
      <Menu
        style={{ overflow: "hidden", border: "none" }}
        className="sidebar-routes"
        mode="inline"
        items={[
            {
              key: "/",
              label: "Organization",
              icon: <BsBuilding/>
            },
            {
                key: "/settings",
                label: "Settings",
                icon: <SettingOutlined />
              }]}
        selectedKeys={activeURL()}
        triggerSubMenuAction="click"
        onClick={({ key }) => {
          navigate(key)
          if (!screens.lg) setSidebarHidden(true)
        }}
      />
      <Menu
        className="sidebar-logout"
        mode="inline"
        items={LogoutItem}
        onClick={handleLogout}
      />
    </Sider>
  )
}

export default SidebarOrg
