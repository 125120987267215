export const mapDeviceType = type => {
  switch (type) {
    case "glucose machine":
      return "glucose monitor"
    case "oximeter":
      return "pulse oximeter"
    case "blood pressure machine":
      return "bp monitor"
    default:
      return type
  }
}
