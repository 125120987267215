import React, { useState, useEffect } from "react"
import Loading from "../components/layouts/Loading/Loading"
import { Helmet } from "react-helmet"
import PlanView from "@fitpeo/fit-plan-viewer"
import { TempAxiosGet } from "../utils/TokenStorage"
import { convertCareplanToV2 } from "../utils/careplanVersioning"

const MobileCCM = () => {
  const [patientInfoDetail, setPatientInfoDetail] = useState(null)
  const [carePlanData, setCarePlanData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [firstCall, setFirstCall] = useState(false)
  const [providers, setProviders] = useState(null)
  const [practitioners, setPractitioners] = useState(null)
  const [icdCodes, setIcdCodes] = useState(null)

  // useEffect(() => {
  //     setLoading(true)
  //     const timer = setTimeout(() => {
  //         if (window.value !== undefined && window.token !== undefined && firstCall === false) {
  //                 //dispatch(getPatientInfo({ patientId: window.value.patientId }));
  //                 getPatientData(window.value.patientId)
  //                 getCcmPlan(window.value.patientId)
  //                 setFirstCall(true)
  //         }
  //     }, 1000);
  //     setLoading(false)
  //     return () => clearTimeout(timer);

  // }, [window.token]);

  useEffect(() => {
    setTimeout(() => {
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        getPatientData(window.value.patientId)
        setFirstCall(true)
      }
    }, 1000)
  }, [window.token]) //eslint-disable-line

  const getCcmPlan = async patientID => {
    const res = await TempAxiosGet(
      window.token,
      `/api/ccm/careplan/comprehensive/latest/${patientID}/fetch`
    )
    setCarePlanData(res?.details)

    if (!res?.details?.plan?.planIcds) getIcds(patientID)
    if (res?.details?.plan?.providers) {
      setProviders(res?.details?.plan?.providers)
      setPractitioners(res?.details?.plan?.practitioners)
    } else if (res?.details?.plan?.provider) {
      setProviders([`${res?.details?.plan?.provider?.providerFirstName && res?.details?.plan?.provider?.providerFirstName !== "" ? " " + res?.details?.plan?.provider?.providerFirstName : ""}
      ${res?.details?.plan?.provider?.providerMiddleName && res?.details?.plan?.provider?.providerMiddleName !== "" ? " " + res?.details?.plan?.provider?.providerMiddleName : ""} 
      ${res?.details?.plan?.provider?.providerLastName && res?.details?.plan?.provider?.providerLastName !== "" ? " " + res?.details?.plan?.provider?.providerLastName : ""}`])
    } else {
      getProvider(patientID)
    }
  }

  const getPatientData = async patientId => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/${patientId}/profile`)
    if (res?.status === 200) {
      setPatientInfoDetail(res?.details)
      await getCcmPlan(patientId)
      setLoading(false)
    }
  }

  const getIcds = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/diagnostic/${patientID}/fetch`)
    setIcdCodes(res?.details?.map(({ icdCode }) => icdCode))
  }

  const getProvider = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/monitoring/${patientID}/fetch`)
    let provider = []
    let practitioner = []

    if (res?.details?.ccm?.[0]) {
      provider = res?.details?.ccm?.[0]?.providerId ? [` ${res?.details?.ccm?.[0]?.providerfirstName}
      ${res?.details?.ccm?.[0]?.providerMiddleName && res?.details?.ccm?.[0]?.providerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.providerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.providerLastName}`] : []
    }
    if (res?.details?.ccm?.[0]) {
      practitioner = res?.details?.ccm?.[0]?.practitionerId ? [` ${res?.details?.ccm?.[0]?.practitionerfirstName}
      ${res?.details?.ccm?.[0]?.practitionerMiddleName && res?.details?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.practitionerLastName}`] : []
    }

    setProviders(provider)
    setPractitioners(practitioner)
  }

  return (
    <>
      <Helmet>
        <script>{`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData))
                // var content = {"token":"eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoiMzIwZmE1NDUtMDE1MS00NjQ2LThiYjMtMWNlZTcyMTdlYTBiIiwiZXhwIjoxNzA0Njk4MjAyLCJpYXQiOjE3MDQ2OTQ2MDIsInNjb3BlIjoiYWRtaW4ifQ.p1wmEjZKGNNB7LIW624cZcJiuWzsiYk164GN9QTfdAyuaqhY197rKmkWJ0t5axDyLFaQ8izUm1FwpL6ByZbB758-ZK5i2gL3fnb3CRLbE4kQ2Cx7EcgMJ9CV1AKf_ConxChTA5TfjB25pFUCwclBtWWRkUnckVzmT5if0MQvmxBg3UksBh9tAQSXgIUoHidlAjFLdQEwXr4zHz6T8b8qj8xvAZKJ6XQlWaQ8an_OxYfVKmx_EgiGuFau23lJnF-Twco5e9jnCyC8l_2sP09azbMct9MKDxKVdsJLc2cEVscmnHSVIEQHUctzFMrUSuFy1s7LuTx8-ywDsuITdV3elw", patientId:"5079b9b1-a968-4b63-9b29-e2ed8eb9345b"};
                
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token; 
                }`}</script>
      </Helmet>
      {loading & !carePlanData ? (
        <Loading />
      ) : (
        <PlanView
          type="ccm"
          planDate={carePlanData?.planDate}
          providers={providers}
          practitioners={practitioners}
          patientInfoDetail={patientInfoDetail}
          plan={convertCareplanToV2(carePlanData?.plan)}
          planIcds={carePlanData?.plan?.planIcds ?? icdCodes}
          addedBy={carePlanData?.plan?.addedBy}
        />
      )}
    </>
  )
}

export default MobileCCM
