import React, { useEffect, useState } from "react"
import { Modal, Input, Button, Select, Table } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { useParams } from "react-router-dom"
import {
  AddSpecialInstructionAction,
  // DeleteSpecialInstructionAction,
  EditSpecialInstructionAction,
  GetSpecialInstruction,
  GetSpecialInstructionHistory
} from "../../redux/actions/specialInstructionsActions"
import moment from "moment"
import { BsArrowLeft } from "react-icons/bs";

const { TextArea } = Input

const options = [
  { label: "last 5 edits", value: 5 },
  { label: "last 10 edits", value: 10 },
  { label: "last 15 edits", value: 15 },
  { label: "last 20 edits", value: 20 },
  { label: "last 25 edits", value: 25 },
  { label: "last 30 edits", value: 30 },
  { label: "last 35 edits", value: 35 },
  { label: "last 40 edits", value: 40 },
  { label: "last 45 edits", value: 45 },
  { label: "last 50 edits", value: 50 },
]

const SaveInst = ({ isAdd, open, clear, loading, preInst, handleClose, handleSave }) => {
  const [inputValue, setInputValue] = useState(open && "")
  const [wordsCount, setWordsCount] = useState(preInst?.trim()?.split(/\s+/)?.length ?? 0)

  const wordLimit = 100

  useEffect(() => {
    // clear input on close
    if (isAdd && clear) setInputValue("")
  }, [isAdd, clear])

  useEffect(() => {
    if (preInst) setInputValue(preInst)
    else setInputValue("")
  }, [preInst])

  const handleInputChange = e => {
    const value = e.target.value
    const words = value.trim().split(/\s+/)

    if (value === "") {
      setInputValue("")
      setWordsCount(0)
    } else if (words.length <= wordLimit) {
      setInputValue(value)
      setWordsCount(words.length)
    }
  }

  return (
    <>
      <TextArea
        placeholder="Add special instruction..."
        value={inputValue}
        onChange={handleInputChange}
        style={{
          height: 80,
          resize: "none",
          backgroundColor: "rgba(218, 218, 218, 0.2)"
        }}
      />
      <div className="flex justify-end opacity-75">
        <small>{`${wordsCount}/${wordLimit} words`}</small>
      </div>
      <div className="flex justify-end gap-2 mt-3">
        <Button className="w-[140px] h-[35px]" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="w-[140px] h-[35px]"
          type="primary"
          onClick={() => handleSave(inputValue)}
          loading={loading}>
          Save
        </Button>
      </div>
    </>
  )
}

const SpecialInstHistory = ({ specialInsHistoryData, showViewHostoryModal, setShowViewHostoryModal }) => {
  const [selectedCount, setSelectedCount] = useState(5)

  const columns = [
    {
      title: 'Modified On',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Modified By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Special Instruction Previously Added',
      dataIndex: 'prevInstruction',
      key: 'prevInstruction',
      className: 'max-w-[250px]',
    },
    {
      title: 'Special Instruction Changed To',
      dataIndex: 'instruction',
      key: 'instruction',
      className: 'max-w-[250px]',
    },
  ];

  const handleClose = () => {
    setShowViewHostoryModal(false)
  }
  return (
    <Modal
      title={<h4 className="text-xl">Special Instructions History</h4>}
      width={800}
      open={showViewHostoryModal}
      onCancel={handleClose}
      centered
      footer={null}
    >
      <div>
        <Select
          optionFilterProp="children"
          placeholder="Select Edit Count"
          size="large"
          id="countSelect"
          defaultValue={selectedCount}
          onChange={(value) => setSelectedCount(value)}
          options={options}
        />
        <Table
          scroll={{ x: "max-content" }}
          className="mt-2"
          columns={columns}
          dataSource={specialInsHistoryData?.slice(0, selectedCount)}
        />
        <div>
          <Button
            type="primary"
            size="large"
            icon={<BsArrowLeft />}
            className="w-[140px] flex justify-center items-center"
            onClick={handleClose}>
            Back
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const SpecialInstructions = ({ patientId, specialInstData, open, handleClose }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [edit, setEdit] = useState(false)
  // const [confirmDelete, setConfirmDelete] = useState(false)
  const [clear, setClear] = useState(0)
  const [showViewHostoryModal, setShowViewHostoryModal] = useState(false)
  const [specialInsHistoryData, setSpecialInsHistoryData] = useState([])

  useEffect(() => {
    setClear(Math.random())
    if (!open) {
      setEdit(false)
      // setConfirmDelete(false)
    }
  }, [open])

  const { loading: addLoading } = useSelector(state => state.addSpecialInstructions)
  const { loading: editLoading } = useSelector(state => state.editSpecialInstructions)
  const { loading: specialInsLoading, specialInstructionsHistory } = useSelector(state => state.GetSpecialInstructionsHistory)
  // const { loading: deleteLoading } = useSelector(state => state.deleteSpecialInstructions)

  useEffect(() => {
    if (specialInstructionsHistory) {
      let sortedData = [...specialInstructionsHistory]
      sortedData = sortedData?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))  // sort by date
      const data = sortedData?.map((item, index) => {
        return {
          key: index,
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          createdBy: `${item?.firstName}${(item?.middleName && (item?.middleName !== "")) ? " " + item?.middleName : ""} ${item?.lastName}`,
          prevInstruction: (index === sortedData?.length - 1) ? "-" : sortedData[index + 1]?.specialInstruction,
          instruction: item?.specialInstruction,
        }
      })

      setSpecialInsHistoryData(data)
    }
  }, [specialInstructionsHistory])

  useEffect(() => {
    dispatch(GetSpecialInstructionHistory(patientId))
  }, [patientId])

  const displayError = errorMsg => {
    addToast(errorMsg, {
      appearance: "error",
      autoDismiss: true
    })
  }

  const displaySuccess = successMsg => {
    addToast(successMsg, {
      appearance: "success",
      autoDismiss: true
    })
  }

  const addSplInst = async specialInstructions => {
    const { isDone, error } = await dispatch(
      AddSpecialInstructionAction({
        patientId: id,
        specialInstructions
      })
    )
    if (isDone) {
      dispatch(GetSpecialInstruction({ patientId: id }))
      dispatch(GetSpecialInstructionHistory(patientId))
      displaySuccess("Successfully Added!")
      handleClose()
    } else displayError(error)
  }

  const updateSplInst = async specialInstructions => {
    const { isDone, error } = await dispatch(
      EditSpecialInstructionAction({
        patientId: id,
        specialInstructions,
        instructionId: specialInstData?.id
      })
    )
    if (isDone) {
      dispatch(GetSpecialInstruction({ patientId: id }))
      dispatch(GetSpecialInstructionHistory(patientId))
      displaySuccess("Successfully Edited!")
      handleClose()
    } else displayError(error)
  }

  const initiateEdit = () => {
    setEdit(true)
  }

  const CancelEdit = () => {
    setEdit(false)
  }

  // const showConfirmDelete = () => {
  //   setConfirmDelete(true)
  // }

  // const hideConfirmDelete = () => {
  //   setConfirmDelete(false)
  // }

  // const handleConfirmDelete = async () => {
  //   const { isDone, error } = await dispatch(
  //     DeleteSpecialInstructionAction({
  //       patientId: id,
  //       instructionId: specialInstData?.id
  //     })
  //   )
  //   if (isDone) {
  //     dispatch(GetSpecialInstruction({ patientId: id }))
  //     displaySuccess("Successfully Deleted!")
  //     handleClose()
  //   } else displayError(error)
  // }

  return (
    <>
      <Modal title="Special Instruction" open={open} onCancel={handleClose} centered footer={null}>
        {!specialInstData && (
          <SaveInst
            isAdd={true}
            clear={clear}
            loading={addLoading}
            handleSave={addSplInst}
            handleClose={handleClose}
          />
        )}

        {specialInstData &&
          (edit ? (
            <SaveInst
              loading={editLoading}
              preInst={specialInstData?.specialInstructions}
              handleSave={updateSplInst}
              handleClose={CancelEdit}
            />
          ) : (
            // !confirmDelete && (
            <>
              <div
                style={{
                  border: "1px solid rgba(218, 218, 218, 1)",
                  borderRadius: 6,
                  padding: 8,
                  fontSize: 13
                }}>
                <span style={{ fontWeight: "600" }}>{specialInstData?.specialInstructions}</span>
                <hr className="my-2" />
                <div
                  className="flex justify-between mb-2"
                  style={{ color: "rgba(102, 107, 123, 1)" }}>
                  <span>
                    Noted on:{" "}
                    {moment(specialInstData?.lastModifiedDate).format("MM/DD/YYYY [at] hh A")}
                  </span>
                  <span className="capitalize">Noted by: {specialInstData?.lastModifiedName}</span>
                </div>
              </div>
              <div className="flex justify-end gap-2 mt-4">
                {/* <Button className="w-[140px] h-[35px]" onClick={showConfirmDelete}>
                  Delete
                </Button> */}
                <Button className="w-[140px] h-[35px]" type="default" onClick={() => setShowViewHostoryModal(true)}>
                  View History
                </Button>
                <Button className="w-[140px] h-[35px]" type="primary" onClick={initiateEdit}>
                  Edit
                </Button>
              </div>
            </>
            // )
          ))}

        {/* {specialInstData && confirmDelete && (
        <>
          <h2 className="text-lg">Are you sure you want to delete special instruction?</h2>
          <div className="flex justify-end gap-2 mt-4">
            <Button className="w-[140px] h-[35px]" onClick={hideConfirmDelete}>
              Cancel
            </Button>
            <Button
              className="w-[140px] h-[35px]"
              type="primary"
              onClick={handleConfirmDelete}
              loading={deleteLoading}>
              Delete
            </Button>
          </div>
        </>
      )} */}
      </Modal>
      <SpecialInstHistory
        specialInsHistoryData={specialInsHistoryData}
        showViewHostoryModal={showViewHostoryModal}
        setShowViewHostoryModal={setShowViewHostoryModal}
      />
    </>
  )
}

export default SpecialInstructions
