import { useEffect } from "react"
import { Form, Modal, Input } from "antd"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  editEligiblePatientAction,
  resetEditEligiblePatientAction
} from "../../../redux/actions/selfOnboardingAction"
import FloatInput from "../../../utils/FloatInput/FloatInput"

const INVALID_EMAIL = "Invalid email address"

// Define the validation schema
const schema = yup.object().shape({
  email: yup.string().required(INVALID_EMAIL).email(INVALID_EMAIL)
})

const UpdateEmail = props => {
  const { selectedPatient, closeModal } = props

  const { addToast } = useToasts()

  const dispatch = useDispatch()

  const { loading, error: apiErr } = useSelector(state => state.editEligiblePatient)

  useEffect(() => {
    if (!selectedPatient) return
    dispatch(resetEditEligiblePatientAction())
    setValue("email", selectedPatient?.email ?? "")
    clearErrors()
  }, [selectedPatient])

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    const res = await dispatch(
      editEligiblePatientAction({
        patientId: selectedPatient?.id,
        email: data?.email || null,
        mobileNumber: selectedPatient?.phone || null
      })
    )
    if (res?.isUpdated) {
      addToast("Updated Successfully", { appearance: "success", autoDismiss: true })
      closeModal()
    }
  }

  return (
    <Modal
      open={Boolean(selectedPatient)}
      onCancel={closeModal}
      title="Update Email"
      okText="Update"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={loading}>
      <Form onFinish={handleSubmit(onSubmit)} className="mt-4">
        <Form.Item validateStatus={errors.email ? "error" : ""} help={errors.email?.message}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FloatInput label="Email">
                <Input
                  size="large"
                  {...field}
                  onChange={e => {
                    field.onChange(e.target.value?.replace(/\s+/g, ""))
                  }}
                />
              </FloatInput>
            )}
          />
        </Form.Item>
      </Form>
      {apiErr && <span className="text-danger">{apiErr}</span>}
    </Modal>
  )
}

export default UpdateEmail
