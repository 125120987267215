import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

// import AddCareplan from "fit-plan-creator"
import AddCareplan from "@fitpeo/fit-plan-creator"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { getPatientInfo, getPatientMonitoringData, getPatientDiagnosticsData } from "../../../redux/actions/patientAction"
import { postRPMUserActions } from "../../../redux/actions/rpmAction"
import Loading from "../../layouts/Loading/Loading"
import RPMOnlyViewQuestion from "../component/RPMViewQuestion/RPMOnlyViewQuestion"

const RPMAdminPatient = ({ isFromAddPatient }) => {
  const { id, orgId, orgName } = useParams()
  const [answers, setAnswers] = useState([])
  const [diagnosticCodes, setDiagnosticCodes] = useState([]);
  const [devices, setDevices] = useState([]);
  const [providers, setProviders] = useState([])
  const [practitioners, setPractitioners] = useState([])

  const dispatch = useDispatch();
  const location = useLocation();

  const patientInfoDetail = {
    id: id,
    firstName: location.state?.name?.firstName,
    middleName: location.state?.name?.middleName,
    lastName: location.state?.name?.lastName,
    dob: location.state?.dob
  }

  // const navigate = useNavigate()
  const { addToast } = useToasts()

  // useSelector
  const userInfo = useSelector(state => state.userInfo);
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData);
  const { loading: icdCodesLoading, patientDiagnosticData } = useSelector(state => state.patientDiagnosticData);

  const UserName = `${userInfo?.userInfo.firstName} ${userInfo?.userInfo.middleName ? userInfo?.userInfo.middleName : "" } ${userInfo?.userInfo.lastName}`;
  const addingBy = { name: UserName, userType: "Admin" }
  const providerName = { providerFirstName: patientMonitoringData?.rpm[0].providerfirstName, providerMiddleName: patientMonitoringData?.rpm[0].providerMiddleName, providerLastName: patientMonitoringData?.rpm[0].providerLastName }

  useEffect(() => {
    if (patientMonitoringData?.rpm) {
      let providers = []
      let practitioners = []
      patientMonitoringData?.rpm?.map(item => {
        if (item.providerId) {
          providers.push(`${item?.providerfirstName}${(item?.providerMiddleName && item?.providerMiddleName !== "") ? " " + item?.providerMiddleName : ""} ${item?.providerLastName}`)
        }
        if (item.practitionerId) {
          practitioners.push(`${item?.practitionerfirstName}${(item?.practitionerMiddleName && item?.practitionerMiddleName !== "") ? " " + item?.practitionerMiddleName : ""} ${item?.practitionerLastName}`)
        }
      })
      setProviders(providers)
      setPractitioners(practitioners)
    }

  }, [patientMonitoringData])

  useEffect(() => {
    if (id) {
      dispatch(getPatientMonitoringData({ patientId: id }))
      dispatch(getPatientDiagnosticsData({ patientId: id }))
    }
  }, [dispatch, id])

  useEffect(() => {
    let codes = []
    patientDiagnosticData?.map((item) => {
      codes.push({ icdCode: item.icdCode, description: item.description })
    })

    setDiagnosticCodes(codes);
  }, [patientDiagnosticData])


  useEffect(() => {
    let device = [];
    patientMonitoringData?.rpm?.map((item) => {
      if (item.monitoringType === "rpm") {
        item.programs.map((itm) => {
          device.push(itm.programName)
        })
      }
    })
    setDevices(device);
  }, [patientMonitoringData]);

  const submitForm = async () => {
    dispatch(postRPMUserActions(patientInfoDetail?.id, { ...answers, addingBy: addingBy })).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        addToast("Treatement Plan Added Successfully", { appearance: "success", autoDismiss: true })
        // navigate("/ccm/new-patients");
        setTimeout(() => {
          setAnswers([])
          window.location.href = `/${orgId}/${orgName}/rpm/patients`
        }, 1000)
      } else {
        addToast("Unable To Add Care Plan!", { appearance: "error", autoDismiss: true })
      }
    })
  }

  useEffect(() => {
    if (answers.length) {
      submitForm()
    }
  }, [answers])

  return icdCodesLoading ? (
    <Loading />
  ) : (
    patientInfoDetail !== undefined &&
    (isFromAddPatient ? (
      <AddCareplan
        type="rpm"
        answers={answers}
        diagnosticData={patientDiagnosticData}
        addingBy={addingBy}
        providers={providers}
        practitioners={practitioners}
        setAnswers={setAnswers}
        patientInfoDetail={patientInfoDetail}
        navigateUrl={`/${orgId}/${orgName}/rpm/patients`}
        devices={devices}
      />
    ) : (
      <RPMOnlyViewQuestion
        patientInfoDetail={patientInfoDetail}
        diagnosticData={diagnosticCodes?.map(item => item.icdCode)}
        providers={providers}
        practitioners={practitioners}
      />
    ))
  )
}

export default RPMAdminPatient;
