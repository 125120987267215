import React, { useEffect, useState } from "react"

import { sortBy } from "../../utils/sortBy"
import ProviderHeader from "./ProviderHeader"
import ProviderNewTable from "./ProviderNewTable"
import Loading from "../layouts/Loading/Loading"

import { useDispatch, useSelector } from "react-redux"
import { getProviderList, resetProviderPatientAdvanceFilter } from "../../redux/actions/providerAction"
import { dashboarKPIAction } from "../../redux/actions/dashboardAction"
import { resetFilterUsersAction } from "../../redux/actions/ccmActions"
import { useParams } from "react-router-dom"

const ProviderComponent = () => {
  const [sortColumn, setSortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })
  const [pageSize, setPageSize] = useState(10)
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1) //eslint-disable-line

  const userInfo = useSelector(state => state.userInfo)
  const { loading, providerList } = useSelector(state => state.providerList)

  const dispatch = useDispatch()
  const {orgId} = useParams()

  useEffect(() => {
    dispatch(getProviderList(orgId))
    // dispatch(dashboarKPIAction())
  }, [dispatch])

    useEffect(() => {
      dispatch(resetFilterUsersAction())
      dispatch(resetProviderPatientAdvanceFilter())
  }, [])

  let sortedData
  let allprovider

  if (providerList !== undefined) allprovider = providerList

  if (providerList !== undefined) {
    if (searchQuery) {
      const query = searchQuery?.toLowerCase()
      allprovider = providerList.filter(m => {
        const fullName = `${m.firstName}${m?.middleName || ""}${m.lastName}`.toLowerCase()
        const reversedFullName = `${m.lastName}${m?.middleName || ""}${m.firstName}`.toLowerCase()
        const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
        return (
          m?.firstName?.toLowerCase().includes(query) ||
          m?.middleName?.toLowerCase().includes(query) ||
          m?.lastName?.toLowerCase().includes(query) ||
          m?.userName?.toLowerCase().includes(query) ||
          m?.email?.toLowerCase().includes(query) ||
          m?.npiNumber?.toLowerCase().includes(query) ||
          m?.id?.toLowerCase().includes(query) ||
          fullName.includes(trimmedSearchValue) ||
          reversedFullName.includes(trimmedSearchValue)
        )
      })
    }
  }

  const handleSort = (path, order) => {
    setSortColumn({ path, order })
  }

  if (sortColumn.path === sortBy.AtoZ) {
    sortedData = allprovider?.sort((a, b) =>
      a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
    )
  }

  if (sortColumn.path === sortBy.ZtoA) {
    sortedData = allprovider?.sort((a, b) =>
      a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
    )
  }
  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }

  return loading ? (
    <div className="text-center">
      <Loading />
    </div>
  ) : (
    <>
      <ProviderHeader
        pageSize={pageSize}
        userInfo={userInfo}
        onAscOrder={handleSort}
        onDescOrder={handleSort}
        setPageSize={setPageSize}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        setCurrentPage={setCurrentPage}
        onPatientCountSort={handleSort}
        setSearchQuery={setSearchQuery}
      />
      <div className="main-container">
        {providerList !== undefined && (
          <ProviderNewTable loading={loading} sortedData={sortedData} />
        )}
      </div>
    </>
  )
}

export default ProviderComponent
