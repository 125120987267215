import { axiosDelete, axiosGet, axiosPost } from "../axios"
import axios from "axios"
import {
  PROVIDER_ADD_FAIL,
  PROVIDER_ADD_REQUEST,
  PROVIDER_ADD_SUCCESS,
  PROVIDER_KPI_FAIL,
  PROVIDER_KPI_REQUEST,
  PROVIDER_KPI_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_NPI_FAIL,
  PROVIDER_NPI_REQUEST,
  PROVIDER_NPI_SUCCESS,
  PROVIDER_PATIENT_LIST_FAIL,
  PROVIDER_PATIENT_LIST_REQUEST,
  PROVIDER_PATIENT_LIST_SUCCESS,
  SINGLE_PROVIDER_FAIL,
  SINGLE_PROVIDER_REQUEST,
  SINGLE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_REQUEST,
  UPDATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_FAIL,
  GET_PROVIDER_NPI_REQUEST,
  GET_PROVIDER_NPI_SUCCESS,
  GET_PROVIDER_NPI_FAIL,
  UPDATE_PROVIDER_NPI_REQUEST,
  UPDATE_PROVIDER_NPI_SUCCESS,
  UPDATE_PROVIDER_NPI_FAIL,
  GET_PROVIDER_TAXONOMY_REQUEST,
  GET_PROVIDER_TAXONOMY_SUCCESS,
  GET_PROVIDER_TAXONOMY_FAIL,
  REGISTER_PROVIDER_TAXONOMY_REQUEST,
  REGISTER_PROVIDER_TAXONOMY_SUCCESS,
  REGISTER_PROVIDER_TAXONOMY_FAIL,
  DELETE_PROVIDER_TAXONOMY_REQUEST,
  DELETE_PROVIDER_TAXONOMY_SUCCESS,
  DELETE_PROVIDER_TAXONOMY_FAIL,
  GET_PROVIDER_SPECIALIZATION_REQUEST,
  GET_PROVIDER_SPECIALIZATION_SUCCESS,
  GET_PROVIDER_SPECIALIZATION_FAIL,
  REGISTER_PROVIDER_SPECIALIZATION_REQUEST,
  REGISTER_PROVIDER_SPECIALIZATION_SUCCESS,
  REGISTER_PROVIDER_SPECIALIZATION_FAIL,
  DELETE_PROVIDER_SPECIALIZATION_REQUEST,
  DELETE_PROVIDER_SPECIALIZATION_SUCCESS,
  DELETE_PROVIDER_SPECIALIZATION_FAIL,
  UPDATE_PROVIDER_ADDRESS_REQUEST,
  UPDATE_PROVIDER_ADDRESS_SUCCESS,
  UPDATE_PROVIDER_ADDRESS_FAIL,
  GET_PROVIDER_ADDRESS_REQUEST,
  GET_PROVIDER_ADDRESS_SUCCESS,
  GET_PROVIDER_ADDRESS_FAIL,
  PROVIDER_FILTER_PATIENT_REQUEST,
  PROVIDER_FILTER_PATIENT_SUCCESS,
  PROVIDER_FILTER_PATIENT_FAIL
} from "../types/providerConstant"
import { userLogoutAction } from "./userActions"
let adminId = JSON.parse(localStorage.getItem("id"))

export const getProviderList = (orgId) => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_LIST_REQUEST
    })
    if (orgId) {
      const { data } = await axiosGet(
        `/api/cs/provider/orgId/${orgId}/profiles`
      )
  
      dispatch({
        type: PROVIDER_LIST_SUCCESS,
        payload: data?.details
      })
    }

  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getProviderKPI = orgId => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_KPI_REQUEST
    })

    const { data } = await axiosGet(`v1/getProviderListKPIDB?orgId=${orgId}`)

    dispatch({
      type: PROVIDER_KPI_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_KPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getProviderPatientList = providerId => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_PATIENT_LIST_REQUEST
    })
   // `/api/cs/patients/provider/deedf07b-12fb-4f60-b3df-c15a070c488b/fetchAll`
    const { data } = await axiosGet(
      `/api/cs/patients/provider/${providerId.providerId}/fetchAll`
    )
    //`/admin/v1/getUsersForProvider?providerId=${providerId.providerId}`
console.log('data', data)
    dispatch({
      type: PROVIDER_PATIENT_LIST_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_PATIENT_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getFilterProviderPatientAction = ({formData, providerId}) => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_FILTER_PATIENT_REQUEST
    })
    const { data } = await axiosPost(
      `/api/cs/patients/filters/provider/${providerId}/fetch`,
      formData
    )
    dispatch({
      type: PROVIDER_FILTER_PATIENT_SUCCESS,
      payload: data?.details
    })
    return data?.details
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_FILTER_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const providerNPIAction = providerNPI => async dispatch => {
  try {
    dispatch({ type: PROVIDER_NPI_REQUEST })
    
    const { data } = await axiosGet(`/api/cs/config/npi/${providerNPI}/search`)
    
    dispatch({ type: PROVIDER_NPI_SUCCESS, payload: data?.details })
    return data
  } catch (error) {
    dispatch({
      type: PROVIDER_NPI_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    error?.response?.status === 401 && dispatch(userLogoutAction())
  }
}

export const getSingleProviderAction = providerId => async dispatch => {
  try {
    dispatch({ type: SINGLE_PROVIDER_REQUEST })

    const { data } = await axiosGet(`/api/cs/provider/${providerId}/profile`)
    dispatch({ type: SINGLE_PROVIDER_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: SINGLE_PROVIDER_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const updateProviderAction = (providerId, formData) => async dispatch => {
  try {
    dispatch({ type: UPDATE_PROVIDER_REQUEST })

    const { data } = await axiosPost(`/api/cs/provider/${providerId}/update`, formData)
    dispatch({ type: UPDATE_PROVIDER_SUCCESS, payload: data.details })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    
    const errMsg = error?.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : error?.response?.data?.error


    dispatch({
      type: UPDATE_PROVIDER_FAIL,
      payload: error.response && error?.response?.data?.details
    })

    return errMsg
  }
}



export const addProviderAction =
  (values, orgId) =>
  async dispatch => {
    try {
      dispatch({
        type: PROVIDER_ADD_REQUEST
      })
      if (orgId) {
        const { data } = await axiosPost(`/api/cs/provider/${orgId}/create`, values)
  
        dispatch({
          type: PROVIDER_ADD_SUCCESS,
          payload: data
        })
        return data
      }

    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PROVIDER_ADD_FAIL,
        payload:
          error.response && error.response.data.details
          // ? error.response.data.detail : error.message
      })
    }
  }

// to add practitioner
export const addPractitionerAction = ({values, orgId}) => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_ADD_REQUEST
    })

    if (orgId) {
      const { data } = await axiosPost(`/api/cs/practitioner/${orgId}/create`, values)

      dispatch({
        type: PROVIDER_ADD_SUCCESS,
        payload: data
      })
      return data
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_ADD_FAIL,
      payload:
        error.response && error.response.data.details ? error.response.data.details : error.message
    })
  }
}

export const clearNpiAction = () => async dispatch => {
  dispatch({ type: "PROVIDER_NPI_CLEAR" })
}

// get provider list for practitioners
// export const getProviderListForPractitioners = () => async (dispatch) => {
//     try {
//         dispatch({
//             type: PROVIDER_LIST_REQUEST,
//         });

//         const {data} = await axiosGet(`/providers/v1/getProviderList`);

//         dispatch({
//             type: PROVIDER_LIST_SUCCESS,
//             payload: data?.result,
//         });
//     } catch (error) {
//         error?.response?.status === 401 && dispatch(userLogoutAction())
//         dispatch({
//             type: PROVIDER_LIST_FAIL,
//             payload: error.response && error?.response?.data?.detail,
//         });
//     }
// };

export const getProviderNPIAction = providerId => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_NPI_REQUEST })

    const { data } = await axiosGet(`/api/cs/provider/npi/${providerId}/fetch`)
    dispatch({ type: GET_PROVIDER_NPI_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PROVIDER_NPI_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const updateProviderNPIAction = (providerId, formData) => async dispatch => {
  try {
    dispatch({ type: UPDATE_PROVIDER_NPI_REQUEST })

    const { data } = await axiosPost(`/api/cs/provider/npi/${providerId}/update`, formData)
    dispatch({ type: UPDATE_PROVIDER_NPI_SUCCESS, payload: data.details })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_PROVIDER_NPI_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getProviderTaxonomyAction = providerId => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_TAXONOMY_REQUEST })

    const { data } = await axiosGet(`/api/cs/provider/taxonomies/${providerId}/fetch`)
    dispatch({ type: GET_PROVIDER_TAXONOMY_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PROVIDER_TAXONOMY_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const deleteProviderTaxonomyAction = (providerId, taxonomyId) => async dispatch => {
  try {
    dispatch({ type: DELETE_PROVIDER_TAXONOMY_REQUEST })

    const { data } = await axiosDelete(`/api/cs/provider/taxonomies/${providerId}/delete/${taxonomyId}`)
    dispatch({ type: DELETE_PROVIDER_TAXONOMY_SUCCESS, payload: data.details })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: DELETE_PROVIDER_TAXONOMY_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const registerProviderTaxonomyAction = (providerId, formData) => async dispatch => {
  try {
    dispatch({ type: REGISTER_PROVIDER_TAXONOMY_REQUEST })

    const { data } = await axiosPost(`/api/cs/provider/taxonomies/${providerId}/create`, formData)
    dispatch({ type: REGISTER_PROVIDER_TAXONOMY_SUCCESS, payload: data.details })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: REGISTER_PROVIDER_TAXONOMY_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}
export const getProviderSpecializationAction = providerId => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_SPECIALIZATION_REQUEST })

    const { data } = await axiosGet(`/api/cs/provider/specializations/${providerId}/fetch`)
    dispatch({ type: GET_PROVIDER_SPECIALIZATION_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PROVIDER_SPECIALIZATION_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const deleteProviderSpecializationAction = (providerId, taxonomyId) => async dispatch => {
  try {
    dispatch({ type: DELETE_PROVIDER_SPECIALIZATION_REQUEST })

    const { data } = await axiosDelete(`/api/cs/provider/specializations/${providerId}/delete/${taxonomyId}`)
    dispatch({ type: DELETE_PROVIDER_SPECIALIZATION_SUCCESS, payload: data.details })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: DELETE_PROVIDER_SPECIALIZATION_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const registerProviderSpecializationAction = (providerId, formData) => async dispatch => {
  try {
    dispatch({ type: REGISTER_PROVIDER_SPECIALIZATION_REQUEST })

    const { data } = await axiosPost(`/api/cs/provider/specializations/${providerId}/create`, formData)
    dispatch({ type: REGISTER_PROVIDER_SPECIALIZATION_SUCCESS, payload: data.details })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: REGISTER_PROVIDER_SPECIALIZATION_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getProviderAddressAction = providerId => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_ADDRESS_REQUEST })

    const { data } = await axiosGet(`/api/cs/address/provider/${providerId}/fetch`)
    dispatch({ type: GET_PROVIDER_ADDRESS_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PROVIDER_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const updateProviderAddressAction = (providerId, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_PROVIDER_ADDRESS_REQUEST
    })

    const { data } = await axiosPost(`/api/cs/address/provider/${providerId}/update`, formData)

    dispatch({
      type: UPDATE_PROVIDER_ADDRESS_SUCCESS,
      payload: data?.details
    })
    // dispatch(getProviderAddressAction(providerId))

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())

    const errMsg = error?.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : error?.response?.data?.error

    dispatch({
      type: UPDATE_PROVIDER_ADDRESS_FAIL,
      payload: errMsg
    })
    return errMsg
  }
}

export const saveProviderPatientAdvanceFilter = (data) => async dispatch =>{
  dispatch({
    type: "SET_PROVIDER_PATIENT_ADVANCE_FILTER",
    payload: data
  })
}
export const resetProviderPatientAdvanceFilter = () => async dispatch =>{
  dispatch({
    type: "RESET_PROVIDER_PATIENT_ADVANCE_FILTER",
  })
}