import React, { useEffect, useState, memo } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { HiDocumentRemove, HiLightBulb } from "react-icons/hi"

import Loading from "../layouts/Loading/Loading"

import { Table, Input, Button, Card, Tooltip } from "antd"

import { GoAlert } from "react-icons/go"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { getTriageCriticalPatientsAction } from "../../redux/actions/patientAction"
import moment from "moment"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const { Search } = Input

export const isElapsedGreaterThan4Hours = prevTime => {
  const currentTime = new Date();
  const elapsedTime = currentTime.getTime() - new Date(prevTime).getTime();
  // Check if elapsed time is less than 4 hours (in milliseconds)
  const isGreaterThan4Hours = elapsedTime >= 4 * 60 * 60 * 1000;
  console.log(`Elapsed time greater than or equal to 4 hours: ${isGreaterThan4Hours}`);
  return isGreaterThan4Hours;
};

const RPMCriticalTimePassed = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { orgId, orgName } = useParams()

  const [RpmData, setRpmData] = useState([])
  const [serachedData, setSearchedData] = useState([])
  const [serachTerm, setSearchTerm] = useState("")

  const { loading, triageCriticalPatients: criticalPatient } = useSelector(
    state => state.triageCriticalPatients
  )
  const { addToast } = useToasts()

  useEffect(() => {
    dispatch(getTriageCriticalPatientsAction(orgId))
  }, [dispatch])

  const handleCriticalDeviceAlert = device => {
    return addToast(`This patient got critical reading from : ${device}`, {
      appearance: "info",
      autoDismiss: true
    })
  }

  useEffect(() => {
    if (criticalPatient !== undefined && criticalPatient !== null) {
      let filtered = criticalPatient
      let filteredCriticalPatients = []
      criticalPatient.map((item) => {
        if (isElapsedGreaterThan4Hours(item.criticalDate)) {
          filteredCriticalPatients.push(item)
        }
      })

      let newData = []
      let sno = 1
      filteredCriticalPatients?.map(
        ({
          firstName,
          middleName,
          lastName,
          id,
          criticalStatus,
          criticalDate
        }) => {
          middleName
            ? newData.push({
              key: sno,
              sNo: sno,
              name: firstName + " " + middleName + " " + lastName,
              id: id,
              criticalStatus: criticalStatus,
              criticalDate: criticalDate
            })
            : newData.push({
              key: sno,
              sNo: sno,
              name: firstName + " " + lastName,
              id: id,
              criticalStatus: criticalStatus,
              criticalDate: criticalDate
            })
          sno += 1
        }
      )
      console.log(newData, 'newData')
      setRpmData(newData)
    }
  }, [criticalPatient])

  // useEffect(() => {
  //   if (criticalPatient !== undefined && criticalPatient !== null) {
  //     const filtered = criticalPatient?.filter(
  //       x =>
  //         (x?.oximeter[0]?.createdAt !== null &&
  //           x?.oximeter[0]?.createdAt !== undefined &&
  //           (currDate !== new Date(x.oximeter[0]?.createdAt).toDateString() ||
  //             (Number(toTime(new Date(x.oximeter[0]?.createdAt))?.substring(1, 2)) >= 4 &&
  //               Number(toTime(new Date(x.oximeter[0]?.createdAt))?.substring(3, 5)) > 1))) ||
  //         (x?.bp[0]?.createdAt !== null &&
  //           x?.bp[0]?.createdAt !== undefined &&
  //           (currDate !== new Date(x.bp[0]?.createdAt).toDateString() ||
  //             (Number(toTime(new Date(x.bp[0]?.createdAt))?.substring(1, 2)) >= 4 &&
  //               Number(toTime(new Date(x.bp[0]?.createdAt))?.substring(3, 5)) > 1)))
  //     )

  //     const newData = []
  //     let sno = 1
  //     filtered?.map(
  //       ({ firstName, middleName, lastName, userId, FitpeoUserCriticalStatus, oximeter, bp }) => {
  //         middleName
  //           ? newData.push({
  //               key: sno,
  //               sNo: sno,
  //               name: `${firstName} ${middleName} ${lastName}`,
  //               id: userId,
  //               criticalDetais: FitpeoUserCriticalStatus,
  //               oxyCritical: oximeter,
  //               bpCritical: bp
  //             })
  //           : newData.push({
  //               key: sno,
  //               sNo: sno,
  //               name: `${firstName} ${lastName}`,
  //               id: userId,
  //               criticalDetais: FitpeoUserCriticalStatus,
  //               oxyCritical: oximeter,
  //               bpCritical: bp
  //             })
  //         sno += 1
  //         return userId
  //       }
  //     )
  //     setRpmData(newData)
  //   }
  // }, [criticalPatient]) //eslint-disable-line
  // console.log(RpmData)

  let currDate = new Date().toDateString()
  // column for table
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <p
          className="capitalize"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${id}`, {
            state: {
              name: name
            }
          })}>
          {name}
        </p>
      ),
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    {
      title: "Critical Status time",
      dataIndex: "criticalDate",
      key: "criticalDate",
      render: (criticalDate) => (
        <div className="w-full flex gap-2">
          <span>{moment(criticalDate).format("MM/DD/YYYY [at] hh:mm A")}</span>
        </div>
      )
    },
    // {
    //   title: "Actions",
    //   render: (criticalDate, { id }) => (
    //     <div className="w-full flex gap-2">
    //       <Tooltip title="Triage patient">
    //         <Button
    //           type="primary"
    //           icon={<HiLightBulb size={22} />}
    //           onClick={() =>
    //             (window.location.href = `/rpm-triage-patient/${id}/oxy/${criticalDate[0]?.id}`)
    //           }
    //         />
    //       </Tooltip>
    //     </div>

    //   )
    // }
  ]

  const handleSearch = (e) => {
    const prevData = RpmData
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchTerm(queryWithoutSpace)
    const newData = prevData.filter(item => item.name?.toLowerCase().includes(serachTerm))
    if (newData.length) {
      setSearchedData(newData)
    } else {
      setSearchedData([])
    }
  }

  return loading ? (
    <div className="loaderLoading">
      <Loading />
    </div>
  ) : (
    <>
      <OrgBackNavigation />
      <Card className="rounded-b-none mt-2">
        <div className="d-flex justify-content-between">
          <h3 className="text-xl">Critical Time Over Patients</h3>
          <Search
            allowClear
            size="large"
            placeholder="Search"
            style={{ width: 350 }}
            value={serachTerm}
            // onSearch={value => handleSearch(value)}
            onChange={e => handleSearch(e)}
          />
        </div>
      </Card>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={serachTerm ? serachedData : RpmData}
      />
    </>
  )
}

export default memo(RPMCriticalTimePassed)
