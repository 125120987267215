import React, { useEffect, useState, memo } from "react"

import { Button, Table, Input, Card, Tooltip } from "antd"
import { AiOutlineFundView, AiOutlineEdit, AiOutlineProfile } from "react-icons/ai"

import Loading from "../layouts/Loading/Loading"

import { useDispatch, useSelector } from "react-redux"
import { getrpmDataActions } from "../../redux/actions/rpmAction"
import { getMonitorListTypeAction } from "../../redux/actions/devicesActions"
import { useParams } from "react-router-dom"

const { Search } = Input

const RPMIsPatientDetails = () => {
  const dispatch = useDispatch()
  const {orgId, orgName} = useParams()

  const [rpmMonitorId, setRpmMonitorId] = useState(null)
  const { loading, getRpm } = useSelector(state => state.rpmList)
  const { monitorTypeList } = useSelector(state => state.monitorTypeList)
  const [RpmData, setRpmData] = useState([])
  const [serachedData, setSearchedData] = useState([])
  const [serachTerm, setSearchTerm] = useState("")
  // console.log('getRpm',getRpm)

  useEffect(() => {
    if (monitorTypeList !== undefined) {
      const data = monitorTypeList?.filter(value => value.name === "rpm")
      data && setRpmMonitorId(data?.[0]?.moniterId)
    }
  }, [getRpm, loading, monitorTypeList])

  useEffect(() => {
    dispatch(getMonitorListTypeAction())
  }, [])

  useEffect(() => {
    // dispatch(getMonitorListTypeAction());
    rpmMonitorId !== null && dispatch(getrpmDataActions(rpmMonitorId))
  }, [rpmMonitorId, dispatch])

  // column for table
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: name => <p className="text-capitalize wrap-text mb-0">{name}</p>
    },
    // {
    //   title: 'User Id',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: "Action",
      render: ({ id }) => (
        <div className="flex flex-wrap gap-3">
          <Tooltip title="View">
            <Button
              shape="circle"
              type="primary"
              icon={<AiOutlineFundView size={22} />}
              onClick={() => (window.location.href = `/${orgId}/${orgName}/rpm-view-treatement/${id}`)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              shape="circle"
              type="primary"
              icon={<AiOutlineEdit size={22} />}
              onClick={() => (window.location.href = `/${orgId}/${orgName}/rpm-edit-treatement/${id}`)}
            />
          </Tooltip>
          <Tooltip title="Billing">
            <Button shape="circle" icon={<AiOutlineProfile size={22} />} type="primary" />
          </Tooltip>
        </div>
      )
    }
  ]

  useEffect(() => {
    const newData = []
    let sno = 1
    getRpm?.active?.map(({ firstName, middleName, lastName, userId }) => {
      middleName !== null
        ? newData.push({
            key: sno,
            sNo: sno,
            name: `${firstName} ${middleName} ${lastName}`,
            id: userId
          })
        : newData.push({ key: sno, sNo: sno, name: `${firstName} ${lastName}`, id: userId })
      sno += 1
      return userId
    })
    setRpmData(newData)
  }, [getRpm])

  // console.log('RpmData',RpmData)

  const handleSearch = term => {
    const prevData = RpmData
    setSearchTerm(term?.toLowerCase())
    const newData = prevData.filter(item => item?.name?.toLowerCase().includes(term?.toLowerCase()))
    if (newData.length) {
      setSearchedData(newData)
    } else {
      setSearchedData([])
    }
  }

  return loading ? (
    <div className="loaderLoading">
      <Loading />
    </div>
  ) : (
    <>
      <Card className="rounded-b-none">
        <div className="flex justify-between">
          <h3 className="text-xl">Patient's Treatment Plan Details</h3>
          <Search
            allowClear
            size="large"
            placeholder="Search"
            className="max-w-[350px]"
            onSearch={value => handleSearch(value)}
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
      </Card>
      <Table
        columns={columns}
        dataSource={serachTerm ? serachedData : RpmData}
        scroll={{ x: "max-content" }}
      />
    </>
  )
}

export default memo(RPMIsPatientDetails)
