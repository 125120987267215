import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import moment from "moment"
import { Modal, List, DatePicker, Button, Tooltip } from "antd"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { deletePatientInsuranceAction } from "../../../redux/actions/patientAction"
import { getPatientInfo, getPatientInsurance } from "../../../redux/actions/patientAction"

import { BiTrash, BiEdit } from "react-icons/bi"

import Loading from "../../layouts/Loading/Loading"
import { EditOutlined } from "@ant-design/icons"

const InsuranceListModal = props => {
  const {
    setSelectedInsurance,
    setInsuranceAction,
    insuranceListApiCalls,
    setShowUpdatePatientInsurance ,
    showPatientInsuranceList,
    handleAddUpdateInsuranceListClose
  } = props

  const patientId = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()


  useEffect(() => {
    if (insuranceListApiCalls === true) {
      dispatch(getPatientInsurance(patientInfoDetail?.id))
    }
  }, [showPatientInsuranceList])

  const { patientInfoDetail } = useSelector(state => state.patientInfo)
  const { patientInsurance, loading: insuranceLoading } = useSelector(
    state => state.patientInsurance
  )

  const handleDelete = InsuranceId => {
    dispatch(deletePatientInsuranceAction(patientInfoDetail?.id, InsuranceId)).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        dispatch(getPatientInsurance(patientInfoDetail?.id))
        addToast("Patient Insurance Deleted Successfully", {
            appearance: "success",
            autoDismiss: true
          })
      }else {
        addToast("Somthing went wrong", {
            appearance: "error",
            autoDismiss: true
          })
      }
    })
  }

  const handleClose = () => {
    setSelectedInsurance(null)
    handleAddUpdateInsuranceListClose()
  }

  const handleUpdateClick = ({row, actionType}) => {
    setSelectedInsurance(row)
    setInsuranceAction(actionType)
    setShowUpdatePatientInsurance(true)
    setTimeout(() => handleAddUpdateInsuranceListClose(), 100)
  }

  return (
    <Modal
      footer={null}
      open={showPatientInsuranceList}
      onCancel={handleClose}
      title={<h4 className="text-xl">Insurance</h4>}>
      <div className="pt-4">
        {insuranceLoading ? (
          <Loading />
        ) : patientInsurance !== undefined && patientInsurance?.length ? (
          <>
            <List
              dataSource={patientInsurance}
              renderItem={(values, index) => {
                const isLastItem = patientInsurance.length - 1 === index
                return (
                  <div className={`mb-3 ${!isLastItem ? "border-b-2 border-current pb-3" : ""}`}>
                    <div className="flex items-center gap-2">
                      <span className="min-w-[80px]">Insurance Number : </span>
                      <span className="font-medium ml-5">{values?.insuranceNumber}</span>
                    </div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="min-w-[80px]">Insurance Provider : </span>
                      <span className="font-medium capitalize ml-5">
                        {values?.insuranceProvider}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="min-w-[80px]">Insurance Type : </span>
                      <span className="font-medium capitalize ml-10">{values?.insuranceType}</span>
                    </div>
                    <div className="flex items-center gap-2 mt-1">
                      <span className="min-w-[80px]">Active Status : </span>
                      <span className="font-medium ml-14">{values?.status ? "Yes" : "No"}</span>
                    </div>
                    <div className="flex items-center gap-3 justify-end mr-5">
                      <Tooltip title="Edit">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleUpdateClick({row:values, actionType:"update"})}
                        />
                      </Tooltip>
                      {/* <Tooltip title="Delete">
                        <Button
                          danger
                          type="primary"
                          shape="circle"
                          icon={<BiTrash />}
                          onClick={() => handleDelete(values.insuranceNumber)}
                        />
                      </Tooltip> */}
                    </div>
                  </div>
                )
              }}
            />
          </>
        ) : (
          <h3 className="text-xl">No Insurance found for patient.</h3>
        )}

        <div xs={24}>
          <div className="flex flex-wrap gap-3 mt-5">
            <Button size="large" type="primary"  onClick={() => handleUpdateClick({actionType:"add"})}>
              Add Insurance
            </Button>
            <Button size="large" danger onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InsuranceListModal
