import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import moment from "moment"
import { Table } from "antd"

import Loading from "../layouts/Loading/Loading"

import "react-datepicker/dist/react-datepicker.css"
import { useSelector } from "react-redux"

export const getFullName = (firstName, middleName, lastName) => {
  return `${firstName?.[0]?.toUpperCase() + firstName.slice(1)} ${middleName} ${lastName}`
}

const ProviderNewTable = ({ loading, sortedData }) => {
  // modal

  const [data, setData] = useState([])
  const [currentDate] = useState(new Date())
  const [priorDate, setPriorDate] = useState() //eslint-disable-line

  useEffect(() => {
    if (currentDate !== undefined) {
      const manageDate = moment(currentDate).subtract(40, "days")
      setPriorDate(manageDate._d) //eslint-disable-line
    }
  }, [currentDate])

  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <p
          className="text-capitalize wrap-text mb-0"
          onClick={() => navigate(`/${orgId}/${orgName}/provider/${id}`)}
          style={{ cursor: "pointer" }}>
          {name}
        </p>
      ),
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    // {
    //   title: "Username",
    //   dataIndex: "username",
    //   key: "username",
    //   render: (username, { id }) => (<p className="wrap-text mb-0"
    //     onClick={() => navigate(`/provider/${id}`)}
    //     style={{ cursor: "pointer" }}
    //   >{username}</p>),
    //   sorter: (a, b) => a?.username?.localeCompare(b?.username)
    // },
    // {
    //   title: "Provider Id",
    //   dataIndex: "id",
    //   key: "id",
    //   render: id => <p className="wrap-text mb-0">{id}</p>
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: email => <p className="wrap-text mb-0">{email}</p>,
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    // {
    //   title: "Provider NPI No",
    //   dataIndex: "npi",
    //   key: "npi",
    //   render: npi => <p className="wrap-text mb-0">MISSING</p>
    // },
    {
      title: "Total Patient",
      dataIndex: "patientCount",
      key: "patientCount",
      render: patientCount => <p className="wrap-text mb-0">{patientCount}</p>,
      sorter: (a, b) => a?.patientCount - b?.patientCount
    }
  ]

  useEffect(() => {
    const newData = []

    let sno = 1
    sortedData?.map(data => {
      const { id, email, patientCount, middleName, firstName, lastName } = data

      newData.push({
        id,
        key: sno,
        sNo: sno,
        name:
          !middleName || middleName === null
            ? `${firstName} ${lastName}`
            : `${firstName} ${middleName} ${lastName}`,
        // username: userName,
        patientCount: patientCount,
        email,
        // npi: npiNumber
      })

      sno += 1
      return id
    })
    setData(newData)
  }, [sortedData])

  if (loading) {
    return <Loading />
  }

  return <Table columns={columns} dataSource={data} scroll={{ x: "max-content" }} />
}

export default ProviderNewTable
