import React, { useState } from "react"

import { Input, Button, Card } from "antd"

import ProviderCards from "./ProviderCards"
import AddProvider from "./AddProvider/AddProvider"

const ProviderHeader = ({ searchQuery, setSearchQuery }) => {
  const [showAddProvider, setShowAddProvider] = useState(false)
  const handleAddPatientShow = () => {
    setShowAddProvider(true)
  }

  const handleAddPatientClose = () => {
    setShowAddProvider(false)
  }

  const handleSearch = (e) => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }

  return (
    <>
      <div className="provider-header">
        <div className="mb-4">
          <ProviderCards />
        </div>

        <Card className="rounded-b-none">
          <div className="flex flex-wrap gap-3 items-center justify-between">
            <Input.Search
              allowClear
              type="text"
              size="large"
              value={searchQuery}
              placeholder="Search..."
              className="max-w-[350px]"
              onChange={e => handleSearch(e)}
            />
            <Button size="large" type="primary" onClick={handleAddPatientShow}>
              Add New Provider
            </Button>
          </div>
        </Card>
      </div>
      <AddProvider
        showAddProvider={showAddProvider}
        setShowAddProvider={setShowAddProvider}
        handleAddPatientClose={handleAddPatientClose}
      />
    </>
  )
}

export default ProviderHeader
