import React, { useState, useEffect } from "react"

import { Helmet } from "react-helmet"
import AddCareplan from "fit-plan-creator"
import { useToasts } from "react-toast-notifications"

import Loading from "../components/layouts/Loading/Loading"
import { TempAxiosGet, TempAxiosPost } from "../utils/TokenStorage"

const MobileAddTreatementPlan = () => {
  const [answers, setAnswers] = useState([])
  const [patientInfo, setPatientInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [firstCall, setFirstCall] = useState(false)
  const { addToast } = useToasts()

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      // if (window.value.patientDetails !== undefined) {
      //     setPatientInfo(window.value.patientDetails)
      // }
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        // dispatch(getPatientInfo({ patientId: window.value.patientId }));
        getPatientData(window.value.patientId)
        setFirstCall(true)
      }
    }, 1000)
    setLoading(false)
    return () => clearTimeout(timer)
  }, [window.value]) //eslint-disable-line

  // console.log('ans',answers)

  const getPatientData = async patientId => {
    const res = await TempAxiosGet(window.token, `/admin/v2/getUserProfile?memberId=${patientId}`)
    setPatientInfo(res)
  }

  const postAnswers = async () => {
    const res = await TempAxiosPost(
      window.token,
      { userId: patientInfo?.userId, careplan: { ...answers } },
      `/admin/v1/postRPMCareplan`
    )
    if (res?.success) {
      addToast("Saved Successfully", { appearance: "success", autoDismiss: true })
    } else {
      addToast("Unable To Save", { appearance: "error", autoDismiss: true })
    }
  }

  useEffect(() => {
    if (answers.length) {
      postAnswers()
    }
  }, [answers]) //eslint-disable-line

  return (
    <div className="mx-3">
      <Helmet>
        <script>{`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData))
                //var content = {"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzNWMyNTA5Y2YzMDUxMTBiYWM1ZjVjNDU5ZjY3MDA1IiwiZXhwIjoxNjcwOTk2MDY4fQ.L4zUfTvRScKH9aovKdEO5SBlqJYAwzoUKpf6LXvo-Ys", patientId:"48ce90169d695e008a0733c56d6529ba"};
                
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token; 
                      
        }`}</script>
      </Helmet>
      {loading || patientInfo === null ? (
        <Loading />
      ) : (
        <AddCareplan
          patientInfoDetail={patientInfo}
          type="rpm"
          answers={answers}
          setAnswers={setAnswers}
        />
      )}
    </div>
  )
}

export default MobileAddTreatementPlan
