/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Modal, Typography, theme } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { CloseOutlined } from "@ant-design/icons"

import { useParams } from "react-router-dom"
import "./EligiblePatient.css"
import { useToasts } from "react-toast-notifications"
import {
  getSelfOnboardingPendingPatientAction,
  updateBulkCaretakerActivePatientAction,
  updateSelfOnboardingPatientCaretakerAction,
  removeSelfOnboardingPatientCaretakerAction,
  removeBulkCaretakerPatientAction
} from "../../../redux/actions/selfOnboardingAction"
import { criticalPatientAction } from "../../../redux/actions/dashboardAction"
import { PatientStage } from "./Types"

const { useToken } = theme

const AddRemoveConfirmationModal = props => {
  const {
    setIsModalOpen,
    fromDropdown,
    selectedCaretakers,
    newSelectedCaretakerList,
    onCaretakerSelectionClear,
    selectedRowKeys,
    patientStage,
    caretakerNames
  } = props
  const { loading: updateLoading, error: updateError } = useSelector(
    state => state.updateBulkCaretaker
  )
  const { loading: updateCaretakerActivePatientLoading, error: updateCaretakerActivePatientError } =
    useSelector(state => state.updateBulkCaretakerActivePatient)
  const { loading: removeCaretakerPatientLoading, error: removeCaretakerPatientError } =
    useSelector((state) => state.removeBulkCaretakerPatient)
  const { loading: removeCaretakerSOBPatientLoading, error: removeCaretakerSOBPatientError } =
    useSelector((state) => state.removeBulkCaretakerSOB)

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const {orgId} = useParams()
  const { token } = useToken()
  const { isDark } = useSelector(state => state.themeConfig)

  const closeModal = () => {
    setIsModalOpen(false)
  }
  const handleConfirmation = async () => {
    const caretakerIds = (fromDropdown === "add" ? newSelectedCaretakerList : caretakerNames)?.map(
      (caretakerIds) => caretakerIds?.value
    )
    const formData = selectedRowKeys?.map(patientId => ({
      patientId: patientId,
      caretakerId: caretakerIds
    }))
    try {
    const apiRes = await Promise.resolve(
      patientStage === PatientStage.Eligible
        ? fromDropdown === "add"
          ? dispatch(updateSelfOnboardingPatientCaretakerAction(formData, orgId))
          : dispatch(removeSelfOnboardingPatientCaretakerAction(orgId, formData))
        : fromDropdown === "add"
        ? dispatch(updateBulkCaretakerActivePatientAction(formData, orgId))
        : dispatch(removeBulkCaretakerPatientAction(orgId, formData))
    )

    if (apiRes?.status === 200) {
      if (patientStage === PatientStage.Active) {
        dispatch(criticalPatientAction(orgId))
      } else {
        dispatch(getSelfOnboardingPendingPatientAction(orgId))
      }
      closeModal()
      addToast(
        <>
          {
            <span className="capitalize font-medium">
              {(fromDropdown === "add" ? newSelectedCaretakerList : caretakerNames)?.map(
                (c, index) =>
                  index ===
                  (fromDropdown === "add"
                    ? newSelectedCaretakerList?.length - 1
                    : caretakerNames?.length - 1)
                    ? c?.label?.toLowerCase()
                    : `${c?.label?.toLowerCase()}, `
              )}
            </span>
          }{" "}
          caretakers are {fromDropdown === "add" ? "added" : "updated"} successfully for selected
          patients
        </>,
        { appearance: "success", autoDismiss: true }
      )
      // onCaretakerSelectionClear()
    } else {
      console.log("error occured" ,updateError || updateCaretakerActivePatientError || removeCaretakerPatientError || removeCaretakerSOBPatientError)
    } 
    }catch(error) {
      addToast(error?.message, {
        appearance: "error",
        autoDismiss: true
      })
    }
  }
  return (
    <Modal
      styles={{
        content: {
          borderRadius: "6px",
          padding: 0,
          overflow: "hidden"
        }
      }}
      classNames={{ body: "flex flex-col  m-2" }}
      footer={null}
      centered
      open={true}
      closeIcon={
        <span className='text-["#fff"]'>
          <CloseOutlined />
        </span>
      }
      onCancel={closeModal}
      destroyOnClose>
      <Typography.Title
        className="w-full rounded-t-md p-4 bg-[#d7a66d]"
        level={4}
        style={{
          color: "#fff"
        }}>
        {<span className="capitalize">{fromDropdown}</span>}&nbsp;Caretaker
      </Typography.Title>
      <div className="p-4 flex flex-wrap gap-2">
        <Typography.Text className="text-xl">
          {`Are you sure you want to ${fromDropdown} caretaker? `}
        </Typography.Text>

        <Typography.Text className="text-sm">
          This action will {fromDropdown} caretaker{" "}
          {
            <span className="capitalize font-medium">
              {(fromDropdown === "add" ? newSelectedCaretakerList : caretakerNames)?.map(
                (c, index) =>
                  index ===
                  (fromDropdown === "add"
                    ? newSelectedCaretakerList?.length - 1
                    : caretakerNames?.length - 1)
                    ? c?.label?.toLowerCase()
                    : `${c?.label?.toLowerCase()}, `
              )}
            </span>
          }{" "}
          for the selected patients
        </Typography.Text>
      </div>
      <div
        className="mt-4 p-3 w-full rounded-b-md flex justify-between"
        style={{
          backgroundColor: isDark ? token?.Modal?.contentBg : "#F1F1F1",
          borderTop: isDark ? "1px solid rgba(255,255,255, 0.1)" : "none"
        }}>
        <Button
          size="large"
          className="color-primary"
          onClick={closeModal}
          disabled={
            updateLoading ||
            updateCaretakerActivePatientLoading ||
            removeCaretakerPatientLoading ||
            removeCaretakerSOBPatientLoading
          }>
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={handleConfirmation}
          loading={
            updateLoading ||
            updateCaretakerActivePatientLoading ||
            removeCaretakerPatientLoading ||
            removeCaretakerSOBPatientLoading
          }>
          Confirm and Proceed
        </Button>
      </div>
    </Modal>
  )
}

export default AddRemoveConfirmationModal
