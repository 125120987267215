import DatePicker from "react-datepicker"
import { Select, Row, Col, Input, Button, Modal } from "antd"
import { useEffect, useState } from "react"
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"

import { useToasts } from "react-toast-notifications"
import {
  getSinglePractitioner,
  updatePractitionerAction
} from "../../redux/actions/practitionerAction"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"

const alphaRegex = /^[a-zA-Z]+$/

const FIRST_NAME_ERR_MSG = "First Name should contain alphabets only"
const MIDDLE_NAME_ERR_MSG = "Middle Name should contain alphabets only"
const LAST_NAME_ERR_MSG = "Last Name should contain alphabets only"

const MOBILE_MIN_ERR_MSG = "Mobile Number must be at least 10 characters"
const MOBILE_MAX_ERR_MSG = "Mobile Number must be at most 10 characters"

const INVALID_USERNAME = "Invalid User Name"
const INVALID_DOB = "Invalid DOB"
const INVALID_GENDER = "Invalid Gender"
const INVALID_EMAIL = "Invalid Email"
const INVALID_MOBILENUMBER = "Invalid Mobile Number"
const INVALID_FIRSTNAME = "Invalid First Name"
const INVALID_LASTNAME = "Invalid Last Name"
const ONLY_ALPHA_USERNAME = "Only alphabets and numbers are allowed"

const SUCCESS_MSG = "Practitioner Updated Successfully"

const schema = yup
  .object({
    dob: yup.string().required(INVALID_DOB),
    gender: yup.string().required(INVALID_GENDER),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, INVALID_EMAIL).required(INVALID_EMAIL),
    userName: yup.string().min(1).max(100).matches(/^[a-zA-Z0-9]*$/, ONLY_ALPHA_USERNAME ).required(INVALID_USERNAME),
    mobileNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, INVALID_MOBILENUMBER)
      .required(INVALID_MOBILENUMBER),
    firstName: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .matches(alphaRegex, FIRST_NAME_ERR_MSG)
      .required(INVALID_FIRSTNAME),
    middleName: yup
      .string()
      .trim()
      .test("checkMiddleName", "", (value, { createError, path }) => {
        if (value.length && !alphaRegex.test(value)) {
          return createError({
            path,
            message: MIDDLE_NAME_ERR_MSG
          })
        } else {
          return true
        }
      }),
    lastName: yup
      .string()
      .trim()
      .min(1)
      .max(100)
      .matches(alphaRegex, LAST_NAME_ERR_MSG)
      .required(INVALID_LASTNAME)
  })
  .required()

const UpdatePractitioner = props => {
  const { singlePractitioner, updatePractitionerShow, setUpdatePractitionerShow } = props

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { loading } = useSelector(state => state.updatePractitioner)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })
  const [updateError, setUpdateError] = useState("")
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!updatePractitionerShow || !singlePractitioner) return
    setValue("email", singlePractitioner?.email || "")
    setValue("gender", singlePractitioner?.gender || "")
    setValue("userName", singlePractitioner?.userName || "")
    setValue("lastName", singlePractitioner?.lastName || "")
    setValue("firstName", singlePractitioner?.firstName || "")
    setValue("middleName", singlePractitioner?.middleName || "")
    setValue("mobileNumber", formatPhoneNumber(singlePractitioner?.mobileNumber) || "")
    setValue("dob", singlePractitioner?.dob ? new Date(singlePractitioner?.dob) : new Date())
  }, [singlePractitioner, updatePractitionerShow])

  const onSubmit = async data => {
    const middleName = data.middleName || null
    setUpdateError("")
    const result = await dispatch(
      updatePractitionerAction(singlePractitioner?.id, {
        ...data,
        middleName,
        mobileNumber: data?.mobileNumber?.replace(/[^\d]/g, ''),
        countryCode: singlePractitioner?.countryCode.toUpperCase(),
        dob: new Date(data.dob).toISOString()
      })
    )
    if (result?.status === 200) {
      setUpdatePractitionerShow(false)
      dispatch(getSinglePractitioner(singlePractitioner?.id))
      addToast(SUCCESS_MSG, {
        appearance: "success",
        autoDismiss: true
      })
    } else {
      return setUpdateError(result)
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };

  return (
    <Modal
      width={800}
      footer={null}
      open={updatePractitionerShow}
      onCancel={() => setUpdatePractitionerShow(false)}
      title={<h4 className="text-xl">Update Practitioner</h4>}>
      <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-firstName">
              First Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="input-firstName"
                  className="w-100"
                  status={errors.firstName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.firstName ? (
              <small className="text-danger">{errors.firstName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-middleName">
              Middle Name
            </label>
            <Controller
              name="middleName"
              control={control}
              render={({ field }) => (
                <Input
                  id="input-middleName"
                  className="w-100"
                  status={errors.middleName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.middleName ? (
              <small className="text-danger">{errors.middleName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-lastName">
              Last Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="input-lastName"
                  className="w-100"
                  status={errors.lastName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.lastName ? (
              <small className="text-danger">{errors.lastName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-userName">
              Username <span className="text-danger">*</span>
            </label>
            <Controller
              name="userName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="input-userName"
                  className="w-100"
                  status={errors.userName ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.userName ? (
              <small className="text-danger">{errors.userName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-mobileNumber">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const {ref, ...rest} = field
              return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-mobile-input text-sm leading-[1.35rem] flex px-[11px] py-[4px]  w-full  border border-${
                      errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                    }  rounded-lg focus:outline-none`}
                    id="input-mobileNumber"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
              )}
             }
            />
            {errors.mobileNumber ? (
              <small className="text-danger">{errors.mobileNumber.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-email">
              Email <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="input-email"
                  className="w-100"
                  type="email"
                  status={errors.email ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-gender">
              Gender at the time of birth <span className="text-danger">*</span>
            </label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  id="input-gender"
                  className="w-100"
                  status={errors.gender ? "error" : undefined}
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" }
                  ]}
                  {...field}
                />
              )}
            />
            {errors.gender ? <small className="text-danger">{errors.gender.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-dob">
              DOB <span className="text-danger">*</span>
            </label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  id="input-dob"
                  required
                  selected={field.value}
                  // selected={new Date()}
                  onChange={date => field.onChange(date)}
                  dateFormatCalendar={"MMM yyyy"}
                  maxDate={Date.now()}
                  showPopperArrow={false}
                  className="border p-3 rounded w-100 h-[30px]"
                  withPortal
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  {...field}
                />
              )}
            />
            {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
          </Col>

          <Col xs={24} md={12}>
            {updateError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-medium">{updateError}</p>
              </Col>
            ) : null}
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default UpdatePractitioner
