import { useEffect, useState } from "react"

import { Card, Button, Row, Col, Avatar, Modal } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getSingleProviderAction, getProviderSpecializationAction } from "../../redux/actions/providerAction"
import { getProviderAddressAction } from "../../redux/actions/providerAction";

import UpdateProvider from "./providerModals/UpdateProvider"
// import UpdateNPI from "./providerModals/UpdateNPI"

import defaultProvider from "../../assets/doctor.png"
// import UpdateTaxonomy from "./providerModals/Taxonomy/DeleteTaxonomy"
// import AddTaxonomy from "./providerModals/Taxonomy/AddTaxonomy"
// import UpdateSpecialization from "./providerModals/Specialization/DeleteSpecialization"
// import AddSpecialization from "./providerModals/Specialization/AddSpecialization"
import UpdateAddress from "./providerModals/Address/UpdateAddress"
import ProviderAddress from "./ProviderAddress";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import moment from "moment";

const ProviderDetailHeader = ({ providerId }) => {
  const [showActions, setShowActions] = useState(false)

  const [updateProviderShow, setUpdateProviderShow] = useState(false)
  // const [updateNpiShow, setUpdateNpiShow] = useState(false)
  // const [updateTaxonomyShow, setUpdateTaxonomyShow] = useState(false)
  // const [addTaxonomyShow, setAddTaxonomyShow] = useState(false)
  // const [updateSpecializationShow, setUpdateSpecializationShow] = useState(false)
  // const [addSpecializationShow, setAddSpecializationShow] = useState(false)
  const [updateAddressShow, setUpdateAddressShow] = useState(false)

  const dispatch = useDispatch()
  const { singleProvider } = useSelector(state => state.singleProvider)
  const { loading: specializationLoading, data: Specialization, error: SpecializationError } = useSelector(state => state.getProviderSpecializationReducer)

  const profilePicture = singleProvider?.profileImage
    ? `data:image/png;base64,${singleProvider?.profileImage}`
    : defaultProvider

  useEffect(() => {
    if (singleProvider) {
      dispatch(getProviderSpecializationAction(providerId))
      dispatch(getProviderAddressAction(providerId));
    }
  }, [providerId])

  const handleUpdateProviderShow = () => {
    setShowActions(false)
    setUpdateProviderShow(true)
  }
  // const handleUpdateNpiShow = () => {
  //   setShowActions(false)
  //   setUpdateNpiShow(true)
  // }
  // const handleUpdateTaxonomiesShow = () => {
  //   setShowActions(false)
  //   setUpdateTaxonomyShow(true)
  // }
  // const handleAddTaxonomiesShow = () => {
  //   setShowActions(false)
  //   setAddTaxonomyShow(true)
  // }
  // const handleUpdateSpecializationShow = () => {
  //   setShowActions(false)
  //   setUpdateSpecializationShow(true)
  // }
  // const handleAddSpecializationShow = () => {
  //   setShowActions(false)
  //   setAddSpecializationShow(true)
  // }
  const handleUpdateAddressShow = () => {
    setShowActions(false)
    setUpdateAddressShow(true)
  }


  return (
    <>
      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={16} md={24} xs={24}>
          <Card className="shadow-sm h-full" loading={singleProvider === undefined}>
            <div className="flex gap-3 flex-wrap justify-between">
              <h4 className="text-2xl">Provider Details</h4>
              <Button type="primary" onClick={() => setShowActions(true)}>
                Provider Actions
              </Button>
            </div>
            <div className="mt-4 flex gap-16 flex-wrap">
              <div className="flex gap-3">
                <Avatar className="border-2 border-current p-2" size={60} src={profilePicture} />
                <div>
                  <h5 className="mb-1 text-xl capitalize">
                    {singleProvider?.firstName?.[0]?.toUpperCase() + singleProvider?.firstName.slice(1)}{" "}
                    {singleProvider?.middleName} {singleProvider?.lastName}
                  </h5>
                  <p className="text-sm text-secondary">
                    {singleProvider?.email || "No Email isused for this patient."}
                  </p>
                </div>
              </div>
              <div>
                {singleProvider?.userName ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px]">User Name : </span>
                    {singleProvider?.userName}
                  </div>
                ) : null}
                {singleProvider?.gender ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px] mr-1">Gender at the time of birth : </span>
                    {singleProvider?.gender}
                  </div>
                ) : null}
              </div>
              <div>
                {singleProvider?.gender ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">DOB : </span>
                    {singleProvider?.dob ? moment(singleProvider?.dob).format("MM-DD-YYYY") : null}
                  </div>
                ) : null}
                {singleProvider?.mobileNumber ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">Mobile Number : </span>
                    {formatPhoneNumber(singleProvider?.mobileNumber)}
                  </div>
                ) : null}
                {Specialization?.length ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">Specializations : </span>
                    {Specialization?.map((item, index) => (
                      <div className="mr-3">
                        <span>{index + 1}. </span>
                        <span key={index}>{item?.specialization}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <ProviderAddress handleUpdateAddressShow={handleUpdateAddressShow} />
        </Col>
      </Row>


      <UpdateProvider
        singleProvider={singleProvider}
        updateProviderShow={updateProviderShow}
        setUpdateProviderShow={setUpdateProviderShow}
      />
      {/* <UpdateNPI
        singleProvider={singleProvider}
        updateNpiShow={updateNpiShow}
        setUpdateNpiShow={setUpdateNpiShow}
      />
      <UpdateTaxonomy
        singleProvider={singleProvider}
        updateTaxonomyShow={updateTaxonomyShow}
        setUpdateTaxonomyShow={setUpdateTaxonomyShow}
      />
      <AddTaxonomy
        singleProvider={singleProvider}
        addTaxonomyShow={addTaxonomyShow}
        setAddTaxonomyShow={setAddTaxonomyShow}
      />
      <UpdateSpecialization
        singleProvider={singleProvider}
        updateSpecializationShow={updateSpecializationShow}
        setUpdateSpecializationShow={setUpdateSpecializationShow}
      />
      <AddSpecialization
        singleProvider={singleProvider}
        addSpecializationShow={addSpecializationShow}
        setAddSpecializationShow={setAddSpecializationShow}
      /> */}
      <UpdateAddress
        singleProvider={singleProvider}
        updateAddressShow={updateAddressShow}
        setUpdateAddressShow={setUpdateAddressShow}
      />

      <Modal
        footer={null}
        open={showActions}
        onCancel={() => setShowActions(false)}
        title={<h4 className="text-xl">Provider Actions</h4>}>
        <Button block className="mt-4" onClick={handleUpdateProviderShow}>
          Update Provider Info
        </Button>
        {/* <Button block className="mt-2" onClick={handleUpdateNpiShow}>
          Update NPI
        </Button>
        <Button block className="mt-2" onClick={handleAddTaxonomiesShow}>
          Add Taxonomies
        </Button>
        <Button block className="mt-2" onClick={handleUpdateTaxonomiesShow}>
          View/Delete Taxonomies
        </Button>
        <Button block className="mt-2" onClick={handleAddSpecializationShow}>
          Add Specializations
        </Button>
        <Button block className="mt-2" onClick={handleUpdateSpecializationShow}>
          View/Delete Specializations
        </Button> */}
      </Modal>
    </>
  )
}

export default ProviderDetailHeader
