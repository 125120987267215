import React, { useState } from "react"
import { Button, Modal, Select } from "antd"
import {
  deleteMapPractitionerToProviderAction,
  getPractitionerProvidersList,
  mapPractitionerToProviderAction,
  resetMapPractitionerToProvider
} from "../../redux/actions/practitionerAction"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProviderList } from "../../redux/actions/providerAction"
import Loading from "../layouts/Loading/Loading"
import { useToasts } from "react-toast-notifications"
import { useParams } from "react-router-dom"

const PROVIDER_SELECT_PLACEHOLDER = "Select Provider"
const SUCCESS_UPDATE_PROVIDER = "Provider(s) Updated Successfully"

const UpdateMappedProviders = props => {
  const { practitionerId, updateMappedProviderShow, setUpdateMappedProviderShow } = props
  const [options, setOptions] = useState([])
  const [selectedIds, setSelectedIds] = useState([])

  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const {orgId} = useParams()

  const { loading: allProvidersLoading, providerList: allProviders } = useSelector(
    state => state.providerList
  )
  const { loading: practitionerProvidersLoading, practitionerProviders } = useSelector(
    state => state.practitionerProviders
  )

  const { loading: mapPractitionerToProviderLoading, error: mapError } = useSelector(
    state => state.mapPractitionerToProvider
  )

  useEffect(() => {
    if (!practitionerId || !updateMappedProviderShow) return
    dispatch(resetMapPractitionerToProvider())
    dispatch(getProviderList(orgId))
    dispatch(getPractitionerProvidersList(practitionerId))
  }, [updateMappedProviderShow, practitionerId])

  useEffect(() => {
    const ids = practitionerProviders?.map(({ id }) => id)
    setSelectedIds(ids)
  }, [allProviders])

  useEffect(() => {
    if (!allProviders) return
    const providers = allProviders?.map(provider => ({
      label: `${provider?.firstName} ${provider?.lastName}`,
      value: provider?.id
    }))
    setOptions(providers)
  }, [allProviders])

  const onSubmit = async () => {
    try {
      const actualProviderIds = practitionerProviders?.map(({ id }) => id)

      const providersToBeMap = selectedIds?.filter(id => !actualProviderIds.includes(id)) // create
      for (const providerId of providersToBeMap) {
        const isDone = await dispatch(
          mapPractitionerToProviderAction({ practitionerId, providerId })
        )
        if (!isDone) throw ""
      }

      const providersToBeUnlink = actualProviderIds?.filter(id => !selectedIds.includes(id)) // delete
      for (const providerId of providersToBeUnlink) {
        const isDone = await dispatch(
          deleteMapPractitionerToProviderAction({ practitionerId, providerId })
        )
        if (!isDone) throw ""
      }
      setUpdateMappedProviderShow(false) // close modal
      addToast(SUCCESS_UPDATE_PROVIDER, {
        appearance: "success",
        autoDismiss: true
      })
    } catch (err) {}
  }

  return (
    <Modal
      width={800}
      footer={null}
      open={updateMappedProviderShow}
      onCancel={() => setUpdateMappedProviderShow(false)}
      title={<h4 className="text-xl">Update Provider</h4>}>
      {allProvidersLoading || practitionerProvidersLoading ? (
        <Loading />
      ) : (
        <>
          <label htmlFor="select-provider" className="opacity-70 mt-6 mb-2">
            Provider
          </label>
          <Select
            mode="multiple"
            className="w-full"
            size="large"
            value={selectedIds}
            options={options}
            onChange={setSelectedIds}
            id="select-provider"
            placeholder={PROVIDER_SELECT_PLACEHOLDER}
            // filterOption is to enable the search feature
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
          <p className="my-3 px-2 min-h-[40px] text-danger">{mapError}</p>
          <Button
            size="large"
            type="primary"
            onClick={onSubmit}
            loading={mapPractitionerToProviderLoading}>
            Submit
          </Button>
        </>
      )}
    </Modal>
  )
}

export default UpdateMappedProviders
