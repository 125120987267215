import React, { useEffect, useState } from "react"

import { Card } from "antd"

import CaretakerNewTable from "./CaretakerNewTable"
import CaretakerFilters, { sortBy } from "./CaretakerFilters"

import { useDispatch, useSelector } from "react-redux"
import { getCaretakerListAction, resetCaretakerPatientAdvanceFilter } from "../../redux/actions/caretakerActions"
import { resetFilterUsersAction } from "../../redux/actions/ccmActions"
import { useParams } from "react-router-dom"
import OrgBackNavigation from "../Organization/OrgBackNavigation"
import formatPhoneNumber from "../../utils/formatPhoneNumber"

const CareTaker = () => {
  const [sortColumn, setSortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })
  const [pageSize, setPageSize] = useState(10)
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1) //eslint-disable-line

  // ADD CARETAKER
  const [showAddCaretaker, setShowAddCaretaker] = useState(false)

  const handleCaretakerModalClose = () => setShowAddCaretaker(false)
  const handleCaretakerShow = () => setShowAddCaretaker(true)

  const { loading, caretakerLists } = useSelector(state => state.caretakerList)

  const dispatch = useDispatch()
  const {orgId, orgName} = useParams()

  useEffect(() => {
    dispatch(getCaretakerListAction(orgId))
  }, [dispatch])

  useEffect(() => {
    dispatch(resetFilterUsersAction())
    dispatch(resetCaretakerPatientAdvanceFilter())
}, [])

  const handleSort = (path, order) => {
    setSortColumn({ path, order })
  }

  let sortedData
  let allCaretakerLists
  if (caretakerLists !== undefined) allCaretakerLists = caretakerLists
  const a = [1, 2]

  if (caretakerLists !== undefined) {
    if (searchQuery) {
      const trimmedSearchValue = searchQuery.replace(/\s+/g, "").toLowerCase()
      allCaretakerLists = caretakerLists?.filter(
        m =>
          // m?.userName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          `${m?.firstName?.toLowerCase()}${
            m?.middleName?.toLowerCase() ?? ""
          }${m?.lastName?.toLowerCase()}`
            .replace("  ", " ")
            .includes(trimmedSearchValue) ||
          m?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          m?.id?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          m?.mobileNumber?.includes(searchQuery) ||
          formatPhoneNumber(m?.mobileNumber)?.includes(searchQuery)
      )
    }
  }

  if (sortColumn.path === sortBy.AtoZ) {
    sortedData = allCaretakerLists?.sort((a, b) =>
      a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
    )
  }

  if (sortColumn.path === sortBy.ZtoA) {
    sortedData = allCaretakerLists?.sort((a, b) =>
      a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
    )
  }

  return (
    <div>
      <OrgBackNavigation />
      <Card className="mt-2" title={<h4 className="text-2xl">Caretakers List</h4>}>
        <CaretakerFilters
          pageSize={pageSize}
          onAscSort={handleSort}
          onDecSort={handleSort}
          setPageSize={setPageSize}
          searchQuery={searchQuery}
          onDefaultSort={handleSort}
          setSearchQuery={setSearchQuery}
          setCurrentPage={setCurrentPage}
          showAddCaretaker={showAddCaretaker}
          handleCaretakerModalClose={handleCaretakerModalClose}
          handleCaretakerShow={handleCaretakerShow}
          setShowAddCaretaker={setShowAddCaretaker}
        />
        <CaretakerNewTable loading={loading} sortedData={sortedData} />
      </Card>
    </div>
  )
}

export default CareTaker
