import React from "react"

const RPMEditFormInput = ({
  ques,
  handleNormalCheck,
  handleText,
  handleNormalText,
  handleSingle,
  handleBpmNone,
  handleWeightMachineCheck,
  handleWeightMachineOtherText,
  handleWeightMachineNone,
  handleBool,
  quesDisabled
}) => {
  switch (ques.quesType) {
    case "text":
      return (
        <div style={{ marginTop: "10px" }}>
          {ques.datefield ? (
            <input
              required
              id={ques.id}
              type="date"
              disabled={quesDisabled ? true : ques.notApplicable ? true : false}
              style={{ width: "100%", height: "50px" }}
              value={ques.text}
              //onClick={e=>console.log(ques.id)}//only id
              onChange={e => {
                handleNormalText(ques.id, e, ques)
              }}
            />
          ) : (
            <textarea
              required
              id={ques.id}
              type="text"
              disabled={quesDisabled ? true : ques.notApplicable ? true : false}
              style={{ width: "100%", height: "50px" }}
              value={ques.notApplicable ? "" : ques.value ? ques.value : ""}
              //onClick={e=>console.log(ques.id)}//only id
              onChange={e => {
                handleNormalText(ques.id, e, ques)
              }}></textarea>
          )}
        </div>
      )
    case "single":
      return (
        <div style={{ marginTop: "10px" }}>
          <div className="d-flex align-items-center justify-content-evenly">
            {ques.questionaryOptions.map((item, i) => (
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  id={item.id}
                  name="single-select"
                  checked={item.checked}
                  onChange={e => handleSingle(ques.id, i, e, ques)}
                  disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                />
                <label className="text-capitalize">&nbsp;{item.name}</label>
              </div>
            ))}
          </div>
        </div>
      )
    case "bool":
      return (
        <div style={{ marginTop: "10px" }}>
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <label>Yes</label>
              <input
                className="mx-2"
                type="radio"
                id={ques.id}
                name={`bool-select-4-${ques.id}`}
                checked={ques.questionaryOptions[0] === "true" ? true : false}
                value="true"
                onChange={e => handleBool(ques.id, e, ques)}
                disabled={quesDisabled ? true : ques.notApplicable ? true : false}
              />
            </div>
            <div className="d-flex mx-5">
              <label>No</label>
              <input
                className="mx-2"
                type="radio"
                id={ques.id}
                name={`bool-select-4-${ques.id}`}
                checked={ques.questionaryOptions[0] === "false" ? true : false}
                value="false"
                onChange={e => handleBool(ques.id, e, ques)}
                disabled={quesDisabled ? true : ques.notApplicable ? true : false}
              />
            </div>
          </div>
        </div>
      )
    case "multi":
      return (
        <div style={{ marginTop: "10px" }}>
          {ques.device === undefined ? (
            <>
              {ques.questionaryOptions.map((item, i) => (
                <div className="d-flex mb-1" onClick={e => console.log(ques.id, i)}>
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={item.checked}
                    onChange={e => handleNormalCheck(ques.id, i, e, ques)}
                    // disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                    // disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                    disabled={
                      (quesDisabled ? true : ques.notApplicable ? true : false) || item.disabled
                    }
                  />
                  <label className="text-capitalize mx-3">{item.name}</label>
                </div>
              ))}
            </>
          ) : ques.device === "blood pressure machine" || ques.device === "glucose machine" ? (
            ques.questionaryOptions.map((item, i) =>
              item.name === "Other" ? (
                <>
                  <div className="d-flex mb-1">
                    <input
                      type="checkbox"
                      id={item.id}
                      checked={item.checked}
                      onChange={e => handleNormalCheck(ques.id, i, e, ques)}
                      // disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                      // disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                      disabled={
                        (quesDisabled ? true : ques.notApplicable ? true : false) || item.disabled
                      }
                    />
                    <label className="mx-3 text-capitalize">{item.name}</label>
                  </div>
                  {item.checked === true && (
                    <div>
                      <textarea
                        type="text"
                        style={{ width: "100%", height: "50px" }}
                        onChange={e => handleText(ques.id, i, e, ques)}
                        //    disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        // disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                        disabled={
                          (quesDisabled ? true : ques.notApplicable ? true : false) || item.disabled
                        }
                        value={item.text}></textarea>
                    </div>
                  )}
                </>
              ) : item.name === "None of the above" ? (
                <>
                  <div className="d-flex mb-1">
                    <input
                      type="checkbox"
                      id={item.id}
                      checked={item.checked}
                      onChange={e => handleBpmNone(ques.id, i, e, ques)}
                      disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                    />
                    <label className="mx-3 text-capitalize">{item.name}</label>
                  </div>
                </>
              ) : (
                <div className="d-flex mb-1">
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={item.checked}
                    onChange={e => handleNormalCheck(ques.id, i, e, ques)}
                    //disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                    // disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                    disabled={
                      (quesDisabled ? true : ques.notApplicable ? true : false) || item.disabled
                    }
                  />
                  <label className="mx-3 text-capitalize">{item.name}</label>
                </div>
              )
            )
          ) : ques.device === "weight machine" || ques.device === "oximeter" ? (
            ques.questionaryOptions.map((item, i) => (
              <>
                <div className="d-flex mb-1">
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={item.checked}
                    onChange={e => handleWeightMachineCheck(ques.id, i, e, ques)}
                    //disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                    disabled={
                      (quesDisabled ? true : ques.notApplicable ? true : false) || item.disabled
                    }
                  />
                  <label className="mx-3 text-capitalize">{item.name}</label>
                </div>
                {item.checked === true
                  ? item.subQuestion.map((sub_ques, ii) => (
                      <div className="card shadow rounded p-4 my-4">
                        <h6 className="mb-3 text-capitalize">{sub_ques.name}</h6>
                        {sub_ques.questionaryOptions.map((inner_ques, iii) =>
                          inner_ques.name === "Other" ? (
                            <>
                              <div className="d-flex mb-1">
                                <input
                                  type="checkbox"
                                  id={inner_ques.id}
                                  checked={inner_ques.checked}
                                  onChange={e =>
                                    handleWeightMachineCheck(ques.id, i, e, ii, iii, ques)
                                  }
                                  // disabled={inner_ques.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                  //disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                                  disabled={
                                    (quesDisabled ? true : ques.notApplicable ? true : false) ||
                                    inner_ques.disabled
                                  }
                                />
                                <label className="mx-3 text-capitalize">{inner_ques.name}</label>
                              </div>
                              {inner_ques.checked === true && (
                                <div>
                                  <textarea
                                    type="text"
                                    style={{ width: "100%", height: "50px" }}
                                    onChange={e =>
                                      handleWeightMachineOtherText(ques.id, i, e, ii, iii, ques)
                                    }
                                    //    disabled={inner_ques.disabled && inner_ques.disabled}
                                    //    disabled={inner_ques.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                    // disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                                    disabled={
                                      (quesDisabled ? true : ques.notApplicable ? true : false) ||
                                      inner_ques.disabled
                                    }
                                    value={inner_ques.text}></textarea>
                                </div>
                              )}
                            </>
                          ) : inner_ques.name === "None of the above" ? (
                            <div className="d-flex mb-1">
                              <input
                                type="checkbox"
                                id={inner_ques.id}
                                checked={inner_ques.checked}
                                onChange={e =>
                                  handleWeightMachineNone(ques.id, i, e, ii, iii, ques)
                                }
                                disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                              />
                              <label className="mx-3 text-capitalize">{inner_ques.name}</label>
                            </div>
                          ) : (
                            <div className="d-flex mb-1">
                              <input
                                type="checkbox"
                                id={inner_ques.id}
                                checked={inner_ques.checked}
                                onChange={e =>
                                  handleWeightMachineCheck(ques.id, i, e, ii, iii, ques)
                                }
                                //disabled={inner_ques.disabled && inner_ques.disabled}
                                //   disabled={inner_ques.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                //disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                                disabled={
                                  (quesDisabled ? true : ques.notApplicable ? true : false) ||
                                  inner_ques.disabled
                                }
                              />
                              <label className="mx-3 text-capitalize">{inner_ques.name}</label>
                            </div>
                          )
                        )}
                      </div>
                    ))
                  : null}
              </>
            ))
          ) : // ques.device === 'oximeter'?
          // ques.questionaryOptions.map((item,i)=>(
          //     <>
          //     <div className="d-flex mb-1">
          //     <input type="checkbox" id={item.id} checked={item.checked} onClick={e=>console.log(ques.id,i)}/>
          //     <label className="mx-3">{item.name}</label>
          //     </div>
          //     {item.checked === false ?
          //     item.subQuestion.map((sub_ques,ii)=>(
          //         <div className="card shadow rounded p-4 my-4">
          //             <h6 className="mb-3">{sub_ques.name}</h6>
          //             {sub_ques.questionaryOptions.map((inner_ques,iii)=>(
          //               <div className="d-flex mb-1">
          //               <input type="checkbox" id={inner_ques.id} checked={inner_ques.checked} onClick={e=>console.log(ques.id,i,ii,iii)}/>
          //               <label className="mx-3">{inner_ques.name}</label>
          //               </div>
          //             ))}
          //         </div>
          //     ))
          // :
          // null
          // }

          //     </>
          // ))

          //:
          null}
        </div>
      )
    case "multi-field":
      return (
        <div style={{ marginTop: "10px" }}>
          <>
            {ques.questionaryOptions.map((item, i) =>
              item.field ? (
                <>
                  <div className="my-3">
                    <label className="text-capitalize">{item.name}</label>
                    <br></br>
                    <textarea
                      type="text"
                      style={{ width: "100%", height: "50px" }}
                      value={item.text}
                      onChange={e => handleText(ques.id, i, e, ques)}
                      disabled={quesDisabled ? true : ques.notApplicable ? true : false}></textarea>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex mb-1">
                    <input
                      type="checkbox"
                      id={item.id}
                      checked={item.checked}
                      onChange={e => handleNormalCheck(ques.id, i, e, ques)}
                      disabled={quesDisabled ? true : ques.notApplicable ? true : false}
                    />
                    <label className="mx-3 text-capitalize">{item.name}</label>
                  </div>
                </>
              )
            )}
          </>
        </div>
      )
    default:
      return <div>Invalid question</div>
  }
}

export default RPMEditFormInput
