import { axiosPost } from "../axios"
import {
  GET_ALL_ORG_FAIL,
  GET_ALL_ORG_REQUEST,
  GET_ALL_ORG_SUCCESS
} from "../types/orgDetailsConstant"
import { userLogoutAction } from "./userActions"

export const getAllOrgAction = (startDate, endDate) => async dispatch => {
  try {
    dispatch({
      type: GET_ALL_ORG_REQUEST
    })
    const userInfo =
    localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const { data } = await axiosPost(`api/cs2/org/admin/${userInfo?.id}/fetch`, { startDate, endDate })
    dispatch({
      type: GET_ALL_ORG_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ALL_ORG_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}
