import React from "react"
import { Card, Col, Row } from "antd"

import moment from "moment"

const PatientDetailedReport = props => {
  const {
    patientInfo,
    patientInfoDetail,
    deviceData,
    providerList,
    getPatientReport,
    chronicConcernId,
    pdfDivReference,
    chronicConcernCondition,
    devicesGiven,
    patientBpReading,
    patientWmReading,
    patientOmReading
  } = props

  const dateFormat = value => {
    const date = new Date(value * 1000)
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    return formattedDate
  }

  return (
    patientInfo !== undefined && (
      <div className="patient-report-card" id="patient-report-card" ref={pdfDivReference}>
        <div className="p-4">
          <Row className="mb-2">
            <Col sm={24} md={12} className="float-end">
              <p className="mb-1">
                Patient Name : <span className="fw-bold">{patientInfo.name}</span>
              </p>
              <p className="mb-1">
                Monitor Types :{" "}
                {deviceData !== undefined &&
                  Array.from(deviceData)
                    .map(element => element)
                    .join(", ")}
              </p>
              <p className="mb-1">
                Providers :{" "}
                {patientInfo.providerId
                  ? providerList !== undefined &&
                    providerList.providerList.map(({ name, providerId }) => {
                      if (providerId === patientInfo.providerId) {
                        return name
                      }
                      return null
                    })
                  : "No Provider is Selected for this Patient!"}
              </p>
              <p className="mb-1">
                CareTaker :{" "}
                {patientInfo.careTaker !== ""
                  ? patientInfo.careTaker
                  : "No Caretaker is Selected for this Patient!"}
              </p>
              <p className="mb-1">
                Total readings taken : {patientInfoDetail?.userData?.readingCount} readings
              </p>
            </Col>
            <Col sm={24} md={12} className="d-flex flex-column align-items-end fw-bolder">
              <p>
                This report is from{" "}
                <span>{moment.unix(`${getPatientReport?.startDate}`).format("DD/MM/YYYY")}</span> to{" "}
                <span>{moment.unix(`${getPatientReport?.endDate}`).format("DD/MM/YYYY")}</span>
              </p>
            </Col>
          </Row>
          <div className="d-flex">
            <p className="fw-bolder me-2">Diagnosis : </p>
            <p>
              {Array.from(chronicConcernId)
                .map(value => value)
                .join(", ")}
            </p>
          </div>
          <div>
            {/* <h4>Plans:</h4> */}
            <h5 className="mb-0">Care Plan : </h5>
            <p>
              <span className="ms-5" />{" "}
              <span className="fw-bold">
                {patientInfo.gender === "male" ? "Mr." : "Mrs./Miss"} {patientInfo.name}
              </span>{" "}
              currently has the following chronic conditions:{" "}
              <span className="fw-bold">
                {Array.from(chronicConcernCondition)
                  .map(value => value)
                  .join(", ")}
              </span>{" "}
              for which he is being monitored. The patient has been enrolled in our _______________
              program since _______________ and is in possession of the following devices:{" "}
              <span className="fw-bold">
                {Array.from(devicesGiven)
                  .map(value => value)
                  .join(", ")}
              </span>
              . The healthcare provider ordered the devices to collect health data from the patient
              and to monitor/personalize their plan of care remotely. The patient has been provided
              informed verbal consent for the RPM program at the time of annual enrollment. Care
              Coordinator RN communicated{" "}
              <span className="fw-bold">
                {patientInfoDetail?.admin?.userName[0].toUpperCase() +
                  patientInfoDetail?.admin?.userName.slice(1)}
              </span>{" "}
              with the patient to review the plan of care. The patient has been educated on how to
              properly utilize the equipment and the importance of monitoring their vital signs
              daily via the FitPeo equipment provided.
            </p>
          </div>
          {deviceData !== undefined &&
            Array.from(deviceData).map(element => (
              <>
                {getPatientReport !== undefined &&
                  element === "CCM" &&
                  getPatientReport.data?.data?.manualLog?.data?.CCM && (
                    <>
                      <h5 className="mb-3">CCM Log : </h5>
                      <Row>
                        {getPatientReport.data?.data?.manualLog?.data?.CCM.map((data, index) => (
                          <Col key={index} xs={24} md={8} className="mb-3">
                            <Card className="h-100">
                              <div>
                                <div>
                                  <span className="fw-bolder">Date : </span>
                                  {moment.unix(data.date).format("DD/MM/YYYY")}
                                </div>
                                <div>
                                  <span className="fw-bolder">Care : </span>
                                  {data.care?.toString()}
                                </div>
                                <div>
                                  <span className="fw-bolder">Time : </span>
                                  {data.time} minutes
                                </div>
                                <div>
                                  <span className="fw-bolder">Admin : </span>
                                  {data.admin}
                                </div>
                                <div>
                                  <span className="fw-bolder">Notes : </span>
                                  {data.notes}
                                </div>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                {getPatientReport !== undefined &&
                  element === "RPM" &&
                  getPatientReport.data?.data?.manualLog?.data?.RPM && (
                    <>
                      <h5 className="mb-3">RPM Log : </h5>
                      <Row>
                        {getPatientReport.data?.data?.manualLog?.data?.RPM.map((data, index) => (
                          <Col key={index} xs={24} md={8} className="mb-3">
                            <Card className="h-100">
                              <div>
                                <div>
                                  <span className="fw-bolder">Date : </span>
                                  {moment.unix(data.date).format("DD/MM/YYYY")}
                                </div>
                                <div>
                                  <span className="fw-bolder">Care : </span>
                                  {data.care?.toString()}
                                </div>
                                <div>
                                  <span className="fw-bolder">Time : </span>
                                  {data.time} minutes
                                </div>
                                <div>
                                  <span className="fw-bolder">Admin : </span>
                                  {data.admin}
                                </div>
                                <div>
                                  <span className="fw-bolder">Notes : </span>
                                  {data.notes}
                                </div>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                {getPatientReport !== undefined &&
                  element === "BHI" &&
                  getPatientReport.data?.data?.manualLog?.data?.BHI && (
                    <>
                      <h5 className="mb-3">BHI Log : </h5>
                      <Row>
                        {getPatientReport.data?.data?.manualLog?.data?.BHI.map((data, index) => (
                          <Col key={index} xs={24} md={8} className="mb-3">
                            <Card className="h-100">
                              <div>
                                <div>
                                  <span className="fw-bolder">Date : </span>
                                  {moment.unix(data.date).format("DD/MM/YYYY")}
                                </div>
                                <div>
                                  <span className="fw-bolder">Care : </span>
                                  {data.care?.toString()}
                                </div>
                                <div>
                                  <span className="fw-bolder">Time : </span>
                                  {data.time} minutes
                                </div>
                                <div>
                                  <span className="fw-bolder">Admin : </span>
                                  {data.admin}
                                </div>
                                <div>
                                  <span className="fw-bolder">Notes : </span>
                                  {data.notes}
                                </div>
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
              </>
            ))}
          {getPatientReport !== undefined && (
            <Card className="mb-3">
              <div>
                <h5 className="mb-0">
                  Total Calling Time : {getPatientReport?.data?.data?.callLog?.totalminutes} minutes
                </h5>
              </div>
            </Card>
          )}
          {getPatientReport !== undefined &&
            getPatientReport.data.data.callLog.callData.length > 0 && (
              <Card className="mb-3">
                <div>
                  <h5 className="mb-1">Total Calling Logs : </h5>
                  {getPatientReport.data.data.callLog.callData.length > 0 ? (
                    <Row>
                      {getPatientReport.data.data.callLog.callData.map(
                        ({ date, startTime, endTime }, index) => (
                          <Col key={index} l xs={24} md={8} className="mb-3">
                            <Card className="h-100">
                              <div>
                                <div>
                                  <span className="fw-bolder">Date : </span>
                                  {moment.unix(date).format("DD/MM/YYYY")}
                                </div>
                                <div>
                                  <span className="fw-bolder">Care : </span>
                                  {moment.unix(startTime).format("hh:MM")}
                                  <span>(in minutes)</span>
                                </div>
                                <div>
                                  <span className="fw-bolder">Care : </span>
                                  {moment.unix(endTime).format("hh:MM")}
                                  <span>(in minutes)</span>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        )
                      )}
                    </Row>
                  ) : (
                    <h5 className="mb-3 text-danger">No Logs</h5>
                  )}
                </div>
              </Card>
            )}
          <Card>
            <div>
              <h4 className="text-xl mt-2">List of Reading</h4>
              {patientWmReading !== undefined && patientWmReading?.readings.length !== 0 && (
                <Card className="mb-3">
                  <div>
                    <h4 className="text-xl mt-2">Weight Machine Readings</h4>
                    <table className="text-center">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Weight</th>
                          <th>Active Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientWmReading?.readings.slice(0, 30).map((reading, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {reading.weight}
                              {reading.weightUnit}
                            </td>
                            <td>{dateFormat(reading.date)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              )}
              {patientBpReading !== undefined && patientBpReading?.readings.length !== 0 && (
                <Card className="mb-3">
                  <div>
                    <h4 className="text-xl mt-2">Blood Pressure Readings</h4>
                    <table className="text-center">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Heart Rate</th>
                          <th>SYS</th>
                          <th>DYS</th>
                          <th>Active Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientBpReading?.readings.slice(0, 30).map((reading, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{reading.heartRate}</td>
                            <td>{reading.hightPressure}</td>
                            <td>{reading.lowPressure}</td>
                            <td>{dateFormat(reading.date)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              )}
              {patientOmReading !== undefined && patientOmReading?.readings.length !== 0 && (
                <Card className="mb-3">
                  <div>
                    <h4 className="text-xl mt-2">Oxymeter Readings</h4>
                    <table className="text-center">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Pulse Rate</th>
                          <th>SpO2 - Oxygen Range</th>
                          <th>Active Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientOmReading?.readings.slice(0, 30).map((reading, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{reading.pulseRate}</td>
                            <td>{reading.spo}</td>
                            <td>{dateFormat(reading.date)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              )}
            </div>
          </Card>
        </div>
      </div>
    )
  )
}

export default PatientDetailedReport
