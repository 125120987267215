import React, { useEffect } from "react"

import { Card } from "antd"

import { useSelector, useDispatch } from "react-redux"
// import { criticalPatientAction } from "../../redux/actions/dashboardAction"

import Loading from "../layouts/Loading/Loading"
import { useNavigate } from "react-router-dom"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const ProviderCards = () => {
  //const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, providerList } = useSelector(state => state.providerList)
  // const { criticalPatient } = useSelector(state => state.criticalPatient)
  // const { loading, dashboardKPI } = useSelector(state => state.dashboardKPI)

  // useEffect(() => {
  //   dispatch(criticalPatientAction())
  // }, [])

  return <>
    <OrgBackNavigation />
    {loading ? (
    <Loading />
  ) : (
    <div className="provider-cards flex flex-wrap gap-4">
      <Card className="min-w-[300px] mt-2" loading={providerList === undefined}>
        <div className="flex items-center flex-col">
          <img alt="icon-providers" src="/assets/doctor.png" className="w-[50px]" />
          {/* {dashboardKPI ? <p className="my-3">{dashboardKPI["0"]?.name}</p> : null} */}
          <p className="my-3">Total Providers</p>
          <p className="text-3xl font-semibold text-current">{providerList?.length}</p>
        </div>
      </Card>
      {/* <Card className="min-w-[300px]" loading={dashboardKPI === undefined}>
        <div className="flex items-center flex-col">
          <img alt="icon-onboarding" src="/assets/icon-group.png" />
          {dashboardKPI ? <p className="my-3">{dashboardKPI["1"]?.name}</p> : null}
          <p className="text-3xl font-semibold text-current">{providerList?.length}</p>
        </div>
      </Card> */}
    </div>
  )}</>
}

export default ProviderCards
