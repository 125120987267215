import {
  GET_CCM_FAIL,
  GET_CCM_REQUEST,
  GET_CCM_SUCCESS,
  POST_CCM_USER_FAIL,
  POST_CCM_USER_REQUEST,
  POST_CCM_USER_SUCCESS,
  GET_CCM_CAREPLAN_REQUEST,
  GET_CCM_CAREPLAN_SUCCESS,
  GET_CCM_CAREPLAN_FAIL,
  GET_FACILITY_ADDRESS_REQUEST,
  GET_FACILITY_ADDRESS_SUCCESS,
  GET_FACILITY_ADDRESS_FAIL,
  GET_FILTER_USERS_REQUEST,
  RESET_FILTER_USERS_REQUEST,
  GET_FILTER_USERS_SUCCESS,
  GET_FILTER_USERS_FAIL,
  GET_USER_SEARCH_REQUEST,
  GET_USER_SEARCH_SUCCESS,
  GET_USER_SEARCH_FAIL,
  GET_CCM_FOLLOW_UP_REQUEST,
  GET_CCM_FOLLOW_UP_SUCCESS,
  GET_CCM_FOLLOW_UP_FAIL,
  GET_PREV_CAREPLANS_REQUEST,
  GET_PREV_CAREPLANS_SUCCESS,
  GET_PREV_CAREPLANS_FAIL,
  GET_DASHBOARD_FILTER_USERS_REQUEST,
  GET_DASHBOARD_FILTER_USERS_SUCCESS,
  GET_DASHBOARD_FILTER_USERS_FAIL,
  GET_FILTER_USERS_THROUGH_DASHBOARD_SUCCESS,
  GET_FILTER_USERS_THROUGH_DASHBOARD_FAIL,
  GET_CCM_ARCHIVE_PLANS_REQUEST,
  GET_CCM_ARCHIVE_PLANS_SUCCESS,
  GET_CCM_ARCHIVE_PLANS_FAIL,
  UPDATE_CCM_PLAN_REQUEST,
  UPDATE_CCM_PLAN_SUCCESS,
  UPDATE_CCM_PLAN_FAIL,
  POST_CCM_FOLLOW_UP_REQUEST,
  POST_CCM_FOLLOW_UP_SUCCESS,
  POST_CCM_FOLLOW_UP_FAIL,
  GET_LATEST_CCM_CAREPLAN_REQUEST,
  GET_LATEST_CCM_CAREPLAN_SUCCESS,
  GET_LATEST_CCM_CAREPLAN_FAIL,
} from "../types/ccmConstant"
import { axiosGet, axiosPost } from "../axios"
import { userLogoutAction } from "./userActions"
import moment from "moment"

export const getccmDataActions = ({orgId, ccmMonitorId}) => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/careplan/orgId/${orgId}/monitoring/${ccmMonitorId}/fetch`)

    dispatch({
      type: GET_CCM_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}
export const postCcmPlan = ({patientId, ccmMonitorId, formInputs}) => async dispatch => {
  try {
    dispatch({
      type: POST_CCM_USER_REQUEST
    })

    // console.log('formInputs',formInputs);
    // debugger

    const { data } = await axiosPost(`/api/ccm/careplan/comprehensive/${ccmMonitorId}/patient/${patientId}/create`, {
      planDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      plan: formInputs
    })

    dispatch({
      type: POST_CCM_USER_SUCCESS,
      payload: data
    })
    // window.location.href = "/ccm/new-patients"
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CCM_USER_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const postCcmFollowUpPlanActions = ({previousPlanId, patientId, ccmProgramId, plan}) => async dispatch => {
  try {
    dispatch({
      type: POST_CCM_FOLLOW_UP_REQUEST
    })

    const { data } = await axiosPost(`/api/ccm/careplan/comprehensive/${ccmProgramId}/followup/${patientId}/create`, {
      previousPlanId,
      planDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      plan
    })

    dispatch({
      type: POST_CCM_FOLLOW_UP_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CCM_FOLLOW_UP_FAIL,
      payload:
        error.response && error.response.data.details ? error.response.data.details : error.detail
    })
  }
}

export const updateCcmPlanActions = ({patientId, planId, formInputs}) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CCM_PLAN_REQUEST
    })

    const { data } = await axiosPost(`/api/ccm/careplan/comprehensive/${patientId}/update/${planId}`, {
      planDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      plan: formInputs
    })

    dispatch({
      type: UPDATE_CCM_PLAN_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_CCM_PLAN_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const getLatestCareplanActions = ({patientId}) => async dispatch => {
  try {
    dispatch({
      type: GET_LATEST_CCM_CAREPLAN_REQUEST
    })
    const { data } = await axiosGet(`/api/ccm/careplan/comprehensive/latest/${patientId}/fetch`)
    
    dispatch({
      type: GET_LATEST_CCM_CAREPLAN_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_LATEST_CCM_CAREPLAN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}


export const getccmCareplanDataActions = ({patientId}) => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_CAREPLAN_REQUEST
    })
    // let data
    // // console.log("year--", year, "month--", month)
    // if (year !== undefined && month !== undefined && userId !== undefined) {
    //   // const {data} = await ...
    //   data = await axiosGet(
    //     `/support/v1/getCCMCareplan?userId=${userId}&month=${month}&year=${year}`
    //   )
    // } else if (userId !== undefined) {
    //   data = await axiosGet(`/support/v1/getCCMCareplan?userId=${userId}`)
    // }
    const { data } = await axiosGet(`/api/ccm/careplan/comprehensive/${patientId}/fetch`)
    
    dispatch({
      type: GET_CCM_CAREPLAN_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_CAREPLAN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCcmArchivePlansActions = ({patientId, startDate, endDate}) => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_ARCHIVE_PLANS_REQUEST
    })
    const { data } = await axiosPost(`/api/ccm/careplan/comprehensive/${patientId}/archive`, {startDate, endDate})

    dispatch({
      type: GET_CCM_ARCHIVE_PLANS_SUCCESS,
      payload: data.details
    })
    return data.details
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_ARCHIVE_PLANS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getFacilityAddressAction = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_FACILITY_ADDRESS_REQUEST
    })
    // console.log(orgId)
    const { data } = await axiosGet(`/support/v1/getFaciltiyAddressByOrgId?orgId=${orgId}`)
    // console.log('facility address action',data)
    dispatch({
      type: GET_FACILITY_ADDRESS_SUCCESS,
      payload: data.organizations
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FACILITY_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getFilterUsersAction = (formData, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_FILTER_USERS_REQUEST
    })
    const { data } = await axiosPost(
      `/api/cs/patients/filters/orgId/${orgId}/fetch`,
      formData
    )
    dispatch({
      type: GET_FILTER_USERS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FILTER_USERS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getDashboardOnboardingPatientAction = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_DASHBOARD_FILTER_USERS_REQUEST
    })
    const { data } = await axiosPost(`/admin/v3/getUnregisteredUsers?orgId=${orgId}`)
    dispatch({
      type: GET_DASHBOARD_FILTER_USERS_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_DASHBOARD_FILTER_USERS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getOnboardingPatientAction = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_FILTER_USERS_REQUEST
    })
    const { data } = await axiosPost(`/admin/v3/getUnregisteredUsers?orgId=${orgId}`)
    dispatch({
      // type: GET_FILTER_USERS_SUCCESS,
      type: GET_FILTER_USERS_THROUGH_DASHBOARD_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      // type: GET_FILTER_USERS_FAIL,
      type: GET_FILTER_USERS_THROUGH_DASHBOARD_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const resetFilterUsersAction = () => async dispatch => {
  try {
    dispatch({
      type: RESET_FILTER_USERS_REQUEST
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FILTER_USERS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUserSearchFilters = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_USER_SEARCH_REQUEST
    })
    const { data } = await axiosGet(`/filter/v1/getUserSearchFilters?orgId=${orgId}`)
    dispatch({
      type: GET_USER_SEARCH_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_USER_SEARCH_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUsersForCcmFollowUpAction = (month, year, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_CCM_FOLLOW_UP_REQUEST
    })
    const { data } = await axiosGet(
      `/admin/v1/getUserForCCMFollowUpPlan?month=${month}&year=${year}&orgId=${orgId}`
    )
    dispatch({
      type: GET_CCM_FOLLOW_UP_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CCM_FOLLOW_UP_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getPreviousCareplansAction = id => async dispatch => {
  try {
    dispatch({
      type: GET_PREV_CAREPLANS_REQUEST
    })
    const { data } = await axiosGet(`/admin/v1/getUsersCareplanStats?userId=${id}`)
    dispatch({
      type: GET_PREV_CAREPLANS_SUCCESS,
      payload: data?.result
    })
    return data?.result
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PREV_CAREPLANS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}
