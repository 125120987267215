export const base64ImageConvertor = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      const result = fileReader?.result?.split(",")[1]
      resolve(result.substr(result.indexOf(",") + 1))
      // resolve(fileReader.result);
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}
