/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Modal, Typography, theme } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { CloseOutlined } from "@ant-design/icons"
import { useToasts } from "react-toast-notifications"
import {
  BulkMessageMailActionIntroduction,
  BulkMessageMailActionWelcome,
  MessageType
} from "./Types"
import { postBulkMailMessagePatientAction } from "../../../redux/actions/selfOnboardingAction"

const { useToken } = theme

const OnboardingActionConfirmationModal = props => {
  const {
    setIsModalOpen,
    fromDropdown,
    selectedStage,
    onBoardingStageSelectionClear,
    selectedRowKeys
  } = props
  const dispatch = useDispatch()
  const { token } = useToken()
  const { isDark } = useSelector(state => state.themeConfig)
  const { loading: postLoading, error: postError } = useSelector(
    state => state.postBulkMessagePatient
  )
  const { addToast } = useToasts()

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleConfirmation = async () => {
    let messageType
    if (
      // @ts-expect-error
      BulkMessageMailActionIntroduction[selectedStage] ===
      BulkMessageMailActionIntroduction.sendIntroductionMail
    )
      messageType = MessageType.IntroductionMail
    else if (
      // @ts-expect-error
      BulkMessageMailActionIntroduction[selectedStage] ===
      BulkMessageMailActionIntroduction.sendIntroductionMessageToPhone
    )
      messageType = MessageType.IntroductionMessage
    else if (
      // @ts-expect-error
      BulkMessageMailActionWelcome[selectedStage] ===
      BulkMessageMailActionWelcome.SendWelcomeToFitPeoMail
    )
      messageType = MessageType.WelcomeMail
    else if (
      // @ts-expect-error
      BulkMessageMailActionWelcome[selectedStage] ===
      BulkMessageMailActionWelcome.SendWelcomeMessageToPhone
    ) {
      messageType = MessageType.WelcomeMessage
    }
    const formData = {
      patientIds: [...selectedRowKeys],
      messageType
    }
    const apiRes = await Promise.resolve(dispatch(postBulkMailMessagePatientAction(formData)))

    // @ts-expect-error
    if (apiRes?.status === 200) {
      closeModal()
      addToast(<>{fromDropdown} successfully for the selected patients</>, {
        appearance: "success",
        autoDismiss: true
      })
      // onBoardingStageSelectionClear()
    } else {
      console.log("error occured")
      addToast(postError, { appearance: "error", autoDismiss: true })
    }
  }
  return (
    <Modal
      styles={{
        content: {
          borderRadius: "6px",
          padding: 0,
          overflow: "hidden"
        }
      }}
      classNames={{ body: "flex flex-col  m-2" }}
      footer={null}
      centered
      open={true}
      closeIcon={
        <span className='text-["#fff"]'>
          <CloseOutlined />
        </span>
      }
      onCancel={closeModal}
      destroyOnClose>
      <Typography.Title
        className=" bg-[#d7a66d] w-full rounded-t-md p-4"
        level={4}
        style={{
          color: "#fff"
        }}>
        {<span className="capitalize">{fromDropdown}</span>}
      </Typography.Title>
      <div className="p-4">
        <Typography.Text className="text-xl">
          {`Are you sure you want to ${selectedStage?.replace(/([A-Z])/g, " $1")}? `}
        </Typography.Text>
        <br />

        <Typography.Text className="text-sm">
          This action will {selectedStage?.replace(/([A-Z])/g, " $1")} for the selected patients
        </Typography.Text>
      </div>
      <div
        className="mt-4 p-3 w-full rounded-b-md flex justify-between"
        style={{
          backgroundColor: isDark ? token?.Modal?.contentBg : "#F1F1F1",
          borderTop: isDark ? "1px solid rgba(255,255,255, 0.1)" : "none"
        }}>
        <Button size="large" className="color-primary" onClick={closeModal} disabled={postLoading}>
          Cancel
        </Button>
        <Button size="large" type="primary" onClick={handleConfirmation} loading={postLoading}>
          Confirm and Proceed
        </Button>
      </div>
    </Modal>
  )
}

export default OnboardingActionConfirmationModal
