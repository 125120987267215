import { useState, useEffect } from "react"

import { Row, Col, Input, Button, Modal } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"
import { updateCaretakerAddressAction } from "../../redux/actions/caretakerActions"

const schema = yup
  .object({
    street: yup.string().required("Invalid Street"),
    city: yup.string().required("Invalid City"),
    state: yup.string().required("Invalid State"),
    zipcode: yup.string().min(5).max(5).required("Invalid Zipcode")
  })
  .required()

const UpdateCaretakerAddress = props => {
  const { caretakerId, currentAddress, showUpdateAddress, setShowUpdateAddress } = props

  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [addressError, setAddressError] = useState("")

  useEffect(() => {
    if (!showUpdateAddress) setAddressError("")
  }, [showUpdateAddress])

  const { loading } = useSelector(store => store.updateCaretakerAddress)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      street: currentAddress?.street || "",
      city: currentAddress?.city || "",
      state: currentAddress?.state || "",
      zipcode: currentAddress?.zipcode || ""
    }
  })

  const onSubmit = async data => {
    const result = await dispatch(updateCaretakerAddressAction(caretakerId, data))
    if (result?.status && result?.status === 200) {
      addToast("Update Address Successful!", {
        appearance: "success",
        autoDismiss: true
      })
      setShowUpdateAddress(false)
    } else setAddressError(result)
  }

  return (
    <Modal
      width={800}
      footer={null}
      open={showUpdateAddress}
      onCancel={() => setShowUpdateAddress(false)}
      title={<h4 className="text-xl">Update Address</h4>}>
      <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col className="mb-4" xs={24} md={16}>
            <label className="mb-1" htmlFor="input-street">
              Street <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="street"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-street"
                  status={errors.street ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.street ? (
              <small className="text-danger capitalize">{errors.street.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={8}>
            <label className="mb-1" htmlFor="input-zipcode">
              Zipcode <span className="text-danger">*</span>
            </label>
            <Controller
              name="zipcode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  type="number"
                  id="input-zipcode"
                  status={errors.zipcode ? "error" : undefined}
                  {...field}
                  onChange={e => /^\d{0,5}$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.zipcode ? (
              <small className="text-danger capitalize">{errors.zipcode.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12}>
            <label className="mb-1" htmlFor="input-city">
              City <span className="text-danger">*</span>
            </label>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-city"
                  status={errors.city ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.city ? (
              <small className="text-danger capitalize">{errors.city.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12}>
            <label className="mb-1" htmlFor="input-state">
              State <span className="text-danger">*</span>
            </label>
            <Controller
              name="state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-state"
                  status={errors.state ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.state ? (
              <small className="text-danger capitalize">{errors.state.message}</small>
            ) : null}
          </Col>
          {addressError ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-medium">{addressError}</p>
            </Col>
          ) : null}
          <Col xs={24} md={12}>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? "Updating..." : "Update"}
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default UpdateCaretakerAddress
