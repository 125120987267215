/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useState } from "react"
import { Button, Checkbox, Divider, Dropdown, Input, theme } from "antd"
import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import AddRemoveConfirmationModal from "./AddRemoveConfirmationModal"
import { debounce } from "lodash"

const { useToken } = theme

const RemoveCaretakerDropdown = props => {
  const { selectedCaretakers, selectedRowKeys, onCaretakerSelectionClear, patientStage } = props
  const { token } = useToken()

  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [caretakerNames, setCaretakerNames] = useState([])
  const [newCaretakers, setNewCaretakers] = useState(selectedCaretakers)

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  const handleApply = () => {
    setIsModalOpen(true)
    setOpen(false)
    // onCaretakerSelection(selectedCaretakers, values)
  }

  const onOpenChange = open => {
    // if (!open) {
    //  setCaretakerNames(selectedCaretakers)
    // }
    setOpen(open)
  }

  const onSearchCaretaker = e => {
    let filterData = []
    if (e?.target?.value) {
      filterData = selectedCaretakers?.filter(caretaker =>
        caretaker?.label?.toLowerCase().includes(e?.target?.value?.toLowerCase())
      )
    } else filterData = selectedCaretakers

    setCaretakerNames(filterData)
  }

  const onSelectedCaretaker = checkedItem => {
    // @ts-expect-error
    const isSelected = caretakerNames.some(item => item.value === checkedItem.value)

    // Update the selected caretakers array
    const updatedCaretakers = isSelected
      ? // @ts-expect-error
        caretakerNames.filter(item => item?.value !== checkedItem?.value) // Remove the item if already selected
      : [...caretakerNames, checkedItem] // Append the item if not selected
    // @ts-expect-error
    setCaretakerNames(updatedCaretakers)
    // Extract values from array 'caretakers' for easier comparison
    const caretakers = updatedCaretakers.map(c => c.value)

    // Filter array 'selectedCaretakers' to find values that are not in array 'caretakers'
    const caretakerInSelctedNotInCaretakerNames = selectedCaretakers.filter(
      selectedCare => !caretakers.some(valueA => valueA === selectedCare.value)
    )
    setNewCaretakers(caretakerInSelctedNotInCaretakerNames)
  }

  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        dropdownRender={_ => (
          <div style={contentStyle}>
            <>
              <Input
                key="search"
                className="mb-3 px-4"
                style={{ width: "100%", height: 48 }}
                onChange={debounce(onSearchCaretaker, 200)}
                allowClear
                // disabled={!tab2Data?.length}
                placeholder="Search Caretaker"
                prefix={<SearchOutlined className="disable" />}
              />

              <div className=" px-4 mr-8 h-32 overflow-auto">
                {/* <Checkbox.Group
                  className="flex-col w-full capitalize"
                  options={caretakerNames}
                  value={values}
                  onChange={onSelectedCaretaker}
                /> */}
                <Checkbox.Group
                  className="flex-col w-full capitalize"
                  value={caretakerNames?.map(item => item?.value)}>
                  {selectedCaretakers
                    ?.sort((a, b) => a?.label?.localeCompare(b?.label))

                    ?.map(item => (
                      <Checkbox
                        key={item.value}
                        onChange={selectedValues => onSelectedCaretaker(item)}
                        value={item.value} // Use the item's value directly
                        disabled={
                          caretakerNames?.length === selectedCaretakers?.length - 1 &&
                          newCaretakers[0]?.value === item.value
                        } // atleast one caretaker should be assiged so dont remove the last caretaker
                      >
                        {item?.label}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              </div>
            </>

            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button disabled={!selectedCaretakers.length || !caretakerNames?.length} type="primary" onClick={handleApply}>
                Done
              </Button>
            </div>
          </div>
        )}>
        <Button
          className="color-primary"
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div className="flex items-center justify-center gap-2">
            <>
              Remove Caretaker
              <DownOutlined />
            </>
          </div>
        </Button>
      </Dropdown>
      {isModalOpen && (
        <AddRemoveConfirmationModal
          setIsModalOpen={setIsModalOpen}
          fromDropdown={"remove"}
          selectedCaretakers={selectedCaretakers}
          selectedRowKeys={selectedRowKeys}
          newSelectedCaretakerList={newCaretakers}
          onCaretakerSelectionClear={onCaretakerSelectionClear}
          patientStage={patientStage}
          caretakerNames={caretakerNames}
        />
      )}
    </>
  )
}

export default RemoveCaretakerDropdown
