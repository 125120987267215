import React, { useEffect, useState } from "react"

import { Button, Table, Input, Card } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import { useDispatch, useSelector } from "react-redux"
import { getccmDataActions } from "../../redux/actions/ccmActions"
import { getMonitorListTypeAction } from "../../redux/actions/devicesActions"

import Loading from "../layouts/Loading/Loading"

const { Search } = Input

const CCMIsNewPatient = () => {
  const dispatch = useDispatch()
  const [ccmMonitorId, setCcmMonitorId] = useState(null)
  const { loading, getCcm } = useSelector(state => state.ccmList)
  const { monitorTypeList } = useSelector(state => state.monitorTypeList)
  const [ccmData, setCcmData] = useState([])
  const [searchedData, setSearchedData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (monitorTypeList !== undefined) {
      const data = monitorTypeList.filter(value => value.name === "ccm")
      setCcmMonitorId(data[0].moniterId)
    }
  }, [getCcm, loading, monitorTypeList])

  useEffect(() => {
    dispatch(getMonitorListTypeAction())
  }, [dispatch])

  useEffect(() => {
    ccmMonitorId !== null && dispatch(getccmDataActions(ccmMonitorId?.toString()))
  }, [ccmMonitorId, dispatch])

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: name => <p className="capitalize">{name}</p>
    },
    {
      title: "Action",
      render: ({ id }) => (
        <div>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => (window.location.href = `/ccm-questions/${id}`)}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    const newData = []
    let sno = 1
    getCcm?.inactive?.forEach(({ firstName, middleName, lastName, userId }) => {
      middleName !== null
        ? newData.push({
            key: sno,
            sNo: sno,
            name: `${firstName} ${middleName} ${lastName}`,
            id: userId
          })
        : newData.push({ key: sno, sNo: sno, name: `${firstName} ${lastName}`, id: userId })
      sno += 1
    })
    setCcmData(newData)
  }, [getCcm])

  const handleSearch = term => {
    const prevData = ccmData
    setSearchTerm(term?.toLowerCase())
    const newData = prevData.filter(item => item?.name?.toLowerCase().includes(term?.toLowerCase()))
    if (newData.length) {
      setSearchedData([...newData])
    } else {
      setSearchedData([])
    }
  }

  return loading ? (
    <div className="loaderLoading">
      <Loading />
    </div>
  ) : (
    <>
      <Card className="rounded-b-none">
        <div className="flex items-center flex-wrap justify-between gap-4">
          <h3 className="text-xl">New Patients To Add Careplan</h3>
          <Search
            allowClear
            size="large"
            placeholder="Search"
            className="max-w-[350px]"
            onSearch={value => handleSearch(value)}
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
      </Card>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={searchTerm ? searchedData : ccmData}
      />
    </>
  )
}

export default CCMIsNewPatient
