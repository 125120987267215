import React from "react"
import { Chrono } from "react-chrono"

const PatientTimeLine = () => {
  const items = [
    {
      title: "May 1940",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    },
    {
      title: "May 1941",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    },
    {
      title: "May 1942",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    },
    {
      title: "May 1943",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    },
    {
      title: "May 1944",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    },
    {
      title: "May 1945",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    },
    {
      title: "May 1946",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
      cardDetailedText: "On 10 May 1940, Hitler began his long-awaited offensive in the west..."
    }
  ]

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Chrono items={items} slideShow mode="VERTICAL_ALTERNATING" />
    </div>
  )
}

export default PatientTimeLine
