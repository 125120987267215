import {
  ADD_CARETAKER_FAIL,
  ADD_CARETAKER_REQUEST,
  ADD_CARETAKER_SUCCESS,
  CARETAKER_CARD_FAIL,
  CARETAKER_CARD_REQUEST,
  CARETAKER_CARD_SUCCESS,
  CARETAKER_LIST_FAIL,
  CARETAKER_LIST_REQUEST,
  CARETAKER_LIST_SUCCESS,
  UPDATE_CARETAKER_REQUEST,
  UPDATE_CARETAKER_SUCCESS,
  UPDATE_CARETAKER_FAIL,
  SINGLE_CARETAKER_REQUEST,
  SINGLE_CARETAKER_SUCCESS,
  SINGLE_CARETAKER_FAIL,
  CARETAKER_PATIENT_REQUEST,
  CARETAKER_PATIENT_SUCCESS,
  CARETAKER_PATIENT_FAIL,
  GET_CARE_TYPES_FAIL,
  GET_CARE_TYPES_REQUEST,
  GET_CARE_TYPES_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_REQUEST,
  UPDATE_CARETAKER_MANUAL_TIME_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_FAIL,
  CARETAKER_ADDRESS_REQUEST,
  CARETAKER_ADDRESS_SUCCESS,
  CARETAKER_ADDRESS_FAIL,
  UPDATE_CARETAKER_ADDRESS_REQUEST,
  UPDATE_CARETAKER_ADDRESS_SUCCESS,
  UPDATE_CARETAKER_ADDRESS_FAIL,
  CARETAKER_FILTER_PATIENT_REQUEST,
  CARETAKER_FILTER_PATIENT_SUCCESS,
  CARETAKER_FILTER_PATIENT_FAIL
} from "../types/caretakerConstant"
import { RESET_FILTER_USERS_REQUEST } from "../types/ccmConstant"

export const caretakerCardReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_CARD_REQUEST:
      return { loading: true }
    case CARETAKER_CARD_SUCCESS:
      return { loading: false, caretakerCards: action.payload }
    case CARETAKER_CARD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerListReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_LIST_REQUEST:
      return { loading: true }
    case CARETAKER_LIST_SUCCESS:
      return { loading: false, caretakerLists: action.payload }
    case CARETAKER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addCaretakerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CARETAKER_REQUEST:
      return { loading: true }
    case ADD_CARETAKER_SUCCESS:
      return { loading: false, addCaretaker: action.payload }
    case ADD_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCaretakerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CARETAKER_REQUEST:
      return { loading: true }
    case UPDATE_CARETAKER_SUCCESS:
      return { loading: false, UpdateCaretaker: action.payload }
    case UPDATE_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCaretakerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CARETAKER_ADDRESS_REQUEST:
      return { loading: true }
    case UPDATE_CARETAKER_ADDRESS_SUCCESS:
      return { loading: false, UpdateCaretakerAddress: action.payload }
    case UPDATE_CARETAKER_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const singleCaretakerReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_CARETAKER_REQUEST:
      return { loading: true }
    case SINGLE_CARETAKER_SUCCESS:
      return { loading: false, singleCaretaker: action.payload }
    case SINGLE_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getCaretakerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_ADDRESS_REQUEST:
      return { loading: true }
    case CARETAKER_ADDRESS_SUCCESS:
      return { loading: false, caretakerAddress: action.payload }
    case CARETAKER_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_PATIENT_REQUEST:
      return { loading: true }
    case CARETAKER_PATIENT_SUCCESS:
      return { loading: false, caretakerPatient: action.payload }
    case CARETAKER_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerFilterPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_FILTER_PATIENT_REQUEST:
      return { loading: true }
    case CARETAKER_FILTER_PATIENT_SUCCESS:
      return { loading: false, getFilterUsersData: action.payload }
    case CARETAKER_FILTER_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    case RESET_FILTER_USERS_REQUEST:
      return { loading: false }
    default:
      return state
  }
}

export const getCareTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CARE_TYPES_REQUEST:
      return { loading: true }
    case GET_CARE_TYPES_SUCCESS:
      return { loading: false, careTypes: action.payload }
    case GET_CARE_TYPES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCaretakerManualTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CARETAKER_MANUAL_TIME_REQUEST:
      return { loading: true }
    case UPDATE_CARETAKER_MANUAL_TIME_SUCCESS:
      return { loading: false, careTakerManualTime: action.payload }
    case UPDATE_CARETAKER_MANUAL_TIME_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerPatientAdvFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_CARETAKER_PATIENT_ADVANCE_FILTER":
      return { ...state, caretakerPatientAdvFilter: action.payload }
    case "RESET_CARETAKER_PATIENT_ADVANCE_FILTER":
      return { ...state, caretakerPatientAdvFilter: null } 
    default:
      return state
  }
}