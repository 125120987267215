import { useEffect } from "react"
import { Form, Modal, Input } from "antd"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  editEligiblePatientAction,
  resetEditEligiblePatientAction
} from "../../../redux/actions/selfOnboardingAction"
import FloatInput from "../../../utils/FloatInput/FloatInput"
import parsePhoneNumber from "../../../utils/parsePhoneNumber"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"

const INVALID_PHONE_NUM = "Phone number must be exactly 10 digits"

// Define the validation schema
const schema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required(INVALID_PHONE_NUM)
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, INVALID_PHONE_NUM)
})

const UpdatePhoneNumber = props => {
  const { selectedPatient, closeModal } = props

  const { addToast } = useToasts()

  const dispatch = useDispatch()

  const { loading, error: apiErr } = useSelector(state => state.editEligiblePatient)

  useEffect(() => {
    if (!selectedPatient) return
    dispatch(resetEditEligiblePatientAction())
    setValue("phoneNumber", selectedPatient?.phone ? formatPhoneNumber(selectedPatient.phone) : "")
    clearErrors()
  }, [selectedPatient])

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    const res = await dispatch(
      editEligiblePatientAction({
        patientId: selectedPatient?.id,
        mobileNumber: data?.phoneNumber ? parsePhoneNumber(data.phoneNumber) : null,
        email: selectedPatient?.email || null
      })
    )
    if (res?.isUpdated) {
      addToast("Updated Successfully", { appearance: "success", autoDismiss: true })
      closeModal()
    }
  }

  return (
    <Modal
      open={Boolean(selectedPatient)}
      onCancel={closeModal}
      title="Update Phone Number"
      okText="Update"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={loading}>
      <Form onFinish={handleSubmit(onSubmit)} className="mt-4">
        <Form.Item
          validateStatus={errors.phoneNumber ? "error" : ""}
          help={errors.phoneNumber?.message}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <div className="mt-2">
                <FloatInput label="Phone Number">
                  <Input
                    size="large"
                    {...field}
                    onChange={e => {
                      let orgVal = e.target.value
                      let parsedVal = parsePhoneNumber(orgVal)
                      if (parsedVal?.length > 10) return
                      if (parsedVal?.length === 10) field.onChange(formatPhoneNumber(orgVal))
                      else field.onChange(parsedVal)
                    }}
                  />
                </FloatInput>
              </div>
            )}
          />
        </Form.Item>
      </Form>
      {apiErr && <span className="text-danger">{apiErr}</span>}
    </Modal>
  )
}

export default UpdatePhoneNumber
