import React, { useEffect, useState } from "react"
import { Button, Checkbox, Divider, Dropdown, Input, theme } from "antd"
import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import AddRemoveConfirmationModal from "./AddRemoveConfirmationModal"
import { useDispatch, useSelector } from "react-redux"
import { getCaretakerListAction } from "../../../redux/actions/caretakerActions"
import { useParams } from "react-router-dom"
import { debounce } from "lodash"

const { useToken } = theme

const AddCaretakerDropdown = (props) => {
  const {
    // caretakerAssigned,
    // setCaretakerAssigned,
    selectedRowKeys,
    selectedCaretakers,
    onCaretakerSelectionClear,
    patientStage
  } = props
  const { token } = useToken()
  const { loading: caretakerLoading, caretakerLists } = useSelector(state => state.caretakerList)

  const [open, setOpen] = useState(false)
  const [caretakerNames, setCaretakerNames] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchedQuery, setSearchedQuery] = useState("")
  const [filteredCaretakerList, setFilteredCaretakerList] = useState([])
  const [newSelectedCaretakerList, setNewSelectedCaretakerList] = useState(selectedCaretakers)

  const dispatch = useDispatch()
  const {orgId, orgName} = useParams()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  useEffect(() => {
   
    if (!caretakerLists?.length )
      dispatch(getCaretakerListAction(orgId))
  }, [])

  useEffect(() => {
    if (caretakerLists) {
      const care = caretakerLists?.map((item) => ({
        label:
          item?.firstName + " " + (item?.middleName ? item?.middleName + " " : "") + item?.lastName  + `  <${item?.email}>`,
        value: item?.id
      }))
      setFilteredCaretakerList(care)
      setCaretakerNames(care)
    }
  }, [caretakerLoading, open])

  const handleApply = () => {
    setIsModalOpen(true)
    setOpen(false)
    // setCaretakerAssigned(values)
    // onCaretakerSelection(selectedCaretakers, values)
  }

  // const handleResetFilter = () => {
  //   setCaretakerAssigned("")
  //   setValues("")
  //   onCaretakerSelectionClear()
  // }

  const onOpenChange = (open) => {
    setOpen(open)
  }
  const onSearchCaretaker = (e) => {
    setSearchedQuery(e?.target?.value)
  }
  useEffect(() => {
    let filterData = []
    filterData = caretakerNames?.filter(caretaker =>
      caretaker?.label?.toLowerCase().includes(searchedQuery?.toLowerCase())
    )
    setFilteredCaretakerList(filterData)
  }, [searchedQuery])

  const onSelectedCaretaker = (checkedItem) => {
    // Check if the item is already selected
    const isSelected = newSelectedCaretakerList.some(item => item.value === checkedItem.value)

    // Update the selected caretakers array
    const updatedCaretakers = isSelected
      ? 
        newSelectedCaretakerList.filter(item => item?.value !== checkedItem?.value) // Remove the item if already selected
      : [...newSelectedCaretakerList, checkedItem] // Append the item if not selected

    setNewSelectedCaretakerList(updatedCaretakers)
  }

  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        dropdownRender={_ => (
          <div style={contentStyle}>
            <>
              <Input
                key="search"
                className="mb-3 px-4"
                style={{ width: "100%", height: 48 }}
                onChange={debounce(onSearchCaretaker, 200)}
                allowClear
                // disabled={!tab2Data?.length}
                placeholder="Search Caretaker"
                prefix={<SearchOutlined className="disable" />}
              />

              <div className=" px-4 mr-8  h-44 overflow-auto">
                <Checkbox.Group
                  className="flex-col w-full capitalize"
                  // @ts-expect-error
                  value={newSelectedCaretakerList?.map(item => item?.value)}>
                  {filteredCaretakerList
                    ?.sort((a, b) => a?.label?.localeCompare(b?.label))
                    .map(item => (
                      <Checkbox
                       
                        key={item?.value}
                        disabled={selectedCaretakers?.some(
                          caretaker => caretaker?.value === item?.value
                        )}
                        onChange={selectedValues => onSelectedCaretaker(item)}
                        value={item?.value} // Use the item's value directly
                      >
                        {item?.label}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              </div>
            </>

            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Done
              </Button>
            </div>
          </div>
        )}>
        <Button
          className="color-primary"
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div className="flex items-center justify-center gap-2">
            Add Caretaker
            <DownOutlined />
          </div>
        </Button>
      </Dropdown>
      {isModalOpen && (
        <AddRemoveConfirmationModal
          setIsModalOpen={setIsModalOpen}
          fromDropdown={"add"}
          newSelectedCaretakerList={newSelectedCaretakerList}
          selectedRowKeys={selectedRowKeys}
          selectedCaretakers={selectedCaretakers}
          onCaretakerSelectionClear={onCaretakerSelectionClear}
          patientStage={patientStage}
        />
      )}
      {/* <Space.Compact
        className={`border card ${isDark ? "dark" : "light"}`}
        onClick={handleResetFilter}
        style={{ borderWidth: 1 , height: "inherit"}}>
        <span className="secondary px-4 ">
          <CloseOutlined />
        </span>
      </Space.Compact> */}
    </>
  )
}

export default AddCaretakerDropdown
