import React, { useState, useEffect } from "react"

import { useSelector } from "react-redux"

import { Select } from "antd"

const { Option } = Select

const PrimaryProviderSelect = ({ setProviderIds }) => {
  const [data, setData] = useState([])

  const { providerList } = useSelector(store => store.providerList)

  const onChange = value => {
    setProviderIds(value)
  }

  useEffect(() => {
    const children = []
    if (providerList !== undefined && providerList !== null && providerList.length) {
      providerList.forEach((item, i) => {
        children.push(
          <Option key={i} value={item?.id}>
            {item?.firstName} {item?.lastName}
          </Option>
        )
      })
    }
    setData(children)
  }, [providerList])

  return (
    <div>
      <label className="mb-2 block" htmlFor="select-provider">
        Provider
      </label>
      <Select
        mode="multiple"
        className="w-full"
        size="large"
        onChange={onChange}
        id="select-provider"
        placeholder="Select Provider"
        filterOption={(inputValue, option) => {
          return option.children.some(i => i.includes(inputValue.toLowerCase()))
        }}>
        {data}
      </Select>
    </div>
  )
}

export default PrimaryProviderSelect
