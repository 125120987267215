import React, { useEffect, useState } from "react"

import moment from "moment"
import { Table, Tag, Button, Spin } from "antd"
import ReactDragListView from "react-drag-listview"
import PatientManualLogModal from "../Patient/PatientManualLogModal"
import { useNavigate, useParams } from "react-router-dom"
import Loading from "../layouts/Loading/Loading"

const ProviderPatientTable = ({ sortedData, loading }) => {
  const [data, setData] = useState([])
  const [currentDate] = useState(new Date())
  const [priorDate, setPriorDate] = useState() //eslint-disable-line
  const [manualLogOpen, setManualLogOpen] = useState(false)
  const [patientDetails, setPatientDetails] = useState(null)
  const [manualCountType, setManualCountType] = useState()

  const {orgId, orgName} = useParams()

  const handleShowManualLogClick = (obj, programType) => {
    setManualLogOpen(true)
    setPatientDetails(obj)
    setManualCountType(programType)
  }

  const [columns, setColumns] = useState([
    {
      title: <span className="dragHandler cursor-move">S.No.</span>,
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: <span className="dragHandler cursor-move">Full Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name, { id , status, facilityId, totalReading}) => {
        return (
          <p
            className={
              `${status ? "text-danger" :""} text-capitalize wrap-text mb-0`
            }
            onClick={() => {
              navigate(`/${orgId}/${orgName}/patient/${id}`, {state:{facilityId, totalReading}})
            }}
            style={{ cursor: "pointer" }}>
            {name}
          </p>
        )
      },
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    {
      title: <span className="dragHandler cursor-move">Username</span>,
      dataIndex: "username",
      key: "username",
      render: username => <p className="wrap-text mb-0">{username}</p>
    },
    {
      title: <span className="dragHandler cursor-move">User Type</span>,
      dataIndex: "userType",
      key: "userType",
      render: userType => <p className="wrap-text mb-0 capitalize">{userType || null}</p>
    },
    {
      title: <span className="dragHandler cursor-move">Total Reading</span>,
      dataIndex: "totalReading",
      key: "totalReading",
      render: (totalReading) => (
       <p className="wrap-text mb-0">{totalReading}</p>) ,
      sorter: (a, b) =>  a.totalReading - b.totalReading
    },
    // {
    //   title: <span className="dragHandler cursor-move">RTM Pain Management Count</span>,
    //   dataIndex: "rtmPainManagementCount",
    //   key: "rtmPainManagementCount",
    //   render: rtmPainManagementCount => <p className="wrap-text mb-0">{rtmPainManagementCount}</p>,
    //   sorter: (a, b) => a.rtmPainManagementCount - b.rtmPainManagementCount
    // },
    // {
    //   title: <span className="dragHandler cursor-move">RTM Medicated Assisted Therapy Count</span>,
    //   dataIndex: "rtmMedicatedAssistedTherapyCount",
    //   key: "rtmMedicatedAssistedTherapyCount",
    //   render: rtmMedicatedAssistedTherapyCount => (
    //     <p className="wrap-text mb-0">{rtmMedicatedAssistedTherapyCount}</p>
    //   ),
    //   sorter: (a, b) => a.rtmMedicatedAssistedTherapyCount - b.rtmMedicatedAssistedTherapyCount
    // },

    {
      title: <span className="dragHandler cursor-move">Unit No.</span>,
      dataIndex: "unit",
      key: "unit",
      render: unit =>
      unit !== null ? (
        <p className="wrap-text mb-0">{unit}</p>
      ) : (
        <span>N/A</span>
      ),
      //   sorter: (a, b) => a?.unit.localeCompare(b?.unit)
      sorter: (a, b) => a?.unit - b?.unit
    },
    {
      title: <span className="dragHandler cursor-move">Room No.</span>,
      dataIndex: "room",
      key: "room",
      render: room => 
      room !== null  ? ( 
      <p className="wrap-text mb-0">{room}</p>
      ) : (
        <span>N/A</span>
      ),
      //   sorter: (a, b) => a?.room.localeCompare(b?.room)
      sorter: (a, b) => a?.room - b?.room
    },
    {
      title: <span className="dragHandler cursor-move">Critical Status</span>,
      dataIndex: "status",
      key: "status",
      render: status =>
        status !== null && status === true ? (
          <Tag color="red">Critical</Tag>
        ) : (
          <span>N/A</span>
        ),
      sorter: a => (a?.status !== null && a?.status === true ? 1 - 0 : 0 - 1)
    },
    {
      key: "rpmManualLogCount",
      dataIndex: "rpmManualLogCount",
      sorter: (a, b) => a.rpmManualLogCount - b.rpmManualLogCount,
      title: <span className="dragHandler cursor-move">Total RPM Mins Spent</span>,
      render: (rpmManualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row , 'rpm')}>{rpmManualLogCount} Mins</Button>
      )
    },
    {
      key: "ccmManualLogCount",
      dataIndex: "ccmManualLogCount",
      sorter: (a, b) => a.ccmManualLogCount - b.ccmManualLogCount,
      title: <span className="dragHandler cursor-move">Total CCM Mins Spent</span>,
      render: (ccmManualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row , 'ccm')}>{ccmManualLogCount} Mins</Button>
      )
    },
    {
      key: "manualLogCount",
      dataIndex: "manualLogCount",
      sorter: (a, b) => a.manualLogCount - b.manualLogCount,
      title: <span className="dragHandler cursor-move">Total Mins Spent</span>,
      render: (manualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row)}>{manualLogCount} Mins</Button>
      )
    }
  ])

  useEffect(() => {
    // const priorDate = new Date(new Date().setDate(currentDate.getDate() - 28));
    if (currentDate !== undefined) {
      const manageDate = moment(currentDate).subtract(40, "days")
      setPriorDate(manageDate._d) //eslint-disable-line
    }
  }, [currentDate])

  const navigate = useNavigate()

  useEffect(() => {
    // console.log('changed')
    const newData = []

    let sno = 1
    sortedData?.forEach(data => {
      const {
        id,
        unit,
        room,
        userType,
        userName,
        lastName,
        firstName,
        middleName,
        criticalStatus,
        manualLogCount,
        rpmManualLogCount,
        ccmManualLogCount,
        rpmReadingCount,
        monthlyLastestTotalReadingRPM,
        rtmPainManagementCount,
        rtmMedicatedAssistedTherapyCount,
        rpmActiveDate,
        facilityId
      } = data
      newData.push({
        unit,
        room,
        key: sno,
        sNo: sno,
        name:
          !middleName || middleName === null
            ? `${firstName} ${lastName}`
            : `${firstName} ${middleName} ${lastName}`,
        username: userName,
        id: id,
        userType,
        manualLogCount,
        rpmManualLogCount,
        ccmManualLogCount,
        rtmPainManagementCount,
        totalReading: monthlyLastestTotalReadingRPM,
        rtmMedicatedAssistedTherapyCount,
        status: criticalStatus,
        rpmActiveDate,
        facilityId
      })

      sno += 1
    })
    setData(newData)
  }, [sortedData])

  useEffect(() => {
    setTimeout(() => {
      if (columns && columns.length && localStorage.getItem("patientCols")) {
        const storedCols = JSON.parse(localStorage.getItem("patientCols"))
        const defaultCols = [...columns]
        const storedColsIndex = storedCols.map((col, index) => col.key)
        const sorted = defaultCols.sort(function (a, b) {
          return storedColsIndex.indexOf(a.key) - storedColsIndex.indexOf(b.key)
        })
        Promise.all(sorted).then(() => {
          setColumns([...defaultCols])
        })
      }
    }, 1000)
  }, [])

  const onDragEnd = (fromIndex, toIndex) => {
    setColumns(prev => {
      const nextColumns = [...prev]
      const item = nextColumns.splice(fromIndex, 1)[0]
      nextColumns.splice(toIndex, 0, item)
      localStorage.setItem(
        "patientCols",
        JSON.stringify(nextColumns.map(col => ({ key: col.key })))
      )
      return nextColumns
    })
  }

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center my-5">
    <Spin size="large" />
  </div>
  }
  return (
    <>
      <ReactDragListView.DragColumn
        onDragEnd={onDragEnd}
        nodeSelector="th"
        handleSelector=".dragHandler"
        ignoreSelector="react-resizable-handle">
        <Table columns={columns} dataSource={data} scroll={{ x: "max-content" }} />
      </ReactDragListView.DragColumn>
      <PatientManualLogModal
        manualLogOpen={manualLogOpen}
        patientDetails={patientDetails}
        setManualLogOpen={setManualLogOpen}
        manualCountType = {manualCountType}
      />
    </>
  )
}

export default ProviderPatientTable
