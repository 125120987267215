import React, { useEffect, useState } from "react"

import { Alert, Col, Row, Slider, Card, Table, Radio, DatePicker, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getOxyDetails } from "../../redux/actions/patientAction"
import moment from "moment"
import ReactApexChart from "react-apexcharts"
import Loading from "../layouts/Loading/Loading"
import PatientOxiProgressbar from "./PatientOxiProgressbar"
import PatientOxiCustomParameter from "./PatientOxiCustomParameter"

const dateFormat = value => {
  return moment(value * 1000).format("DD-MMM-YYYY [at] hh:mm A")
  // const date = new Date(value * 1000)
  // return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
  //   -2
  // )}/${date.getFullYear()}`
}

const userTimeFormat = value => {
  return moment(value).format("DD-MMM-YYYY [at] hh:mm A")
}

const columns = [
  {
    key: "pulseRate",
    title: "Pulse Rate",
    dataIndex: "pulseRate"
  },
  {
    key: "spo",
    dataIndex: "spo",
    title: "SpO2 - Oxygen Range"
  },
  {
    key: "activeDate",
    title: "Taken At",
    dataIndex: "activeDate",
    render: (_, { createdAt, readingDate }) =>
      readingDate ? userTimeFormat(readingDate) : dateFormat(createdAt)
  }
]

const spoChartConfig = series => ({
  series,
  options: {
    chart: {
      type: "radialBar"
    },
    stroke: {
      lineCap: "round"
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%"
        }
      }
    },
    labels: ["SPO2"],
    colors: ["#d7a66d"]
  }
})

const lineChartOptions = (series, categories) => ({
  series,
  options: {
    chart: {
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#60a5fa", "#ef4444"],
    stroke: {
      curve: "straight"
    },
    xaxis: {
      categories
    }
  }
})

const PatientOxymeterComponent = props => {
  const { deviceData, patientId, currentDate, durationObj, setOxiCustomParameterTab } = props

  const [dateRange, setDateRange] = useState(null)
  const [errorMsg, setErrorMsg] = useState("No reading has been taken for Oximeter in past 30 days")
  const [programId, setProgramId] = useState(null)

  const [oxyData, setOxyData] = useState([])
  const [duration, setDuration] = useState("month")
  const [graphSeries, setGraphSeries] = useState([])
  const [graphCategories, setGraphCategories] = useState([])
  const [showCustomParameter, setShowCustomParameter] = useState(false);
  const [isCustomParameter, setIsCustomParameter] = useState(false); // for identification of custom para after fetch api.
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch()
  const { patientOxyDetail, loading } = useSelector(state => state.healthOxy)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  useEffect(() => {
    if (!patientId || !patientMonitoringData || !duration) return

    // const program = patientMonitoringData?.rpm[0]?.programs?.find(
    //   device => device?.programId  === RPM_DEVICES[0].programId
    // )
    const selectedProgram = patientMonitoringData?.["rpm"]
      ?.map((programs, index) =>
        programs?.programs?.length ? programs?.programs?.map(program => program) : null
      )
      .flat(Infinity)
    const program = selectedProgram?.find(device => device?.programName === "oximeter")
    if (!program) return
    else setProgramId(program?.programId)
    if (programId) {
      dispatch(
        getOxyDetails({
          patientId,
          endDate: currentDate,
          startDate: durationObj[duration],
          programId
        })
      )
    }
  }, [dispatch, patientId, duration, patientMonitoringData, programId])

  useEffect(() => {
    if (!patientOxyDetail?.length) {
      setOxyData([])
      switch (duration) {
        case "week":
          setErrorMsg("No reading has been taken for Oximeter in past 7 days")
          break
        case "year":
          setErrorMsg("No reading has been taken for Oximeter in past 365 days")
          break
        case "month":
          setErrorMsg("No reading has been taken for Oximeter in past 30 days")
          break
        default:
          setErrorMsg("No reading has been taken for Oximeter")
      }
    } else {
      const formattedGraphDate = []
      const series = [
        {
          name: "Pulse Rate",
          data: []
        },
        {
          name: "SPO2",
          data: []
        }
      ]
      let decrement = patientOxyDetail
        ?.sort(
          (a, b) =>
            new Date(b?.readingDate || b?.createdAt) - new Date(a?.readingDate || a?.createdAt)
        )
        ?.map((reading, index) => ({ ...reading, index }))
      setOxyData(decrement)

      const increment = [...decrement]?.reverse()

      increment !== undefined &&
        increment?.forEach(reading => {
          const graphDate = new Date(reading?.readingDate)
          const formattedDate =
            `${`0${graphDate.getMonth() + 1}`.slice(-2)}` +
            `/${`0${graphDate.getDate()}`.slice(-2)}/${graphDate.getFullYear()}`
          formattedGraphDate.push(formattedDate)
          reading?.pulseRate &&
            reading?.pulseRate !== "--" &&
            series[0].data.push(reading?.pulseRate)
          reading?.spo && reading?.spo !== "--" && series[1].data.push(reading?.spo)
        })
      setGraphSeries(series)
      setGraphCategories(formattedGraphDate)
    }
  }, [patientOxyDetail, duration])

  const handleRangeChange = (date, dateString) => {
    setDateRange(date)
    if (!date) {
      setDuration("month")
      dispatch(
        getOxyDetails({
          patientId,
          programId,
          startDate: durationObj[duration],
          endDate: currentDate
        })
      )
    } else if (date && date?.length === 2) {
      setDuration(null)
      dispatch(
        getOxyDetails({
          patientId,
          programId,
          startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
          endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
        })
      )
    }
  }
  const handleDuration = e => {
    setDuration(e.target.value)
    if (dateRange !== null) setDateRange(null)
  }

  return (
    <Card title="">
      <div>
        <div className="flex flex-col items-start justify-center gap-3 sm:flex-row sm:justify-between sm:items-center sm:gap-0 mb-4">
          <h2 className="text-2xl">Oxygen Meter Reading</h2>
          {oxyData.length > 0 ?
            <>
              {isCustomParameter ?
                <Button
                  size="large"
                  className={`${showCustomParameter ? "bg-[#c3c3c3]" : ""}`}
                  type={`${showCustomParameter ? "default" : "primary"}`}
                  onClick={() => {
                    setIsEdit(true)
                    setShowCustomParameter(!showCustomParameter)
                  }}
                >
                  Edit/Remove Custom Parameters
                </Button>
                :
                <Button
                  size="large"
                  className={`${showCustomParameter ? "bg-[#c3c3c3]" : ""}`}
                  type={`${showCustomParameter ? "default" : "primary"}`}
                  onClick={() => setShowCustomParameter(!showCustomParameter)}
                >
                  Set Custom Parameters
                </Button>
              }
            </> :
            <div className="my-3 pb-2">
              <p className="font-semibold mb-2">Duration Pickers:</p>
              <div className="flex items-center gap-4">
                <Radio.Group value={duration} onChange={handleDuration}>
                  <Radio.Button value="week">Week</Radio.Button>
                  <Radio.Button value="month">Month</Radio.Button>
                  <Radio.Button value="year">Year</Radio.Button>
                </Radio.Group>
                <DatePicker.RangePicker
                  disabledDate={current => current > moment()}
                  value={dateRange}
                  onChange={handleRangeChange}
                />
              </div>
            </div>
          }
        </div>
        {loading ? (
          <Loading />
        ) : oxyData && oxyData.length ? (
          <>
            {/* <Row className="mb-4 items-center">
              <Col sm={24} md={12}>
                <ReactApexChart
                  height={300}
                  type="radialBar"
                  {...spoChartConfig([oxyData?.[0]?.spo])}
                />
              </Col>
              <Col sm={24} md={12}>
                <div className="oxygenrange-container">
                  <h4 className="mb-4">{oxyData?.[0]?.spo} Spo2- Oxygen Range</h4>
                  <div className="progressBar-container-oxygen">
                    <div className="progressbar-top flex w-full justify-between">
                      <h6 className="mb-0">&lt;95</h6>
                      <h6 className="mb-0">95-100</h6>
                    </div>

                    <div className="range-wrap">
                      <Slider min={95} max={100} value={oxyData?.[0]?.spo} />
                    </div>
                    <div className="progressbar-bottom flex justify-between">
                      <h6 className="text-danger">Critical</h6>
                      <h6 className="text-success">Normal</h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
            <div>
              {showCustomParameter ?
                <PatientOxiCustomParameter
                  patientId={patientId}
                  setShowCustomParameter={setShowCustomParameter}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  setIsCustomParameter={setIsCustomParameter}
                  setOxiCustomParameterTab={setOxiCustomParameterTab}
                />
                :
                <PatientOxiProgressbar
                  patientId={patientId}
                  oxyData={oxyData}
                  setOxiCustomParameterTab={setOxiCustomParameterTab}
                  setIsCustomParameter={setIsCustomParameter}
                />
              }
            </div>
            <Card title="Patient Readings Chart" className="my-4"
              extra={<div className="my-3 pb-2">
                <p className="font-semibold mb-2">Duration Pickers:</p>
                <div className="flex items-center gap-4">
                  <Radio.Group value={duration} onChange={handleDuration}>
                    <Radio.Button value="week">Week</Radio.Button>
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                  <DatePicker.RangePicker
                    disabledDate={current => current > moment()}
                    value={dateRange}
                    onChange={handleRangeChange}
                  />
                </div>
              </div>}
            >
              {graphSeries?.length && graphCategories?.length ? (
                <ReactApexChart
                  type="line"
                  height={350}
                  {...lineChartOptions(graphSeries, graphCategories)}
                />
              ) : (
                "Loading..."
              )}
            </Card>

            <Card title="Patient Readings Table" className="patient-reading-card mt-4">
              <Table columns={columns} dataSource={oxyData} rowKey="index" />
            </Card>
          </>
        ) : (
          <Alert type="error" message={errorMsg} />
        )}
      </div>
    </Card>
  )
}

export default PatientOxymeterComponent
