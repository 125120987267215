import React, { useEffect, useState } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"

import AddCareplan from "@fitpeo/fit-plan-creator"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { getPatientDiagnosticsData } from "../../../redux/actions/patientAction"
import { postCcmPlan } from "../../../redux/actions/ccmActions"

import Loading from "../../layouts/Loading/Loading"

import "./CCMAdminPatient.css"

const CCMAdminPatient = ({ isFromAddPatient }) => {
  const dispatch = useDispatch()

  const [answers, setAnswers] = useState(null)
  const [addingBy, setAddingBy] = useState([])

  const location = useLocation()
  const patientId = location?.state?.id
  const patientNameObj = location?.state?.patientNameObj
  const provider = location?.state?.provider
  const practitioner = location?.state?.practitioner
  const ccmMonitorId = location?.state?.ccmMonitorId
  const dob = location?.state?.dob

  const navigate = useNavigate()
  const { addToast } = useToasts()
  const {orgId, orgName} = useParams()

  useEffect(() => {
    if (patientId !== undefined) {
      dispatch(getPatientDiagnosticsData({ patientId }))
    } else navigate(`/${orgId}/${orgName}/ccm/patients`)
  }, [dispatch, patientId])

  const { userInfo: adminInfo } = useSelector(state => state.userInfo)
  const { loading: diagnosticsLoading, patientDiagnosticData } = useSelector(
    state => state.patientDiagnosticData
  )
  const { loading: postCcmLoading } = useSelector(state => state.postCCMData)

  useEffect(() => {
    if (!adminInfo) return

    setAddingBy({
      id: adminInfo?.id,
      name: adminInfo?.middleName
        ? `${adminInfo?.firstName} ${adminInfo?.middleName ? adminInfo?.middleName : ""} ${adminInfo?.lastName}`
        : `${adminInfo?.firstName} ${adminInfo?.lastName}`,
      userType: "admin"
    })
  }, [adminInfo])

  const submitForm = async () => {
    const postCarePlan = await dispatch(
      postCcmPlan({ patientId, ccmMonitorId, formInputs: answers })
    )
    if (postCarePlan?.status === 200) {
      setAnswers(null)
      addToast("Care Plan Add Successfully", { appearance: "success", autoDismiss: true })

      setTimeout(() => {
        setAnswers(null)
        window.location.href = `/${orgId}/${orgName}/ccm/patients`
      }, 1000)
    } else {
      addToast("Unable To Add Care Plan!", { appearance: "error", autoDismiss: true })
    }
  }

  useEffect(() => {
    if (!answers) return
    submitForm()
  }, [answers]) //eslint-disable-line

  return diagnosticsLoading ? (
    <Loading />
  ) : (
    patientDiagnosticData && provider && patientNameObj && dob && patientId && isFromAddPatient && (
      <AddCareplan
        patientInfoDetail={{ ...patientNameObj, dob, id: patientId }}
        diagnosticData={patientDiagnosticData}
        type="ccm"
        answers={answers}
        setAnswers={setAnswers}
        navigate={navigate}
        navigateUrl={`/${orgId}/${orgName}/ccm/patients`}
        providers={provider}
        practitioners={practitioner}
        addingBy={addingBy}
        submissionLoading={postCcmLoading}
      />
    )
  )
}

export default CCMAdminPatient
