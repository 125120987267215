// ccm
export const GET_CCM_REQUEST = "GET_CCM_REQUEST"
export const GET_CCM_SUCCESS = "GET_CCM_SUCCESS"
export const GET_CCM_FAIL = "GET_CCM_FAIL"
export const GET_CCM_RESET = "GET_CCM_RESET"

export const POST_CCM_USER_REQUEST = "POST_CCM_USER_REQUEST"
export const POST_CCM_USER_SUCCESS = "POST_CCM_USER_SUCCESS"
export const POST_CCM_USER_FAIL = "POST_CCM_USER_FAIL"

export const GET_CCM_CAREPLAN_REQUEST = "GET_CCM_CAREPLAN_REQUEST"
export const GET_CCM_CAREPLAN_SUCCESS = "GET_CCM_CAREPLAN_SUCCESS"
export const GET_CCM_CAREPLAN_FAIL = "GET_CCM_CAREPLAN_FAIL"

export const GET_FACILITY_ADDRESS_REQUEST = "POST_FACILITY_ADDRESS_REQUEST"
export const GET_FACILITY_ADDRESS_SUCCESS = "POST_FACILITY_ADDRESS_SUCCESS"
export const GET_FACILITY_ADDRESS_FAIL = "POST_FACILITY_ADDRESS_FAIL"

export const GET_FILTER_USERS_REQUEST = "POST_FILTER_USERS_REQUEST"
export const RESET_FILTER_USERS_REQUEST = "RESET_FILTER_USERS_REQUEST"
export const GET_FILTER_USERS_SUCCESS = "POST_FILTER_USERS_SUCCESS"
// will be used when clied from dashbord onbard card
export const GET_FILTER_USERS_THROUGH_DASHBOARD_SUCCESS =
  "GET_FILTER_USERS_THROUGH_DASHBOARD_SUCCESS"
export const GET_FILTER_USERS_THROUGH_DASHBOARD_FAIL = "GET_FILTER_USERS_THROUGH_DASHBOARD_FAIL"
export const RESET_FILTER_USERS_THROUGH_DASHBOARD = "RESET_FILTER_USERS_THROUGH_DASHBOARD"
//
export const GET_FILTER_USERS_FAIL = "POST_FILTER_USERS_FAIL"

export const GET_DASHBOARD_FILTER_USERS_REQUEST = "POST_DASHBOARD_FILTER_USERS_REQUEST"
export const GET_DASHBOARD_FILTER_USERS_SUCCESS = "POST_DASHBOARD_FILTER_USERS_SUCCESS"
export const GET_DASHBOARD_FILTER_USERS_FAIL = "POST_DASHBOARD_FILTER_USERS_FAIL"

export const GET_USER_SEARCH_REQUEST = "POST_USER_SEARCH_REQUEST"
export const GET_USER_SEARCH_SUCCESS = "POST_USER_SEARCH_SUCCESS"
export const GET_USER_SEARCH_FAIL = "POST_USER_SEARCH_FAIL"

export const GET_CCM_FOLLOW_UP_REQUEST = "GET_CCM_FOLLOW_UP_REQUEST"
export const GET_CCM_FOLLOW_UP_SUCCESS = "GET_CCM_FOLLOW_UP_SUCCESS"
export const GET_CCM_FOLLOW_UP_FAIL = "GET_CCM_FOLLOW_UP_FAIL"

export const GET_PREV_CAREPLANS_REQUEST = "GET_PREV_CAREPLANS_REQUEST"
export const GET_PREV_CAREPLANS_SUCCESS = "GET_PREV_CAREPLANS_SUCCESS"
export const GET_PREV_CAREPLANS_FAIL = "GET_PREV_CAREPLANS_FAIL"

export const GET_CCM_ARCHIVE_PLANS_REQUEST = "GET_CCM_ARCHIVE_PLANS_REQUEST"
export const GET_CCM_ARCHIVE_PLANS_SUCCESS = "GET_CCM_ARCHIVE_PLANS_SUCCESS"
export const GET_CCM_ARCHIVE_PLANS_FAIL = "GET_CCM_ARCHIVE_PLANS_FAIL"

export const UPDATE_CCM_PLAN_REQUEST = "UPDATE_CCM_PLAN_REQUEST"
export const UPDATE_CCM_PLAN_SUCCESS = "UPDATE_CCM_PLAN_SUCCESS"
export const UPDATE_CCM_PLAN_FAIL = "UPDATE_CCM_PLAN_FAIL"

export const POST_CCM_FOLLOW_UP_REQUEST = "POST_CCM_FOLLOW_UP_REQUEST"
export const POST_CCM_FOLLOW_UP_SUCCESS = "POST_CCM_FOLLOW_UP_SUCCESS"
export const POST_CCM_FOLLOW_UP_FAIL = "POST_CCM_FOLLOW_UP_FAIL"

export const GET_LATEST_CCM_CAREPLAN_REQUEST = "GET_LATEST_CCM_CAREPLAN_REQUEST"
export const GET_LATEST_CCM_CAREPLAN_SUCCESS = "GET_LATEST_CCM_CAREPLAN_SUCCESS"
export const GET_LATEST_CCM_CAREPLAN_FAIL = "GET_LATEST_CCM_CAREPLAN_FAIL"