import { useState, useEffect } from "react"
import { Button, Input, Table, Typography } from "antd"
import { useSelector, useDispatch } from "react-redux"
// import { ProTable } from "@ant-design/pro-components"
import { FaPlusCircle, FaRegEdit } from "react-icons/fa"
import {
    CloseCircleOutlined,
    SearchOutlined
} from "@ant-design/icons"

import "./style.css"
import CreateGroup from "./CreateGroup"
import EditGroup from "./EditGroup"
import { GetGroups } from "../../redux/actions/groupsActions"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const { Search } = Input

const GroupList = () => {
    const [tableData, setTableData] = useState([])
    const [searchInp, setSearchInp] = useState("")
    const [showCreateGroup, setShowCreateGroup] = useState(false)
    const [showEditGroup, setShowEditGroup] = useState(false)
    const [selectedGroupDetails, setSelectedGroupDetails] = useState({
        key: 0,
        name: "",
        id: "",
        createdBy: "",
        managers: []
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { orgId, orgName } = useParams()

    const { userInfo: adminInfo } = useSelector(state => state.userInfo)
    const { groups, loading } = useSelector((state) => state.getGroups)

    const highlightManager = (managers) => {
        return managers.map((manager, index) => (
            <>
                <Typography.Text className={`capitalize ${manager.id === adminInfo?.id ? "underline font-semibold" : ""}`}>{manager.managerName}</Typography.Text>
                {index < managers.length - 1 && ', '}
            </>
        ))
    }

    const columns = [
        {
            disable: true,
            title: "GROUPS",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (_, group) =>
                <p
                    className="capitalize cursor-pointer"
                    onClick={() => {
                        navigate(`/${orgId}/${orgName}/groups-details`, {
                            state: { groupDetails: group }
                        })
                    }}
                >
                    {group?.name}
                </p>
        },
        {
            title: "GROUP MANAGERS",
            dataIndex: "managers",
            sorter: (a, b) =>
                a.managers.length - b.managers.length,
            render: managers => (
                <div>
                    {Array.isArray(managers) && managers.length ? highlightManager(managers) : "-"}
                </div>
            )
        },
        {
            title: "CREATED ON",
            dataIndex: "createdOn",
            sorter: (a, b) => moment(a.createdOn) - (moment(b.createdOn)),
            render: createdOn => (
                <p>
                    {!createdOn ? "-" : moment.utc(createdOn)?.format("MM/DD/YYYY")}
                </p>
            )
        },
        {
            title: "JOINED ON",
            dataIndex: "joinedOn",
            sorter: (a, b) => moment(a.joinedOn) - (moment(b.joinedOn)),
            render: joinedOn => (
                <p>
                    {!joinedOn ? "-" : moment.utc(joinedOn)?.format("MM/DD/YYYY")}
                </p>
            )
        },
        {
            disable: true,
            title: "CREATED BY",
            dataIndex: "createdBy",
            sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
            render: createdBy => <p className="capitalize">{createdBy}</p>
        },
        {
            title: "ACTION",
            render: (_, group) => (
                group.managers.find(manager => manager.id === adminInfo?.id) ?
                    <Typography.Link
                        className="flex items-center gap-3"
                        onClick={() => {
                            setShowEditGroup(true)
                            setSelectedGroupDetails(group)
                        }}>
                        Edit
                        <FaRegEdit />
                    </Typography.Link>
                    : "-"
            )
        }
    ]

    useEffect(() => {
        dispatch(GetGroups(adminInfo.id))
    }, [])

    useEffect(() => {
        if (groups?.length !== 0) {
            const data = []
            groups?.forEach((group, index) => {
                const managers = group?.managers?.map((manager) => ({
                    managerName: `${manager?.firstName} ${manager?.middleName ? manager?.middleName : ""} ${manager?.lastName}`,
                    id: manager?.id,
                    profileType: manager?.profileType
                }))
                const members = group?.members?.map((member) => ({
                    memberName: `${member?.firstName} ${member?.middleName ? member?.middleName : ""} ${member?.lastName}`,
                    id: member?.id,
                    profileType: member?.profileType
                }))
                const memberJoinDate = group?.members?.find(m => m.id === adminInfo?.id)?.createdAt
                const createdBy = group?.createdByFirstName ? (group?.createdByFirstName + " " + (group?.createdByMiddleName ? group?.createdByMiddleName : "") + " " + group.createdByLastName) : "-"
                data.push({
                    key: index,
                    id: group?.id,
                    name: group?.name,
                    createdOn: group?.createdAt,
                    managers,
                    members,
                    createdBy,
                    joinedOn: memberJoinDate
                })
            })

            setTableData(data)
        }
    }, [groups])

    const onSearchGroup = (query) => {
        const data = []

        const trimmedSearchValue = query?.replace(/\s+/g, "").toLowerCase()

        let filterData = []
        if (query) {
            filterData = groups?.filter(
                ({ id, name, createdByFirstName, createdByLastName, createdByMiddleName, managers, members, createdAt }) => {
                    const fullName = `${createdByFirstName}${createdByMiddleName || ""}${createdByLastName}`.toLowerCase()
                    const reversedFullName = `${createdByLastName}${createdByMiddleName || ""}${createdByFirstName}`.toLowerCase()
                    const managerNames = managers.map(manager => `${manager?.firstName}${manager?.middleName || ""}${manager?.lastName}`.toLowerCase())
                    const memberJoinDate = members.find(m => m.id === adminInfo?.id)?.createdAt
                    const createdOn = moment.utc(createdAt).format("MM/DD/YYYY")
                    return (
                        id?.toLowerCase()?.includes(query?.trim()?.toLowerCase()) ||
                        name?.toLowerCase()?.includes(query?.trim()?.toLowerCase()) ||
                        createdByFirstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        createdByMiddleName?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        createdByLastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
                        fullName?.includes(trimmedSearchValue) ||
                        reversedFullName?.includes(trimmedSearchValue) ||
                        managerNames.some(managerName => managerName.includes(trimmedSearchValue)) ||
                        moment.utc(memberJoinDate)?.format("MM/DD/YYYY")?.includes(query?.trim()?.toLowerCase()) ||
                        createdOn?.includes(query?.trim()?.toLowerCase())
                    )
                }
            )
        } else filterData = groups

        filterData?.forEach((group, index) => {
            const managers = group?.managers?.map((manager) => ({
                managerName: `${manager?.firstName} ${manager?.middleName ? manager?.middleName : ""} ${manager?.lastName}`,
                id: manager?.id,
                profileType: manager?.profileType
            }))
            const members = group?.members?.map((member) => ({
                memberName: `${member?.firstName} ${member?.middleName ? member?.middleName : ""} ${member?.lastName}`,
                id: member?.id,
                profileType: member?.profileType
            }))
            const memberJoinDate = group?.members?.find(m => m.id === adminInfo?.id)?.createdAt
            const createdBy = group?.createdByFirstName ? (group?.createdByFirstName + " " + (group?.createdByMiddleName ? group?.createdByMiddleName : "") + " " + group.createdByLastName) : "-"
            data.push({
                key: index,
                name: group?.name,
                id: group?.id,
                createdOn: group?.createdAt,
                joinedOn: group?.joinedOn,
                managers,
                members,
                createdBy,
                joinedOn: memberJoinDate
            })
        })

        setTableData(data)
    }

    useEffect(() => {
        if (searchInp !== "") {
            onSearchGroup(searchInp)
        } else {
            onSearchGroup("")
        }

    }, [searchInp])

    const handlesearch = (e) => {
        const query = e?.target?.value
        let queryWithoutSpace = query?.replace(/^\s+/g, "")
        queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
        setSearchInp(queryWithoutSpace)
    }

    return (
        <div>
            <OrgBackNavigation />
            <div className="flex justify-between items-center flex-col sm:flex-row">
                <Typography.Title className="pt-3" level={3}>Groups</Typography.Title>
                <Button
                    className="ml-auto flex justify-center items-center gap-2"
                    style={{ width: "246px", height: "48px" }}
                    type="primary"
                    icon={<FaPlusCircle />}
                    onClick={() => setShowCreateGroup(true)}>
                    Create Group
                </Button>
            </div>

            <div className="mt-4 w-full flex justify-end">
                <Search
                    allowClear
                    size="large"
                    placeholder="Search Group"
                    className="max-w-[350px]"
                    value={searchInp}
                    onChange={e => handlesearch(e)}
                />
            </div>
            <p className="mt-3">
                <span className="font-semibold">Note :</span>
                <span> Underlined name in the group managers column indicates that you are the manager of that particular group. </span>
            </p>

            <Table
                columns={columns}
                dataSource={tableData}
                loading={loading}
                style={{ paddingTop: "10px" }}
                scroll={{ x: "max-content" }}
            />
            {showCreateGroup &&
                <CreateGroup
                    showCreateGroup={showCreateGroup}
                    setShowCreateGroup={setShowCreateGroup}
                />
            }
            {showEditGroup &&
                <EditGroup
                    showEditGroup={showEditGroup}
                    setShowEditGroup={setShowEditGroup}
                    selectedGroupDetails={selectedGroupDetails}
                />
            }
        </div>
    )
}

export default GroupList
