import { axiosPost } from "../axios"
import {
  GET_INTERNAL_NOTE_REQUEST,
  GET_INTERNAL_NOTE_SUCCESS,
  GET_INTERNAL_NOTE_FAIL,
  ADD_INTERNAL_NOTE_REQUEST,
  ADD_INTERNAL_NOTE_SUCCESS,
  ADD_INTERNAL_NOTE_FAIL,
  EDIT_INTERNAL_NOTE_REQUEST,
  EDIT_INTERNAL_NOTE_SUCCESS,
  EDIT_INTERNAL_NOTE_FAIL
} from "../types/internalNotesConstant"
import { userLogoutAction } from "./userActions"
import moment from "moment"

export const AddInternalNoteAction =
  ({ title, concern, bookMark, important, files, patientId }) =>
    async dispatch => {
      try {
        dispatch({
          type: ADD_INTERNAL_NOTE_REQUEST
        })

        const { data } = await axiosPost(`/api/cs/patients/concern/${patientId}/create`, {
          title,
          concern,
          bookMark,
          important,
          files,
          date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
        })

        dispatch({
          type: ADD_INTERNAL_NOTE_SUCCESS,
          payload: data
        })
        return { isDone: true }
      } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())

        const errMsg = error?.response?.data?.details
          ? Object.values(error.response.data.details)[0]
          : "Something went wrong!"
        dispatch({
          type: ADD_INTERNAL_NOTE_FAIL,
          payload: errMsg
        })
        return { isDone: false, error: errMsg }
      }
    }

export const EditInternalNoteAction =
  ({ title, concern, bookMark, important, files, concernId, date, patientId }) =>
    async dispatch => {
      try {
        dispatch({
          type: EDIT_INTERNAL_NOTE_REQUEST
        })

        const { data } = await axiosPost(
          `/api/cs/patients/concern/${patientId}/update/${concernId}`,
          {
            title,
            concern,
            bookMark,
            important,
            files,
            date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
          }
        )

        dispatch({
          type: EDIT_INTERNAL_NOTE_SUCCESS,
          payload: data
        })
        return { isDone: true }
      } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())

        const errMsg = error?.response?.data?.details
          ? Object.values(error.response.data.details)[0]
          : "Something went wrong!"
        dispatch({
          type: EDIT_INTERNAL_NOTE_FAIL,
          payload: errMsg
        })
        return { isDone: false, error: errMsg }
      }
    }

export const getInternalNotesAction =
  ({ patientId }) =>
    async dispatch => {
      try {
        dispatch({
          type: GET_INTERNAL_NOTE_REQUEST
        })

        const endDate =  moment().utc().endOf("day")

        const { data } = await axiosPost(`/api/cs/patients/concern/${patientId}/fetch`, {
          startDate: moment.utc(endDate).subtract(1, "month").startOf("day").toDate().toISOString(),
          endDate
        })
        // console.log(data)

        dispatch({
          type: GET_INTERNAL_NOTE_SUCCESS,
          payload: data
        })
      } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
          type: GET_INTERNAL_NOTE_FAIL,
          payload: error.response && error?.response?.data?.detail
        })
      }
    }
