export const EquipmentStatus = {
    Delivered : "Delivered",
    DeliveryRequested : "Delivery Requested",
    EquipmentActive : "Equipment Active",
    EquipmentMissing : "Equipment Missing",
    FacingEquipmentIssue : "Facing Equipment Issue",
    InTransit : "In Transit",
    PickedUpFromClinic : "Picked Up From Clinic",
    WillPickUpFromClinic : "Will Pick Up From Clinic"
  }

  export const PatientStatus = {
    Active : "Active",
    DeniedServices : "Denied Services",
    DeviceReceived: "Device Received",
    FacingIssue : "Facing Issue",
    Inactive : "Inactive"
  }