import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment/moment"
import { getPatientCaretaker, getPatientDiagnosticsData } from "../../redux/actions/patientAction"

const PatientPlanHeader = ({ patientInfoDetail, id, moniteringType, requestFrom, readingDetails }) => {
  const [diagnosticCodes, setDiagnosticCodes] = useState([])
  const [patientCaretakerData, setPatientCaretakerData] = useState([])

  const dispatch = useDispatch()

  const { loading: caretakerLoading, patientCaretaker } = useSelector(
    state => state.patientCaretaker
  )
  const { loading, patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
  useEffect(() => {
    if (!patientCaretaker) {
      dispatch(getPatientCaretaker(id))
    }
    if (!patientDiagnosticData?.length)
    {
      dispatch(getPatientDiagnosticsData({ patientId: id }))
    }
  }, [id])

  useEffect(() => {
    let codes = []
    patientDiagnosticData?.map(item => {
      codes.push({ icdCode: item.icdCode })
    })
    setDiagnosticCodes(codes)
    setPatientCaretakerData(patientCaretaker)
  }, [patientDiagnosticData])

  return (
    <div className="container-fluid p-3 shadow mb-4 rounded patient-detail-card">
      <h4 className="fw-bold title">Patient Details</h4>
      <div className="row justify-content-between">
        <div className="col-md-6 text-dark">
          {/* <p className='fw-bold text-capitalize mb-0'>
            Date of Service:&nbsp;<span className='fw-normal'>{patientInfoDetail?.careplanStats ? moment.unix(patientInfoDetail?.careplanStats?.createdAt).format("MM/DD/YYYY") :
              patientInfoDetail?.createdAt ? moment.unix(patientInfoDetail?.createdAt).format("MM/DD/YYYY") : ''}</span>
          </p> */}

          {patientInfoDetail?.name ? (
            <p className="fw-bold text-capitalize mb-0">
              Patient Name :&nbsp;<span className="fw-normal">{patientInfoDetail?.name}</span>
            </p>
          ) : (
            <p className="fw-bold text-capitalize mb-0">
              Patient Name :&nbsp;
              <span className="fw-normal">
                {patientInfoDetail?.firstName} {patientInfoDetail?.middleName}{" "}
                {patientInfoDetail?.lastName}
              </span>
            </p>
          )}
          <p className="fw-bold text-capitalize mb-0">
            Patient DOB :&nbsp;
            <span className="fw-normal">
              {moment(patientInfoDetail?.dob).format("MM/DD/YYYY")}
            </span>
          </p>

          <div className="d-flex flex-wrap fw-bold">
            Monitor Type :{" "}
            <span className="uppercase fw-normal">
              {requestFrom !== "plan-history"
                ? "rpm"
                : moniteringType.map(program => `${program}, `)}
            </span>
            {/* {patientInfoDetail?.monitorType ?
              patientInfoDetail?.monitorType?.map((item) =>
                item ===
                  patientInfoDetail?.monitorType[patientInfoDetail?.monitorType?.length - 1] ? (
                  <p className="fw-normal mb-0 pb-0">{item?.toUpperCase()}</p>
                ) : (
                  <p className="fw-normal mb-0 pb-0">{item?.toUpperCase()},&nbsp;</p>
                )
              )
              :
              patientInfoDetail?.moniteringDetails?.map((item) =>
                item ===
                  patientInfoDetail?.moniteringDetails[patientInfoDetail?.moniteringDetails?.length - 1] ? (
                  <p className="fw-normal mb-0 pb-0">{item?.moniterData?.name.toUpperCase()}</p>
                ) : (
                  <p className="fw-normal mb-0 pb-0">{item?.moniterData?.name.toUpperCase()},&nbsp;</p>
                )
              )
            } */}
          </div>
          {readingDetails && readingDetails?.length ?
          <div className="fw-bold align-items-center">
          <span>Critical Reading :</span>
            {readingDetails?.map(item => (            
              <div>      
               <span className="font-medium">{moment(item?.readingDetails?.readingDate).format("MM-DD-YYYY HH:mm:ss")}&nbsp;</span>
              <div className="font-normal">{item?.deviceType === "oximeter" &&
              <div>
                  <span>SpO2 : {item?.readingDetails?.spo},&nbsp;</span>
                  <span>Pulse rate : {item?.readingDetails?.pulseRate} {item?.readingDetails?.pulseUnit}&nbsp;</span>
              </div>
          }
          {item?.deviceType === "blood pressure machine" &&
              <div>
                  <span >Heart rate : {item?.readingDetails?.heartRate} {item?.readingDetails?.pulseUnit}, &nbsp;</span>
                  <span>Systolic pressure : {item?.readingDetails?.systolicPressure} {item?.readingDetails?.bloodPressureUnit},&nbsp;</span>
                  <span>Diastolic pressure : {item?.readingDetails?.diastolicPressure} {item?.readingDetails?.bloodPressureUnit}</span>
              </div>
          }
          {item?.deviceType === "glucose machine" &&
              <div>
                  <span>Glucose level : {item?.readingDetails?.glucoseLevel} {item?.readingDetails?.glucoseUnit}</span>
              </div>
          } </div>
          </div>
            ))}
          </div>
          : null
          }
        </div>

        <div className="col-md-6 text-dark">
          <div className="d-flex flex-wrap flex-column">
            {patientInfoDetail?.moniteringDetails?.map((value, idx) => (
              <div key={idx}>
                <p className="mb-0 pb-0">
                  <span className="fw-bolder">
                    {value?.moniterData?.name.toUpperCase()} Provider :{" "}
                  </span>
                  <span className="text-capitalize">
                    {value?.providerDetails?.providerData?.firstName}
                  </span>{" "}
                  <span className="text-capitalize">
                    {value?.providerDetails?.providerData?.lastName}
                  </span>
                </p>
              </div>
            ))}
          </div>

          <div className="d-flex flex-wrap">
            <p className="mb-0 pb-0">
              <span className="fw-bolder">CareTaker : </span>
              {patientCaretakerData?.length === 0 && (
                <span>No Caretaker selected for this patient.</span>
              )}
              {patientCaretakerData?.map((ct, index) => (
                <>
                  <span style={{ fontWeight: "800" }}>{` ${index + 1}. `}</span>
                  <span className="text-capitalize">{`${ct?.firstName}`}</span>{" "}
                  <span className="text-capitalize">{ct?.lastName}</span>
                </>
              ))}
            </p>
          </div>

          <div className="d-flex flex-wrap fw-bold align-items-center">
            Diagnostic(ICD) Codes :
            {diagnosticCodes.map(item => (
              <span className="font-normal">&nbsp;{item.icdCode}, </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientPlanHeader
