import React from "react";
// import AddIcon from "@mui/icons-material/Add";
// import { Button } from "@mui/material";
// import { indexOf } from "lodash";


const TriageFormInput = ({ques, handleNormalCheck, handleText,handleNormalText ,handleSingle,handleBpmNone,handleWeightMachineCheck,
    handleWeightMachineOtherText,handleWeightMachineNone,handleBool,handleBoolText,handleBoolAlert}) => {
    switch (ques.quesType) {
        case "text":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.datefield ?
                    <input
                    required
                    id={ques.id}
                    type='date'
                    max={new Date().toISOString().split('T')[0]}
                    disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                    style={{width: "100%", height: "50px"}}
                    value={ques.text}
                    onChange={(e) => {
                        handleNormalText(ques.id, e);
                    }}
              />
                :
                            <textarea
                            required
                            id={ques.id}
                            type='text'
                            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                            style={{width: "100%", height: "50px"}}
                            value={ques.notApplicable ? "" : ques.text ? ques.text : ""}
                            //onClick={e=>console.log(ques.id)}//only id
                            onChange={(e) => {
                                handleNormalText(ques.id, e);
                            }}
                      ></textarea>
                }
                      
                  </div>
            );
            case "bool":
                return(
                    <div style={{marginTop: "10px"}}>
                        <div className="d-flex align-items-center">
                            <div className="d-flex">
                            
                                <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'true' ? true : false} value='true' onChange={e=>handleBool(ques.id,e)}
                                disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                <label className="mx-2">Yes</label>
                            </div>
                            <div className="d-flex mx-5">
                            
                                <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'false' ? true : false} value='false' onChange={e=>handleBool(ques.id,e)}
                                disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                <label className="mx-2">No</label>
                            </div>
                        </div>
                      </div>
                );
                case "bool-alert":
                    return(
                        <div style={{marginTop: "10px"}}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex">
                                
                                    <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'true' ? true : false} value='true' onChange={e=>handleBoolAlert(ques.id,e)}
                                    disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                    <label className="mx-2">Yes</label>
                                </div>
                                <div className="d-flex mx-5">
                                
                                    <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'false' ? true : false} value='false' onChange={e=>handleBoolAlert(ques.id,e)}
                                    disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                    <label className="mx-2">No</label>
                                </div>
                            </div>
                          </div>
                    );
            case "bool-text":
                return(
                    <div style={{marginTop: "10px"}}>
                        <div className="d-flex align-items-center">
                            <div className="d-flex">
                            
                                <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'true' ? true : false} value='true' onChange={e=>handleBoolText(ques.id,e)}
                                disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                <label className="mx-2">Yes</label>
                            </div>
                            <div className="d-flex mx-5">
                            
                                <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'false' ? true : false} value='false' onChange={e=>handleBoolText(ques.id,e)}
                                disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                <label className="mx-2">No</label>
                            </div>
                        </div>
                        {ques.questionaryOptions[0] === 'true' ?
                        <>
                        <label className="fw-bold mt-3">{ques?.subQuestionaryOptions[0]?.name}</label>
                        <textarea
                        id={ques.id}
                        type='text'
                        disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        style={{width: "100%", height: "50px"}}
                        value={ques?.subQuestionaryOptions[0]?.text ? ques?.subQuestionaryOptions[0]?.text : ''}
                        //onClick={e=>console.log(ques.id)}//only id
                        onChange={(e) => {
                            handleBoolText(ques.id, e,'text');
                        }}
                    ></textarea>
                    </>
                  : null
                        }
                        </div>
                );
                case "bool-text-reverse":
                    return(
                        <div style={{marginTop: "10px"}}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex">
                                
                                    <input type="radio" id={ques.id} name="bool-select-rev" checked={ques.questionaryOptions[0] === 'true' ? true : false} value='true' onChange={e=>handleBoolText(ques.id,e)}
                                    disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                    <label className="mx-2">Yes</label>
                                </div>
                                <div className="d-flex mx-5">
                                
                                    <input type="radio" id={ques.id} name="bool-select-rev" checked={ques.questionaryOptions[0] === 'false' ? true : false} value='false' onChange={e=>handleBoolText(ques.id,e)}
                                    disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                    <label className="mx-2">No</label>
                                </div>
                            </div>
                            {ques.questionaryOptions[0] === 'false' ?
                            <>
                            <label className="fw-bold mt-3">{ques?.subQuestionaryOptions[0]?.name}</label>
                            <textarea
                            id={ques.id}
                            type='text'
                            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                            style={{width: "100%", height: "50px"}}
                            value={ques?.subQuestionaryOptions[0]?.text ? ques?.subQuestionaryOptions[0]?.text : ''}
                            //onClick={e=>console.log(ques.id)}//only id
                            onChange={(e) => {
                                handleBoolText(ques.id, e,'text');
                            }}
                        ></textarea>
                        </>
                      : null
                            }
                            </div>
                    );
        case "bool-text-alert":
            return(
<div style={{marginTop: "10px"}}>
    <div className="d-flex align-items-center">
        <div className="d-flex">
        
            <input type="radio" id={ques.id} name="bool-select-text" checked={ques.questionaryOptions[0] === 'true' ? true : false} value='true' onChange={e=>handleBoolText(ques.id,e)}
            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
            <label className="mx-2">Yes</label>
        </div>
        <div className="d-flex mx-5">
        
            <input type="radio" id={ques.id} name="bool-select-text" checked={ques.questionaryOptions[0] === 'false' ? true : false} value='false' onChange={e=>handleBoolText(ques.id,e)}
            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
            <label className="mx-2">No</label>
        </div>
    </div>
    {ques.questionaryOptions[0] === 'true' ?
    <>
    <label className="fw-bold mt-3">{ques?.subQuestionaryOptions[0]?.name}</label>
    <textarea
    id={ques.id}
    type='text'
    disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
    style={{width: "100%", height: "50px"}}
    value={ques?.subQuestionaryOptions[0]?.text ? ques?.subQuestionaryOptions[0]?.text : ''}
    //onClick={e=>console.log(ques.id)}//only id
    onChange={(e) => {
        handleBoolText(ques.id, e,'text');
    }}
></textarea>
</>
: null
    }
    </div>
            );
case "text-and-bool-text":
    return(
<div style={{marginTop: "10px"}}>
                        <div>
                            <div>
                        <textarea
                        id={ques.id}
                        type='text'
                        disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        style={{width: "100%", height: "50px"}}
                        value={ques?.text ? ques?.text : ''}
                        //onClick={e=>console.log(ques.id)}//only id
                        onChange={(e) => {
                            handleBoolText(ques.id, e,null,'tbt');//tbt=>text and bool text
                        }}
                    ></textarea>
                        </div>
                        <div>
                        <label className="fw-bold mt-3">{ques?.subQuestionaryOptions[0]?.subHeading}</label>
                        <div className="d-flex align-items-center">
                            <div className="d-flex">
                            
                                <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'true' ? true : false} value='true' onChange={e=>handleBoolText(ques.id,e)}
                                disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                <label className="mx-2">Yes</label>
                            </div>
                            <div className="d-flex mx-5">
                            
                                <input type="radio" id={ques.id} name="bool-select" checked={ques.questionaryOptions[0] === 'false' ? true : false} value='false' onChange={e=>handleBoolText(ques.id,e)}
                                disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                                <label className="mx-2">No</label>
                            </div>
                        </div>
                        </div>
                        </div>
                        {ques.questionaryOptions[0] === 'true' ?
                        <>
                        <label className="fw-bold mt-3">{ques?.subQuestionaryOptions[0]?.name}</label>
                        <textarea
                        id={ques.id}
                        type='text'
                        disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        style={{width: "100%", height: "50px"}}
                        value={ques?.subQuestionaryOptions[0]?.text ? ques?.subQuestionaryOptions[0]?.text : ''}
                        //onClick={e=>console.log(ques.id)}//only id
                        onChange={(e) => {
                            handleBoolText(ques.id, e,'text');
                        }}
                    ></textarea>
                    </>
                  : null
                        }
                        </div>
    );
    case "root-multi":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.questionaryOptions.map((item,i)=>(
                        <div className="d-flex mb-1" onClick={e=>console.log(ques.id,i)}>
                            
                        <input type="checkbox" id={item.id} checked={item.checked} onChange={e=>handleNormalCheck(ques.id,i,e,'check')}
                        disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        
                        </div>
                        
                    ))}
                </div>
            );
        case "multi":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.questionaryOptions.map((item,i)=>(
                        <div className="d-flex mb-1" onClick={e=>console.log(ques.id,i)}>
                            
                        <input type="checkbox" id={item.id} checked={item.checked} onChange={e=>handleNormalCheck(ques.id,i,e,'check')}
                        disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        
                        </div>
                        
                    ))}
                </div>
            );
        case "radio-multi":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.questionaryOptions.map((item,i)=>(
                        item.value !== undefined ?
                        <div className="d-flex align-items-center">
                        <div style={{width:'260px'}}>
                            <p className="fw-bold">{item?.name}</p>
                        </div>
                        <div className="d-flex">
                        
                            <input type="radio" id={item.id} name={`bool-select-${i}`} checked={item.value[0] === 'true' ? true : false} value='true' onChange={e=>handleNormalCheck(ques.id,item.id,e)}
                            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                            <label className="mx-2">Yes</label>
                        </div>
                        <div className="d-flex mx-5">
                        
                            <input type="radio" id={item.id} name={`bool-select-${i}`} checked={item.value[0] === 'false' ? true : false} value='false' onChange={e=>handleNormalCheck(ques.id,item.id,e)}
                            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                            <label className="mx-2">No</label>
                        </div>
                    </div>
                    :
                    // for checkbox
                    <div className="d-flex align-items-center">
                        <div className="d-flex">  
                        <input type="checkbox" id={item.id} checked={item.checked} onChange={e=>handleNormalCheck(ques.id,i,e,'check')}
                        disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                        </div>
                        <div>
                            <p className="fw-bold mx-3 mt-3">{item?.name}</p>
                        </div>
                    </div>
                    ))}
                </div>
            );
            case "radio":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.questionaryOptions.map((item,i)=>(
                        item.value !== undefined ?
                        <div className="d-flex align-items-center">
                        <div style={{width:'260px'}}>
                            <p className="fw-bold">{item?.name}</p>
                        </div>
                        <div className="d-flex">
                        
                            <input type="radio" id={item.id} name={`bool-select-radio`} checked={item.value[0] === 'true' ? true : false} value='true' onChange={e=>handleNormalCheck(ques.id,item.id,e)}
                            disabled={ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                        </div>
                    </div>
                    :
                    // for checkbox
                    <div className="d-flex align-items-center">
                        <div className="d-flex">  
                        <input type="checkbox" id={item.id} checked={item.checked} onChange={e=>handleNormalCheck(ques.id,i,e,'check')}
                        disabled={item.disabled || ques.notApplicable !== undefined && ques.notApplicable ? true : false}/>
                        </div>
                        <div>
                            <p className="fw-bold mx-3 mt-3">{item?.name}</p>
                        </div>
                    </div>
                    ))}
                </div>
            );
        default:
            return(
                <div>
                    Invalid question
                </div>
            )

    }
}

export default TriageFormInput
