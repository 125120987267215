import moment from "moment";
import { axiosGet, axiosPost, axiosDelete } from "../axios"

import {
    GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    POST_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    POST_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    POST_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    UPDATE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    UPDATE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    UPDATE_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    DELETE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    DELETE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    DELETE_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    POST_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    POST_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    POST_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    DELETE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    DELETE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    DELETE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    RESET_PATIENT_BP_CUSTOM_PARAMETER,
    RESET_PATIENT_OXI_CUSTOM_PARAMETER,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
    RESET_PATIENT_GLUCO_CUSTOM_PARAMETER,
    POST_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    POST_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    POST_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
    UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
    DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL
} from "../types/customParameterConstant";
import { userLogoutAction } from "./userActions";

export const getPatientBpCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/patients/specifiedParameter/${patientId}/bp/fetch`)
        dispatch({
            type: GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const resetPatientBpCustomParameter = () => async dispatch => {
    dispatch({
        type: RESET_PATIENT_BP_CUSTOM_PARAMETER
    })
}

export const postPatientBpCustomParameter = (formData, patientId) => async dispatch => {
    try {
        dispatch({
            type: POST_PATIENT_BP_CUSTOM_PARAMETER_REQUEST
        })
        const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
        const { data } = await axiosPost(`/api/cs2/patients/specifiedParameter/${patientId}/bp/create`, updatedFormData)
        dispatch({
            type: POST_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: POST_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const updatePatientBpCustomParameter = (formData, patientId) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST
        })
        const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
        const { data } = await axiosPost(`/api/cs2/patients/specifiedParameter/${patientId}/bp/update`, updatedFormData)
        dispatch({
            type: UPDATE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: UPDATE_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const deletePatientBpCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: DELETE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosDelete(
          `/api/cs2/patients/specifiedParameter/${patientId}/bp/delete`,
          { data: { date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") } }
        )
        dispatch({
            type: DELETE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: DELETE_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

// oximeter 

export const getPatientOxiCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/patients/specifiedParameter/${patientId}/oximeter/fetch`)
        dispatch({
            type: GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const resetPatientOxiCustomParameter = () => async dispatch => {
    dispatch({
        type: RESET_PATIENT_OXI_CUSTOM_PARAMETER
    })
}

export const postPatientOxiCustomParameter = (formData, patientId) => async dispatch => {
    try {
        dispatch({
            type: POST_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST
        })
        const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
        const { data } = await axiosPost(`/api/cs2/patients/specifiedParameter/${patientId}/oximeter/create`, updatedFormData)
        dispatch({
            type: POST_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: POST_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const updatePatientOxiCustomParameter = (formData, patientId) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST
        })
        const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
        const { data } = await axiosPost(`/api/cs2/patients/specifiedParameter/${patientId}/oximeter/update`, updatedFormData)
        dispatch({
            type: UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const deletePatientOxiCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: DELETE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosDelete(
          `/api/cs2/patients/specifiedParameter/${patientId}/oximeter/delete`,
          { data: { date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") } }
        )
        dispatch({
            type: DELETE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: DELETE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

// GLUCOSE METER
export const getPatientGlucoCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosGet(`/api/cs/patients/specifiedParameter/${patientId}/glucose/fetch`)
        dispatch({
            type: GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const resetPatientGlucoCustomParameter = () => async dispatch => {
    dispatch({
        type: RESET_PATIENT_GLUCO_CUSTOM_PARAMETER
    })
}

export const postPatientGlucoCustomParameter = (formData, patientId) => async dispatch => {
    try {
        dispatch({
            type: POST_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST
        })
        const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
        const { data } = await axiosPost(`/api/cs2/patients/specifiedParameter/${patientId}/glucose/create`, updatedFormData)
        dispatch({
            type: POST_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: POST_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const updatePatientGlucoCustomParameter = (formData, patientId) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST
        })
        const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
        const { data } = await axiosPost(`/api/cs2/patients/specifiedParameter/${patientId}/glucose/update`, updatedFormData)
        dispatch({
            type: UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

export const deletePatientGlucoCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosDelete(
          `/api/cs2/patients/specifiedParameter/${patientId}/glucose/delete`,
          { data: { date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") } }
        )
        dispatch({
            type: DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}