import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import moment from "moment"
import { Tag, Button, Input, Checkbox, Space, Tooltip } from "antd"
import ReactDragListView from "react-drag-listview"
import "../PatientDetails/patient.css"
import Loading from "../layouts/Loading/Loading"
import PatientManualLogModal from "../Patient/PatientManualLogModal"
import { ProTable } from "@ant-design/pro-components"
import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import OnboardingStageActionDropdown from "../SelfOnboarding/EligiblePatient/OnboardingStageActionDropdown"
import { CaretakerTypes, PatientStage } from "../SelfOnboarding/EligiblePatient/Types"
import CaretakerAssignedDropdown from "../SelfOnboarding/EligiblePatient/CaretakerAssignedDropdown"
import AddCaretakerDropdown from "../SelfOnboarding/EligiblePatient/AddCaretakerDropdown"
import RemoveCaretakerDropdown  from "../SelfOnboarding/EligiblePatient/RemoveCaretakerDropdown"
import { getCaretakerListAction } from "../../redux/actions/caretakerActions"
import { useDispatch, useSelector } from "react-redux"
import EquipmentStatusDropdown from "../SelfOnboarding/EquipmentStatusDropdown"
import PatientStatusFilterDropdown from "../SelfOnboarding/PatientStatusFilterDropdown"

const PatientTable = ({ loading, sortedData }) => {
  // modal
  const [data, setData] = useState([])
  const [currentDate] = useState(new Date())
  const [priorDate, setPriorDate] = useState() //eslint-disable-line
  const [manualLogOpen, setManualLogOpen] = useState(false)
  const [patientDetails, setPatientDetails] = useState(null)
  const [manualCountType, setManualCountType] = useState()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filteredPatients, setFilteredPatients] = useState([])
  const [searchPatients, setSearchPatients] = useState([])
  const [selectedCaretakers, setSelectedCaretakers] = useState()
  const [caretakerAssignedType, setCaretakerAssignedType] = useState()
  const [onboardingSelectedStage, setOnboardingSelectedStage] = useState()
  const [searchQuery, setSearchQuery] = useState()
  const [equipmentStatus, setEquipmentStatus] = useState()
  const [patientStatus, setPatientStatus] = useState()

  const { loading: caretakerLoading, caretakerLists } = useSelector(state => state.caretakerList)
  const dispatch = useDispatch()
  const { orgId, orgName } = useParams()

  const handleShowManualLogClick = (obj, programType) => {
    setManualLogOpen(true)
    setPatientDetails(obj)
    setManualCountType(programType)
  }

  const getCaretakerById = (caretakerIds) => {
    if (!caretakerIds) return
    const caretakerIdArray = caretakerIds?.split("~") // Splitting multiple IDs if present
    const caretakerNames = []
    for (const id of caretakerIdArray) {
      // @ts-expect-error
      const caretaker = caretakerLists?.find(c => c.id === id)
      if (caretaker) {
        caretakerNames.push(
          `${caretaker?.firstName} ${caretaker?.middleName ? caretaker?.middleName + " " : ""} ${
            caretaker?.lastName
          }  <${caretaker?.email}>`
        )
      }
    }
    return caretakerNames
  }
  const [columns, setColumns] = useState([
    {
      title: <span className="dragHandler cursor-move">S.No.</span>,
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: <span className="dragHandler cursor-move">Full Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name, { id , status, facilityId, totalReading}) => {
        return (
          <p
            className={
              `${status ? "text-danger" :""} text-capitalize wrap-text mb-0`
            }
            onClick={() => {
              navigate(`/${orgId}/${orgName}/patient/${id}`, { state: { facilityId, totalReading } })
            }}
            style={{ cursor: "pointer" }}>
            {name}
          </p>
        )
      },
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    {
      title: <span className="dragHandler cursor-move">Username</span>,
      dataIndex: "username",
      key: "username",
      render: username => <p className="wrap-text mb-0">{username}</p>
    },
    {
      title: <span className="dragHandler cursor-move">User Type</span>,
      dataIndex: "userType",
      key: "userType",
      render: userType => <p className="wrap-text mb-0 capitalize">{userType || null}</p>
    },
    {
      title: <span className="dragHandler cursor-move">Total Reading</span>,
      dataIndex: "totalReading",
      key: "totalReading",
      render: (totalReading) => (
       <p className="wrap-text mb-0">{totalReading}</p>) ,
      sorter: (a, b) =>  a.totalReading - b.totalReading
    },
    // {
    //   title: <span className="dragHandler cursor-move">RTM Pain Management Count</span>,
    //   dataIndex: "rtmPainManagementCount",
    //   key: "rtmPainManagementCount",
    //   render: rtmPainManagementCount => <p className="wrap-text mb-0">{rtmPainManagementCount}</p>,
    //   sorter: (a, b) => a.rtmPainManagementCount - b.rtmPainManagementCount
    // },
    // {
    //   title: <span className="dragHandler cursor-move">RTM Medicated Assisted Therapy Count</span>,
    //   dataIndex: "rtmMedicatedAssistedTherapyCount",
    //   key: "rtmMedicatedAssistedTherapyCount",
    //   render: rtmMedicatedAssistedTherapyCount => (
    //     <p className="wrap-text mb-0">{rtmMedicatedAssistedTherapyCount}</p>
    //   ),
    //   sorter: (a, b) => a.rtmMedicatedAssistedTherapyCount - b.rtmMedicatedAssistedTherapyCount
    // },

    {
      title: <span className="dragHandler cursor-move">Unit No.</span>,
      dataIndex: "unit",
      key: "unit",
      render: unit =>
      unit !== null ? (
        <p className="wrap-text mb-0">{unit}</p>
      ) : (
        <span>N/A</span>
      ),
      //   sorter: (a, b) => a?.unit.localeCompare(b?.unit)
      sorter: (a, b) => a?.unit - b?.unit
    },
    {
      title: <span className="dragHandler cursor-move">Room No.</span>,
      dataIndex: "room",
      key: "room",
      render: room => 
      room !== null  ? ( 
      <p className="wrap-text mb-0">{room}</p>
      ) : (
        <span>N/A</span>
      ),
      //   sorter: (a, b) => a?.room.localeCompare(b?.room)
      sorter: (a, b) => a?.room - b?.room
    },
    {
      title: <span className="dragHandler cursor-move">Critical Status</span>,
      dataIndex: "status",
      key: "status",
      render: status =>
        status !== null && status === true ? (
          <Tag color="red">Critical</Tag>
        ) : (
          <span>N/A</span>
        ),
      sorter: a => (a?.status !== null && a?.status === true ? 1 - 0 : 0 - 1)
    },
    {
      title: <span className="dragHandler cursor-move">Caretaker</span>,
      dataIndex: "caretaker",
      key: "caretaker",
      width: "25vw",
      render: (caretaker) => (
        <span>
          {Array.isArray(caretaker)
            ? caretaker.map((item, index) => {
                const [name, email] = item.split("<")
                return (
                  <span key={index}>
                    <span className="font-semibold capitalize">{name?.toLowerCase()}</span>&lt;
                    {email}
                    {index !== caretaker.length - 1 ? ", " : ""}
                  </span>
                )
              })
            : caretaker}
        </span>
      )
    },
    {
      title: <span className="dragHandler cursor-move">Equipment Status</span>,
      dataIndex: "equipmentStatus",
      key: "equipmentStatus",
      sorter: (a, b) => (a.equipmentStatus ?? "").localeCompare(b.equipmentStatus ?? ""),
      render: (equipmentStatus) => (
        <span className="capitalize">
          {equipmentStatus}
        </span>
      )
    },
    {
      title: <span className="dragHandler cursor-move">Patient Status</span>,
      dataIndex: "patientStatus",
      key: "patientStatus",
      sorter: (a, b) => (a.patientStatus ?? "").localeCompare(b.patientStatus ?? ""),
      render: (patientStatus) => (
        <span className="capitalize">
          {patientStatus}
        </span>
      )
    },
    {
      key: "rpmManualLogCount",
      dataIndex: "rpmManualLogCount",
      sorter: (a, b) => a.rpmManualLogCount - b.rpmManualLogCount,
      title: <span className="dragHandler cursor-move">Total RPM Mins Spent</span>,
      render: (rpmManualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row , 'rpm')}>{rpmManualLogCount} Mins</Button>
      )
    },
    {
      key: "ccmManualLogCount",
      dataIndex: "ccmManualLogCount",
      sorter: (a, b) => a.ccmManualLogCount - b.ccmManualLogCount,
      title: <span className="dragHandler cursor-move">Total CCM Mins Spent</span>,
      render: (ccmManualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row , 'ccm')}>{ccmManualLogCount} Mins</Button>
      )
    },
    {
      key: "manualLogCount",
      dataIndex: "manualLogCount",
      sorter: (a, b) => a.manualLogCount - b.manualLogCount,
      title: <span className="dragHandler cursor-move">Total Mins Spent</span>,
      render: (manualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row)}>{manualLogCount} Mins</Button>
      )
    }
  ])

  useEffect(() => {
    // const priorDate = new Date(new Date().setDate(currentDate.getDate() - 28));
    if (currentDate !== undefined) {
      const manageDate = moment(currentDate).subtract(40, "days")
      setPriorDate(manageDate._d) //eslint-disable-line
    }
  }, [currentDate])
  useEffect(() => {
   
    if (!caretakerLists?.length )
      dispatch(getCaretakerListAction(orgId))
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    // console.log('changed')
    const newData = []

    let sno = 1
    sortedData?.forEach(data => {
      const {
        id,
        email,
        mobileNumber,
        unit,
        room,
        userType,
        userName,
        lastName,
        firstName,
        middleName,
        criticalStatus,
        caretakerIds,
        manualLogCount,
        rpmManualLogCount,
        ccmManualLogCount,
        rpmReadingCount,
        monthlyLastestTotalReadingRPM,
        rtmPainManagementCount,
        rtmMedicatedAssistedTherapyCount,
        rpmActiveDate,
        facilityId,
        equipmentStatus,
        patientStatus
      } = data
      newData.push({
        unit,
        room,
        key: sno,
        sNo: sno,
        name:
          !middleName || middleName === null
            ? `${firstName} ${lastName}`
            : `${firstName} ${middleName} ${lastName}`,
        username: userName,
        email,
        phone: mobileNumber,
        id: id,
        userType,
        caretaker: getCaretakerById(caretakerIds),
        caretakerIds,
        manualLogCount,
        rpmManualLogCount,
        ccmManualLogCount,
        rtmPainManagementCount,
        totalReading: monthlyLastestTotalReadingRPM,
        rtmMedicatedAssistedTherapyCount,
        status: criticalStatus,
        rpmActiveDate,
        facilityId,
        equipmentStatus,
        patientStatus
      })

      sno += 1
    })
    setData(newData)
  }, [sortedData])

  useEffect(() => {
    setTimeout(() => {
      if (columns && columns.length && localStorage.getItem("patientCols")) {
        const storedCols = JSON.parse(localStorage.getItem("patientCols"))
        const defaultCols = [...columns]
        const storedColsIndex = storedCols.map((col, index) => col.key)
        const sorted = defaultCols.sort(function (a, b) {
          return storedColsIndex.indexOf(a.key) - storedColsIndex.indexOf(b.key)
        })
        Promise.all(sorted).then(() => {
          setColumns([...defaultCols])
        })
      }
    }, 1000)
  }, [])

  useEffect(() => {
    let filtered = data
    // Filter based on onboarding stage
    // if (onboardingSelectedStage) {
    //   filtered = filtered?.filter(
    //     (patient: any) =>
    //       patient?.onBoardingStage?.toLowerCase() === onboardingSelectedStage?.toLowerCase()
    //   )
    // }
    if (selectedCaretakers) {
      if (caretakerAssignedType === CaretakerTypes.NoCaretakerAssigned) {
        filtered = filtered.filter(
          (patient) => !patient.caretakerIds === null || !patient?.caretaker?.length
        )
    
      } else if (
        caretakerAssignedType === CaretakerTypes.MultipleCaretaker &&
        !selectedCaretakers.length
      ) {
        filtered = filtered.filter((patient) => patient?.caretaker?.length)
      } else if (caretakerAssignedType === CaretakerTypes.MultipleCaretaker) {
        filtered = filtered?.filter(patient =>
          selectedCaretakers.some(caretakerChecked =>
            patient.caretakerIds?.includes(caretakerChecked.value)
          ) && patient.caretaker?.length >= selectedCaretakers.length
        )
      }
    }
    if (equipmentStatus) {
      filtered = filtered.filter(
        (patient) => patient.equipmentStatus?.toLowerCase()?.trim() === equipmentStatus?.toLowerCase()?.trim()
      )
    }
    if (patientStatus) {
      filtered = filtered.filter(
        (patient) => patient.patientStatus?.toLowerCase()?.trim() === patientStatus?.toLowerCase()?.trim()
      )
    }
    setFilteredPatients(filtered)
  }, [onboardingSelectedStage, selectedCaretakers, caretakerAssignedType, equipmentStatus, patientStatus, caretakerLoading, data])

  useEffect(() => {
    setSearchPatients(filterPatients(filteredPatients, searchQuery))
  }, [searchQuery])

  const handleSearch = (e) => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }
  const filterPatients = (patients, query) => {
    if (!query) return patients

    const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()

    return patients.filter((p) => {
      return (
        p.username?.toLowerCase().includes(trimmedSearchValue) ||
        p.email?.toLowerCase().includes(trimmedSearchValue) ||
        p.name?.toLowerCase().includes(query?.trim()?.toLowerCase()) ||
        String(p.phone)
          ?.toLowerCase()
          .includes(trimmedSearchValue?.replace(/[()-]/g,"") || "") ||
        p.id?.toLowerCase().includes(trimmedSearchValue)
      )
    })
  }

  const onCaretakerSelection = (caretakers, caretakerAssigned) => {
    setCaretakerAssignedType(caretakerAssigned)
    setSelectedCaretakers(caretakers)
  }

  const onEquipStatusSelection = (status) => {
    setEquipmentStatus(status)
  }

  const onEquipStatusSelectionClear = () => {
    setEquipmentStatus("")
    if(!caretakerAssignedType)
      setFilteredPatients(data)
  }
  
  const onPatientStatusSelection = (status) => {
    setPatientStatus(status)
  }

  const onPatientStatusSelectionClear = () => {
    setPatientStatus("")
    if(!caretakerAssignedType)
      setFilteredPatients(data)
  }

  const handleSelectAll = (selected) => {
    // @ts-expect-error
    setSelectedRowKeys(selected ? filteredPatients?.map(r => r.id) : [])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnTitle: (
      <Checkbox
        checked={selectedRowKeys?.length === data?.length}
        indeterminate={
          selectedRowKeys?.length > 0 && selectedRowKeys.length < data?.length
        }
        onChange={e => handleSelectAll(e.target.checked)}
      />
    )
  }
  const onCaretakerSelectionClear = () => {
    setCaretakerAssignedType("")
    setSelectedRowKeys([])
    if(!onboardingSelectedStage)
      setFilteredPatients(data)
  }

  const onBoardingStageSelectionClear = () => {
    setOnboardingSelectedStage("")
    setFilteredPatients(data)
  }

  const onDragEnd = (fromIndex, toIndex) => {
    setColumns(prev => {
      const nextColumns = [...prev]
      const item = nextColumns.splice(fromIndex, 1)[0]
      nextColumns.splice(toIndex, 0, item)
      localStorage.setItem(
        "patientCols",
        JSON.stringify(nextColumns.map(col => ({ key: col.key })))
      )
      return nextColumns
    })
  }

  return (
    <>
      <ReactDragListView.DragColumn
        onDragEnd={onDragEnd}
        nodeSelector="th"
        handleSelector=".dragHandler"
        ignoreSelector="react-resizable-handle">
        <ProTable className="active-patients-table" columns={columns} 
          onRow={() => ({
            className: "tableRow"
          })}
         dataSource={!searchQuery ? filteredPatients : searchPatients}
         rowKey={record => record.id}
         loading={loading}
        toolbar={{
          actions: [
            <Input
              key="search"
              style={{ width: 241, height: 48 }}
              value={searchQuery}
              onChange={e => handleSearch(e)}
              allowClear
              disabled={!data?.length}
              placeholder="Search Patient"
              prefix={<SearchOutlined className="disable" />}
            />
          ]
        }}
        tableAlertOptionRender={({ onCleanSelected }) => (
          <div className="flex max-sm:flex-col">
            <div>
              {caretakerAssignedType && (
                <AddCaretakerDropdown
                  selectedCaretakers={selectedCaretakers}
                  selectedRowKeys={selectedRowKeys}
                  onCaretakerSelectionClear={onCaretakerSelectionClear}
                  patientStage={PatientStage.Active}
                />
              )}
            </div>
            <div className="pl-4">
              {caretakerAssignedType === CaretakerTypes.MultipleCaretaker && (
                <RemoveCaretakerDropdown
                  selectedCaretakers={selectedCaretakers}
                  selectedRowKeys={selectedRowKeys}
                  onCaretakerSelectionClear={onCaretakerSelectionClear}
                  patientStage={PatientStage.Active}
                />
              )}
            </div>
            <div className="pl-4">
              {onboardingSelectedStage && (
                <OnboardingStageActionDropdown
                  selectedRowKeys={selectedRowKeys}
                  onboardingSelectedStage={onboardingSelectedStage}
                  onBoardingStageSelectionClear={onBoardingStageSelectionClear}
                />
              )}
            </div>
            &nbsp; &nbsp;
            <Button onClick={onCleanSelected} className="mr-auto text-danger border-danger">
              Clear selection
            </Button>
          </div>
        )}
        rowSelection={
          onboardingSelectedStage?.length || caretakerAssignedType?.length ? rowSelection : false
        }
        options={{
          density: false,
          reload: false,
          fullScreen: false,
          setting: {
            children: (
              <Space.Compact
                className={`card light flex md:flex-row max-md:flex-col max-md:mb-20`}
                style={{ borderWidth: 0, height: 48 }}>
                <Button
                  style={{ width: 241, height: "inherit" }}
                  className="flex place-items-center p-0">
                  <span className="secondary text-xs px-4">Filter By</span>
                  <div
                    className="border-l flex-1 flex items-center justify-center gap-2"
                    style={{
                      height: "inherit",
                      borderColor: "rgba(0, 0, 0, 0.1)"
                    }}>
                    Column Visibility
                    <DownOutlined />
                  </div>
                </Button>
                <Space.Compact
                  style={{ height: "inherit", display: "flex" }}
                  onClick={e => e.stopPropagation()}>
                  <CaretakerAssignedDropdown
                    // caretakerAssigned={caretakerAssigned}
                    // setCaretakerAssigned={setCaretakerAssigned}
                    onCaretakerSelection={onCaretakerSelection}
                    onCaretakerSelectionClear={onCaretakerSelectionClear}
                  />
                </Space.Compact>
                <Space.Compact
                 style={{ height: "inherit", display: "flex" }}
                 onClick={e => e.stopPropagation()}>
                 <EquipmentStatusDropdown
                   onEquipStatusSelection={onEquipStatusSelection}
                   onEquipStatusSelectionClear={onEquipStatusSelectionClear}
                 />
                </Space.Compact>
                <Space.Compact
                  style={{ height: "inherit", display: "flex" }}
                  onClick={e => e.stopPropagation()}>
                    <PatientStatusFilterDropdown
                      onPatientStatusSelection={onPatientStatusSelection}
                      onPatientStatusSelectionClear={onPatientStatusSelectionClear}
                    />
                </Space.Compact>
              </Space.Compact>
            )
          }
        }}
        search={false}
        pagination={{
          defaultPageSize: 10,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
        }}
        scroll={{ x: "max-content" }} />
      </ReactDragListView.DragColumn>
      <PatientManualLogModal
        manualLogOpen={manualLogOpen}
        patientDetails={patientDetails}
        setManualLogOpen={setManualLogOpen}
        manualCountType={manualCountType}
      />
    </>
  )
}

export default PatientTable
