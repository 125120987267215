import React, { useEffect, useState } from "react"

import { useNavigate, useParams } from "react-router-dom"

import moment from "moment"
import { Table } from "antd"

import "react-datepicker/dist/react-datepicker.css"
import formatPhoneNumber from "../../utils/formatPhoneNumber"

const CaretakerNewTable = ({ loading, sortedData }) => {
  const [data, setData] = useState([])
  const [currentDate] = useState(new Date())
  const [priorDate, setPriorDate] = useState() //eslint-disable-line

  const {orgId, orgName} = useParams()

  useEffect(() => {
    if (currentDate !== undefined) {
      const manageDate = moment(currentDate).subtract(40, "days")
      setPriorDate(manageDate._d) //eslint-disable-line
    }
  }, [currentDate])

  const navigate = useNavigate()

  // column for table
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <a
          className="capitalize truncate"
          onClick={() => navigate(`/${orgId}/${orgName}/caretaker/${id}`)}
          style={{ cursor: "pointer" }}>
          {name}
        </a>
      ),
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    {
      title: "User Id",
      dataIndex: "id",
      key: "id",
      render: id => <p>{id}</p>
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: email => <p>{email}</p>,
      sorter: (a, b) => a.email.localeCompare(b.email)
    },

    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: mobileNumber => <p>{formatPhoneNumber(mobileNumber)}</p>,
      // sorter: (a, b) => a.mobileNumber.localeCompare(b.mobileNumber)
    },

    {
      title: "Total Patients",
      dataIndex: "patientCount",
      key: "patientCount",
      render: patientCount => <p>{patientCount ?? "-"}</p>,
      sorter: (a, b) => a.patientCount - b.patientCount
    }
  ]

  useEffect(() => {
    const newData = []

    let sno = 1
    sortedData?.forEach(
      ({ firstName, middleName, lastName, id, email, mobileNumber, patientCount }) => {
        newData.push({
          key: sno,
          sNo: sno,
          name: `${firstName} ${middleName ?? ""} ${lastName}`,
          id,
          patientCount,
          email,
          mobileNumber
        })

        sno += 1
      }
    )
    setData(newData)
  }, [sortedData])

  return (
    <Table loading={loading} columns={columns} dataSource={data} scroll={{ x: "max-content" }} />
  )
}

export default CaretakerNewTable
