import React from "react"

import { Input, Button } from "antd"

import AddCaretakerModal from "./AddCaretakerModal"

export const sortBy = Object.freeze({
  AtoZ: 0,
  ZtoA: 1
})

const { Search } = Input

const CaretakerFilters = ({
  pageSize,
  onAscSort,
  onDecSort,
  setPageSize,
  searchQuery,
  onDefaultSort,
  setCurrentPage,
  setSearchQuery,
  showAddCaretaker,
  handleCaretakerShow,
  setShowAddCaretaker,
  handleCaretakerModalClose
}) => {

  const handleSearch = e => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }
  return (
    <>
      <div className="mb-4">
        <div className="flex gap-4 flex-wrap justify-between">
          <Search
            size="large"
            allowClear
            value={searchQuery}
            className="max-w-[300px]"
            placeholder="Search Caretaker..."
            onChange={(e) => handleSearch(e)}
          />
          <Button size="large" type="primary" onClick={() => handleCaretakerShow()}>
            Add New Caretaker
          </Button>
        </div>
      </div>
      <AddCaretakerModal
        show={showAddCaretaker}
        setShowAddCaretaker={setShowAddCaretaker}
        handleClose={handleCaretakerModalClose}
      />
    </>
  )
}

export default CaretakerFilters
