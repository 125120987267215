import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import moment from "moment"
import { Card, Button, Table, Tooltip } from "antd"

import { useSelector, useDispatch } from "react-redux"
import { getrpmTriageDataActions } from "../../../redux/actions/rpmAction"
import { saveSelectedDateRange } from "../../../redux/actions/patientAction"

import Loading from "../../layouts/Loading/Loading"
import { EyeOutlined } from "@ant-design/icons"
import MyDatePicker from "../../../utils/MyDatePicker"

const { RangePicker } = MyDatePicker

const ViewTriageScreen = () => {
  const { id, orgId, orgName } = useParams()
  const [value, setValue] = useState(null)
  const [fetchedTriage, setFetchedTriage] = useState([])
  const [triageData, setTriageData] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { loading, triage } = useSelector(state => state.rpmTriageData)
  const selectedDateRange = useSelector(state => state.selectedDateRange)

  const patientInfo = location?.state?.patientInfo

  const [range, setRange] = useState(
    selectedDateRange?.TriageReportRange
      ? [
        selectedDateRange?.TriageReportRange?.startDate,
        selectedDateRange?.TriageReportRange?.endDate
      ]
      : [moment(new Date()).subtract(1, "month").startOf("day"), moment(new Date()).endOf("day")]
  )

  useEffect(() => {
    if (id) {
      dispatch(
        getrpmTriageDataActions(
          // moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
          // moment.utc().endOf("day").toDate().toISOString(),
          moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          moment(range[1]).format("YYYY-MM-DDTHH:mm:ss"),
          id
        )
      ).then(res => {
        setFetchedTriage(res)
      })
    }
  }, [id])

  const onOpenChange = open => {
    if (open) {
      setDates([null, null])
    } else {
      setDates(null)
    }
  }

  // Function to disable dates outside one month range
  const disabledDate = current => {
    if (!range || range.length === 0) {
      // If no range is selected yet, allow all dates
      return false
    }
    const tooLate = range[0] && current.diff(range[0], "days") > 32
    return !!tooLate || current > moment()
  }
  const handleRangeChange = async (date, dateString) => {
    if (dateString?.[0]?.length && dateString?.[1]?.length)
      setRange([moment(dateString[0]).startOf("day"), moment(dateString[1]).endOf("day")])
  }

  const handleDateSelection = async () => {
    let res
    if (range && range?.length === 2) {
      dispatch(saveSelectedDateRange("TriageReportRange", range?.[0], range?.[1]))
      res = await dispatch(
        getrpmTriageDataActions(
          moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          moment(range[1]).format("YYYY-MM-DDTHH:mm:ss"),
          id
        )
      )
    } else {
      res = await dispatch(
        getrpmTriageDataActions(
          moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
          moment.utc().endOf("day").toDate().toISOString(),
          id
        )
      )
    }
    setFetchedTriage(res)
  }

  const handleDateReset = async () => {
    setRange([moment(new Date()).subtract(1, "month"), moment(new Date())])
    dispatch(
      saveSelectedDateRange(
        "TriageReportRange",
        moment(new Date()).subtract(1, "month"),
        moment(new Date())
      )
    )
    const res = await dispatch(
      getrpmTriageDataActions(
        moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
        moment.utc().endOf("day").toDate().toISOString(),
        id
      )
    )
    setFetchedTriage(res)
  }
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Triage Type",
      dataIndex: "triageType",
      key: "triageType",
      render: triageType => <p className="capitaliz">{triageType}</p>
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: createdAt => (
        <p className="capitaliz">{!createdAt ? "-" : moment(createdAt).format("MM-DD-YYYY")}</p>
      )
    },
    {
      title: "Resolved At",
      dataIndex: "resolvedAt",
      key: "resolvedAt",
      render: resolvedAt => (
        <p className="capitaliz">{!resolvedAt ? "-" : moment(resolvedAt).format("MM-DD-YYYY")}</p>
      )
    },
    {
      title: "Resolved By",
      dataIndex: "resolvedBy",
      key: "resolvedBy",
      render: resolvedBy => (
        <p className="capitaliz">{!resolvedBy ? "-" : resolvedBy}</p>
      )
    },
    {
      title: "Action",
      render: (detail, { userId, report }) => (
        <>
          <Tooltip title={report ? "View" : null}>
            <Button
              type="primary"
              shape="circle"
              // disabled={report ? false : true}
              icon={<EyeOutlined />}
              onClick={() => {
                if (report === null) {
                  navigate(`/${orgId}/${orgName}/triage-false-report`, { state: { patientInfo, detail } })
                } else {
                  navigate(`/${orgId}/${orgName}/rpm-view-triage/${userId}/BP_Oxi`, { state: { list: report } })
                }
              }
              }
            />
          </Tooltip>
        </>
      )
    }
  ]

  useEffect(() => {
    const newData = []
    let sno = 1
    fetchedTriage &&
      (fetchedTriage?.data?.details || [])
        ?.sort((a, b) => new Date(b?.triageDate) - new Date(a?.triageDate))
        ?.map(({ triageDate, report, readingIds, reason, resolvedByFirstName, resolvedByMiddleName, resolvedByLastName, triageStartedAt, triageResolvedAt, comment }) => {
          newData.push({
            key: sno,
            sNo: sno,
            userId: id,
            createdAt: triageStartedAt,
            report,
            triageType: report?.[0]?.triageType ?? reason,
            resolvedAt: triageResolvedAt,
            comment,
            resolvedBy: `${resolvedByFirstName}${(resolvedByMiddleName && resolvedByMiddleName !== "") ? " " + resolvedByMiddleName : ""} ${resolvedByLastName}`.trim(),
            readingIds
          })
          sno += 1
          return id
        })
    setTriageData(newData)
  }, [fetchedTriage, loading])

  return (
    <>
      <Card className="rounded-b-none">
        <div className="flex justify-end">
          <div>
            <div className="flex flex-wrap gap-3">
              <div>
                <RangePicker
                  value={range}
                  allowClear={false}
                  onChange={handleRangeChange}
                  format="MM-DD-YYYY"
                  disabledDate={disabledDate}
                />
              </div>
              <div>
                <Button type="primary" onClick={handleDateSelection} disabled={loading}>
                  Confirm
                </Button>
                <Button
                  type="default"
                  onClick={handleDateReset}
                  disabled={loading}
                  className="ml-2 text-danger border-danger">
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <Table columns={columns} dataSource={triageData} scroll={{ x: "max-content" }} />
        )}
      </div>
    </>
  )
}

export default ViewTriageScreen
