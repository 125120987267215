import { useState } from "react"
import { Button, Card } from "antd"
import { PlusCircleFilled } from "@ant-design/icons"
import InternalNotesList from "./InternalNotesList"
import ViewNotesCard from "./ViewNotesCard"
import AddAndEditNote from "./AddAndEditNote"

const InternalNotes = () => {
  const [showNotesList, setShowNotesList] = useState(true)
  const [showIndividualNote, setShowIndividualNote] = useState(false)
  const [selectedNoteToView, setSelectedNoteToView] = useState([])
  const [openIndivNote, setOpenIndivNote] = useState(false)
  const [isAddNote, setIsAddNote] = useState(false)
  const [editNote, setEditNote] = useState(false) // false or note object

  const handleViewNoteFunc = (note, view) => {
    setSelectedNoteToView(note)
    setOpenIndivNote(view)
    setShowNotesList(false)
    setShowIndividualNote(true)
  }

  const handleCloseNoteFunc = view => {
    setSelectedNoteToView([])
    setOpenIndivNote(view)
    setShowIndividualNote(false)
    setShowNotesList(true)
  }

  return (
    <div>
      <Card className="min-h-screen">
        {isAddNote ? (
          <AddAndEditNote setIsAddNote={setIsAddNote} />
        ) : (
          <>
            <div className="mb-7 flex justify-between items-center">
              <h4 className="text-2xl">Internal Notes</h4>
              <Button
                onClick={() => setIsAddNote(true)}
                size="large"
                type="primary"
                className="flex justify-center items-center">
                <PlusCircleFilled />
                Add Internal Notes
              </Button>
            </div>
            <div>
              {editNote ? (
                <AddAndEditNote editNote={editNote} setEditNote={setEditNote} />
              ) : showIndividualNote ? (
                <ViewNotesCard
                  note={selectedNoteToView}
                  openIndivNote={openIndivNote}
                  handleCloseNoteFunc={handleCloseNoteFunc}
                  setEditNote={setEditNote}
                />
              ) : showNotesList ? (
                <InternalNotesList
                  handleViewNoteFunc={handleViewNoteFunc}
                  setEditNote={setEditNote}
                />
              ) : null}
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

export default InternalNotes
