import React, { useEffect, useState } from "react"
import PatientComponent from "../components/Patient/Patient"
import { Tabs, Space } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getUserCountStatsAction } from "../redux/actions/dashboardAction"
import EligiblePatient from "../components/SelfOnboarding/EligiblePatient/Index"
import { getOrgOnboardPatientStatistics } from "../redux/actions/selfOnboardingAction"
import { GetGroups, GetPatientsForGroup } from "../redux/actions/groupsActions"
import GroupedByDropdown from "../components/Patient/GroupedByDropdown"
import GroupsPatientsList from "../components/Groups/GroupsPatientsList"
import { useParams } from "react-router-dom"
import OrgBackNavigation from "../components/Organization/OrgBackNavigation"

const PatientView = () => {
  const dispatch = useDispatch()
  const {orgId} = useParams()

  const [showPotientialPatient, setShowPotientialPatient] = useState("activePatient")
  const [selectedGroupIds, setSelectedGroupIds] = useState([])


  const { userInfo } = useSelector(state => state.userInfo)
  const { userCountStats, loading: userCountStatsLoading } = useSelector(state => state.getUserCountStats)
  const { onboardpatientStatistics } = useSelector(state => state.getOrgOnboardPatientStatistics)
  const { loading, patientsForGroup } = useSelector(state => state.getPatientsForGroup)

  useEffect(() => {
    if (userInfo && loading === false && selectedGroupIds?.length > 0) {
      const data = {
        groupIds: selectedGroupIds,
        filters: null
      }
      dispatch(GetPatientsForGroup(data))
    }
  }, [selectedGroupIds])

  useEffect(() => {
    setShowPotientialPatient("activePatient")
    dispatch(GetGroups(userInfo?.id))
  }, [])

  useEffect(() => {
    if (!orgId) return
    dispatch(getOrgOnboardPatientStatistics(orgId))
    if (!userCountStats) {
      dispatch(getUserCountStatsAction(orgId))
    }
  }, [dispatch]) //eslint-disable-line

  const onPatientTabChange = key => {
    setShowPotientialPatient(key)
  }

  const patientTabItems = [
    {
      key: "activePatient",
      label: `Patients (${userCountStats?.patientCount ?? "-"})`
    },
    {
      key: "eligiblePatient",
      label: `Eligible Patients (${onboardpatientStatistics?.totalCount ?? "-"})`
    }
  ]

  const groupedPatientTabItems = [
    {
      key: "activePatient",
      label: `Patients (${patientsForGroup?.length > 0 ? patientsForGroup?.length : "-"})`
    }
  ]

  const onGroupSelection = (selectedGroups) => {
    let newSelectedGroupIds = []
    selectedGroups.map(group => {
      if (group.value !== "All") {
        newSelectedGroupIds.push(group.value)
      }
    })
    setSelectedGroupIds(newSelectedGroupIds)
  }

  const onGroupSelectionClear = () => {
    setSelectedGroupIds([])
  }

  return (
    <>
      <OrgBackNavigation />
      <Space.Compact
        className="mt-2"
        style={{ height: "inherit", display: "flex", marginBottom: "10px" }}
        onClick={e => e.stopPropagation()}>
        <GroupedByDropdown
          onGroupSelection={onGroupSelection}
          onGroupSelectionClear={onGroupSelectionClear}
        />
      </Space.Compact>
      <Tabs
        defaultActiveKey="activePatient"
        items={selectedGroupIds && (selectedGroupIds.length !== 0) ? groupedPatientTabItems : patientTabItems}
        onChange={onPatientTabChange}
        className="mb-3"
      />
      {selectedGroupIds && (selectedGroupIds.length !== 0) ? <GroupsPatientsList selectedGroupIds={selectedGroupIds} /> : showPotientialPatient === "activePatient" ? <PatientComponent /> : <EligiblePatient />}
    </>
  )
}

export default PatientView
