import React, { useEffect, useState, memo } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Loading from "../layouts/Loading/Loading"
import { useDispatch, useSelector } from "react-redux"
import { pushSessionTriageDataAction } from "../../redux/actions/utilsActions"
import { getTriageCriticalPatientsAction } from "../../redux/actions/patientAction"

import { Table, Input, Button, Card, Tooltip } from "antd"
import { useToasts } from "react-toast-notifications"
import { HiDocumentRemove, HiLightBulb } from "react-icons/hi"

import ClearModal from "./Triage/clearModal/ClearModal"
import moment from "moment/moment"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const { Search } = Input

export const isElapsedLessThan4Hours = prevTime => {
  const currentTime = new Date();
  const elapsedTime = currentTime.getTime() - new Date(prevTime).getTime();
  // Check if elapsed time is less than 4 hours (in milliseconds)
  const isLessThan4Hours = elapsedTime < 4 * 60 * 60 * 1000;
  return isLessThan4Hours;
};

const RPMCriticalCare = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { orgId, orgName } = useParams()

  const [RpmData, setRpmData] = useState([])
  const [serachedData, setSearchedData] = useState([])
  const [serachTerm, setSearchTerm] = useState("")
  // const [showClearModal, setShowClearModal] = useState(false)
  // const [patietnId, setPatientId] = useState("")
  const { loading, triageCriticalPatients: criticalPatient } = useSelector(
    state => state.triageCriticalPatients
  )
  // const shortClearData = useSelector(state => state.shortClearData)

  const { addToast } = useToasts()

  useEffect(() => {
    dispatch(getTriageCriticalPatientsAction(orgId))
  }, [dispatch])

  // const handleClear = (id) => {
  //   setPatientId(id)
  //   setShowClearModal(true)
  // }

  const handleCriticalDeviceAlert = device => {
    return addToast(`This patient got critical reading from : ${device}`, {
      appearance: "info",
      autoDismiss: true
    })
  }

  useEffect(() => {
    clearInterval()
    dispatch(pushSessionTriageDataAction(criticalPatient))
  }, [criticalPatient, dispatch])

  useEffect(() => {
    if (criticalPatient !== undefined && criticalPatient !== null) {
      let filtered = criticalPatient
      let filteredCriticalPatients = []
      criticalPatient.map((item) => {
        if (isElapsedLessThan4Hours(item.criticalDate)) {
          filteredCriticalPatients.push(item)
        }
      })
      // if (
      //   shortClearData !== undefined &&
      //   shortClearData !== null &&
      //   shortClearData !== "undefined" &&
      //   shortClearData?.length
      // ) {
      //   let newData = []
      //   shortClearData?.map(item => {
      //     if (new Date(item?.currentTime).getTime() > new Date().getTime()) {
      //       newData.push(item?.id)
      //     }
      //   })
      //   filteredCriticalPatients = filteredCriticalPatients?.filter(x => {
      //     return !newData.includes(x?.id)
      //   })
      // }

      let newData = []
      let sno = 1
      filteredCriticalPatients?.map(
        ({
          firstName,
          middleName,
          lastName,
          id,
          criticalStatus,
          criticalDate
        }) => {
          middleName
            ? newData.push({
              key: sno,
              sNo: sno,
              name: firstName + " " + middleName + " " + lastName,
              id: id,
              criticalStatus: criticalStatus,
              criticalDate: criticalDate
            })
            : newData.push({
              key: sno,
              sNo: sno,
              name: firstName + " " + lastName,
              id: id,
              criticalStatus: criticalStatus,
              criticalDate: criticalDate
            })
          sno += 1
        }
      )
      setRpmData(newData)
    }
  }, [criticalPatient])

  let currDate = new Date().toDateString()

  // column for table
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => (
        <p
          className="capitalize"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${id}`, {
            state: {
              name: name
            }
          })}>
          {name}
        </p>
      ),
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    {
      title: "Critical Status time",
      dataIndex: "criticalDate",
      key: "criticalDate",
      render: (criticalDate) => (
        <div className="w-full flex gap-2">
          <span>{moment(criticalDate).format("MM/DD/YYYY [at] hh:mm A")}</span>
        </div>
      )
    },
    // {
    //   title: "Actions",
    //   render: (criticalDate, { id }) => (
    //     <div className="w-full flex gap-2">
    //       <Tooltip title="Clear">
    //         <Button
    //           type="primary"
    //           className="mb-2"
    //           icon={<HiDocumentRemove size={22} />}
    //           onClick={() => handleClear(id)}
    //         />
    //       </Tooltip>
    //       <Tooltip title="Triage patient">
    //         <Button
    //           type="primary"
    //           icon={<HiLightBulb size={22} />}
    //           onClick={() =>
    //             (window.location.href = `/rpm-triage-patient/${id}`)
    //           }
    //         />
    //       </Tooltip>
    //     </div>

    //   )
    // }
  ]

  const handleSearch = (e) => {
    const prevData = RpmData
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchTerm(queryWithoutSpace)
    const newData = prevData.filter(item => item.name?.toLowerCase().includes(serachTerm))
    if (newData.length) {
      setSearchedData(newData)
    } else {
      setSearchedData([])
    }
  }

  return loading ? (
    <div className="loaderLoading">
      <Loading />
    </div>
  ) : (
    <>
      <OrgBackNavigation />
      <Card className="mt-2">
        <div className="d-flex justify-content-between rounded-b-none">
          <h3 className="text-xl">Critical Patient</h3>
          <Search
            allowClear
            size="large"
            placeholder="Search"
            style={{ width: 350 }}
            value={serachTerm}
            // onSearch={value => handleSearch(value)}
            onChange={e => handleSearch(e)}
          />
        </div>
      </Card>
      <Table
        columns={columns}
        dataSource={serachTerm ? serachedData : RpmData}
        scroll={{ x: "max-content" }}
      />
      {/* <ClearModal
        showClearModal={showClearModal}
        setShowClearModal={setShowClearModal}
        id={patietnId}
      /> */}
    </>
  )
}

export default memo(RPMCriticalCare)
