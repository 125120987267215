import React, { useState, useEffect } from "react"

import { Input, Select, Button, Card, Badge } from "antd"

import { useDispatch, useSelector } from "react-redux"

import AdvancedFilters from "./AdvancedFilters"
import {
  caretakerPatientAction,
  getCaretakerFilterPatientsAction,
  resetCaretakerPatientAdvanceFilter
} from "../../redux/actions/caretakerActions"
import { clearFilterModalValuesAction } from "../../redux/actions/utilsActions"
import { resetGroupPatientAdvanceFilter } from "../../redux/actions/groupsActions"
//import AdvancedFilters from "../Patient/AdvancedFilters"

export const sortBy = Object.freeze({
  AtoZ: 0,
  ZtoA: 1,
  DailyReadingCount: 2,
  LastActiveCount: 3,
  CriticalSortCount: 4
})

const GroupPatientFilter = ({
  groupId,
  searchQuery,
  applyFilters,
  resetFilters,
  setSortedData,
  setCurrentPage,
  setSearchQuery,
  setFilteredData,
  caretakerPatient,
  sortReadingCount,
  sortLatestReading,
  filterCriticalReading,
  from
}) => {
  const [sortValue, setSortValue] = useState("title")
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [onBoardSelect, setOnBoardSelect] = useState(false)
  const dispatch = useDispatch()

  const { getUserSearchData } = useSelector(state => state.filterSearch)

  // console.log('from filter',getUserSearchData, loading)
  // console.log('sorteddata', sortedData)

  const { groupPatientAdvFilter } = useSelector(state => state.groupPatientAdvFilter)
  const { groupPatientsListAdvFilter } = useSelector(state => state.groupPatientsListAdvFilter)
  const [filteredValues, setFilteredValues] = useState(groupPatientAdvFilter)

  // const applyFilters = (type, data) => {
  //   // console.log(type, data)
  //   setSearchQuery("")
  //   setCurrentPage(1)
  //   if (type === "normal") {
  //     dispatch(getCaretakerFilterPatientsAction({ caretakerId, formData: data }))
  //   } else {
  //     getOnBoardingPatients()
  //   }
  // }

  const getOnBoardingPatients = async () => {
    dispatch(caretakerPatientAction(caretakerId))
    // dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
  }

  // const resetFilters = () => {
  //   setSearchQuery("")
  //   setSortValue("title")
  //   setSortedData(caretakerPatient)
  //   if (
  //     filteredValues?.userType ||
  //     filteredValues?.unit ||
  //     filteredValues?.room ||
  //     filteredValues?.bedLocation || 
  //     filteredValues?.facilityId
  //   ) {
  //     dispatch(caretakerPatientAction(caretakerId))
  //     setFilteredValues({})
  //   }
  // }
  const resetCallback = () => {
    dispatch(resetGroupPatientAdvanceFilter())
    setFilteredValues({})
    setOnBoardSelect(false)
  }

  useEffect(() => {
    if (onBoardSelect !== true) {
      //setOnBoardSelect(true)
      dispatch(clearFilterModalValuesAction())
    }
  }, [])

  const handleSort = value => {
    setSortValue(value)
    if (value === "count") {
      sortReadingCount("desc")
    } else if (value === "Reading") {
      sortLatestReading("desc")
    } else if (value === "critical") {
      filterCriticalReading("desc")
    }
  }

  return (
    <Card>
      <div>
        <div className="flex gap-4 flex-wrap items-center justify-between">
          <Select
            size="large"
            value={sortValue}
            onChange={handleSort}
            className="min-w-[200px]"
            options={[
              {
                disabled: true,
                value: "title",
                label: "Filter Patient By"
              },
              {
                value: "count",
                label: "Reading Count"
              },
              {
                value: "Reading",
                label: "Recent Reading"
              },
              {
                value: "critical",
                label: "Critical Reading"
              }
            ]}
          />
          <Badge dot={from === "group" ? groupPatientAdvFilter : groupPatientsListAdvFilter} style={{ dot: { width: '50px' } }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setShowAdvancedFilters(true)
              }}>
              Advanced Filters
            </Button>
          </Badge>
          <Button
            danger
            size="large"
            onClick={() => {
              setSortValue("title")
              setFilteredValues({})
              resetFilters(resetCallback)
              dispatch(clearFilterModalValuesAction())
            }}>
            Reset Filters
          </Button>
        </div>
      </div>
      <AdvancedFilters
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        facilities={getUserSearchData}
        filteredValues={filteredValues}
        resetCallback={resetCallback}
        setFilteredValues={setFilteredValues}
        showAdvancedFilters={showAdvancedFilters}
        setShowAdvancedFilters={setShowAdvancedFilters}
        onBoardSelect={onBoardSelect}
        setOnBoardSelect={setOnBoardSelect}
      />
    </Card>
  )
}

export default GroupPatientFilter
