import { useEffect, useState } from "react"

import { Card, Button, Row, Col, Avatar } from "antd"

import { useDispatch, useSelector } from "react-redux"

import UpdatePractitioner from "./UpdatePractitioner"

import defaultPractitioner from "../../assets/doctor.png"
import PractitionerAddress from "./PractitionerAddress"
import UpdateAddress from "./UpdateAddress"
import {
  getPractitionerAddressAction,
  getPractitionerSpecializationAction
} from "../../redux/actions/practitionerAction"
import UpdateMappedProviders from "./UpdateMappedProviders"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import moment from "moment"

const PractitionerDetailHeader = ({ practitionerId }) => {
  const [updatePractitionerShow, setUpdatePractitionerShow] = useState(false)
  const [updateAddressShow, setUpdateAddressShow] = useState(false)
  const [updateMappedProviderShow, setUpdateMappedProviderShow] = useState(false)

  const dispatch = useDispatch()

  const { singlePractitioner } = useSelector(state => state.singlePractitioner)
  const {
    loading: specializationLoading,
    data: Specialization,
    error: SpecializationError
  } = useSelector(state => state.practitionerSpecialization)

  const profilePicture = singlePractitioner?.profileImage
    ? `data:image/png;base64,${singlePractitioner?.profileImage}`
    : defaultPractitioner

  useEffect(() => {
    if (singlePractitioner) {
      dispatch(getPractitionerSpecializationAction(practitionerId))
      dispatch(getPractitionerAddressAction(practitionerId))
    }
  }, [practitionerId])

  const handleUpdateAddressShow = () => {
    setUpdatePractitionerShow(false)
    setUpdateAddressShow(true)
  }

  return (
    <>
      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={16} md={24} xs={24}>
          <Card className="shadow-sm h-full" loading={singlePractitioner === undefined}>
            <div className="flex gap-3 flex-wrap justify-between">
              <h4 className="text-2xl">Practitioner Details</h4>
              <div className="flex gap-3 flex-wrap">
                <Button type="primary" onClick={() => setUpdateMappedProviderShow(true)}>
                  Update Provider
                </Button>
                <Button type="primary" onClick={() => setUpdatePractitionerShow(true)}>
                  Practitioner Actions
                </Button>
              </div>
            </div>
            <div className="mt-4 flex gap-16 flex-wrap">
              <div className="flex gap-3">
                <Avatar className="border-2 border-current p-2" size={60} src={profilePicture} />
                <div>
                  <h5 className="mb-1 text-xl capitalize">
                    {singlePractitioner?.firstName?.[0]?.toUpperCase() +
                      singlePractitioner?.firstName.slice(1)}{" "}
                    {singlePractitioner?.middleName} {singlePractitioner?.lastName}
                  </h5>
                  <p className="text-sm text-secondary">
                    {singlePractitioner?.email || "No Email isused for this patient."}
                  </p>
                </div>
              </div>
              <div>
                {singlePractitioner?.userName ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px]">User Name : </span>
                    {singlePractitioner?.userName}
                  </div>
                ) : null}
                {singlePractitioner?.gender ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px] mr-1">
                      Gender at the time of birth :{" "}
                    </span>
                    {singlePractitioner?.gender}
                  </div>
                ) : null}
              </div>
              <div>
                {singlePractitioner?.gender ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">DOB : </span>
                    {singlePractitioner?.dob ? moment(singlePractitioner?.dob).format("MM-DD-YYYY") : null}
                  </div>
                ) : null}
                {singlePractitioner?.mobileNumber ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">Mobile Number : </span>
                    {formatPhoneNumber(singlePractitioner?.mobileNumber)}
                  </div>
                ) : null}
                {Specialization?.length ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[120px]">Specializations : </span>
                    {Specialization?.map((item, index) => (
                      <div className="mr-3" key={index}>
                        <span>{index + 1}. </span>
                        <span key={index}>{item?.specialization}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <PractitionerAddress handleUpdateAddressShow={handleUpdateAddressShow} />
        </Col>
      </Row>

      <UpdateMappedProviders
        practitionerId={singlePractitioner?.id}
        updateMappedProviderShow={updateMappedProviderShow}
        setUpdateMappedProviderShow={setUpdateMappedProviderShow}
      />

      <UpdatePractitioner
        singlePractitioner={singlePractitioner}
        updatePractitionerShow={updatePractitionerShow}
        setUpdatePractitionerShow={setUpdatePractitionerShow}
      />

      <UpdateAddress
        practitionerId={singlePractitioner?.id}
        updateAddressShow={updateAddressShow}
        setUpdateAddressShow={setUpdateAddressShow}
      />
    </>
  )
}

export default PractitionerDetailHeader
