import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import { Button, Input, Typography } from "antd"

import { useSelector, useDispatch } from "react-redux"

import { ProTable } from "@ant-design/pro-components"
import { CloseCircleOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons"
import ReactDragListView from "react-drag-listview"
import "./style.css"
import { getAllOrgAction } from "../../redux/actions/orgDetailsAction"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { criticalPatientResetAction } from "../../redux/actions/dashboardAction"
import { GET_PCM_RESET } from "../../redux/types/pcmConstant"
import { GET_CCM_RESET } from "../../redux/types/ccmConstant"
import moment from "moment"

const OrganizationList = () => {
  const navigate = useNavigate()

  const [tableData, setTableData] = useState([])
  const [searchInp, setSearchInp] = useState("")

  const dispatch = useDispatch()
  const { orgData, loading } = useSelector(state => state.orgDetails)
  const { isDark } = useSelector(state => state.themeConfig)

  const transformOrgData = orgData => {
    return (
      orgData?.map((opt, index) => ({
        key: index,
        organizationName: opt?.organizationName,
        orgId: opt?.id,
        mobileNumber: opt?.mobileNumber,
        email: opt?.email,
        activeCount: opt?.activeCount,
        criticalCount: opt?.criticalCount,
        patientsCount: opt?.patientsCount
      })) || []
    )
  }

  const [columns, setColumns] = useState([
    {
      disable: true,
      title: <span className="dragHandler cursor-move">NAME</span>,
      width: "10%",
      dataIndex: "organizationName",
      sorter: (a, b) => a.organizationName.localeCompare(b.organizationName),
      render: organizationName => <p className="capitalize">{organizationName}</p>
    },
    {
      title: "EMAIL",
      dataIndex: "email"
    },
    {
      disable: true,
      title: <span className="dragHandler cursor-move">PHONE NUMBER</span>,
      dataIndex: "mobileNumber",
      sorter: (a, b) => Number(a.mobileNumber) - Number(b.mobileNumber),
      renderText: text => formatPhoneNumber(text)
    },
    {
      title: <span className="dragHandler cursor-move">NUMBER OF PATIENTS</span>,
      dataIndex: "patientsCount",
      sorter: (a, b) => Number(a.patientsCount) - Number(b.patientsCount)
    },
    {
      title: <span className="dragHandler cursor-move">CRITICAL PATIENTS</span>,
      dataIndex: "criticalCount",
      sorter: (a, b) => Number(a.criticalCount) - Number(b.criticalCount)
    },
    {
      title: <span className="dragHandler cursor-move">ACTIVE PATIENTS</span>,
      dataIndex: "activeCount",
      sorter: (a, b) => Number(a.activeCount) - Number(b.activeCount)
    },
    {
      title: <span className="dragHandler cursor-move">ORGANIZATION ID</span>,
      dataIndex: "orgId"
    },
    {
      // key: "more",
      disable: true,
      fixed: "right",
      title: "ACTION",
      width: "6%",
      render: (_, { orgId, organizationName }) => (
        <Typography.Link
          onClick={() => {
            navigate(`${orgId}/${organizationName}/dashboard`)
          }}>
          View More
        </Typography.Link>
      )
    }
  ])

  useEffect(() => {
    dispatch(criticalPatientResetAction())
    dispatch({ type: GET_PCM_RESET })
    dispatch({ type: GET_CCM_RESET })
    dispatch({ type: "GET_ADVANCED_FILTERS_OPTIONS_RESET" })

    dispatch(
      getAllOrgAction(
        moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"), 
        moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss")
      )
    )
  }, [])

  useEffect(() => {
    if (orgData?.length) {
      const data = transformOrgData(orgData)
      setTableData(data)
    }
  }, [orgData, loading])

  useEffect(() => {
    setTimeout(() => {
      if (columns && columns.length && localStorage.getItem("pro-table-org-list-columns")) {
        const storedCols = [JSON.parse(localStorage.getItem("pro-table-org-list-columns"))]
        const defaultCols = [...columns]
        const storedColsIndex = storedCols.map((col, index) => col.key)
        const sorted = defaultCols.sort(function (a, b) {
          return storedColsIndex.indexOf(a.key) - storedColsIndex.indexOf(b.key)
        })
        Promise.all(sorted).then(() => {
          setColumns([...defaultCols])
        })
      }
    }, 1000)
  }, [])

  const onSearchOrg = e => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchInp(queryWithoutSpace)

    const data = []

    let filterData = []
    if (query) {
      const trimmedSearchValue = query?.trim()?.toLowerCase()
      filterData = orgData?.filter(
        ({ id, organizationName, mobileNumber, email }) =>
          id?.toLowerCase()?.includes(trimmedSearchValue) ||
          organizationName?.toLowerCase()?.includes(trimmedSearchValue) ||
          mobileNumber?.includes(trimmedSearchValue) ||
          formatPhoneNumber(mobileNumber)?.includes(trimmedSearchValue) ||
          email?.toLowerCase()?.includes(trimmedSearchValue)
      )
    } else filterData = orgData

    filterData?.forEach((opt, index) =>
      data.push({
        key: index,
        organizationName: opt?.organizationName,
        orgId: opt?.id,
        mobileNumber: opt?.mobileNumber,
        email: opt?.email,
        activeCount: opt?.activeCount,
        criticalCount: opt?.criticalCount,
        patientsCount: opt?.patientsCount
      })
    )

    setTableData(data)
  }

  const onDragEnd = (fromIndex, toIndex) => {
    setColumns(prev => {
      const nextColumns = [...prev]
      const item = nextColumns.splice(fromIndex, 1)[0]
      nextColumns.splice(toIndex, 0, item)
      localStorage.setItem(
        "pro-table-org-list-columns",
        JSON.stringify(nextColumns.map(col => ({ key: col.key })))
      )
      return nextColumns
    })
  }

  return (
    <div>
      <div className="flex justify-between flex-col sm:flex-row">
        <Typography.Title level={3}>Organization</Typography.Title>
      </div>
      <ReactDragListView.DragColumn
        onDragEnd={onDragEnd}
        nodeSelector="th"
        handleSelector=".dragHandler"
        ignoreSelector="react-resizable-handle">
        <ProTable
          id="orgListProTable"
          className="orgTable"
          columns={columns}
          dataSource={tableData}
          loading={loading}
          columnsState={{
            persistenceKey: "pro-table-org-list-columns",
            persistenceType: "localStorage"
          }}
          rowKey="key"
          toolbar={{
            actions: [
              <Input
                key="search"
                style={{ width: 241, height: 48 }}
                value={searchInp}
                onChange={onSearchOrg}
                allowClear={{
                  clearIcon: <CloseCircleOutlined onClick={() => setSearchInp("")} />
                }}
                disabled={!orgData?.length}
                placeholder="Search Organization"
                prefix={<SearchOutlined className="disable" />}
              />
            ]
          }}
          options={{
            density: false,
            reload: false,
            fullScreen: false,
            setting: {
              children: (
                <div
                  className={`card ${isDark ? "dark" : "light"}`}
                  style={{
                    width: 241,
                    height: 48,
                    display: "flex",
                    placeItems: "center",
                    flexDirection: "row"
                  }}>
                  <span className="disable text-xs px-3">Filter By</span>
                  <div
                    className="primary border-l-2 h-full text-center flex-1"
                    style={{
                      display: "flex",
                      placeItems: "center",
                      borderColor: isDark ? "#424242" : "rgba(0, 0, 0, 0.1)"
                    }}>
                    <div className="flex-1">
                      <span className="px-2">Column Visibility</span>
                      <DownOutlined />
                    </div>
                  </div>
                </div>
              )
            }
          }}
          search={false}
          // dateFormatter="string"
          pagination={{
            defaultPageSize: 10,
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
          }}
          style={{ paddingTop: "30px" }}
          scroll={{ x: "max-content" }}
        />
      </ReactDragListView.DragColumn>
    </div>
  )
}

export default OrganizationList
