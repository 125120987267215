export const getThemeReducer = (
  state = {
    isDark: false
  },
  action
) => {
  switch (action.type) {
    case "DARK_MODE_ACTION":
      return { ...state, isDark: action.payload }
    default:
      return state
  }
}
