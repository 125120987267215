import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Radio, Modal, Button } from "antd"

const BpHighLowModal = ({ setIsHighBp, showHighLowAlert, setShowHighLowAlert, patientInfoDetail }) => {
  const [value, setValue] = useState("")
  const {orgId, orgName} = useParams()

  const onChange = e => {
    setValue(e.target.value)
  }

  const handleClose = () => {
    if (!value) return alert("Please select BP type")
    setIsHighBp(value)
    setShowHighLowAlert(false)
  }
  const navigate = useNavigate()

  return (
    <Modal
      open={showHighLowAlert}
      onCancel={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
        state: {
          name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
        }
      })}
      footer={null}
      title={<h4 className="text-xl">Select BP Type</h4>}>
      <div className="pt-4">
        <Radio.Group onChange={onChange} value={value}>
          <Radio value="high">High Blood Pressure</Radio>
          <Radio value="low">Low Blood Pressure</Radio>
        </Radio.Group>
        <div className="flex justify-end gap-3 mt-4">
          <Button danger onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
            state: {
              name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
            }
          })}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleClose}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default BpHighLowModal
