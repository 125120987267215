import { Button, Input, Modal } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { Controller, useForm } from "react-hook-form"
import TextArea from "antd/es/input/TextArea"
import moment from "moment"
import { postPatientEnrollManualAction } from "../../../redux/actions/patientAction"
import { criticalPatientAction } from "../../../redux/actions/dashboardAction"
import { useParams } from "react-router-dom"
import { getSelfOnboardingPendingPatientAction } from "../../../redux/actions/selfOnboardingAction"

const EnrollByCodeModal = ({ setManualPatientEnroll, selectedPatient }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const {orgId} = useParams()
  const POST_ENROLL_SUCCESS = "Patient enrolled successfully"
  const { loading: postLoading, error: postError } = useSelector(
    state => state.postPatientEnrollManual
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    // resolver: yupResolver(schema)
  })

  const onClose = () => {
    setManualPatientEnroll(null)
    reset()
  }

  const onSubmit = async data => {
    const res = await dispatch(
      postPatientEnrollManualAction({
        patientId: selectedPatient?.id,
        formData: {
          enrolledOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          notes: data?.notes,
          zendeskTicketId: data?.zendeskTicket
        }
      })
    )

    if (res?.status === 200) {
      dispatch(getSelfOnboardingPendingPatientAction(orgId))
      dispatch(criticalPatientAction(orgId))
      onClose()
      addToast(POST_ENROLL_SUCCESS, {
        appearance: "success",
        autoDismiss: true
      })
    } else {
      console.log(res?.error)
    }
  }

  return (
    <Modal
      open={Boolean(selectedPatient)}
      onCancel={onClose}
      styles={{
        content: {
          borderRadius: 12,
          padding: 6,
          overflow: "hidden"
        }
      }}
      footer={null}
      centered
      closable={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-3 mt-2 flex flex-col justify-center items-center">
          <p className="text-lg mt-3 font-semibold text-center">
            Enter Notes<span className="text-danger">*</span>
          </p>
          <Controller
            name="notes"
            control={control}
            rules={{
              required: "Notes are required",
              maxLength: {
                value: 2000,
                message: "Notes cannot exceed 2000 characters"
              }
            }}
            render={({ field }) => (
              <TextArea
                rows={4}
                className="my-3 w-full"
                id="input-notes"
                status={errors.notes ? "error" : undefined}
                {...field}
                onChange={e => field.onChange(e?.target?.value)}
              />
            )}
          />
          {errors.notes ? <small className="text-danger">{errors.notes.message}</small> : null}
          <p className="text-lg mt-3 font-semibold text-center">
            Enter Zendesk Ticket Number<span className="text-danger">*</span>
          </p>
          <Controller
            name="zendeskTicket"
            control={control}
            rules={{
              required: "Zendesk ticket number is required",
              maxLength: {
                value: 20,
                message: "Ticket Id cannot exceed 20 characters"
              }
            }}
            render={({ field }) => (
              <Input
                className="my-3 w-full"
                id="input-zendeskTicket"
                status={errors.notes ? "error" : undefined}
                {...field}
                onChange={e => field.onChange(e?.target?.value)}
              />
            )}
          />
          {errors.zendeskTicket ? (
            <small className="text-danger">{errors.zendeskTicket.message}</small>
          ) : null}
          {postError && <p className="font-bold text-danger text-center mt-4">{postError}</p>}
          <Button
            className="w-[200px] my-3"
            size="large"
            type="primary"
            htmlType="submit"
            loading={postLoading}>
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EnrollByCodeModal
