import { useEffect } from "react"

import { Modal, Button, Select } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getCaretakerListAction } from "../../../redux/actions/caretakerActions"
import {
  postPatientCaretaker,
  getPatientCaretaker,
  deletePatientCaretaker
} from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { FiX } from "react-icons/fi"

import Loading from "../../layouts/Loading/Loading"
import { useParams } from "react-router-dom"

const schema = yup
  .object({
    caretaker: yup.string().required("Caretaker is a required field.")
  })
  .required()

const updateCaretakers = props => {
  const {
    patientId,
    patientCaretaker,
    caretakerLoading,
    updateCaretakerShow,
    handleUpdateCaretakerClose
  } = props

  const { loading: caretakerListLoading, caretakerLists } = useSelector(
    state => state.caretakerList
  )
  const { loading: postCaretakerLoading, error: postCaretakerError } = useSelector(
    state => state.postPatientCaretaker
  )

  const dispatch = useDispatch()
  const {orgId, orgName} = useParams()

  useEffect(() => {
    if(updateCaretakerShow)
      dispatch(getCaretakerListAction(orgId))
  }, [dispatch, updateCaretakerShow])

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const deleteCaretaker = id => {
    dispatch(deletePatientCaretaker(patientId, id)).then(res => {
      if (res.message.toLowerCase() === "success") {
        dispatch(getPatientCaretaker(patientId))
      }
    })
  }

  const onSubmit = async data => {
    dispatch(postPatientCaretaker(patientId, data.caretaker)).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        dispatch(getPatientCaretaker(patientId))
        reset()
      }
    })
  }

  const filterOption = (input, option) =>
    (option?.label?.props?.children ?? "").toLowerCase().includes(input.toLowerCase())

  return (
    <Modal
      footer={null}
      open={updateCaretakerShow}
      onCancel={handleUpdateCaretakerClose}
      title={<h4 className="text-xl">Update Caretakers</h4>}>
      {caretakerLoading || caretakerListLoading ? (
        <Loading />
      ) : (
        <div className="pt-4">
          <div className="flex flex-col mb-4">
            <p className="font-semibold">Selected Caretakers: </p>
            {!patientCaretaker || Object.keys(patientCaretaker).length === 0 ? (
              <span>No Caretaker selected for this patient.</span>
            ) : (
              <ol className="list-decimal ml-3">
                {patientCaretaker?.map((ct, index) => (
                  <li key={index} className="mt-3">
                    <div className="flex items-center gap-2">
                      <span className="capitalize block min-w-[200px]"> {`${ct?.firstName} ${ct?.middleName ?? ''} ${ct?.lastName}`}</span>
                      {!(patientCaretaker?.length === 1) ?
                      <FiX
                        className="text-danger cursor-pointer"
                        onClick={() => deleteCaretaker(ct.id)}
                      />: null}
                    </div>
                  </li>
                ))}
              </ol>
            )}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="mb-1" htmlFor="input-caretaker">
              Add Caretaker <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="caretaker"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  showSearch
                  size="large"
                  className="w-100"
                  filterOption={filterOption}
                  status={errors.caretaker ? "error" : undefined}
                  options={caretakerLists
                    ?.filter(c => !patientCaretaker.some(pc => pc.id === c.id))
                    ?.map(ct => ({
                      value: ct.id,
                      label: <span className="capitalize">{`${ct.firstName} ${ct.middleName || ""} ${ct.lastName}`}</span>
                    }))}
                  {...field}
                />
              )}
            />
            {errors.caretaker ? (
              <small className="text-danger block">{errors.caretaker.message}</small>
            ) : null}

            {postCaretakerError ? (
              <small className="text-danger block">{postCaretakerError}</small>
            ) : null}

            <div className="flex flex-wrap gap-3 mt-4">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={postCaretakerLoading}
                disabled={
                  caretakerLists?.filter(c => !patientCaretaker?.some(pc => pc.id === c.id))
                    .length === 0
                }>
                {postCaretakerLoading ? "Submitting" : "Submit"}
              </Button>
              <Button size="large" danger onClick={handleUpdateCaretakerClose}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  )
}

export default updateCaretakers
