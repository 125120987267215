import { axiosBasicGet, axiosBasicPost, axiosGet, axiosPost, axiosRefreshGet } from "../axios"
import {
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_FAIL,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPLOAD_REQUEST,
  IMAGE_UPLOAD_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  VALIDATE_LOGIN_DETAILS_SUCCESS,
  VALIDATE_LOGIN_DETAILS_REQUEST,
  VALIDATE_LOGIN_DETAILS_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  EMAIL_UPDATE_REQUEST,
  EMAIL_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAIL,
  PHONE_UPDATE_REQUEST,
  PHONE_UPDATE_SUCCESS,
  PHONE_UPDATE_FAIL,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_SUCCESS,
  ADMIN_UPDATE_FAIL
} from "../types/userConstant"
import qs from "qs"
import AsyncLocalStorage from "@createnextapp/async-local-storage"
import { loginTypeChecker } from "../../utils/loinTypeChecker"
import { deviceInfo } from "../../utils/fixedInfo"
import { Mixpanel } from "../../utils/mixpanel";

let previousUrlWithoutAuth;
export const userLoginAction = (userName, password, extension) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST
    })
    let type = loginTypeChecker(userName)
    let info = deviceInfo()
    const { data } = await axiosBasicPost(`/api/auth/authorize`, {
      userName,
      password,
      loginTypes: extension ? "landline" : type,
      extension: extension ? extension : null,
      appVersion: info.appVersion,
      deviceId: info.deviceId,
      deviceModel: info.deviceModel
    })
    localStorage.setItem("token", JSON.stringify(data?.details?.token))
    localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
    localStorage.setItem("id", JSON.stringify(data?.details?.id))
    let res = null
    if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
      // Mixpanel.identify(data?.details?.id)
      // Mixpanel.people.set({ userid: data?.details?.id, userType: data?.details?.userType })
      await dispatch(getUserProfileAction(data?.details?.id, data?.details?.userType))
    }
  } catch (error) {
    //error?.response?.status === 401 && dispatch(prevUserLogoutAction())
    const errDetails = error?.response?.data?.details
    if (typeof errDetails === "object") {
      const keys = Object.keys(errDetails)
      if (keys.includes("count") && keys.includes("blockedTill") && keys.includes("attemptsLeft")) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: errDetails
        })
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: Object.values(errDetails)?.[0]
        })
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Something went wrong, Please try again!"
      })
    }
  }
}

export const googleLoginAction =
  ({ credential }) =>
  async dispatch => {
    try {
      dispatch({
        type: GOOGLE_LOGIN_REQUEST
      })
      let info = deviceInfo()
      const { data } = await axiosBasicPost(`/api/auth/authorize`, {
        userName: credential,
        loginTypes: "google",
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel
      })
      if (data?.details?.userType?.toLowerCase() !== "admin") {
        dispatch({
          type: GOOGLE_LOGIN_FAIL,
          payload: "Invalid Credentials"
        })
        return
      }
      localStorage.setItem("token", JSON.stringify(data?.details?.token))
      localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
      localStorage.setItem("id", JSON.stringify(data?.details?.id))

      if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
        // document.location.href = "/"
        await dispatch(getUserProfileAction(data?.details?.id, data?.details?.userType))
      }
    } catch (error) {
      dispatch({
        type: GOOGLE_LOGIN_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

export const clearUserLoginError = () => async dispatch => {
  dispatch({
    type: USER_LOGOUT
  })
}

export const validateLoginDetailsAction = (userName, prevUrl) => async dispatch => {
  previousUrlWithoutAuth = prevUrl
  let loginTypes = loginTypeChecker(userName)
  try {
    dispatch({
      type: VALIDATE_LOGIN_DETAILS_REQUEST
    })

    const { data } = await axiosBasicPost(`/api/auth/validate`, { userName, loginTypes })

    if (data?.details?.profileType?.toLowerCase() !== "admin") {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Invalid Credentials"
      })
      return
    }

    dispatch({
      type: VALIDATE_LOGIN_DETAILS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    if (
      loginTypes === "email" &&
      error.response &&
      error?.response?.data?.details?.message === "Invalid login details"
    ) {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Please verify your mail"
      })
    } else if (
      loginTypes === "mobileNumber" &&
      error.response &&
      error?.response?.data?.details?.message === "Invalid login details"
    ) {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Please verify your number"
      })
    } else {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }

    return error.response
  }
}

export const passwordUpdateAction = (userName, otp, password) => async dispatch => {
  let loginTypes = loginTypeChecker(userName)
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosBasicPost(`/api/auth/submitOTP`, {
      userName,
      loginTypes,
      otp,
      password
    })

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}

//password reset from dashboard->security
export const passwordResetAction = (password, oldPassword) => async dispatch => {
  let info = deviceInfo()
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosPost(`/api/resetPassword`, {
      password,
      oldPassword,
      appVersion: info.appVersion,
      deviceId: info.deviceId,
      deviceModel: info.deviceModel
    })

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details?.password
    })
    return error
  }
}


// email
export const emailUpdateAction = (newEmail, oldEmail) => async dispatch => {
  try {
    dispatch({
      type: EMAIL_UPDATE_REQUEST
    })

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosPost(`/support/v1/updateEmail`, { newEmail, oldEmail }, config)

    dispatch({
      type: EMAIL_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: EMAIL_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
    return error
  }
}
// phone number
export const phoneUpdateAction = (phoneNumber, countryCode) => async dispatch => {
  try {
    dispatch({
      type: PHONE_UPDATE_REQUEST
    })

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosPost(
      `/support/v1/updatePhoneNumber`,
      { phoneNumber, countryCode },
      config
    )

    dispatch({
      type: PHONE_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PHONE_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
    return error
  }
}

export const UpdateProfileAction = image => async dispatch => {
  try {
    dispatch({
      type: PROFILE_UPDATE_REQUEST
    })

    const { data } = await axiosPost(
      `/admin/v1/updateProfileImage`,
      { image },
      { headers: { "Content-Type": "application/json" } }
    )

    if (data.success) {
      dispatch(getUserProfileAction())
    }

    dispatch({
      type: PROFILE_UPDATE_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROFILE_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const ImageUploadAction = profilePic => async dispatch => {
  try {
    dispatch({
      type: IMAGE_UPLOAD_REQUEST
    })

    const formData = new FormData()
    formData.append("profImg", profilePic)

    const { data } = await axiosPost(`v1/uploadPhoto`, formData)

    // localStorage.setItem('profileImage',data)
    dispatch({
      type: IMAGE_UPLOAD_SUCCESS,
      payload: data
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: IMAGE_UPLOAD_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUserProfileAction = (id, userType) => async dispatch => {
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
    }
  }
  Mixpanel.identify(id)
  const { data } = await axiosGet(`/api/cs/admin/${id}/fetch`, config)
  await AsyncLocalStorage.setItem("userInfo", JSON.stringify(data?.details))
  // localStorage.setItem("profileImage", JSON.stringify(data.profileImage));

  Mixpanel.people.set({
    "ID": id,
    "Type": userType,
    "UserName": data?.details?.userName,
    "$name": `${data?.details?.firstName} ${data?.details?.middleName} ${data?.details?.lastName}`,
    "$email": data?.details?.email,
    "orgID": data?.details?.orgId
  })
  Mixpanel.register({
    "ID": id,
    "Type": userType,
    "UserName": data?.details?.userName,
    "Email": data?.details?.email,
    "orgID": data?.details?.orgId
  })
  dispatch({
    type: USER_LOGIN_SUCCESS,
    payload: data?.details
  })
  console.log(data?.details)
  // dispatch({ type: GET_ADMIN_SUCCESS, payload: data.profileImage });
  if (data?.details?.id !== null) {
    document.location.href = previousUrlWithoutAuth ? previousUrlWithoutAuth : "/"
  }

  return data
}

// const handleLogout = async () => {
//   try {
//     await axiosGet("/support/v1/logout")
//   } catch (error) {
//     document.location.href = "/login"
//   }
// }
// const handleLogout = async () => {
//   try {
//     await axiosGet("/support/v1/logout")
//   } catch (error) {
//     document.location.href = "/login"
//   }
// }

export const prevUserLogoutAction = () => async dispatch => {
  const res = await axiosGet(`/api/cs/config/logout`)
  if (res?.data?.message === 'Success') {
    // window.webkit.messageHandlers.logout.postMessage({ value: "harish" });
    localStorage.clear()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}

export const userLogoutAction = () => async dispatch => {
  try {
    const res = await axiosRefreshGet("/api/refresh")
    if (res?.data?.details?.token && res?.data?.details?.refreshToken && res?.data?.details?.id) {
      await AsyncLocalStorage.setItem("token", JSON.stringify(res?.data?.details?.token))
        .then(() => {
          AsyncLocalStorage.setItem(
            "refreshToken",
            JSON.stringify(res?.data?.details?.refreshToken)
          )
        })
        .then(() => {
          AsyncLocalStorage.setItem("id", JSON.stringify(res?.data?.details?.id))
        })
        .then(() => {
          window.location.reload()
        })
    }
  } catch (err) {
    localStorage.clear()
    //await handleLogout()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}

export const adminUpdateAction = (adminId, formData) => async dispatch => {
  try {
    dispatch({
      type: ADMIN_UPDATE_REQUEST
    })

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosPost(`/api/cs/admin/${adminId}/update`, formData, config)

    dispatch({
      type: ADMIN_UPDATE_SUCCESS,
      payload: data.details
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    console.log(error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: ADMIN_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}
