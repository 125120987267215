import React, { useState, useEffect } from "react"
import "./CCMViewQuestion.css"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Loading from "../../../../layouts/Loading/Loading"
import PlanView from "@fitpeo/fit-plan-viewer"
import { useDispatch, useSelector } from "react-redux"
import { getLatestCareplanActions } from "../../../../../redux/actions/ccmActions"
import {
  getPatientDiagnosticsData,
  getPatientMonitoringData
} from "../../../../../redux/actions/patientAction"
import { convertCareplanToV2 } from "../../../../../utils/careplanVersioning"

const CCMViewQuestion = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  const [callRestApis, setCallRestApis] = useState(false)
  const [provider, setProvider] = useState([])
  const [practitioner, setPractitioner] = useState([])

  const location = useLocation()
  const patientId = location?.state?.id
  const patientNameObj = location?.state?.patientNameObj
  const dob = location?.state?.dob

  const { loading: careplanLoading, latestCareplan } = useSelector(state => state.getLatestCareplan)
  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  useEffect(() => {
    if (patientId !== undefined) {
      dispatch(getLatestCareplanActions({ patientId }))
      setCallRestApis(true)
    } else navigate(`/${orgId}/${orgName}/ccm/patients`)
  }, [dispatch, patientId])

  useEffect(() => {
    if (latestCareplan && callRestApis) {
      if (!latestCareplan?.plan?.planIcds) dispatch(getPatientDiagnosticsData({ patientId }))
      if (!latestCareplan?.plan?.providers) dispatch(getPatientMonitoringData({ patientId }))
      setCallRestApis(false)
    }
  }, [latestCareplan])

  useEffect(() => {
    let provider = []
    let practitioner = []

    if (latestCareplan?.plan?.providers) provider = latestCareplan?.plan?.providers
    else if (patientMonitoringData?.ccm?.[0]) {
      provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
    }
    if (latestCareplan?.plan?.practitioners) practitioner = latestCareplan?.plan?.practitioners
    else if (patientMonitoringData?.ccm?.[0]) {
      practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
    }
    if (latestCareplan?.plan?.provider) {
      provider = [`${latestCareplan?.plan?.provider?.providerFirstName && latestCareplan?.plan?.provider?.providerFirstName !== "" ? " " + latestCareplan?.plan?.provider?.providerFirstName : ""}
      ${latestCareplan?.plan?.provider?.providerMiddleName && latestCareplan?.plan?.provider?.providerMiddleName !== "" ? " " + latestCareplan?.plan?.provider?.providerMiddleName : ""} 
      ${latestCareplan?.plan?.provider?.providerLastName && latestCareplan?.plan?.provider?.providerLastName !== "" ? " " + latestCareplan?.plan?.provider?.providerLastName : ""}`]
    }

    setProvider(provider)
    setPractitioner(practitioner)
  }, [latestCareplan, patientMonitoringData])

  const planIcds =
    latestCareplan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

  return careplanLoading ? (
    <Loading />
  ) : (
    (latestCareplan && (
      <>
        <div className="d-flex justify-content-end">
          <button
            // className="btn pdf-button btn-sm mx-2"
            className="btn btn-outline-primary btn-sm mx-2"
            onClick={() =>
              navigate(`/${orgId}/${orgName}/ccm-view-care-print`, {
                state: {
                  patientInfoDetail: { ...patientNameObj, dob },
                  planDate: latestCareplan?.planDate,
                  provider,
                  practitioner,
                  plan: convertCareplanToV2(latestCareplan?.plan),
                  planIcds,
                  addedBy: latestCareplan?.plan?.addedBy,
                  planDate: latestCareplan?.planDate
                }
              })
            }>
            Print
          </button>
        </div>
        <PlanView
          type="ccm"
          patientInfoDetail={{ ...patientNameObj, dob }}
          providers={provider}
          practitioners={practitioner}
          plan={convertCareplanToV2(latestCareplan?.plan)}
          planIcds={planIcds}
          addedBy={latestCareplan?.plan?.addedBy}
          planDate={latestCareplan?.planDate}
        />
      </>
    )) || <h1 className="text-xl text-center">No Care Plan Found</h1>
  )
}

export default CCMViewQuestion
