import React from "react"
import TriageHeader from "../components/TriageView/TriageHeader"
import TriagePrintInfoCard from "../components/TriageView/TriagePrintInfoCard"
import TriageViewPrintBelowCard from "../components/TriageView/TriageViewPrintBelowCard"

const TriagePrintView = () => {
  return (
    <>
      <TriageHeader />
      <TriagePrintInfoCard />
      <TriageViewPrintBelowCard />
    </>
  )
}

export default TriagePrintView
