import React from "react"
import { Card, Col, Row } from "antd"

const BillerCards = () => {
  return (
    <Row gutter={16}>
      <Col sm={24} md={6}>
        <Card className="biller-card-inner">
          <div>
            <p>Total Claim Expected</p>
            <h4 className="text-xl font-medium mt-3">233</h4>
          </div>
        </Card>
      </Col>
      <Col sm={24} md={6}>
        <Card className="biller-card-inner">
          <div>
            <p>Total Duration of CCM</p>
            <h4 className="text-xl font-medium mt-3">300 Mins</h4>
          </div>
        </Card>
      </Col>
      <Col sm={24} md={6}>
        <Card className="biller-card-inner">
          <div>
            <p>Total Duration of RPM</p>
            <h4 className="text-xl font-medium mt-3">150 Mins</h4>
          </div>
        </Card>
      </Col>
      <Col sm={24} md={6}>
        <Card className="biller-card-inner">
          <div>
            <p>Total readings taken by this billing cycle</p>
            <h4 className="text-xl font-medium mt-3">40</h4>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default BillerCards
