import moment from "moment"
import { axiosGet, axiosPost } from "../axios"
import {
  GET_ALL_PATIENT_STATUS_FAIL,
  GET_ALL_PATIENT_STATUS_REQUEST,
  GET_ALL_PATIENT_STATUS_SUCCESS,
  GET_SHIPPING_STATUS_FAIL,
  GET_SHIPPING_STATUS_REQUEST,
  GET_SHIPPING_STATUS_SUCCESS,
  UPDATE_PATIENT_STATUS_FAIL,
  UPDATE_PATIENT_STATUS_REQUEST,
  UPDATE_PATIENT_STATUS_SUCCESS,
  UPDATE_SHIPPING_STATUS_FAIL,
  UPDATE_SHIPPING_STATUS_REQUEST,
  UPDATE_SHIPPING_STATUS_SUCCESS
} from "../types/patientStatusConstant"
import { userLogoutAction } from "./userActions"

export const getPatientStatusAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_ALL_PATIENT_STATUS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/statusTrack/${patientId}/fetch`)
    dispatch({
      type: GET_ALL_PATIENT_STATUS_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ALL_PATIENT_STATUS_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const updatePatientStatusAction = (patientId, formData )=> async dispatch => {
  try {
    dispatch({ type: UPDATE_PATIENT_STATUS_REQUEST })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(`/api/cs2/patients/statusTrack/${patientId}/update`, updatedFormData)
    dispatch({
      type: UPDATE_PATIENT_STATUS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_PATIENT_STATUS_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getShipmentStatusAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_SHIPPING_STATUS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/shipmentStatus/${patientId}/fetch`)
    dispatch({
      type: GET_SHIPPING_STATUS_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_SHIPPING_STATUS_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const updateShipmentStatusAction = (patientId, formData )=> async dispatch => {
  try {
    dispatch({ type: UPDATE_SHIPPING_STATUS_REQUEST })

    const { data } = await axiosPost(`/api/cs/patients/shipmentStatus/${patientId}/create`, formData)
    dispatch({
      type: UPDATE_SHIPPING_STATUS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_SHIPPING_STATUS_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}