import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import moment from "moment"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { Modal, Button, Input } from "antd"
import makeAnimated from "react-select/animated"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { getCareTypesAction } from "../../../redux/actions/caretakerActions"
import { AddManualTimeAction } from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup
  .object({
    care: yup.array().required(),
    date: yup.string().required(),
    program: yup.object().required(),
    notes: yup.string().required("Note is a required field"),
    duration: yup.string().required("Duration is a required field")
  })
  .required()

const defaultValues = {
  care: [],
  notes: "",
  program: {},
  duration: "",
  date: new Date()
}

const AddManualTime = props => {
  const { showAddManualTime, handleAddManualTimeClose, addManualApiCalls, programType } = props

  const patientID = useParams()
  const animatedComponents = makeAnimated()

  const dispatch = useDispatch()
  const { careTypes } = useSelector(state => state.careTypes)
  const { loading } = useSelector(state => state.addManualTime)
  const { addToast } = useToasts()

  const [careOptions, setCareOptions] = useState([])

  const {
    reset,
    control,
    setError,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitted, touchedFields }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (addManualApiCalls === true) {
      dispatch(getCareTypesAction(patientID.id))
    }
  }, [dispatch, addManualApiCalls])

  useEffect(() => {
    const data = getValues()
    if (isSubmitted) {
      if (touchedFields) {
        if (data?.care?.length === 0 || Object.keys(data.program).length === 0) {
          if (Object.keys(data.program).length === 0)
            setError("care", { type: "custom", message: "Please select a Care" })
          if (data?.care?.length === 0)
            setError("program", { type: "custom", message: "Please select a Program" })
        }
      }
    }
  }, [isSubmitted, touchedFields])

  useEffect(() => {
    if (showAddManualTime)
      setValue('date', new Date())
  }, [showAddManualTime])

  const updateCareOptions = programValue => {
    setCareOptions([])
    setValue("care", null)
    const ct = careTypes?.find(
      i => i.monitorType.toLowerCase() === programValue?.label.toLowerCase()
    )
    setCareOptions(
      ct?.caretypes?.map((opt, index) => ({
        value: opt,
        label: opt
      }))
    )
  }

  const onSubmit = async data => {
    if (data?.care?.length === 0 || Object.keys(data.program).length === 0) {
      if (Object.keys(data.program).length === 0)
        setError("program", { type: "custom", message: "Please select a Care" })
      if (data?.care?.length === 0)
        setError("care", { type: "custom", message: "Please select a Program" })
    } else {
      const logDate = new Date(data.date)
      logDate.setTime(logDate.getTime() - logDate.getTimezoneOffset() * 60000)
      const dateAsString = logDate.toISOString().substr(0, 19)
      dispatch(
        AddManualTimeAction(
          patientID?.id,
          data.program?.label.toLowerCase(),
          data.care.map(value => value?.label),
          dateAsString,
          parseFloat(data.duration),
          data.notes
        )
      ).then(res => {
        if (res && res.message.toLowerCase() === "success") {
          clearErrors()
          handleAddManualTimeClose()
          reset()
          addToast("Manual Time Added", {
            appearance: "success",
            autoDismiss: true
          })
        }
      })
    }
  }

  const handleClose = () => {
    handleAddManualTimeClose()
    reset()
    clearErrors()
  }

  return (
    <Modal
      footer={null}
      onCancel={handleClose}
      open={showAddManualTime}
      title={<h4 className="text-xl">Add Manual Time</h4>}>
      <div className="pt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label htmlFor="select-program" className="mb-2">
              Select Program <span className="text-danger">*</span>
            </label>
            <Controller
              name="program"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  id="select-program"
                  placeholder="Select Program"
                  components={animatedComponents}
                  value={field.value}
                  onChange={val => {
                    field.onChange(val)
                    updateCareOptions(val)
                  }}
                  options={careTypes?.map((opt, index) => ({
                    value: index,
                    label: opt?.monitorType?.toUpperCase()
                  }))}
                />
              )}
            />
            {errors.program ? (
              <small className="text-danger">{errors.program.message}</small>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="select-care" className="mb-2">
              Select Care <span className="text-danger">*</span>
            </label>
            <Controller
              name="care"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  isMulti
                  id="select-care"
                  value={field.value}
                  options={careOptions}
                  components={animatedComponents}
                  placeholder="Select Care Type"
                  onChange={val => field.onChange(val)}
                />
              )}
            />
            {errors.care ? <small className="text-danger">{errors.care.message}</small> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="date-and-time" className="mb-2">
              Date and Time <span className="text-danger">*</span>
            </label>
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  id="date-and-time"
                  selected={field.value}
                  onChange={date => {
                    if (date <= new Date()) {
                      field.onChange(date)
                    } else {
                      field.onChange(new Date())
                    }
                  }}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  maxDate={new Date()}
                  showPopperArrow={false}
                  placeholderText="Select Date and Time"
                  minDate={moment(new Date()).subtract(10, "years")._d} //eslint-disable-line
                  className="border-[1px] px-3 min-h-[50px] rounded w-full border-[#d9d9d9]"
                  withPortal
                />
              )}
            />
            {errors.date ? <small className="text-danger">{errors.date.message}</small> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="duration" className="mb-2">
              Duration (in Minutes) <span className="text-danger">*</span>
            </label>
            <Controller
              name="duration"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input size="large" type="number" min={1} id="duration" {...field} />
              )}
            />
            {errors.duration ? (
              <small className="text-danger">{errors.duration.message}</small>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="notes" className="mb-2">
              Notes <span className="text-danger">*</span>
            </label>
            <Controller
              name="notes"
              control={control}
              rules={{ required: true }}
              placeholder="Enter Notes"
              render={({ field }) => <Input.TextArea rows={3} id="notes" {...field} />}
            />
            {errors.notes ? <small className="text-danger">{errors.notes.message}</small> : null}
          </div>

          <div>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? "Saving..." : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddManualTime
