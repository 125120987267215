import React, { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import moment from "moment"
import { Button, Table, Tooltip, Card, DatePicker } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { EditOutlined, EyeOutlined, HistoryOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons"
import { getCcmArchivePlansActions, getccmCareplanDataActions } from "../../redux/actions/ccmActions"
import { getPatientCaretaker, getPatientDiagnosticsData, getPatientInfo, getPatientMonitoringData } from "../../redux/actions/patientAction"
import PatientPlanHeader from "./PatientPlanHeader"
import Loading from "../layouts/Loading/Loading"
import { getrpmCareplanDataActions } from "../../redux/actions/rpmAction"

const isInCurrentMonth = date => moment(date).isSame(new Date(), "month")
const prevMonthStart = moment().subtract(1, "month").startOf("month")
const prevMonthEnd = moment().subtract(1, "month").endOf("month")
const isInPrevMonth = date => moment(date).isBetween(prevMonthStart, prevMonthEnd)

const PatientPlans = () => {
  const [ccmData, setCcmData] = useState([])
  const [RpmData, setRpmData] = useState([])
  const [ccmMonitorId, setCcmMonitorId] = useState(null)
  const [entirePlanHistory, setEntirePlanHistory] = useState([])
  const [entirePlanHistoryIsLoading, setEntirePlanHistoryIsLoading] = useState(true)
  const [moniteringType, setMonitoringType] = useState([])

  const { id, orgId, orgName } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { loading: ccmLoading } = useSelector(state => state.ccmPatientCarePlan)
  //const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { loading: rpmLoading } = useSelector(state => state.rpmPatientCarePlan)
  const { loading: caretakerLoading, patientCaretaker } = useSelector(
    state => state.patientCaretaker
  )
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { loading: DiagnosticsLoading, patientDiagnosticData } = useSelector(
    state => state.patientDiagnosticData
  )
  let patientInfo =  location?.state?.patientInfo
  let monitoringData = location?.state?.monitoringData
  let monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const checkMonitorType = async () => {
    if (!monitorTypes?.length) {
      await dispatch(getMonitorTypes())
      monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))
    }
    const data = monitorTypes.find(value => value.name === "ccm")
    setCcmMonitorId(data?.programs[0]?.id)
  }

  useEffect(() => {
    checkMonitorType()
  }, [monitorTypes])

  useEffect(() => {
      setMonitoringType(Object.keys(monitoringData))

    if (id) {
      dispatch(getccmCareplanDataActions({ patientId: id })).then((response) => {
        if (response && response.details) {
          const ccmRes = response.details.map((plan) => ({ ...(plan ?? []), "planType":"care plan" }));
          setCcmData(ccmRes);
        } 
      })
      if(!patientInfo && !patientInfo?.length)
      {
        dispatch(getPatientInfo({ patientId: id }))
      } 
      // if(!patientMonitoringData && !patientMonitoringData?.length)   
      //   dispatch(getPatientCaretaker(id))
      // if(!patientDiagnosticData && !patientDiagnosticData?.length)
      //   dispatch(getPatientDiagnosticsData({ patientId }))

      // dispatch(getPatientMonitoringData({ patientId : id}))
    }
  }, [dispatch, id])


  useEffect(() => {
    if (id) {
      dispatch(getrpmCareplanDataActions(id)).then(res => {
        const rpmRes = res?.map((plan) => ({...(plan ?? []), "planType":"treatment plan" }))
        setRpmData(rpmRes)
      })
    }
  }, [dispatch, id])

  useEffect(() => {
    if (!ccmLoading && !rpmLoading) {
      setEntirePlanHistory(getManupulatedData([...(ccmData ?? []), ...(RpmData ?? [])]))
      setEntirePlanHistoryIsLoading(false)
    } else if (setEntirePlanHistory?.length) {
      setEntirePlanHistoryIsLoading(true)
    }
  }, [ccmLoading, rpmLoading, ccmData, RpmData])

  const getManupulatedData = data => {
    return data?.map(
      (
        {
          firstName,
          middleName,
          lastName,
          id,
          planType,
          planDate,
          providerFirstName,
          providerLastName,
          providerMiddleName,
          practitionerFirstName,
          practitionerrMiddleName,
          practitionerLastName,
          dob,
          plan
        },
        index
      ) => ({
        key: index + 1,
        sNo: index + 1,
        planId : id,
        planDate,
        planType,
        fullName: `${patientInfo?.firstName} ${patientInfo?.middleName ? patientInfo?.middleName : ''} ${patientInfo?.lastName}`,
        patientNameObj: { firstName:patientInfo?.firstName, middleName:patientInfo?.middleName , lastName:patientInfo?.lastName },
        dob: patientInfo?.dob,
        provider: providerFirstName && (providerFirstName !== "") ? [`${providerFirstName}${providerMiddleName && providerMiddleName !== "" ? " " + providerMiddleName : ""} ${providerLastName} `] : [],
        practitioner: practitionerFirstName && (practitionerFirstName !== "") ? [`${practitionerFirstName}${practitionerrMiddleName && practitionerrMiddleName !== "" ? " " + practitionerrMiddleName : ""} ${practitionerLastName} `] : [],
        isSameMonthPlanCreated: isInCurrentMonth(planDate),
        plan
      })
    )
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Plan Date",
      dataIndex: "planDate",
      key: "planDate",
      render: planDate => <p>{moment(planDate).format("MM/DD/YYYY")}</p>,
      sorter: (a, b) => a.planDate.localeCompare(b.planDate)
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, { plan, planType }) => (
        <p className="capitalize">
          {planType === "care plan" ? 
            (plan?.addedBy ? `${plan?.addedBy?.name} (${plan?.addedBy?.userType})` : "-")
            :
            (plan?.addingBy ? `${plan?.addingBy?.name} (${plan?.addingBy?.userType})` : "-")
          }
        </p>
      )
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
      key: "planType",
      render: planType => (
        <p className="capitalize">
          {planType}
        </p>
      ),
      sorter: (a, b) => a.planType.localeCompare(b.planType)
    },
    {
      title: "Action",
      render: ({  isSameMonthPlanCreated, provider, practitioner, patientNameObj, dob, planType, planDate  }) => 
      ( planType === "care plan") ? (
        <div className="flex gap-3 items-center">
          {!isSameMonthPlanCreated && (
            <Tooltip title="Create">
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() =>
                  navigate(`/${orgId}/${orgName}/ccm-questions`, {
                    state: { patientNameObj, id, provider, practitioner, ccmMonitorId, dob }
                  })
                }
              />
            </Tooltip>
          )}
          {planDate && (
            <>
              <Tooltip title="View">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => navigate(`/${orgId}/${orgName}/ccm-view-care`, {
                    state: { patientNameObj, id, dob }
                  })}
                />
              </Tooltip>
              {isInPrevMonth(planDate) && (
                <Tooltip title="Follow Up">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LinkOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/ccm-follow-up/${id}`)}
                  />
                </Tooltip>
              )}
              {isSameMonthPlanCreated && (
                <Tooltip title="Edit">
                  <Button
                    type="primary"
                    shape="circle"
                    disabled={!planDate}
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/ccm-edit-care/${id}`)}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      ) : (
        <>
        <div className="flex items-center gap-3 flex-wrap">
        <Tooltip title="Create Plan">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => navigate(`/${orgId}/${orgName}/rpm-questions/${id}`, {
                state: {
                  name: {
                    firstName: patientInfo.firstName,
                    middleName: patientInfo.middleName,
                    lastName: patientInfo.lastName,
                  },
                  dob: patientInfo.dob,
                }
              })}
            />
          </Tooltip>

          {planDate && planDate.length ? (
            <>
              <Tooltip title="View Plan">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => navigate(`/${orgId}/${orgName}/rpm-view-treatement/${id}`, {
                    state: {
                      name: {
                        firstName: patientInfo.firstName,
                        middleName: patientInfo.middleName,
                        lastName: patientInfo.lastName,
                      },
                      dob: patientInfo.dob,
                    }
                  })}
                />
              </Tooltip>
              {new Date(planDate).getFullYear() < new Date().getFullYear() ? null : (
                <Tooltip title="Update Plan">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/rpm-edit-treatement/${id}`)}
                  />
                </Tooltip>
              )}
              {new Date(planDate).getFullYear() === (new Date().getFullYear() - 1) ? (
                <Tooltip title="Follow Up">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LinkOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/rpm-follow-up/${id}`, {
                      state: {
                        name: {
                          firstName: patientInfo.firstName,
                          middleName: patientInfo.middleName,
                          lastName: patientInfo.lastName,
                        },
                        dob: patientInfo.dob,
                      }
                    })}
                  />
                </Tooltip>
              ) : null}
            </>
          ) : null}
          </div>
        </>
      )
    }

  ]


  return (
    <div>
      {caretakerLoading ? (
          <Loading />
        ) : (
          <>
            {
              <>
              <PatientPlanHeader
                patientInfoDetail={patientInfo}
                id={id}
                requestFrom={'plan-history'}
                moniteringType ={moniteringType}
                // patientCaretaker={patientCaretaker}
                // patientMonitoringData={patientMonitoringData}
                // carePlanData={carePlanData}
              />
              <Card
              title={<h4 className="text-xl">Plan History</h4>}
             >
              <Table
                size="small"
                columns={columns}
                dataSource={entirePlanHistory}
                scroll={{ x: "max-content" }}
                loading={ entirePlanHistoryIsLoading}
              />
            </Card>
            </>
            }
          </>)}
    </div>
  )
}

export default PatientPlans
