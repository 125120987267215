import React from "react"
import { NavLink } from "react-router-dom"

import { Row, Col, Input, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { userLoginAction, clearUserLoginError } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import fitpeoLogo from "../../assets/fitpeo_logo.png"
import { useNavigate } from "react-router-dom"
import loginImage from "../../assets/Doctors.png"


const landlineSchema = yup
  .object({
    password: yup.string().required("Enter Valid Password"),
    landline: yup.string().min(10).max(10).required('Enter 10 digit valid landline number'),
    extension: yup.string().min(1).max(5).required('Enter valid extension')
  })
  .required()

const landlineDefaultValues = {
  landline: "",
  password: "",
  extension: "",
}


const LoginWithLandline = ({setShowLandline}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error } = useSelector(state => state.userInfo)


  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    landlineDefaultValues,
    resolver: yupResolver(landlineSchema)
  })

  
const onSubmit = async({ landline, password, extension }) => {
  await dispatch(userLoginAction(landline, password, extension))
  }

  
  return (
    <div className="flex h-screen p-4">
      <Row className="items-center justify-center w-full">
        <Col sm={24} className="block lg:hidden">
          <img alt="loginImage" src={loginImage} className="img-fluid float-end login-sm-image" />
        </Col>

        <Col sm={24} lg={6}>
          <form className="p-4 border-2 border-current rounded" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center mb-5">
              <img
                alt="logo"
                width={200}
                src={fitpeoLogo}
                className="img-fluid fitpeo_logo_login"
              />
            </div>
            <div className="mb-4">
              <label className="mb-2" htmlFor="input-credential">
               Landline Number & Landline Extension
              </label>
              
              <div className="d-flex justify-content-between">
              <div className="col-md-7 pr-3">
              <Controller
                name="landline"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="number"
                    size="large"
                    id="input-landline"
                    placeholder="Landline Number"
                    status={errors.landline ? "error" : undefined}
                    {...field}
                  />
                )}
              /> 
              </div>
              <div className="col-md-5">
              <Controller
                name="extension"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    type="number"
                    id="input-extension"
                    placeholder="Extension"
                    status={errors.extension ? "error" : undefined}
                    {...field}
                  />
                )}
              /> 
              </div>
              </div>
            

              <div className="d-flex justify-content-center">
              {errors.landline ? 
                <small className="text-danger mr-10">{errors.landline.message}</small>
              : null}
              {errors.extension ? 
                <small className="text-danger">{errors.extension.message}</small>
              : null}
              </div>
            </div>
            <div className="mb-4">
              <p className="text-center text-secondary" style={{cursor:"pointer"}}
              onClick={()=>{setShowLandline(false); dispatch(clearUserLoginError())}}
              >Login with email or mobile</p>
            </div>

            <div className="mb-2">
              <label className="mb-2" htmlFor="input-password">
                Password
              </label>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input.Password
                    size="large"
                    id="input-password"
                    status={errors.password ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.password ? (
                <small className="text-danger">{errors.password.message}</small>
              ) : null}
            </div>
            <div>
              <NavLink to="/forgot-password" className="text-black float-end px-0 no-underline">
                Forgot Password?
              </NavLink>
            </div>
            <div className="mt-5">
              <Button
                block
                size="large"
                type="primary"
                htmlType="submit"
                disabled={Boolean(loading)}>
                {loading ? "Loading..." : "Login"}
              </Button>
            </div>
            {error !== undefined && typeof error === "string" && (
              <p className="text-danger text-center font-medium mt-4">{error}</p>
            )}
          </form>

          {error !== undefined &&
            typeof error === "object" &&
            Object.values(error).map((value, index) => (
              <p key={index} className="text-danger text-center">
                <small>{value}</small>
              </p>
            ))}
        </Col>
        <Col className="hidden lg:block ps-5" lg={12}>
          <img src={loginImage} alt="loginImage" className="img-fluid" />
        </Col>
      </Row>
    </div>
  )
}

export default LoginWithLandline
