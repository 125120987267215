import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPractitionerList } from "../../redux/actions/practitionerAction"
import { Card, Table, Input, Typography } from "antd"
import Loading from "../layouts/Loading/Loading"
import { useNavigate, useParams } from "react-router-dom"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const { Search } = Input

// column constants
const SNO_TITLE = "S.No."
const SNO_DATA_INDEX = "sNo"

const FULLNAME_TITLE = "Full Name"
const FULLNAME_DATA_INDEX = "fullName"

const SPECIALITY_TITLE = "Speciality"
const SPECIALITY_DATA_INDEX = "speciality"

const manupulatedData = practitionerList =>
  practitionerList?.map((practitioner, idx) => ({
    sNo: idx + 1,
    practitionerId: practitioner?.id,
    fullName: `${practitioner?.firstName} ${practitioner?.middleName ?? ""} ${
      practitioner?.lastName
    }`,
    speciality: practitioner?.specialization?.replace(/~/g, ", ")
  }))

const PractitionerList = () => {
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const { loading, practitionerList } = useSelector(state => state.practitionerList)

  const {orgId, orgName} = useParams()
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getPractitionerList(orgId))
  }, [])

  useEffect(() => {
    if (!practitionerList?.length) return
    setData(manupulatedData(practitionerList))
  }, [practitionerList])

  const handleSearch = e => {
    const searchQuery = e?.target?.value
    let queryWithoutSpace = searchQuery?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchTerm(queryWithoutSpace)
    if (searchQuery) {
      const trimmedSearchValue = searchQuery.replace(/\s+/g, "").toLowerCase()
      const filteredPractitioners = practitionerList?.filter(
        p =>
          `${p?.firstName?.toLowerCase()}${p?.middleName?.toLowerCase() ?? ""
            }${p?.lastName?.toLowerCase()}`
            .replace("  ", " ")
            .includes(trimmedSearchValue) ||
          p?.specialization
            ?.toLowerCase()
            ?.replace(/~/g, ", ")
            ?.includes(searchQuery?.toLowerCase())
      )
      setData(manupulatedData(filteredPractitioners))
    } else {
      setData(manupulatedData(practitionerList))
    }
  }

  const columns = [
    {
      title: SNO_TITLE,
      dataIndex: SNO_DATA_INDEX
    },
    {
      title: FULLNAME_TITLE,
      dataIndex: FULLNAME_DATA_INDEX,
      render: (fullName, { practitionerId }) => (
        <a
          className="capitalize truncate"
          onClick={() => navigate(`/${orgId}/${orgName}/practitioner/${practitionerId}`)}
          style={{ cursor: "pointer" }}>
          {fullName}
        </a>
      ),
      sorter: (a, b) => a?.fullName.localeCompare(b?.fullName)
    },
    {
      title: SPECIALITY_TITLE,
      dataIndex: SPECIALITY_DATA_INDEX,
      render: speciality => <span className="capitalize">{speciality}</span>,
      sorter: (a, b) => a?.speciality.localeCompare(b?.speciality)
    }
  ]

  return (
    <>
      <OrgBackNavigation />
      <Typography.Title level={3}>Practitioners</Typography.Title>
      <Card>
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <>
            <Search
              size="large"
              allowClear
              className="max-w-[300px] mb-4"
              placeholder="Search Practitioners..."
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
            />
            <Table columns={columns} rowKey="sNo" dataSource={data} scroll={{ x: "max-content" }} />
          </>
        )}
      </Card>
    </>
  )
}

export default PractitionerList
