import React from "react"
import { Modal, Button, Input } from "antd"

const HypertensiveYesAlertModal = props => {
  const {
    showHypertensiveYesAlert,
    setShowHypertensiveYesAlert,
    HypertesniveYesAlertQus,
    setHypertensiveYesAlertQus
  } = props

  return (
    <Modal
      open={showHypertensiveYesAlert}
      onCancel={() => setShowHypertensiveYesAlert(false)}
      footer={null}
      title={<h4 className="text-xl">Note</h4>}>
      <div className="pt-4">
        <p className="font-medium">{HypertesniveYesAlertQus?.name}</p>

        <Input.TextArea
          value={HypertesniveYesAlertQus?.text}
          onChange={e =>
            setHypertensiveYesAlertQus({ ...HypertesniveYesAlertQus, text: e.target.value })
          }
        />
        <Button className="mt-4" type="primary" onClick={() => setShowHypertensiveYesAlert(false)}>
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default HypertensiveYesAlertModal
