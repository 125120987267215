import React, { useState, useEffect } from "react"

import { Card, Avatar, Input, Row, Col, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"

import { useToasts } from "react-toast-notifications"
import imageCompression from "browser-image-compression"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { base64ImageConvertor } from "../layouts/utils/imageConvertor"
import {
  UpdateProfileAction,
  adminUpdateAction,
  getUserProfileAction
} from "../../redux/actions/userActions"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"

const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid email")
      .required("Invalid Email"),
    mobileNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid Mobile Number")
      .required("Invalid Mobile Number")
  })
  .required()

const Profile = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { userInfo } = useSelector(state => state.userInfo)
  const { error } = useSelector(state => state.adminUpdate)
  const [isFocused, setIsFocused] = useState(false)

  // const [profilePic, setProfilePic] = useState(
  //   userInfo?.profileImage
  //     ? `data:image/png;base64,${userInfo?.profileImage}`
  //     : "/assets/fitpeo_logo.png"
  // )

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const handleFileChange = async e => {
    const file = e.target.files[0]
    if (file !== undefined) {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
        maxWidthOrHeight: 1920
      }
      try {
        const compressedFile = await imageCompression(file, options)
        const base64Image = await base64ImageConvertor(compressedFile)
        const profileImage = await base64Image.replace("data:image/png;base64,", "")
        setProfilePic(profileImage)
        dispatch(UpdateProfileAction(profileImage))
      } catch (error) {
        addToast("we are unable to process at this moment.", {
          appearance: "error",
          autoDismiss: true
        })
      }
    }
  }

  useEffect(() => {
    if (userInfo?.email) {
      setValue("email", userInfo?.email)
    }
    if (userInfo?.mobileNumber) {
      setValue("mobileNumber", formatPhoneNumber(userInfo?.mobileNumber))
    }
  }, [userInfo]) //eslint-disable-line

  const onSubmit = async data => {
    const result = await dispatch(
      adminUpdateAction(userInfo?.id, {
        ...userInfo,
        ...data,
        mobileNumber: data?.mobileNumber?.replace(/[^\d]/g, ""),
        countryCode: "US"
      })
    )
    if (result?.message?.toLowerCase() === "success") {
      addToast("Admin Updated Successfully", {
        appearance: "success",
        autoDismiss: true
      })
      setTimeout(() => dispatch(getUserProfileAction(userInfo?.id)), 1000)
    } else {
      addToast("Error While Updating Admin", {
        appearance: "error",
        autoDismiss: true
      })
    }
  }

  const handleReset = () => {
    if (userInfo?.email) {
      setValue("email", userInfo?.email)
    }
    if (userInfo?.mobileNumber) {
      setValue("mobileNumber", formatPhoneNumber(userInfo?.mobileNumber))
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }
  return (
    <Card title="">
      <div className="flex justify-content-between align-items-center mb-5">
        <div>
          <h2 className="text-2xl">Profile</h2>
        </div>
        <div>
          <p className="text-secondary">Version : 2.4.3</p>
        </div>
      </div>
      {/*
      <div className="mt-5 flex flex-wrap items-center gap-4">
        <Avatar
          size={100}
          className="border-2 border-current"
          src={<img alt="user profile" src={profilePic} />}
        />
        <div>
          <input
            type="file"
            className="form-control"
            accept=".jpg,.jpeg,.png"
            onChange={handleFileChange}
          />
          <p className="text-secondary mb-0 mt-2">Only JPEG & PNG are allowed.</p>
        </div>
      </div>
      <hr className="my-5" />
      */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col sm={24} lg={8} className="mb-4 w-full">
            <label htmlFor="firstName" className="mb-2">
              First Name
            </label>
            <Input
              disabled
              size="large"
              id="firstName"
              value={userInfo.firstName}
              className="capitalize"
            />
          </Col>
          <Col sm={24} lg={8} className="mb-4 w-full">
            <label htmlFor="lastName" className="mb-2">
              Last Name
            </label>
            <Input
              disabled
              size="large"
              id="lastName"
              value={userInfo.lastName}
              className="capitalize"
            />
          </Col>
          <Col sm={24} lg={8} className="mb-4 w-full">
            <label htmlFor="userName" className="mb-2">
              Username
            </label>
            <Input disabled size="large" id="userName" value={userInfo.userName} />
          </Col>
          <Col sm={24} lg={8} className="mb-4 w-full">
            <label className="mb-2" htmlFor="input-email">
              Email <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  status={errors.email ? "error" : undefined}
                  size="large"
                  id="input-email"
                  {...field}
                  onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                />
              )}
            />
            {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
          </Col>
          <Col xs={24} lg={8} className="mb-4 w-full">
            <label className="mb-2" htmlFor="input-mobileNumber">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { ref, ...rest } = field
                return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-mobile-input flex p-2 text-[1rem] w-full  border border-${
                      errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                    }  rounded-lg focus:outline-none`}
                    id="input-mobileNumber"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                )
              }}
            />
            {errors.mobileNumber ? (
              <small className="text-danger">{errors.mobileNumber.message}</small>
            ) : null}
          </Col>
          {error && Object.keys(error).length ? (
            <Col xs={24} className="w-full mb-4">
              {Object.keys(error).map(err => (
                <p className="text-danger font-semibold">{error[err]}</p>
              ))}
            </Col>
          ) : null}
          <Col xs={24} className="w-full">
            <div className="flex flex-wrap gap-3">
              <Button size="large" type="primary" htmlType="submit">
                Submit
              </Button>
              <Button size="large" danger onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Card>
  )
}

export default Profile
