import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  SolutionOutlined,
  FileProtectOutlined,
} from "@ant-design/icons"
import { BsBuilding } from "react-icons/bs";
import { RiGroupLine } from "react-icons/ri";

const Navigation = [
  {
    key: "/",
    label: "Organization",
    icon: <BsBuilding />
  },
  {
    key: "/dashboard",
    label: "Dashboard",
    icon: <HomeOutlined />
  },
  {
    label: "CCM",
    key: "/ccm",
    icon: <PlusSquareOutlined />,
    children: [
      // {
      //   label: "New Patients",
      //   key: "/ccm/new-patients"
      // },
      {
        label: "CCM Patients",
        key: "/ccm/patients"
      },
      // {
      //   label: "Follow Up",
      //   key: "/ccm/follow-up"
      // }
    ]
  },
  {
    label: "RPM",
    key: "/rpm-view",
    icon: <SolutionOutlined />,
    children: [
      {
        label: "RPM Patients",
        key: "/rpm/patients"
        // children: [
        //   {
        //     label: "New Plan",
        //     key: "/rpm/patients"
        //   },
        //   {
        //     label: "Added Plan",
        //     key: "/rpm/new-patient/added-plan"
        //   },
        //   {
        //     label: "Follow Up",
        //     key: "/rpm/new-patient/follow-up"
        //   }
        // ]
      },
      {
        label: "Triage",
        key: "/triage"
      },
      // {
      //   label: "Critical Patients",
      //   key: "/rpm-critical-patient"
      // },
      // {
      //   label: "Critical Time Passed",
      //   key: "/rpm-critical-time-passed"
      // }
    ]
  },
  {
    label: "PCM",
    key: "/pcm",
    icon: <FileProtectOutlined />,
    children: [
      {
        label: "PCM Patients",
        key: "/pcm/patients"
      },
    ]
  },
  {
    key: "/patient",
    label: "Patients",
    icon: <FileTextOutlined />
  },
  {
    key: "/provider",
    label: "Providers",
    icon: <UserOutlined />
  },
  {
    key: "/practitioners",
    label: "Practitioners",
    icon: <UserOutlined />
  },
  {
    key: "/caretaker",
    label: "Caretakers",
    icon: <UserOutlined />
  },
  {
    key: "/groups",
    label: "Groups",
    icon: <RiGroupLine />
  },
  // {
  //   key: "/settings",
  //   label: "Settings",
  //   icon: <SettingOutlined />
  // }
]

export default Navigation
