import React, { useEffect } from "react"
import { Modal, Radio, Select, Button, Checkbox } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getAdvancedFiltersOptions } from "../../redux/actions/patientAction"
import { clearFilterModalValuesAction } from "../../redux/actions/utilsActions"

import Loading from "../layouts/Loading/Loading"
import { customUnitRoomSort } from "../../utils/customUnitRoomSort"
import { useParams } from "react-router-dom"

const getUnits = (arr, facilityId) => arr?.find(i => i.facilityId === facilityId)
const getRooms = (arr, facilityId, facilityUnit) =>
  arr
    ?.find(i => i.facilityId === facilityId)
    ?.filterOptions?.find(r => r.units.unit === facilityUnit)?.units?.rooms

const getBedPositions = (arr, facilityId, facilityUnit) =>
  arr
    ?.find(i => i.facilityId === facilityId)
    ?.filterOptions?.find(r => r.units.unit === facilityUnit)?.units?.bedPositions

const AdvancedFilters = props => {
  const {orgId, orgName} = useParams()
  const {
    facilities,
    applyFilters,
    resetFilters,
    resetCallback,
    onBoardSelect,
    filteredValues,
    setOnBoardSelect,
    setFilteredValues,
    showAdvancedFilters,
    setShowAdvancedFilters
  } = props

  const dispatch = useDispatch()
  const { advancedFiltersOptions, loading } = useSelector(state => state.advancedFiltersOptions)

  useEffect(() => {
    if(showAdvancedFilters && filteredValues?.userType === "facility" && !advancedFiltersOptions?.length )
    dispatch(getAdvancedFiltersOptions(orgId))
  }, [showAdvancedFilters, filteredValues])

  const handleClose = () => {
    setShowAdvancedFilters(false)
  }

  const handleOnboard = e => {
    if (e.target.checked) {
      setOnBoardSelect(true)
      dispatch(clearFilterModalValuesAction())
    } else {
      setOnBoardSelect(false)
    }
  }
  const facilityOptions = advancedFiltersOptions?.filter(i => i.userType === "facility") || []

  return (
    <Modal
      footer={null}
      onCancel={handleClose}
      open={showAdvancedFilters}
      title={<h6 className="text-xl">Advanced Filters</h6>}>
      <div className="py-4">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="mb-4">
              <p className="text-lg mb-3">Patient</p>
              <Checkbox onChange={handleOnboard} checked={onBoardSelect} disabled>
                Onboarding pending
              </Checkbox>
            </div>
            <p className="text-lg mb-3">Facility/OutPatient</p>
            <Radio.Group
              options={[
                {
                  value: "facility",
                  label: "Facility"
                },
                {
                  value: "outpatient",
                  label: "OutPatient"
                }
              ]}
              value={filteredValues?.userType}
              onChange={e =>
                setFilteredValues({
                  ...filteredValues,
                  userType: e.target.value
                })
              }
            />
            {filteredValues?.userType === "facility" ? (
              <div className="mt-4">
                <div>
                  <p>Facility</p>
                  <Select
                    className="w-100"
                    labelInValue
                    style={{ textTransform: "capitalize" }}
                    dropdownStyle={{ textTransform: "capitalize" }}
                    options={facilityOptions?.map(address => ({
                      value: address?.facilityId,
                      label: address?.name
                    }))}
                    defaultValue={{
                      value: filteredValues?.facilityId || null,
                      label: filteredValues?.facilityName || null,
                    }}
                    onChange={({ value: facilityId, label: facilityName }) => {
                      setFilteredValues({
                        facilityId,
                        facilityName,
                        userType: filteredValues.userType,
                        unit: undefined,
                        bedLocation: undefined,
                        room: undefined
                      })
                    }}
                  />
                </div>
                {filteredValues?.facilityId &&
                getUnits(facilityOptions, filteredValues?.facilityId)?.filterOptions ? (
                  <div className="mt-4">
                    <p>Unit</p>
                    <Select
                      className="w-100"
                      value={filteredValues?.unit || null}
                      options={getUnits(facilityOptions, filteredValues?.facilityId)
                        ?.filterOptions?.sort((a, b) =>
                          customUnitRoomSort(a.units.unit, b.units.unit)
                        )
                        ?.map(u => ({
                          value: u.units.unit,
                          label: u.units.unit
                        }))}
                      onChange={val =>
                        setFilteredValues({
                          ...filteredValues,
                          unit: val,
                          bedLocation: undefined,
                          room: undefined
                        })
                      }
                    />
                  </div>
                ) : null}
                {filteredValues?.facilityId &&
                filteredValues?.unit &&
                getUnits(facilityOptions, filteredValues?.facilityId)?.filterOptions &&
                getRooms(facilityOptions, filteredValues?.facilityId, filteredValues.unit) ? (
                  <div className="mt-4">
                    <p>Room</p>
                    <Select
                      className="w-100"
                      value={filteredValues?.room || null}
                      options={getRooms(
                        facilityOptions,
                        filteredValues?.facilityId,
                        filteredValues.unit
                      )
                        ?.room?.sort(customUnitRoomSort)
                        ?.map(r => ({
                          value: r,
                          label: r
                        }))}
                      onChange={val =>
                        setFilteredValues({
                          ...filteredValues,
                          room: val,
                          bedLocation: undefined
                        })
                      }
                    />
                  </div>
                ) : null}
                {filteredValues?.facilityId &&
                filteredValues?.unit &&
                filteredValues?.room &&
                getUnits(facilityOptions, filteredValues?.facilityId)?.filterOptions &&
                getRooms(facilityOptions, filteredValues?.facilityId, filteredValues.unit) &&
                getBedPositions(
                  facilityOptions,
                  filteredValues?.facilityId,
                  filteredValues.unit
                ) ? (
                  <div className="mt-4">
                    <p>Bed Position</p>
                    <Select
                      className="w-100"
                      value={filteredValues?.bedLocation || null}
                      options={getBedPositions(
                        facilityOptions,
                        filteredValues?.facilityId,
                        filteredValues.unit
                      )?.bedPositions?.map(r => ({
                        value: r !== "door" ? `${r}_bed` : r,
                        label: r
                      }))}
                      onChange={val =>
                        setFilteredValues({
                          ...filteredValues,
                          bedLocation: val
                        })
                      }
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="flex flex-wrap gap-3 mt-4">
              <Button
                size="large"
                type="primary"
                disabled={!filteredValues?.userType && !onBoardSelect}
                onClick={() => {
                  applyFilters(onBoardSelect ? "onboard" : "normal", {
                    ...(filteredValues?.userType === "facility"
                      ? { ...filteredValues }
                      : {
                          userType: filteredValues?.userType,
                          unit: undefined,
                          room: undefined,
                          bedLocation: undefined,
                          facilityId: undefined
                        })
                  })
                  setShowAdvancedFilters(false)
                }}>
                Apply
              </Button>
              <Button size="large" danger onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default AdvancedFilters
