import DatePicker from "react-datepicker"
import { Select, Row, Col, Input, Button, Modal } from "antd"
import { useState } from "react"
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch } from "react-redux"
import { updateProviderAction, getSingleProviderAction } from "../../../redux/actions/providerAction"
import { useToasts } from "react-toast-notifications"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"

const schema = yup
  .object({
    middleName: yup.string().optional(),
    dob: yup.string().required("Invalid DOB"),
    gender: yup.string().required("Invalid Gender"),
    lastName: yup.string().required("Invalid Last Name"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid email").required("Invalid Email"),
    userName: yup.string().matches(/^[a-zA-Z0-9]*$/, "Only alphabets and numbers are allowed"),
    mobileNumber: yup.string().required("Invalid Mobile Number"),
    lastName: yup
      .string()
      .min(1)
      .max(100)
      .matches(/^[A-Za-z ]*$/, "Last Name should only contain alphabets")
      .required("Invalid Last Name"),
    firstName: yup
      .string()
      .min(1)
      .max(100)
      .matches(/^[A-Za-z ]*$/, "First Name should only contain alphabets")
      .required("Invalid First Name")
  })
  .required()

const UpdateProvider = props => {
  const { singleProvider, updateProviderShow, setUpdateProviderShow } = props
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch()
  const { addToast } = useToasts()
  //   const { singleProvider } = useSelector(state => state.singleProvider)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: singleProvider?.email || "",
      gender: singleProvider?.gender || "",
      userName: singleProvider?.userName || "",
      lastName: singleProvider?.lastName || "",
      firstName: singleProvider?.firstName || "",
      middleName: singleProvider?.middleName || "",
      mobileNumber: formatPhoneNumber(singleProvider?.mobileNumber) || "",
      dob: singleProvider?.dob ? new Date(singleProvider?.dob) : new Date()
    }
  })
  const [updateError, setUpdateError] = useState("")

  const onSubmit = async data => {
    const middleName = data.middleName || null;
    setUpdateError("")
    const result = await dispatch(
      updateProviderAction(singleProvider?.id, {
        ...data,
        middleName,
        mobileNumber: data?.mobileNumber?.replace(/[^\d]/g, ''),
        countryCode: singleProvider?.countryCode.toUpperCase(),
        dob: new Date(data.dob).toISOString()
      })
    )
    if (result?.message?.toLowerCase() === "success") {
      setUpdateProviderShow(false)
      dispatch(getSingleProviderAction(singleProvider?.id))
      addToast("Provider Updated Successfully", {
        appearance: "success",
        autoDismiss: true
      })
    }else{
      return setUpdateError(result)
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };
  return (
    <Modal
      width={800}
      footer={null}
      open={updateProviderShow}
      onCancel={() => setUpdateProviderShow(false)}
      title={<h4 className="text-xl">Update Provider</h4>}>
      <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-firstName">
              First Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="w-100"
                  status={errors.firstName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.firstName ? (
              <small className="text-danger">{errors.firstName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-middleName">
              Middle Name
            </label>
            <Controller
              name="middleName"
              control={control}
              render={({ field }) => (
                <Input
                  className="w-100"
                  status={errors.middleName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.middleName ? (
              <small className="text-danger">{errors.middleName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-lastName">
              Last Name <span className="text-danger">*</span>
            </label>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="w-100"
                  status={errors.lastName ? "error" : undefined}
                  {...field}
                  onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.lastName ? (
              <small className="text-danger">{errors.lastName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-userName">
              Username <span className="text-danger">*</span>
            </label>
            <Controller
              name="userName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="w-100"
                  status={errors.userName ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.userName ? (
              <small className="text-danger">{errors.userName.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-mobileNumber">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const {ref, ...rest} = field
              return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-mobile-input text-sm leading-[1.35rem] flex px-[11px] py-[4px]  w-full  border border-${
                      errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                    }  rounded-lg focus:outline-none`}
                    id="input-mobileNumber"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
              )}
             }
            />
            {errors.mobileNumber ? (
              <small className="text-danger">{errors.mobileNumber.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-email">
              Email <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="w-100"
                  type="email"
                  status={errors.email ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-2" htmlFor="input-gender">
              Gender at the time of birth <span className="text-danger">*</span>
            </label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  className="w-100"
                  status={errors.gender ? "error" : undefined}
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" }
                  ]}
                  {...field}
                />
              )}
            />
            {errors.gender ? <small className="text-danger">{errors.gender.message}</small> : null}
          </Col>
          <Col xs={24} md={12} className="mb-4">
            <label className="mb-1" htmlFor="input-dob">
              DOB <span className="text-danger">*</span>
            </label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  required
                  selected={field.value}
                  // selected={new Date()}
                  onChange={date => field.onChange(date)}
                  dateFormatCalendar={"MMM yyyy"}
                  maxDate={Date.now()}
                  showPopperArrow={false}
                  className="border p-3 rounded w-100 h-[30px]"
                  withPortal
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  {...field}
                />
              )}
            />
            {errors.dob ? <small className="text-danger">{errors.dob.message}</small> : null}
          </Col>

          <Col xs={24} md={12}>
          {updateError ? (
            <Col className="mb-4" xs={24}>
              <p className="text-danger font-medium">{updateError}</p>
            </Col>
          ) : null}
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default UpdateProvider
