import React, { useState } from "react"

import { Card, Avatar, Row, Col, Button, Tag, Divider } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"

import defaultProfilePicture from "../../assets/doctor.png"
import CaretakerAddress from "./CaretakerAddress"
import UpdateCaretaker from "./UpdateCaretaker"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import moment from "moment"

const renderPermissions = grantType => {
  if (grantType.includes("caretaker_all"))
    return (
      <>
        <Tag className="text-current" icon={<CheckCircleOutlined />}>
          Can Add Patients
        </Tag>
        <Tag className="text-current" icon={<CheckCircleOutlined />}>
          Can Add Manual Time
        </Tag>
      </>
    )
  else if (grantType.includes("caretaker_manual_log"))
    return (
      <Tag className="text-current" icon={<CheckCircleOutlined />}>
        Can Add Manual Time
      </Tag>
    )
  else if (grantType.includes("caretaker_register_patient"))
    return (
      <Tag className="text-current" icon={<CheckCircleOutlined />}>
        Can Add Patients
      </Tag>
    )
  else if (grantType.includes("enrollment_specialist"))
    return (
      <Tag className="text-current" icon={<CheckCircleOutlined />}>
        Enrollment Specialist
      </Tag>
    )
  else
    return (
      <Tag color="error" icon={<CloseCircleOutlined />}>
        {" "}
        No Permissions Granted{" "}
      </Tag>
    )
}

const CaretakerDetailHeader = ({ singleCaretaker }) => {
  const [updateCaretakerShow, setUpdateCaretakerShow] = useState(false)

  const profilePicture = [null, undefined].includes(singleCaretaker?.profileImage)
    ? defaultProfilePicture
    : `data:image/png;base64,${singleCaretaker?.profileImage}`

  return (
    <>
      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={16} md={24} xs={24}>
          <Card className="shadow-sm h-full" loading={singleCaretaker === undefined}>
            <div className="flex gap-3 flex-wrap justify-between">
              <h4 className="text-2xl">Caretaker Details</h4>
              <Button type="primary" onClick={() => setUpdateCaretakerShow(true)}>
                Update Profile
              </Button>
            </div>
            <div className="mt-4 flex gap-x-16 gap-y-5 flex-wrap">
              <div className="flex gap-3">
                <Avatar className="border-2 border-current p-2" size={60} src={profilePicture} />
                <div>
                  <h5 className="mb-1 text-xl capitalize">
                    {singleCaretaker?.firstName} {singleCaretaker?.middleName}{" "}
                    {singleCaretaker?.lastName}
                  </h5>
                  <p className="text-sm text-secondary">
                    {singleCaretaker?.email || "No Email isused for this caretaker."}
                  </p>
                </div>
              </div>
              <div>
                {singleCaretaker?.userName ? (
                  <div className="mb-2">
                    <span className="font-medium min-w-[100px]">User Name : </span>
                    {singleCaretaker?.userName}
                  </div>
                ) : null}
                {singleCaretaker?.gender ? (
                  <div className="mb-2">
                    <span className="font-medium min-w-[100px]">Gender at the time of birth : </span>
                    {singleCaretaker?.gender}
                  </div>
                ) : null}
              </div>
              <div>
                {singleCaretaker?.dob ? (
                  <div className="mb-2">
                    <span className="font-medium min-w-[120px]">DOB : </span>
                    {singleCaretaker?.dob ? moment(singleCaretaker?.dob)?.format("MM-DD-YYYY") : null}
                  </div>
                ) : null}
                {singleCaretaker?.mobileNumber ? (
                  <div className="mb-2">
                    <span className="font-medium min-w-[120px]">Mobile Number : </span>
                    {formatPhoneNumber(singleCaretaker?.mobileNumber)}
                  </div>
                ) : null}
              </div>
            </div>
            <Divider orientation="left">Permission(s)</Divider>
            <div className="flex flex-wrap gap-2">
              {renderPermissions(singleCaretaker?.grantType)}
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <CaretakerAddress caretakerId={singleCaretaker?.id} />
        </Col>
      </Row>

      {updateCaretakerShow && (
        <UpdateCaretaker
          singleCaretaker={singleCaretaker}
          updateCaretakerShow={updateCaretakerShow}
          setUpdateCaretakerShow={setUpdateCaretakerShow}
        />
      )}
    </>
  )
}

export default CaretakerDetailHeader
