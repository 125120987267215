import React from "react"
import { Card, Button } from "antd"
import { useSelector } from "react-redux"

const PractitionerAddress = ({ handleUpdateAddressShow }) => {
  const {
    loading: addressLoading,
    data: address,
    error: addressErrors
  } = useSelector(state => state.practitionerAddress)

  return (
    <Card className="shadow-sm h-full" loading={addressLoading}>
      <div className="flex gap-3 flex-wrap justify-between">
        <h4 className="text-2xl">Address</h4>
        {address && (
          <Button type="primary" onClick={() => handleUpdateAddressShow()}>
            Update Address
          </Button>
        )}
      </div>
      {(address && (
        <div className="mt-4 flex gap-x-16 gap-y-5 flex-wrap ">
          <div>
            {address?.street ? (
              <div className="mb-2">
                <span className="font-medium min-w-[100px]">Street : </span>
                {address?.street}
              </div>
            ) : null}
            {address?.city ? (
              <div className="mb-2">
                <span className="font-medium min-w-[100px]">City : </span>
                {address?.city}
              </div>
            ) : null}
          </div>
          <div>
            {address?.state ? (
              <div className="mb-2">
                <span className="font-medium min-w-[120px]">State : </span>
                {address?.state}
              </div>
            ) : null}
            {address?.zipcode ? (
              <div className="mb-2">
                <span className="font-medium min-w-[120px]">Zip Code : </span>
                {address?.zipcode}
              </div>
            ) : null}
          </div>
        </div>
      )) || <h2 className="mt-4 text-lg text-center">Not Found For This Practitioner</h2>}
    </Card>
  )
}

export default PractitionerAddress
