import { useEffect, useState } from "react"
import * as yup from "yup"
import { Button, Col, Input, Modal, Row } from "antd"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { postRpmOximeterTriageAction } from "../../../redux/actions/rpmAction"
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import AfterSubmitModal from "./afterSubmitModal.js/AfterSubmitModal"

const { TextArea } = Input;

const schema = yup.object({
    details: yup.string().required("Enter details")
})

const defaultValues = {
    details: ""
}

const TriageGlucometerQuesView = ({ setShowGlucometerModel, readingDetails, patientInfoDetail, triageStartedAt }) => {
    const [showAfterSubmitAlert, setShowAfterSubmitAlert] = useState(false)

    const { id, orgId, orgName } = useParams()
    const location = useLocation();
    const { readingIds } = location.state;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { addToast } = useToasts();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    })

    const onSubmit = async (data) => {
        const deviceType = "glucose monitor"
        const report = {
            0: {
                id: 1,
                name: "",
                notApplicable: false,
                onCondition: null,
                quesType: "text",
                questionaryOptions: [],
                text: data?.details,
                triageType: "glucose machine"
            }
        }
        report.readingDetails = readingDetails
        let res = await dispatch(postRpmOximeterTriageAction(id, report, readingIds, triageStartedAt, deviceType))
        if (res?.message === "Success") {
            addToast("Plan Added Successfully", { appearance: "success", autoDismiss: true })
            setShowAfterSubmitAlert(true)
        } else {
            addToast("Error Posting Plan", { appearance: "error", autoDismiss: true })
        }
    }

    const onCancel = () => {
        setShowGlucometerModel(false)
        navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
            state: {
                name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
            }
        })
    }

    return (
        <div>
            {!showAfterSubmitAlert ?
                <Modal
                    open={true}
                    width={700}
                    onCancel={() => onCancel()}
                    footer={null}
                    title={<h4 className="text-xl">Please enter the details of the Glucose Monitor Triage.</h4>}
                >
                    <div className="">
                        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
                            <Row gutter={16}>
                                <Col className="mb-4" xs={24}>
                                    <Controller
                                        name="details"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextArea
                                                rows={4}
                                                cols={9}
                                                status={errors.details ? "error" : undefined}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {errors.details ? (
                                        <small className="text-danger capitalize">{errors.details.message}</small>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row className="flex justify-end items-center gap-4">
                                <Button onClick={() => onCancel()}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Row>
                        </form>
                    </div>
                </Modal> :
                <AfterSubmitModal
                    showModal={showAfterSubmitAlert}
                    setShowModal={setShowAfterSubmitAlert}
                    id={id}
                />
            }
        </div>
    )
}

export default TriageGlucometerQuesView