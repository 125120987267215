import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup"
import { object } from "yup";
import debounce from 'lodash/debounce';
import { useToasts } from "react-toast-notifications"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Col, Input, Modal, Row, Select, Space, Spin } from "antd"
import { AddGroup, GetAdminCaretakerForGroup, GetGroups, ValidateGroupName } from "../../redux/actions/groupsActions";

const defaultValues = {
    groupName: "",
    groupMembers: [],
    groupManagers: []
}

const GROUP_NAME_REGEX = /^[a-zA-Z0-9._%+-]+$/;

const schema = object().shape({
    groupName: yup.string().required("Group Name is required").matches(GROUP_NAME_REGEX, "Group Name Can Only Contain a-z, A=Z, 0-9, ., _, %, +"),
    groupMembers: yup.array().min(2, "Group Members are required").required("Group Members are required"),
    groupManagers: yup.array().min(1, "Group Managers are required").required("Group Managers are required")
});

const ADD_GROUP_SUCCESS_MSG = "Group created successfully"
const ADD_GROUP_ERROR_MSG = "Failed to create group"

const CreateGroup = ({ showCreateGroup, setShowCreateGroup }) => {
    const [fetching, setFetching] = useState(false);
    const [membersOptions, setMembersOptions] = useState([])
    const [managersOptions, setManagersOptions] = useState([])
    const [debounceTimeout, setDebounceTimeout] = useState(500);
    const [isvalidGroupName, setIsvalidGroupName] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [groupName, setGroupName] = useState("")

    const [selectedMembers, setSelectedMembers] = useState([])

    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { userInfo: adminInfo } = useSelector(state => state.userInfo)
    const { loading } = useSelector(state => state.addGroup)
    const { loading: validateLoading } = useSelector((state) => state.validateGroupName)

    useEffect(() => {
        if (selectedMembers.length > 0) {
            const prevManagers = getValues("groupManagers")
            const prevManagerOpt = selectedMembers?.filter((member) => prevManagers.includes(member.value))
            const managers = selectedMembers?.filter((member) => member?.profileType?.toLowerCase() === "admin")
            const newmanagers = [...new Set([...prevManagerOpt, ...managers])]
            setValue("groupManagers", newmanagers?.map((manager) => manager.value))
        }
    }, [selectedMembers])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            if (!value) {
                return;
            }
            setFetching(true);

            dispatch(GetAdminCaretakerForGroup(value))
                .then((data) => {
                    if (data?.details?.length !== 0) {
                        const members = data?.details?.map((member) => ({
                            label: <>
                                <span className="capitalize">{`${member?.firstName} ${member?.middleName ? member?.middleName : ""} ${member?.lastName}`}</span>
                            </>,
                            value: member.id,
                            desc: <>
                                <span className="capitalize">{`${member?.firstName} ${member?.middleName ? member?.middleName : ""} ${member?.lastName}`}</span>
                                {<span className="font-light">{` <${member?.email}>`}</span>}
                            </>,
                            profileType: member?.profileType
                        }))
                        setMembersOptions(members);
                    }
                    setFetching(false);
                }).catch((error) => {
                    console.error(error)
                });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [dispatch, debounceTimeout]);

    const validatGroupName = useMemo(() => {
        const loadOptions = (value) => {
            if (!value) {
                return;
            }

            dispatch(ValidateGroupName(value))
                .then((data) => {
                    setIsvalidGroupName(data?.details?.validate)
                    setDisabled(data?.details?.validate)
                }).catch((error) => {
                    console.error(error)
                });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [dispatch, debounceTimeout]);

    useEffect(() => {
        if (groupName !== "") {
            // added 200 ms delay utill errors are not updated
            setTimeout(() => {
                !errors?.groupName?.message && validatGroupName(groupName)
            }, 200)
        }
    }, [groupName, validatGroupName])

    const handleCancel = () => {
        setShowCreateGroup(false);
    };

    const onSubmit = async (data) => {
        // add admin id to the managers byDefault if not present
        let groupManagers = [...data?.groupManagers]
        if (!groupManagers?.includes(adminInfo?.id)) {
            groupManagers = groupManagers?.concat(adminInfo?.id)
        }
        // add admin id to the members byDefault if not present
        let groupMembers = [...data?.groupMembers]
        if (!groupMembers?.includes(adminInfo?.id)) {
            groupMembers = groupMembers?.concat(adminInfo?.id)
        }
        const formData = {
            name: data?.groupName,
            memberIds: groupMembers,
            managerIds: groupManagers
        }
        const result = await dispatch(AddGroup(formData))
        if (result?.status === 200) {
            addToast(ADD_GROUP_SUCCESS_MSG, { appearance: "success", autoDismiss: true })
            dispatch(GetGroups(adminInfo?.id))
            setShowCreateGroup(false);
        } else {
            addToast(ADD_GROUP_ERROR_MSG, { appearance: "error", autoDismiss: true })
        }
    };

    // const filterOption = (input, option) =>
    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            open={showCreateGroup}
            footer={null}
            onOk={handleCancel}
            onCancel={handleCancel}
            title={<h4 className="text-2xl">Enter Group Info</h4>}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={16} className="flex flex-col justify-center items-start">
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupName">
                                Group Name <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="groupName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Input
                                        size="large"
                                        id="groupName"
                                        placeholder="Group Name"
                                        status={errors.groupName ? "error" : undefined}
                                        onChange={(e) => {
                                            field.onChange(e.target.value?.trim());
                                            setGroupName(e.target.value?.trim())
                                        }}
                                    />
                                )}
                            />
                            {errors.groupName ? (
                                <small className="text-danger">{errors.groupName.message}</small>
                            ) : (
                                isvalidGroupName && <small className="text-danger capitalize">Group Name already exists</small>
                            )}
                        </div>
                    </Col>
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupMembers">
                                Group Members <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="groupMembers"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        className="selectRoot"
                                        style={{ width: '100%' }}
                                        size="large"
                                        id="groupMembers"
                                        placeholder="Group Members"
                                        filterOption={false}
                                        notFoundContent={fetching ? <Spin size="small" /> : null}
                                        onSearch={(value) => debounceFetcher(value)}
                                        status={errors.groupMembers ? "error" : undefined}
                                        onChange={(value, option) => {
                                            field.onChange(value);
                                            setManagersOptions(option);
                                            setSelectedMembers(option)
                                            setValue("groupManagers", getValues("groupManagers").filter((manager) => value.includes(manager)))
                                        }}
                                        options={membersOptions}
                                        optionRender={(option) => (
                                            <Space>
                                                <span>
                                                    {option.data.desc}
                                                </span>
                                            </Space>
                                        )}
                                    />
                                )}
                            />
                            {errors.groupMembers ? (
                                <small className="text-danger capitalize">{errors.groupMembers.message}</small>
                            ) : null}
                        </div>
                    </Col>
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupManagers">
                                Group Managers <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="groupManagers"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        showSearch={false}
                                        style={{ width: '100%' }}
                                        className="selectRoot"
                                        size="large"
                                        id="groupManagers"
                                        placeholder="Group Managers"
                                        filterOption={false}
                                        notFoundContent={fetching ? <Spin size="small" /> : null}
                                        status={errors.groupManagers ? "error" : undefined}
                                        value={field.value}
                                        onChange={(value, option) => {
                                            field.onChange(value)
                                        }}
                                        options={managersOptions}
                                        optionRender={(option) => (
                                            <Space>
                                                <span>
                                                    {option.data.desc}
                                                </span>
                                            </Space>
                                        )}
                                    />
                                )}
                            />
                            {errors.groupManagers ? (
                                <small className="text-danger capitalize">{errors.groupManagers.message}</small>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} className="flex justify-end items-center">
                    <Col className="mt-4">
                        <Button
                            type="primary"
                            size="large"
                            disabled={isvalidGroupName || validateLoading || disabled || groupName === ""}
                            loading={loading}
                            htmlType="submit"
                        >
                            Create Group
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    )
}

export default CreateGroup