import React, { useState, useEffect } from "react"
import "./PCMViewQuestion.css"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Loading from "../../../../layouts/Loading/Loading"
import PlanView from "@fitpeo/fit-plan-viewer"
import { useDispatch, useSelector } from "react-redux"
import {
    getPatientDiagnosticsData,
    getPatientMonitoringData
} from "../../../../../redux/actions/patientAction"
import { convertCareplanToV2 } from "../../../../../utils/careplanVersioning"
import { getLatestPcmCareplanActions } from "../../../../../redux/actions/pcmActions"

const PCMViewQuestion = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {orgId, orgName} = useParams()
    const [callRestApis, setCallRestApis] = useState(false)
    const [provider, setProvider] = useState([])
    const [practitioner, setPractitioner] = useState([])

    const location = useLocation()
    const patientId = location?.state?.id
    const patientNameObj = location?.state?.patientNameObj
    const dob = location?.state?.dob

    const { loading: pcmCareplanLoading, latestPcmCareplan } = useSelector(state => state.getLatestPcmCareplan)
    const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
    const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

    useEffect(() => {
        if (patientId !== undefined) {
            dispatch(getLatestPcmCareplanActions({ patientId }))
            setCallRestApis(true)
        } else navigate(`/${orgId}/${orgName}/pcm/patients`)
    }, [dispatch, patientId])

    useEffect(() => {
        if (latestPcmCareplan && callRestApis) {
            if (!latestPcmCareplan?.plan?.planIcds) dispatch(getPatientDiagnosticsData({ patientId }))
            if (!latestPcmCareplan?.plan?.providers) dispatch(getPatientMonitoringData({ patientId }))
            setCallRestApis(false)
        }
    }, [latestPcmCareplan])

    useEffect(() => {
        let provider = []
        let practitioner = []

        if (latestPcmCareplan?.plan?.providers) provider = latestPcmCareplan?.plan?.providers
        else if (patientMonitoringData?.ccm?.[0]) {
            provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
        }
        if (latestPcmCareplan?.plan?.practitioners) practitioner = latestPcmCareplan?.plan?.practitioners
        else if (patientMonitoringData?.ccm?.[0]) {
            practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
        }
        if (latestPcmCareplan?.plan?.provider) {
            provider = [`${latestPcmCareplan?.plan?.provider?.providerFirstName && latestPcmCareplan?.plan?.provider?.providerFirstName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerFirstName : ""}
          ${latestPcmCareplan?.plan?.provider?.providerMiddleName && latestPcmCareplan?.plan?.provider?.providerMiddleName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerMiddleName : ""} 
          ${latestPcmCareplan?.plan?.provider?.providerLastName && latestPcmCareplan?.plan?.provider?.providerLastName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerLastName : ""}`]
        }

        setProvider(provider)
        setPractitioner(practitioner)
    }, [latestPcmCareplan, patientMonitoringData])

    const planIcds =
        latestPcmCareplan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

    return pcmCareplanLoading ? (
        <Loading />
    ) : (
        (latestPcmCareplan && (
            <>
                <div className="d-flex justify-content-end">
                    <button
                        // className="btn pdf-button btn-sm mx-2"
                        className="btn btn-outline-primary btn-sm mx-2"
                        onClick={() =>
                            navigate(`/${orgId}/${orgName}/pcm-view-care-print`, {
                                state: {
                                    patientInfoDetail: { ...patientNameObj, dob },
                                    planDate: latestPcmCareplan?.planDate,
                                    provider,
                                    practitioner,
                                    plan: convertCareplanToV2(latestPcmCareplan?.plan),
                                    planIcds,
                                    addedBy: latestPcmCareplan?.plan?.addedBy,
                                    planDate: latestPcmCareplan?.planDate
                                }
                            })
                        }>
                        Print
                    </button>
                </div>
                <PlanView
                    type="pcm"
                    patientInfoDetail={{ ...patientNameObj, dob }}
                    providers={provider}
                    practitioners={practitioner}
                    plan={convertCareplanToV2(latestPcmCareplan?.plan)}
                    planIcds={planIcds}
                    addedBy={latestPcmCareplan?.plan?.addedBy}
                    planDate={latestPcmCareplan?.planDate}
                />
            </>
        )) || <h1 className="text-xl text-center">No Care Plan Found</h1>
    )
}

export default PCMViewQuestion
