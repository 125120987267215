import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"

import { Row, Col, Input, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { userLoginAction, clearUserLoginError } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import fitpeoLogo from "../../assets/fitpeo_logo.png"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import LoginWithLandline from "./LoginWithLandline"
import loginImage from "../../assets/Doctors.png"
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Mixpanel } from "../../utils/mixpanel"
import LoginAttemptsMsg from "./LoginAttemptsMsg"

const schema = yup
  .object({
    credential: yup.string().required("Enter a Valid Email or Phone Number"),
    password: yup.string().required("Enter Valid Password")
  })
  .required()



const LoginComponent = ({ userName }) => {
  const [isLoading, setisLoading] = useState(false);
  const defaultValues = {
    credential: userName || "",
    password: "",
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showLandline, setShowLandline] = useState(false)
  const [disableLogin, setDisableLogin] = useState(false)

  const { loading, error: authorizeErr } = useSelector(state => state.userInfo)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ credential, password }) => {
    setisLoading(true)
    await dispatch(userLoginAction(credential, password))
    setisLoading(false)
  }

  useEffect(() => {
    if (authorizeErr) setValue("password","")
  }, [authorizeErr])

  return (
    <>
      {showLandline ?
        <LoginWithLandline setShowLandline={setShowLandline} />
        :
        <div className="flex h-screen p-4">
          <Row className="items-center justify-center w-full">
            <Col sm={24} className="block lg:hidden">
              <img alt="loginImage" src={loginImage} className="img-fluid float-end login-sm-image" />
            </Col>

            <Col sm={24} lg={6}>
              <form className="p-4 border-2 border-current rounded" onSubmit={handleSubmit(onSubmit)}>
                <div className="text-center mb-3">
                  <img
                    alt="logo"
                    width={200}
                    src={fitpeoLogo}
                    className="img-fluid fitpeo_logo_login"
                  />
                </div>
                <LoginAttemptsMsg setDisableLogin={setDisableLogin} />
                <div className="mb-4">
                  <label className="mb-2" htmlFor="input-credential">
                    {showLandline ? 'Landline Number & Landline Extension' : 'Email or Phone Number'}
                  </label>

                  <Controller
                    name="credential"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-credential"
                        status={errors.credential ? "error" : undefined}
                        {...field}
                        disabled={true}
                      />
                    )}
                  />

                  {errors.credential ? (
                    <small className="text-danger">{errors.credential.message}</small>
                  ) : null}

                </div>
                {/* <div className="mb-4">
                  <p className="text-center text-secondary" style={{ cursor: "pointer" }}
                    onClick={() => { setShowLandline(true); dispatch(clearUserLoginError()) }}
                  >Login with landline</p>
                </div> */}

                <div className="mb-2">
                  <label className="mb-2" htmlFor="input-password">
                    Password
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input.Password
                        size="large"
                        id="input-password"
                        status={errors.password ? "error" : undefined}
                        {...field}
                        onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                        iconRender={(visible) =>
                          visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                        }
                        disabled={disableLogin}
                      />
                    )}
                  />
                  {errors.password ? (
                    <small className="text-danger">{errors.password.message}</small>
                  ) : null}
                </div>
                <div>
                  <NavLink to="/forgot-password" className="text-black float-end px-0 no-underline" onClick={() => Mixpanel.track('Forgot Password Clicked')} >
                    Forgot Password?
                  </NavLink>
                </div>
                <div className="mt-5">
                  <Button
                    block
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={Boolean(isLoading) || disableLogin}
                    onClick={() => {
                      Mixpanel.track("Login Clicked", { Email: userName })
                    }}
                  >
                    {isLoading ? "Loading..." : "Login"}
                  </Button>
                </div>

                {authorizeErr !== undefined && typeof authorizeErr === "string" && (
                  <p className="mt-4 text-danger text-center font-medium">{authorizeErr}</p>
                )}
              </form>
            </Col>
            <Col className="hidden lg:block ps-5" lg={12}>
              <img src={loginImage} alt="loginImage" className="img-fluid" />
            </Col>
          </Row>
        </div >
      }
    </>
  )
}

export default LoginComponent
