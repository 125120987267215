import React from "react"

import { Modal, Button, Input } from "antd"

const HypotensiveYesAlertModal = props => {
  const {
    showHypotensiveYesAlert,
    setShowHypotensiveYesAlert,
    HypotesniveYesAlertQus,
    setHypotensiveYesAlertQus
  } = props

  return (
    <Modal
      open={showHypotensiveYesAlert}
      onCancel={() => setShowHypotensiveYesAlert(false)}
      footer={null}
      title={<h4 className="text-xl">Note</h4>}>
      <div className="pt-4">
        <p className="fw-medium">{HypotesniveYesAlertQus?.name}</p>
        <Input.TextArea
          value={HypotesniveYesAlertQus?.text}
          onChange={e =>
            setHypotensiveYesAlertQus({ ...HypotesniveYesAlertQus, text: e.target.value })
          }
        />

        <div>
          <Button type="primary" onClick={() => setShowHypotensiveYesAlert(false)}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default HypotensiveYesAlertModal
