import moment from "moment"
import { axiosDelete, axiosGet, axiosPost } from "../axios"
import {
  ADD_SPECIAL_INSTRUCTIONS_FAIL,
  ADD_SPECIAL_INSTRUCTIONS_REQUEST,
  ADD_SPECIAL_INSTRUCTIONS_SUCCESS,
  DELETE_SPECIAL_INSTRUCTIONS_FAIL,
  DELETE_SPECIAL_INSTRUCTIONS_REQUEST,
  DELETE_SPECIAL_INSTRUCTIONS_SUCCESS,
  EDIT_SPECIAL_INSTRUCTIONS_FAIL,
  EDIT_SPECIAL_INSTRUCTIONS_REQUEST,
  EDIT_SPECIAL_INSTRUCTIONS_SUCCESS,
  GET_SPECIAL_INSTRUCTIONS_FAIL,
  GET_SPECIAL_INSTRUCTIONS_REQUEST,
  GET_SPECIAL_INSTRUCTIONS_SUCCESS,
  GET_SPECIAL_INSTRUCTIONS_HISTORY_REQUEST,
  GET_SPECIAL_INSTRUCTIONS_HISTORY_SUCCESS,
  GET_SPECIAL_INSTRUCTIONS_HISTORY_FAIL
} from "../types/specialInstructionsConstant"
import { userLogoutAction } from "./userActions"

export const GetSpecialInstruction =
  ({ patientId }) =>
  async dispatch => {
    try {
      dispatch({
        type: GET_SPECIAL_INSTRUCTIONS_REQUEST
      })

      const { data } = await axiosGet(`/api/cs/patients/specialInstructions/${patientId}/fetch`)

      dispatch({
        type: GET_SPECIAL_INSTRUCTIONS_SUCCESS,
        payload: data?.details
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: GET_SPECIAL_INSTRUCTIONS_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

export const GetSpecialInstructionHistory = (patientId) => async dispatch => {
  try {
    dispatch({
      type: GET_SPECIAL_INSTRUCTIONS_HISTORY_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/specialInstructions/history/${patientId}/fetch`)
    dispatch({
      type: GET_SPECIAL_INSTRUCTIONS_HISTORY_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_SPECIAL_INSTRUCTIONS_HISTORY_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}


export const AddSpecialInstructionAction =
  ({ specialInstructions, patientId }) =>
  async dispatch => {
    try {
      dispatch({
        type: ADD_SPECIAL_INSTRUCTIONS_REQUEST
      })

      const { data } = await axiosPost(`/api/cs2/patients/specialInstructions/${patientId}/create`, {
        specialInstructions,
        date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
      })

      dispatch({
        type: ADD_SPECIAL_INSTRUCTIONS_SUCCESS,
        payload: data
      })
      return { isDone: true }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())

      const errMsg = error?.response?.data?.details
        ? Object.values(error.response.data.details)[0]
        : "Something went wrong!"
      dispatch({
        type: ADD_SPECIAL_INSTRUCTIONS_FAIL,
        payload: errMsg
      })
      return { isDone: false, error: errMsg }
    }
  }

export const EditSpecialInstructionAction =
  ({ patientId, instructionId, specialInstructions }) =>
  async dispatch => {
    try {
      dispatch({
        type: EDIT_SPECIAL_INSTRUCTIONS_REQUEST
      })

      const { data } = await axiosPost(
        `/api/cs2/patients/${patientId}/specialInstructions/${instructionId}/update`,
        {
          specialInstructions,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
        }
      )

      dispatch({
        type: EDIT_SPECIAL_INSTRUCTIONS_SUCCESS,
        payload: data
      })
      return { isDone: true }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())

      const errMsg = error?.response?.data?.details
        ? Object.values(error.response.data.details)[0]
        : "Something went wrong!"
      dispatch({
        type: EDIT_SPECIAL_INSTRUCTIONS_FAIL,
        payload: errMsg
      })
      return { isDone: false, error: errMsg }
    }
  }

export const DeleteSpecialInstructionAction =
  ({ patientId, instructionId }) =>
  async dispatch => {
    try {
      dispatch({
        type: DELETE_SPECIAL_INSTRUCTIONS_REQUEST
      })

      const { data } = await axiosDelete(
        `/api/cs/patients/${patientId}/specialInstructions/${instructionId}/delete`
      )

      dispatch({
        type: DELETE_SPECIAL_INSTRUCTIONS_SUCCESS,
        payload: data
      })
      return { isDone: true }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())

      const errMsg = error?.response?.data?.details
        ? Object.values(error.response.data.details)[0]
        : "Something went wrong!"
      dispatch({
        type: DELETE_SPECIAL_INSTRUCTIONS_FAIL,
        payload: errMsg
      })
      return { isDone: false, error: errMsg }
    }
  }
