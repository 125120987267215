/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Radio,
  Space,
  Spin,
  Typography,
  theme
} from "antd"
import { CloseOutlined, DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { debounce } from "lodash"
import { getCaretakerListAction } from "../../../redux/actions/caretakerActions"
import { CaretakerTypes } from "./Types"

const { useToken } = theme

const CaretakerAssignedDropdown = props => {
  const {
    // caretakerAssigned,
    // setCaretakerAssigned,
    onCaretakerSelection,
    onCaretakerSelectionClear
  } = props
  const { token } = useToken()
  const { isDark } = useSelector(state => state.themeConfig)

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState()
  const [caretakerNames, setCaretakerNames] = useState([])
  const [filteredCaretakerList, setFilteredCaretakerList] = useState([])
  const [caretakerAssigned, setCaretakerAssigned] = useState()
  const [selectedCaretakers, setSelectedCaretakers] = useState([])
  const [searchedQuery, setSearchQuery] = useState("")
  // const [searchedCaretakerList, setSearchedCaretakerList] = useState(caretakerNames)
  const { loading: caretakerLoading, caretakerLists } = useSelector(state => state.caretakerList)

  const dispatch = useDispatch()
  const {orgId, orgName} = useParams()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }
  useEffect(() => {
    if (!caretakerLists?.length) dispatch(getCaretakerListAction(orgId))
  }, [])

  useEffect(() => {
    // @ts-expect-error
    if (caretakerLists) {
      // @ts-expect-error
      const care = caretakerLists?.map(item => ({
        label:
          item?.firstName + " " + (item?.middleName ? item?.middleName + " " : "") + item?.lastName  + `  <${item?.email}>`,
        value: item?.id
      }))
      setFilteredCaretakerList(care)
      setCaretakerNames(care)
    }
  }, [caretakerLoading, open])

  const handleApply = () => {
    setOpen(false)
    setCaretakerAssigned(values)
    onCaretakerSelection(selectedCaretakers, values)
  }

  const handleResetFilter = () => {
    setCaretakerAssigned("")
    setValues("")
    setSelectedCaretakers([])
    onCaretakerSelectionClear()
  }

  const onOpenChange = open => {
    if (!open) {
      setValues(caretakerAssigned)
    }
    setOpen(open)
  }

  const onSearchCaretaker = e => {
    setSearchQuery(e?.target?.value)
  }

  useEffect(() => {
    let filterData = []

    filterData = caretakerNames?.filter(caretaker =>
      // @ts-expect-error
      caretaker?.label?.toLowerCase().includes(searchedQuery?.toLowerCase())
    )
    setFilteredCaretakerList(filterData)
  }, [searchedQuery])

  const onSelectedCaretaker = checkedItem => {
    // Check if the item is already selected
    // @ts-expect-error
    const isSelected = selectedCaretakers?.some(item => item.value === checkedItem.value)

    // Update the selected caretakers array
    const updatedCaretakers = isSelected
      ? // @ts-expect-error
        selectedCaretakers?.filter(item => item.value !== checkedItem.value) // Remove the item if already selected
      : [...selectedCaretakers, checkedItem] // Append the item if not selected
    // @ts-expect-error
    setSelectedCaretakers(updatedCaretakers)
  }
  const onCaretakerOptionChange = e => {
    setValues(e.target.value)
  }
  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        dropdownRender={_ => (
          <div style={contentStyle}>
            <div className="pt-2 px-4 mr-8">
              <Radio.Group onChange={onCaretakerOptionChange} value={values}>
                <Space direction="vertical">
                  {Object.values(CaretakerTypes)?.map(val => (
                    <Radio key={val} value={val}>
                      {val}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>

            {values === CaretakerTypes.MultipleCaretaker && (
              <>
                {!caretakerLoading ? (
                  <>
                    <Input
                      key="search"
                      className="my-3 px-4"
                      style={{ width: "100%", height: 48 }}
                      onChange={debounce(onSearchCaretaker, 200)}
                      allowClear
                      // disabled={!tab2Data?.length}
                      placeholder="Search Caretaker"
                      prefix={<SearchOutlined className="disable" />}
                    />

                    <div className="px-4 mr-8 h-44 overflow-auto">
                      <Checkbox.Group
                        className="flex-col w-full capitalize"
                        // @ts-expect-error
                        value={selectedCaretakers?.map(item => item?.value)}>
                        {filteredCaretakerList
                          ?.sort((a, b) => a?.label?.localeCompare(b?.label))
                          .map(item => (
                            <Checkbox
                              // @ts-expect-error
                              key={item.value}
                              onChange={selectedValues => onSelectedCaretaker(item)}
                              // @ts-expect-error
                              value={item.value} // Use the item's value directly
                            >
                              {" "}
                              {/* @ts-expect-error */}
                              {item?.label}
                            </Checkbox>
                          ))}
                      </Checkbox.Group>
                    </div>
                  </>
                ) : (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                )}
              </>
            )}
            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Done
              </Button>
            </div>
          </div>
        )}>
        <Button
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div className="flex items-center justify-center gap-2">
            {(caretakerAssigned?.length && (
              <>
                <Typography.Link>
                  Caretaker Assigned : {caretakerAssigned}{" "}
                  {values === CaretakerTypes.MultipleCaretaker &&
                    selectedCaretakers?.length !== 0 && (
                      <span className="bg-primary text-[#fff] rounded-2xl p-[0.2rem] mx-1">
                        +{selectedCaretakers?.length}
                      </span>
                    )}
                </Typography.Link>
                <Typography.Link>
                  <DownOutlined />
                </Typography.Link>
              </>
            )) || (
              <>
                Caretaker Assigned
                <DownOutlined />
              </>
            )}
          </div>
        </Button>
      </Dropdown>
      <Space.Compact
        className={`border card ${isDark ? "dark" : "light"} `}
        onClick={handleResetFilter}
        style={{ borderWidth: 1 }}>
        <span className="secondary px-4 pt-2">
          <CloseOutlined />
        </span>
      </Space.Compact>
    </>
  )
}

export default CaretakerAssignedDropdown
