import React, { useState } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "react-bootstrap/Spinner"
import { useToasts } from "react-toast-notifications"
import { Modal, Button, Row, Col, Input, Select } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { postRpmOximeterClearTriageAction } from "../../../../redux/actions/rpmAction"
import { pushShortClearAction } from "../../../../redux/actions/utilsActions"
import { Controller, useForm } from "react-hook-form"
import { mapDeviceType } from "../../../../utils/mapDeviceType";

const schema = yup.object({
    comment: yup.string().required("Enter reason")
})

const defaultValues = {
    comment: ""
}

const falseAlert = "False Critical Alert"

const FalseAlarmModal = ({ showFalseAlertModal, setShowFalseAlertModal, selectedIds, id, triageStartedAt, setTriageStartedAt, checkedDeviceType }) => {
    const [duration, setDuration] = useState("")
    const [comment, setComment] = useState("")
    const dispatch = useDispatch()
    const { loading } = useSelector(store => store.rpmClearTriage)
    const { addToast } = useToasts()

    const { TextArea } = Input;

    const handleClose = () => {
        setDuration("")
        setComment("")
        setTriageStartedAt(null)
        setShowFalseAlertModal(false)
    }

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    })

    const onSubmit = async (data) => {
        if (data?.comment === "") {
            return addToast("Please select a reason", { appearance: "warning", autoDismiss: true })
        }
        let res = await dispatch(postRpmOximeterClearTriageAction({userId: id, selectedIds, message: falseAlert, triageStartedAt, comment: data?.comment, deviceType: mapDeviceType(checkedDeviceType)}))
        if (res?.message === "Success") {
            addToast("Saved Successfully", { appearance: "success", autoDismiss: true })
            handleClose()
            setTimeout(() => {
                window.location.reload()
            }, 1500)
        } else {
            addToast("Failed to report a false reading", { appearance: "error", autoDismiss: true })
        }

    }

    return (
        <Modal
            open={showFalseAlertModal}
            onCancel={() => handleClose()}
            footer={null}
            width={600}
            centered
        // title={<h4 className="text-xl">Snooze/Clear Triage. </h4>}
        >
            <div className="pt-4">
                <p className="text-base mb-2">You just reported a false reading. Kindly elaborate the reason.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={16}>
                        <Col className="mb-4" xs={24}>
                            <Controller
                                name="comment"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextArea
                                        rows={2}
                                        cols={9}
                                        status={errors.comment ? "error" : undefined}
                                        {...field}
                                    />
                                )}
                            />
                            {errors.comment ? (
                                <small className="text-danger capitalize">{errors.comment.message}</small>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <div className="mt-2 flex flex-wrap gap-3">
                            <Button danger onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="primary" disabled={loading} htmlType="submit">
                                {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
                            </Button>
                        </div>
                    </Row>
                </form>
            </div>
        </Modal>
    )
}

export default FalseAlarmModal
