import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Modal, Button } from "antd"
import TriageFormInput from "../TriageFormInpur"

const OxiTooLowHigh456Modal = props => {
  const { showModal, setShowModal, ques, handleQues, confirmPopup, patientInfoDetail } = props

  const navigate = useNavigate()
  const {orgId, orgName} = useParams()
  
  // console.log("ques", ques)
  return (
    <Modal
      open={showModal}
      onCancel={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
        state: {
          name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
        }
      })}
      footer={null}
      title={<h4 className="text-xl">{ques.name}</h4>}>
      <div className="pt-4">
        <TriageFormInput ques={ques} handleNormalCheck={handleQues} />

        <div className="flex justify-end gap-3 mt-4">
          <Button
            danger
            onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
              state: {
                name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
              }
            })}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={confirmPopup}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default OxiTooLowHigh456Modal
