import React, { useEffect, useState } from "react"

import { useToasts } from "react-toast-notifications"
import { Modal, Input, Row, Col, Button, Select } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  deletePatientInsuranceAction,
  getPatientInfo,
  getPatientInsurance,
  getPatientMenuConfigAction,
  postPatientInsuranceAction,
  updatePatientInsuranceAction
} from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller, set } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Loading from "../../layouts/Loading/Loading"
import { UPDATE_PATIENT_INSURANCE_RESET } from "../../../redux/types/PatientConstant"
import { POST_PATIENT_INSURANCE_RESET } from "../../../redux/types/PatientConstant"

export const validateInsurance = (e, field) => {
  const val = e.target.value
  if (/^[a-zA-Z0-9]+$/.test(val) || !val) {
    field.onChange(val)
  }
}


const schema = yup
  .object({
    insuranceNumber: yup.string().required("Invalid Insurance Number"),
    insuranceProvider: yup
      .string()
      // .matches(/^[A-Za-z1-9]*$/, "Insurance Provider should only contain alphabets or numbers")
      .required("Invalid Insurance Provider"),
    status: yup.string().required("Invalid Insurance Status"),
    insuranceType: yup.string().required("Invalid Insurance Type")
  })
  .required()

const boolToWord = bool => {
  if (bool === true) return "Yes"
  else if (bool === false) return "No"
  else return
}
const WordToBool = word => {
  if (word.toLowerCase() === "yes") return true
  else if (word.toLowerCase() === "no") return false
  else return
}

const AddUpdateInsurance = props => {
  const {
    selectedInsurance,
    insuranceAction,
    setSelectedInsurance,
    showUpdatePatientInsurance,
    setShowUpdatePatientInsurance
  } = props

  const dispatch = useDispatch()

  const { patientInfoDetail } = useSelector(state => state.patientInfo)
  const {
    patientInsurance,
    loading: insuranceLoading,
    error
  } = useSelector(state => state.patientInsurance)
  const {error: updateInsuranceError,  loading: insuranceUpdateLoading,} = useSelector(state => state.updatePatientInsurance)
  const {error: postInsuranceError,  loading: insurancePostLoading,} = useSelector(state => state.postPatientInsurance)

  const { addToast } = useToasts()
  const [isPrimaryExist, setIsPrimaryExists] = useState(false)

  const {
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      insuranceNumber: selectedInsurance?.insuranceNumber || "",
      insuranceProvider: selectedInsurance?.insuranceProvider || "",
      status: boolToWord(selectedInsurance?.status) || null,
      insuranceType: selectedInsurance?.insuranceType || ""
    }
  })

  useEffect(() => {
    if (showUpdatePatientInsurance) {
      setValue("insuranceNumber", selectedInsurance?.insuranceNumber || "")
      setValue("insuranceProvider", selectedInsurance?.insuranceProvider || "")
      setValue("status", boolToWord(selectedInsurance?.status) || null),
      setValue("insuranceType", selectedInsurance?.insuranceType || "")
    } else {
      setIsPrimaryExists(false)
    }
  }, [showUpdatePatientInsurance])

  const handleClose = () => {
    reset()
    clearErrors()
    dispatch({type: UPDATE_PATIENT_INSURANCE_RESET})
    dispatch({type: POST_PATIENT_INSURANCE_RESET})
    setShowUpdatePatientInsurance(false)
    setSelectedInsurance(false)
  }

  const onSubmit = async data => {
    let updatedNewInsurance = {
      id: selectedInsurance?.id,
      insuranceType: data?.insuranceType || selectedInsurance?.insuranceType,
      insuranceNumber: data?.insuranceNumber || selectedInsurance?.insuranceNumber,
      insuranceProvider: data?.insuranceProvider || selectedInsurance?.insuranceProvider,
      status: WordToBool(data?.status)
    }
    if (insuranceAction === "update") {
      const result = dispatch(
        updatePatientInsuranceAction(patientInfoDetail?.id, updatedNewInsurance)
      )
      result.then(res => {
        if (res?.message?.toLowerCase() === "success") {
          handleClose()
          addToast("Patient Insurance Updated Successfully", {
            appearance: "success",
            autoDismiss: true
          })
          //dispatch(getPatientInsurance(patientInfoDetail?.id))
        }
      })
    } else if (insuranceAction === "add") {
      const { id, ...postInsurance } = updatedNewInsurance
      const result = dispatch(
        postPatientInsuranceAction({ patientId: patientInfoDetail?.id, formData: [postInsurance] })
      )
      result.then(res => {
        if (res?.message?.toLowerCase() === "success") {
          handleClose()
          addToast("Patient Insurance Added Successfully", {
            appearance: "success",
            autoDismiss: true
          })
          dispatch(getPatientMenuConfigAction(patientInfoDetail?.id))
          //dispatch(getPatientInsurance(patientInfoDetail?.id))
        }
      })
    }
  }
  return (
    <Modal
      width={600}
      footer={null}
      onCancel={handleClose}
      open={showUpdatePatientInsurance}
      title={<h4 className="text-xl capitalize">{`${insuranceAction} Insurance`}</h4>}>
      {insuranceLoading ? (
        <Loading />
      ) : (
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={24}>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-insuranceNumber">
                Insurance Number <span className="text-danger">*</span>
              </label>
              <Controller
                name="insuranceNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    disabled={insuranceAction === "update"}
                    status={errors.insuranceNumber ? "error" : undefined}
                    size="large"
                    id="input-insuranceNumber"
                    {...field}
                    onChange={e => validateInsurance(e, field)}
                  />
                )}
              />
              {errors.insuranceNumber ? (
                <small className="text-danger">{errors.insuranceNumber.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-insuranceProvider">
                Insurance Provider <span className="text-danger">*</span>
              </label>
              <Controller
                name="insuranceProvider"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    status={errors.insuranceProvider ? "error" : undefined}
                    size="large"
                    className="capitalize"
                    id="input-ins"
                    {...field}
                  />
                )}
              />
              {errors.insuranceProvider ? (
                <small className="text-danger">{errors.insuranceProvider.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-status">
                Insurance Status Active <span className="text-danger">*</span>
              </label>
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="large"
                    className="w-100"
                    status={errors.status ? "error" : undefined}
                    options={[
                      {
                        value: "Yes",
                        label: "Yes"
                      },
                      {
                        value: "No",
                        label: "No"
                      }
                    ]}
                    {...field}
                  />
                )}
              />
              {errors.status ? (
                <small className="text-danger">{errors.status.message}</small>
              ) : null}
            </Col>
            <Col xs={24} md={12} className="mb-4">
              <label className="mb-2" htmlFor="input-insuranceType">
                Insurance Type <span className="text-danger">*</span>
              </label>
              <Controller
                name="insuranceType"
                control={control}
                rules={{ required: true }}
                //disabled={selectedInsurance?.insuranceType === "primary"}
                render={({ field }) => (
                  <Select
                    size="large"
                    className="w-100"
                    status={errors.insuranceType ? "error" : undefined}
                    options={[
                      {
                        value: "primary",
                        label: "Primary"
                        // disabled:
                        //   patientInsurance?.filter(
                        //     insurance => insurance?.insuranceType === "primary"
                        //   )?.length
                      },
                      {
                        value: "secondary",
                        label: "Secondary",
                        disabled: !patientInsurance?.length
                      },
                      {
                        value: "others",
                        label: "Others",
                        disabled: !patientInsurance?.length
                      }
                    ]}
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      if (
                        e === "primary" &&
                        patientInsurance?.filter(
                          insurance => insurance?.insuranceType === "primary"
                        )?.length
                      )
                        setIsPrimaryExists(true)
                      else {
                        setIsPrimaryExists(false)
                      }
                    }}
                  />
                )}
              />
              {errors.insuranceType ? (
                <small className="text-danger">{errors.insuranceType.message}</small>
              ) : null}
            </Col>
            {error || updateInsuranceError || postInsuranceError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">
                  {error || updateInsuranceError || postInsuranceError}
                </p>
              </Col>
            ) : null}
            {isPrimaryExist ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">
                  {
                    "Unable to set as primary insurance number. Please ensure only one primary insurance number is assigned per patient."
                  }
                </p>
              </Col>
            ) : null}
            <Col xs={24}>
              <div className="flex flex-wrap gap-3">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={insuranceUpdateLoading || insuranceLoading || insurancePostLoading}>
                  Submit
                </Button>
                <Button size="large" danger onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Modal>
  )
}

export default AddUpdateInsurance
