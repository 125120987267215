import React from 'react'
import { Modal } from 'react-bootstrap';
import { useLocation } from "react-router-dom"

const TriageAlert = ({ showAlert, setShowAlert, setShowHighLowAlert, setShowOximeterModel, setShowGlucometerModel }) => {
  const location = useLocation();
  const { type } = location.state

  const handleClose = () => {
    setShowAlert(false)
    // setShowGlucometerModel(true)
    if (type === 'blood pressure machine') {
      setShowHighLowAlert(true)
    } else if (type === 'glucose machine') {
      setShowGlucometerModel(true)
    } else {
      setShowOximeterModel(true);
    }
  }
  return (
    <Modal show={showAlert} onHide={handleClose} size='lg' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fw-bold'>Triage is a useful process because it can help prevent a medical emergency and it also allows us to provide real-time patient education. These triage calls are important because the patient can begin to connect their behavior and choices to the elevated readings. These triage calls also help us identify if the patient is experiencing a true medical emergency.</p>
        <div className='d-flex justify-content-end mt-3'>
          <button className='btn btn-info shadow' onClick={handleClose}>OK</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TriageAlert