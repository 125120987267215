import { Button, Checkbox, Input, Space } from "antd"
import ProTable from "@ant-design/pro-table"

import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import CaretakerAssignedDropdown from "./CaretakerAssignedDropdown"
import AddCaretakerDropdown from "./AddCaretakerDropdown"
import RemoveCaretakerDropdown from "./RemoveCaretakerDropdown"
import OnBoardingStageDropdown from "./OnboardingStageDropdown"
import OnboardingStageActionDropdown from "./OnboardingStageActionDropdown"
import { useEffect, useState } from "react"
import { CaretakerTypes, OnBoardingPendingStage, PatientStage } from "./Types"

const OnboardingPendingPatient = props => {
  const { columns, onboardPendingPatient } = props

  const { isDark } = useSelector(state => state.themeConfig)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [filteredPatients, setFilteredPatients] = useState([])
  // const [onboardPendingPatient, setOnboardPendingPatient] = useState([])
  const [selectedCaretakers, setSelectedCaretakers] = useState()
  const [caretakerAssignedType, setCaretakerAssignedType] = useState()
  const [onboardingSelectedStage, setOnboardingSelectedStage] = useState()
  const [searchQuery, setSearchQuery] = useState()

  useEffect(() => {
    let filtered = [...(onboardPendingPatient || [])]
    
    if (!filtered?.length) {
      setFilteredPatients(null)
      return
    }
    
    if (searchQuery) {
      filtered = filterPatients(filtered, searchQuery)
    }

    // Filter based on onboarding stage
    if (onboardingSelectedStage) {
      filtered = filtered?.filter(
        patient =>
          patient?.onBoardingStage?.toLowerCase() === onboardingSelectedStage?.toLowerCase()
      )
    }
    if (selectedCaretakers) {
      if (caretakerAssignedType === CaretakerTypes.NoCaretakerAssigned) {
        filtered = filtered.filter(
          patient => !patient.caretakerIds === null || !patient?.caretaker?.length
        )
      } else if (
        caretakerAssignedType === CaretakerTypes.MultipleCaretaker &&
        !selectedCaretakers?.length
      ) {
        filtered = filtered.filter(patient => patient?.caretaker?.length)
      } else if (caretakerAssignedType === CaretakerTypes.MultipleCaretaker) {
        filtered = filtered?.filter(
          patient =>
            selectedCaretakers.every(caretakerChecked =>
              patient.caretakerIds?.includes(caretakerChecked.value)
            ) && patient.caretaker?.length >= selectedCaretakers.length
        )
      }
    }
    setFilteredPatients(filtered)
  }, [
    onboardingSelectedStage,
    selectedCaretakers,
    caretakerAssignedType,
    onboardPendingPatient,
    searchQuery
  ])

  const handleSearch = e => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }
  const filterPatients = (patients, query) => {
    if (!query) return patients

    const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()

    return patients.filter(p => {
      const fullName = `${p.firstName}${p.middleName || ""}${p.lastName}`.toLowerCase()
      const reversedFullName = `${p.lastName}${p.middleName || ""}${p.firstName}`.toLowerCase()

      return (
        p.firstName?.toLowerCase().includes(query) ||
        p.middleName?.toLowerCase().includes(query) ||
        p.lastName?.toLowerCase().includes(query) ||
        p.email?.toLowerCase().includes(query) ||
        p.id?.toLowerCase().includes(query) ||
        String(p.phone)
          ?.toLowerCase()
          .includes(trimmedSearchValue?.replace(/[()-]/g, "") || "") ||
        fullName.includes(trimmedSearchValue || "") ||
        reversedFullName.includes(trimmedSearchValue || "")
      )
    })
  }

  const onCaretakerSelection = (caretakers, caretakerAssigned) => {
    setCaretakerAssignedType(caretakerAssigned)
    setSelectedCaretakers(caretakers)
  }

  const handleSelectAll = (selected) => {
    if (selected) {
      // Select all filtered patients excluding those with SignUpInitiated onBoardingStage
      const selectedKeys =
        filteredPatients
          ?.filter(
            (record) => record?.onBoardingStage !== OnBoardingPendingStage.SignUpInitiated ||  (!caretakerAssignedType || caretakerAssignedType.length === 0)
          )
          .map((record) => record.id) ?? []
      setSelectedRowKeys(selectedKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => {
      return {
        disabled: record?.onBoardingStage === OnBoardingPendingStage.SignUpInitiated && (caretakerAssignedType !== undefined && caretakerAssignedType.length !== 0)
      }
    },
    columnTitle: (
      <Checkbox
        checked={selectedRowKeys?.length === onboardPendingPatient?.length}
        indeterminate={
          selectedRowKeys?.length > 0 && selectedRowKeys.length < onboardPendingPatient?.length
        }
        onChange={e => handleSelectAll(e.target.checked)}
      />
    )
  }
  const onCaretakerSelectionClear = () => {
    setCaretakerAssignedType("")
  }

  const onBoardingStageSelectionClear = () => {
    setOnboardingSelectedStage("")
    setFilteredPatients(onboardPendingPatient)
  }

  return (
    <ProTable
      className="uploaded-patients-table"
      // @ts-expect-error
      rowKey={record => record.id}
      columnsState={{
        persistenceKey: "pro-table-onboardingpending-patient-columns",
        persistenceType: "localStorage"
      }}
      // loading={patientLoading}
      dataSource={filteredPatients === null ? onboardPendingPatient : filteredPatients}
      columns={columns}
      toolbar={{
        actions: [
          <Input
            key="search"
            style={{ width: 241, height: 48 }}
            value={searchQuery}
            onChange={e => handleSearch(e)}
            allowClear
            disabled={!onboardPendingPatient?.length}
            placeholder="Search Patient"
            prefix={<SearchOutlined className="disable" />}
          />
        ]
      }}
      tableAlertOptionRender={({ onCleanSelected }) => (
        <div className="flex max-sm:flex-col">
          <div>
            {caretakerAssignedType && (
              <AddCaretakerDropdown
                selectedCaretakers={selectedCaretakers}
                selectedRowKeys={selectedRowKeys}
                onCaretakerSelectionClear={onCaretakerSelectionClear}
                patientStage={PatientStage.Eligible}
              />
            )}
          </div>
          <div className="pl-4">
            {caretakerAssignedType === CaretakerTypes.MultipleCaretaker && (
              <RemoveCaretakerDropdown
                selectedCaretakers={selectedCaretakers}
                selectedRowKeys={selectedRowKeys}
                onCaretakerSelectionClear={onCaretakerSelectionClear}
                patientStage={PatientStage.Eligible}
              />
            )}
          </div>
          <div className="pl-4">
            {onboardingSelectedStage && (
              <OnboardingStageActionDropdown
                onboardingSelectedStage={onboardingSelectedStage}
                selectedRowKeys={selectedRowKeys}
                onBoardingStageSelectionClear={onBoardingStageSelectionClear}
              />
            )}
          </div>
          &nbsp; &nbsp;
          <Button onClick={onCleanSelected} className="mr-auto text-danger border-danger">
            Clear selection
          </Button>
        </div>
      )}
      rowSelection={
        onboardingSelectedStage?.length || caretakerAssignedType?.length ? rowSelection : false
      }
      options={{
        density: false,
        reload: false,
        fullScreen: false,
        setting: {
          children: (
            <Space.Compact
              className={`card ${
                isDark ? "dark" : "light"
              } flex md:flex-row max-md:flex-col max-md:mb-20`}
              style={{ borderWidth: 0, height: 48 }}>
              <Button
                style={{ width: 241, height: "inherit" }}
                className="flex place-items-center p-0">
                <span className="secondary text-xs px-4">Filter By</span>
                <div
                  className="border-l flex-1 flex items-center justify-center gap-2"
                  style={{
                    height: "inherit",
                    borderColor: isDark ? "#424242" : "rgba(0, 0, 0, 0.1)"
                  }}>
                  Column Visibility
                  <DownOutlined />
                </div>
              </Button>
              <Space.Compact
                style={{ height: "inherit", display: "flex" }}
                onClick={e => e.stopPropagation()}>
                <OnBoardingStageDropdown
                  setOnboardingSelectedStage={setOnboardingSelectedStage}
                  options={OnBoardingPendingStage}
                />
              </Space.Compact>
              <Space.Compact
                style={{ height: "inherit", display: "flex" }}
                onClick={e => e.stopPropagation()}>
                <CaretakerAssignedDropdown
                  // caretakerAssigned={caretakerAssigned}
                  // setCaretakerAssigned={setCaretakerAssigned}
                  onCaretakerSelection={onCaretakerSelection}
                  onCaretakerSelectionClear={onCaretakerSelectionClear}
                />
              </Space.Compact>
            </Space.Compact>
          )
        }
      }}
      search={false}
      pagination={{
        defaultPageSize: 10,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
      }}
      scroll={{ x: "max-content" }}
    />
  )
}

export default OnboardingPendingPatient
