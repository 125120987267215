import React, { useEffect, useState } from "react"
import { Card, Button } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getCaretakerAddressAction } from "../../redux/actions/caretakerActions"
import UpdateCaretakerAddress from "./UpdateCaretakerAddress"

const CaretakerAddress = ({ caretakerId }) => {
  const [showUpdateAddress, setShowUpdateAddress] = useState(false)
  const { loading, caretakerAddress } = useSelector(state => state.getCaretakerAddress)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!caretakerId) return
    dispatch(getCaretakerAddressAction(caretakerId))
  }, [caretakerId])

  return (
    <>
      <Card className="shadow-sm h-full" loading={loading}>
        <div className="flex gap-3 flex-wrap justify-between">
          <h4 className="text-2xl">Address</h4>
          {caretakerAddress && (
            <Button type="primary" onClick={() => setShowUpdateAddress(true)}>
              Update Address
            </Button>
          )}
        </div>
        {(caretakerAddress && (
          <div className="mt-4 flex gap-x-16 gap-y-5 flex-wrap ">
            <div>
              {caretakerAddress?.street ? (
                <div className="mb-2">
                  <span className="font-medium min-w-[100px]">Street : </span>
                  {caretakerAddress?.street}
                </div>
              ) : null}
              {caretakerAddress?.city ? (
                <div className="mb-2">
                  <span className="font-medium min-w-[100px]">City : </span>
                  {caretakerAddress?.city}
                </div>
              ) : null}
            </div>
            <div>
              {caretakerAddress?.state ? (
                <div className="mb-2">
                  <span className="font-medium min-w-[120px]">State : </span>
                  {caretakerAddress?.state}
                </div>
              ) : null}
              {caretakerAddress?.zipcode ? (
                <div className="mb-2">
                  <span className="font-medium min-w-[120px]">Zip Code : </span>
                  {caretakerAddress?.zipcode}
                </div>
              ) : null}
            </div>
          </div>
        )) || <h2 className="mt-4 text-lg text-center">Not Found For This Caretaker</h2>}
      </Card>
      {caretakerAddress && (
        <UpdateCaretakerAddress
          caretakerId={caretakerId}
          currentAddress={caretakerAddress}
          showUpdateAddress={showUpdateAddress}
          setShowUpdateAddress={setShowUpdateAddress}
        />
      )}
    </>
  )
}

export default CaretakerAddress
