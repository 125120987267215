import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import { Input, Card, Select, Button, Badge } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { clearFilterModalValuesAction } from "../../redux/actions/utilsActions"
import { getOnboardingPatientAction, getUserSearchFilters } from "../../redux/actions/ccmActions"

import AdvancedFilters from "./AdvancedFilters"
import { resetPatientAdvanceFilter } from "../../redux/actions/patientAction"
import { CloseOutlined } from "@ant-design/icons"

const PatientFilters = props => {
  const {
    searchQuery,
    applyFilters,
    resetFilters,
    setSearchQuery,
    sortReadingCount,
    sortLatestReading,
    filterCriticalReading,
    sortValue,
    setSortValue
  } = props

 // const [sortValue, setSortValue] = useState("title")
  const [onBoardSelect, setOnBoardSelect] = useState(false)
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const {patientAdvFilter} = useSelector(state => state.patientAdvFilter)
  const [filteredValues, setFilteredValues] = useState(patientAdvFilter)
  const filter = useSelector(state => state.filter)

  const state = useLocation()
  const dispatch = useDispatch()
  // const filter = useSelector(state => state.filter)
  const userInfo = useSelector(state => state.userInfo)
  const { getUserSearchData, loading } = useSelector(state => state.filterSearch)

  // useEffect(() => {
  //   // dispatch(getUserSearchFilters(userInfo?.userInfo?.orgId))
  // }, [dispatch, userInfo?.userInfo?.orgId])

  const handleSort = value => {
    setSortValue(value)
    if (value === "count") {
      sortReadingCount("desc")
    } else if(value === "Reading"){
      sortLatestReading("desc")
    }else if(value === "critical")
    {
      filterCriticalReading("desc")
    }
  }

  const resetCallback = () => {
    dispatch(resetPatientAdvanceFilter())
    setFilteredValues({})
    setOnBoardSelect(false)
  }

  useEffect(() => {
    if (state?.state?.mark && onBoardSelect !== true) {
      setOnBoardSelect(true)
      dispatch(clearFilterModalValuesAction())
     // dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
    }
  }, [])

  return (
    <Card>
      <div className="flex flex-wrap gap-3 justify-between">
        <Select
          size="large"
          value={sortValue}
          onChange={handleSort}
          className="min-w-[300px]"
          options={[
            {
              disabled: true,
              value: "title",
              label: "Filter Patient By"
            },
            {
              value: "count",
              label: "Reading Count"
            },
            {
              value: "Reading",
              label: "Recent Reading"
            },
            {
              value: "critical",
              label: "Critical Reading"
            }
          ]}
        />
        <Badge dot={patientAdvFilter} style={{dot:{width:'50px'}}}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            setShowAdvancedFilters(true)
          }}>
         Advanced Filters 
        </Button>
        </Badge>
        <Button
          danger
          size="large"
          onClick={() => {
            setSortValue("title")
            setFilteredValues({})
            resetFilters(resetCallback)
            dispatch(clearFilterModalValuesAction())
          }}>
          Reset Filters
        </Button>
      </div>
      <div className="flex flex-wrap gap-2">
        {!patientAdvFilter?.facilityId && patientAdvFilter?.userType === "outpatient" && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">OutPatient</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {patientAdvFilter?.facilityName && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Facility: {patientAdvFilter.facilityName}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {patientAdvFilter?.unit && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Unit: {patientAdvFilter.unit}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {patientAdvFilter?.room && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Room: {patientAdvFilter.room}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  room: undefined,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {patientAdvFilter?.bedLocation && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Bed Position: {patientAdvFilter.bedLocation?.split("_")?.[0]}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
      </div>
      {showAdvancedFilters === true && (
        <AdvancedFilters
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          onBoardSelect={onBoardSelect}
          resetCallback={resetCallback}
          facilities={getUserSearchData}
          filteredValues={filteredValues}
          setOnBoardSelect={setOnBoardSelect}
          setFilteredValues={setFilteredValues}
          showAdvancedFilters={showAdvancedFilters}
          setShowAdvancedFilters={setShowAdvancedFilters}
        />
      )}
    </Card>
  )
}

export default PatientFilters
