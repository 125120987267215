import { RESET_FILTER_USERS_REQUEST } from "../types/ccmConstant"
import {
  PROVIDER_ADD_FAIL,
  PROVIDER_ADD_REQUEST,
  PROVIDER_ADD_SUCCESS,
  PROVIDER_KPI_FAIL,
  PROVIDER_KPI_REQUEST,
  PROVIDER_KPI_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_NPI_FAIL,
  PROVIDER_NPI_REQUEST,
  PROVIDER_NPI_SUCCESS,
  PROVIDER_PATIENT_LIST_FAIL,
  PROVIDER_PATIENT_LIST_REQUEST,
  PROVIDER_PATIENT_LIST_SUCCESS,
  PROVIDER_FILTER_PATIENT_REQUEST,
  PROVIDER_FILTER_PATIENT_SUCCESS,
  PROVIDER_FILTER_PATIENT_FAIL,
  SINGLE_PROVIDER_FAIL,
  SINGLE_PROVIDER_REQUEST,
  SINGLE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_FAIL,
  UPDATE_PROVIDER_REQUEST,
  UPDATE_PROVIDER_SUCCESS,
  GET_PROVIDER_NPI_REQUEST,
  GET_PROVIDER_NPI_SUCCESS,
  GET_PROVIDER_NPI_FAIL,
  UPDATE_PROVIDER_NPI_REQUEST,
  UPDATE_PROVIDER_NPI_SUCCESS,
  UPDATE_PROVIDER_NPI_FAIL,
  GET_PROVIDER_TAXONOMY_REQUEST,
  GET_PROVIDER_TAXONOMY_SUCCESS,
  GET_PROVIDER_TAXONOMY_FAIL,
  REGISTER_PROVIDER_TAXONOMY_REQUEST,
  REGISTER_PROVIDER_TAXONOMY_SUCCESS,
  REGISTER_PROVIDER_TAXONOMY_FAIL,
  DELETE_PROVIDER_TAXONOMY_REQUEST,
  DELETE_PROVIDER_TAXONOMY_SUCCESS,
  DELETE_PROVIDER_TAXONOMY_FAIL,
  GET_PROVIDER_SPECIALIZATION_REQUEST,
  GET_PROVIDER_SPECIALIZATION_SUCCESS,
  GET_PROVIDER_SPECIALIZATION_FAIL,
  REGISTER_PROVIDER_SPECIALIZATION_REQUEST,
  REGISTER_PROVIDER_SPECIALIZATION_SUCCESS,
  REGISTER_PROVIDER_SPECIALIZATION_FAIL,
  DELETE_PROVIDER_SPECIALIZATION_REQUEST,
  DELETE_PROVIDER_SPECIALIZATION_SUCCESS,
  DELETE_PROVIDER_SPECIALIZATION_FAIL,
  UPDATE_PROVIDER_ADDRESS_REQUEST,
  UPDATE_PROVIDER_ADDRESS_SUCCESS,
  UPDATE_PROVIDER_ADDRESS_FAIL,
  GET_PROVIDER_ADDRESS_REQUEST,
  GET_PROVIDER_ADDRESS_SUCCESS,
  GET_PROVIDER_ADDRESS_FAIL


} from "../types/providerConstant"

const initialState = {
  providerList: {},
  providerPatient: {},
  providerKPI: {}
}

export const providerReducer = (state = initialState.providerList, action) => {
  switch (action.type) {
    case PROVIDER_LIST_REQUEST:
      return { loading: true }
    case PROVIDER_LIST_SUCCESS:
      return { loading: false, providerList: action.payload }
    case PROVIDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const providerKPIReducer = (state = initialState.providerKPI, action) => {
  switch (action.type) {
    case PROVIDER_KPI_REQUEST:
      return { loading: true }
    case PROVIDER_KPI_SUCCESS:
      return { loading: false, providerKPI: action.payload }
    case PROVIDER_KPI_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const providerPatientReducer = (state = initialState.providerPatient, action) => {
  switch (action.type) {
    case PROVIDER_PATIENT_LIST_REQUEST:
      return { loading: true }
    case PROVIDER_PATIENT_LIST_SUCCESS:
      return { loading: false, providerPatient: action.payload }
    case PROVIDER_PATIENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const providerFilterPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_FILTER_PATIENT_REQUEST:
      return { loading: true }
    case PROVIDER_FILTER_PATIENT_SUCCESS:
      return { loading: false, getFilterUsersData: action.payload }
    case PROVIDER_FILTER_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    case RESET_FILTER_USERS_REQUEST:
      return { loading: false }
    default:
      return state
  }
}

export const addProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_ADD_REQUEST:
      return { loading: true }
    case PROVIDER_ADD_SUCCESS:
      return { loading: false, providerAdded: action.payload }
    case PROVIDER_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const searchProviderByNPI = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_NPI_REQUEST:
      return { loading: true }
    case PROVIDER_NPI_SUCCESS:
      return { loading: false, providerNPI: action.payload }
    case PROVIDER_NPI_FAIL:
      return { loading: false, error: action.payload }
    case "PROVIDER_NPI_CLEAR":
      return {loading: false, providerNPI: undefined}
    default:
      return state
  }
}

export const getSingleProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_PROVIDER_REQUEST:
      return { loading: true }
    case SINGLE_PROVIDER_SUCCESS:
      return { loading: false, singleProvider: action.payload }
    case SINGLE_PROVIDER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROVIDER_REQUEST:
      return { loading: true }
    case UPDATE_PROVIDER_SUCCESS:
      return { loading: false, updateProvider: action.payload }
    case UPDATE_PROVIDER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getProviderNPIReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROVIDER_NPI_REQUEST:
      return { loading: true }
    case GET_PROVIDER_NPI_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_PROVIDER_NPI_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const updateProviderNPIReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROVIDER_NPI_REQUEST:
      return { loading: true }
    case UPDATE_PROVIDER_NPI_SUCCESS:
      return { loading: false, data: action.payload }
    case UPDATE_PROVIDER_NPI_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const getProviderTaxonomyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROVIDER_TAXONOMY_REQUEST:
      return { loading: true }
    case GET_PROVIDER_TAXONOMY_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_PROVIDER_TAXONOMY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const registerProviderTaxonomyReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_PROVIDER_TAXONOMY_REQUEST:
      return { loading: true }
    case REGISTER_PROVIDER_TAXONOMY_SUCCESS:
      return { loading: false, data: action.payload }
    case REGISTER_PROVIDER_TAXONOMY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const deleteProviderTaxonomyReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PROVIDER_TAXONOMY_REQUEST:
      return { loading: true }
    case DELETE_PROVIDER_TAXONOMY_SUCCESS:
      return { loading: false, data: action.payload }
    case DELETE_PROVIDER_TAXONOMY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const getProviderSpecializationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROVIDER_SPECIALIZATION_REQUEST:
      return { loading: true }
    case GET_PROVIDER_SPECIALIZATION_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_PROVIDER_SPECIALIZATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const registerProviderSpecializationReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_PROVIDER_SPECIALIZATION_REQUEST:
      return { loading: true }
    case REGISTER_PROVIDER_SPECIALIZATION_SUCCESS:
      return { loading: false, data: action.payload }
    case REGISTER_PROVIDER_SPECIALIZATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const deleteProviderSpecializationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PROVIDER_SPECIALIZATION_REQUEST:
      return { loading: true }
    case DELETE_PROVIDER_SPECIALIZATION_SUCCESS:
      return { loading: false, data: action.payload }
    case DELETE_PROVIDER_SPECIALIZATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const getProviderAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROVIDER_ADDRESS_REQUEST:
      return { loading: true }
    case GET_PROVIDER_ADDRESS_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_PROVIDER_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateProviderAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROVIDER_ADDRESS_REQUEST:
      return { loading: true }
    case UPDATE_PROVIDER_ADDRESS_SUCCESS:
      return { loading: false, data: action.payload }
    case UPDATE_PROVIDER_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const providerPatientAdvFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_PROVIDER_PATIENT_ADVANCE_FILTER":
      return { ...state, providerPatientAdvFilter: action.payload }
    case "RESET_PROVIDER_PATIENT_ADVANCE_FILTER":
      return { ...state, providerPatientAdvFilter: null } 
    default:
      return state
  }
}
