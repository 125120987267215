import {
  GET_PRACTITIONER_ADDRESS_FAIL,
  GET_PRACTITIONER_ADDRESS_REQUEST,
  GET_PRACTITIONER_ADDRESS_SUCCESS,
  GET_PRACTITIONER_PROVIDER_LIST_FAIL,
  GET_PRACTITIONER_PROVIDER_LIST_REQUEST,
  GET_PRACTITIONER_PROVIDER_LIST_SUCCESS,
  GET_PRACTITIONER_SPECIALIZATION_FAIL,
  GET_PRACTITIONER_SPECIALIZATION_REQUEST,
  GET_PRACTITIONER_SPECIALIZATION_SUCCESS,
  MAP_PRACTITIONER_TO_PROVIDER_FAIL,
  MAP_PRACTITIONER_TO_PROVIDER_REQUEST,
  MAP_PRACTITIONER_TO_PROVIDER_RESET,
  MAP_PRACTITIONER_TO_PROVIDER_SUCCESS,
  PRACTITIONER_FILTER_PATIENT_FAIL,
  PRACTITIONER_FILTER_PATIENT_REQUEST,
  PRACTITIONER_FILTER_PATIENT_RESET,
  PRACTITIONER_FILTER_PATIENT_SUCCESS,
  PRACTITIONER_LIST_FAIL,
  PRACTITIONER_LIST_REQUEST,
  PRACTITIONER_LIST_SUCCESS,
  PRACTITIONER_PATIENT_LIST_FAIL,
  PRACTITIONER_PATIENT_LIST_REQUEST,
  PRACTITIONER_PATIENT_LIST_SUCCESS,
  RESET_PRACTITIONER_PATIENT_ADVANCE_FILTER,
  SET_PRACTITIONER_PATIENT_ADVANCE_FILTER,
  SINGLE_PRACTITIONER_FAIL,
  SINGLE_PRACTITIONER_REQUEST,
  SINGLE_PRACTITIONER_SUCCESS,
  UPDATE_PRACTITIONER_ADDRESS_FAIL,
  UPDATE_PRACTITIONER_ADDRESS_REQUEST,
  UPDATE_PRACTITIONER_ADDRESS_SUCCESS,
  UPDATE_PRACTITIONER_FAIL,
  UPDATE_PRACTITIONER_REQUEST,
  UPDATE_PRACTITIONER_SUCCESS
} from "../types/practitionerConstant"

export const practitionerListReducer = (state = {}, action) => {
  switch (action.type) {
    case PRACTITIONER_LIST_REQUEST:
      return { loading: true }
    case PRACTITIONER_LIST_SUCCESS:
      return { loading: false, practitionerList: action.payload }
    case PRACTITIONER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const singlePractitionerReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_PRACTITIONER_REQUEST:
      return { loading: true }
    case SINGLE_PRACTITIONER_SUCCESS:
      return { loading: false, singlePractitioner: action.payload }
    case SINGLE_PRACTITIONER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updatePractitionerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRACTITIONER_REQUEST:
      return { loading: true }
    case UPDATE_PRACTITIONER_SUCCESS:
      return { loading: false, updatePractitioner: action.payload }
    case UPDATE_PRACTITIONER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const practitionerPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case PRACTITIONER_PATIENT_LIST_REQUEST:
      return { loading: true }
    case PRACTITIONER_PATIENT_LIST_SUCCESS:
      return { loading: false, practitionerPatient: action.payload }
    case PRACTITIONER_PATIENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getPractitionerProvidersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRACTITIONER_PROVIDER_LIST_REQUEST:
      return { loading: true }
    case GET_PRACTITIONER_PROVIDER_LIST_SUCCESS:
      return { loading: false, practitionerProviders: action.payload }
    case GET_PRACTITIONER_PROVIDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const practitionerFilterPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case PRACTITIONER_FILTER_PATIENT_REQUEST:
      return { loading: true }
    case PRACTITIONER_FILTER_PATIENT_SUCCESS:
      return { loading: false, getFilterUsersData: action.payload }
    case PRACTITIONER_FILTER_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    case PRACTITIONER_FILTER_PATIENT_RESET:
      return { loading: false }
    default:
      return state
  }
}

export const getPractitionerSpecializationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRACTITIONER_SPECIALIZATION_REQUEST:
      return { loading: true }
    case GET_PRACTITIONER_SPECIALIZATION_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_PRACTITIONER_SPECIALIZATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getPractitionerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRACTITIONER_ADDRESS_REQUEST:
      return { loading: true }
    case GET_PRACTITIONER_ADDRESS_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_PRACTITIONER_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updatePractitionerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRACTITIONER_ADDRESS_REQUEST:
      return { loading: true }
    case UPDATE_PRACTITIONER_ADDRESS_SUCCESS:
      return { loading: false, data: action.payload }
    case UPDATE_PRACTITIONER_ADDRESS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const practitionerPatientAdvFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PRACTITIONER_PATIENT_ADVANCE_FILTER:
      return { ...state, practitionerPatientAdvFilter: action.payload }
    case RESET_PRACTITIONER_PATIENT_ADVANCE_FILTER:
      return { ...state, practitionerPatientAdvFilter: null }
    default:
      return state
  }
}

export const mapPractitionerToProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case MAP_PRACTITIONER_TO_PROVIDER_RESET:
      return {}
    case MAP_PRACTITIONER_TO_PROVIDER_REQUEST:
      return { loading: true }
    case MAP_PRACTITIONER_TO_PROVIDER_SUCCESS:
      return { loading: false, data: action.payload }
    case MAP_PRACTITIONER_TO_PROVIDER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
