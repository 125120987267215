import {
  ADD_SPECIAL_INSTRUCTIONS_FAIL,
  ADD_SPECIAL_INSTRUCTIONS_REQUEST,
  ADD_SPECIAL_INSTRUCTIONS_SUCCESS,
  DELETE_SPECIAL_INSTRUCTIONS_FAIL,
  DELETE_SPECIAL_INSTRUCTIONS_REQUEST,
  DELETE_SPECIAL_INSTRUCTIONS_SUCCESS,
  EDIT_SPECIAL_INSTRUCTIONS_FAIL,
  EDIT_SPECIAL_INSTRUCTIONS_REQUEST,
  EDIT_SPECIAL_INSTRUCTIONS_SUCCESS,
  GET_SPECIAL_INSTRUCTIONS_FAIL,
  GET_SPECIAL_INSTRUCTIONS_REQUEST,
  GET_SPECIAL_INSTRUCTIONS_SUCCESS,
  GET_SPECIAL_INSTRUCTIONS_HISTORY_REQUEST,
  GET_SPECIAL_INSTRUCTIONS_HISTORY_SUCCESS,
  GET_SPECIAL_INSTRUCTIONS_HISTORY_FAIL
} from "../types/specialInstructionsConstant"

export const GetSpecialInstructionsReducer = (
  state = { loading: false, specialInstructions: [] },
  action
) => {
  switch (action.type) {
    case GET_SPECIAL_INSTRUCTIONS_REQUEST:
      return { loading: true }
    case GET_SPECIAL_INSTRUCTIONS_SUCCESS:
      return { loading: false, specialInstructions: action.payload }
    case GET_SPECIAL_INSTRUCTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const GetSpecialInstructionsHistoryReducer = (
  state = { loading: false, specialInstructionsHistory: [] },
  action
) => {
  switch (action.type) {
    case GET_SPECIAL_INSTRUCTIONS_HISTORY_REQUEST:
      return { loading: true }
    case GET_SPECIAL_INSTRUCTIONS_HISTORY_SUCCESS:
      return { loading: false, specialInstructionsHistory: action.payload }
    case GET_SPECIAL_INSTRUCTIONS_HISTORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const AddSpecialInstructionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SPECIAL_INSTRUCTIONS_REQUEST:
      return { loading: true }
    case ADD_SPECIAL_INSTRUCTIONS_SUCCESS:
      return { loading: false, addSpecialInstructions: action.payload }
    case ADD_SPECIAL_INSTRUCTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const EditSpecialInstructionsReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SPECIAL_INSTRUCTIONS_REQUEST:
      return { loading: true }
    case EDIT_SPECIAL_INSTRUCTIONS_SUCCESS:
      return { loading: false, editSpecialInstructions: action.payload }
    case EDIT_SPECIAL_INSTRUCTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const DeleteSpecialInstructionsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SPECIAL_INSTRUCTIONS_REQUEST:
      return { loading: true }
    case DELETE_SPECIAL_INSTRUCTIONS_SUCCESS:
      return { loading: false, deleteSpecialInstructions: action.payload }
    case DELETE_SPECIAL_INSTRUCTIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
