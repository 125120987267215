import axios from "axios"
import { axiosGet, axiosPost } from "../axios"
import {
  ADD_CARETAKER_FAIL,
  ADD_CARETAKER_REQUEST,
  ADD_CARETAKER_SUCCESS,
  CARETAKER_LIST_FAIL,
  CARETAKER_LIST_SUCCESS,
  CARETAKER_PATIENT_FAIL,
  CARETAKER_PATIENT_REQUEST,
  CARETAKER_PATIENT_SUCCESS,
  CARETAKER_FILTER_PATIENT_FAIL,
  CARETAKER_FILTER_PATIENT_REQUEST,
  CARETAKER_FILTER_PATIENT_SUCCESS, 
  SINGLE_CARETAKER_FAIL,
  SINGLE_CARETAKER_REQUEST,
  SINGLE_CARETAKER_SUCCESS,
  UPDATE_CARETAKER_FAIL,
  UPDATE_CARETAKER_REQUEST,
  UPDATE_CARETAKER_SUCCESS,
  GET_CARE_TYPES_FAIL,
  GET_CARE_TYPES_REQUEST,
  GET_CARE_TYPES_SUCCESS,
  UPDATE_CARETAKER_MANUAL_TIME_FAIL,
  UPDATE_CARETAKER_MANUAL_TIME_REQUEST,
  UPDATE_CARETAKER_MANUAL_TIME_SUCCESS,
  CARETAKER_CARD_FAIL,
  CARETAKER_CARD_REQUEST,
  CARETAKER_CARD_SUCCESS,
  CARETAKER_LIST_REQUEST,
  CARETAKER_ADDRESS_REQUEST,
  CARETAKER_ADDRESS_SUCCESS,
  CARETAKER_ADDRESS_FAIL,
  UPDATE_CARETAKER_ADDRESS_REQUEST,
  UPDATE_CARETAKER_ADDRESS_SUCCESS,
  UPDATE_CARETAKER_ADDRESS_FAIL
} from "../types/caretakerConstant"

import { userLogoutAction } from "./userActions"

export const getCaretakerCardAction = () => async dispatch => {
  try {
    dispatch({
      type: CARETAKER_CARD_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const { data } = await axios(`https://jsonplaceholder.typicode.com/users`, config)

    dispatch({
      type: CARETAKER_CARD_SUCCESS,
      payload: data
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_CARD_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCaretakerListAction = (orgId) => async dispatch => {
  try {
    dispatch({
      type: CARETAKER_LIST_REQUEST
    })
    if (orgId) {
      const { data } = await axiosGet(`/api/cs/caretaker/${orgId}/fetchAll`)
      dispatch({
        type: CARETAKER_LIST_SUCCESS,
        payload: data?.details
      })
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_LIST_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postCaretakerAction =
  ({
    orgId,
    firstName,
    middleName,
    lastName,
    userName,
    gender,
    dob,
    email,
    mobileNumber,
    addressDetails,
    profileTypes
  }) =>
  async dispatch => {
    try {
      dispatch({
        type: ADD_CARETAKER_REQUEST
      })
      if (orgId) {
        const { data } = await axiosPost(
          `/api/cs/caretaker/${orgId}/create`,
          {
            firstName,
            middleName: middleName || null,
            lastName,
            userName,
            gender,
            email,
            dob,
            mobileNumber,
            addressDetails,
            profileTypes,
            countryCode: "US"
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        dispatch({
          type: ADD_CARETAKER_SUCCESS,
          payload: data?.details
        })
        return data
      }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: ADD_CARETAKER_FAIL,
        payload: error?.response && Object.values(error.response?.data?.details)[0]
      })
      return error?.response?.data?.detail
    }
  }

export const updateCaretakerAction = (caretakerId, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CARETAKER_REQUEST
    })

    const { data } = await axiosPost(`/api/cs/caretaker/${caretakerId}/update`, {
      ...formData,
      middleName: formData?.middleName || null,
      countryCode: "US"
    })

    dispatch({
      type: UPDATE_CARETAKER_SUCCESS,
      payload: data?.details
    })
    dispatch(singleCaretakerAction(caretakerId))
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_CARETAKER_FAIL,
      payload: error?.response && Object.values(error.response?.data?.details)[0]
    })
    return error?.response
  }
}

export const updateCaretakerAddressAction = (caretakerId, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CARETAKER_ADDRESS_REQUEST
    })

    const { data } = await axiosPost(`/api/cs/address/caretaker/${caretakerId}/update`, formData)

    dispatch({
      type: UPDATE_CARETAKER_ADDRESS_SUCCESS,
      payload: data?.details
    })
    dispatch(getCaretakerAddressAction(caretakerId))

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())

    const errMsg = error?.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : error?.response?.data?.error

    dispatch({
      type: UPDATE_CARETAKER_ADDRESS_FAIL,
      payload: errMsg
    })
    return errMsg
  }
}

export const singleCaretakerAction = careTakerId => async dispatch => {
  try {
    dispatch({ type: SINGLE_CARETAKER_REQUEST })
    const { data } = await axiosGet(`/api/cs/caretaker/${careTakerId}/fetch`)

    dispatch({ type: SINGLE_CARETAKER_SUCCESS, payload: data?.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: SINGLE_CARETAKER_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCaretakerAddressAction = careTakerId => async dispatch => {
  try {
    dispatch({ type: CARETAKER_ADDRESS_REQUEST })
    const { data } = await axiosGet(`/api/cs/address/caretaker/${careTakerId}/fetch`)

    dispatch({ type: CARETAKER_ADDRESS_SUCCESS, payload: data?.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCaretakerFilterPatientsAction = ({caretakerId, formData}) => async dispatch => {
  try {
    dispatch({
      type: CARETAKER_FILTER_PATIENT_REQUEST
    })
    const { data } = await axiosPost(
      `/api/cs/patients/filters/caretaker/${caretakerId}/fetch`,
      formData
    )
    dispatch({
      type: CARETAKER_FILTER_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_FILTER_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const caretakerPatientAction = careTakerId => async dispatch => {
  try {
    dispatch({ type: CARETAKER_PATIENT_REQUEST })
    const { data } = await axiosGet(`/api/cs/patients/caretaker/${careTakerId}/fetchAll`)

    dispatch({ type: CARETAKER_PATIENT_SUCCESS, payload: data?.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CARETAKER_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getCareTypesAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_CARE_TYPES_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/caretypes/${patientId}/fetch`)
    dispatch({
      type: GET_CARE_TYPES_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CARE_TYPES_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const updateCaretakerManualTimeAction =
  (userId, isManualLog, isAddPatient, isUpdatePatient) => async dispatch => {
    try {
      dispatch({
        type: UPDATE_CARETAKER_MANUAL_TIME_REQUEST
      })

      const { data } = await axiosPost(`/admin/v1/updateManualLogGrant`, {
        userId,
        isManualLog,
        isAddPatient,
        isUpdatePatient
      })

      dispatch({
        type: UPDATE_CARETAKER_MANUAL_TIME_SUCCESS,
        payload: data
      })
      dispatch(singleCaretakerAction(userId))
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: UPDATE_CARETAKER_MANUAL_TIME_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
      return error.response
    }
  }

  export const saveCaretakerPatientAdvanceFilter = (data) => async dispatch =>{
    dispatch({
      type: "SET_CARETAKER_PATIENT_ADVANCE_FILTER",
      payload: data
    })
  }
  export const resetCaretakerPatientAdvanceFilter = () => async dispatch =>{
    dispatch({
      type: "RESET_CARETAKER_PATIENT_ADVANCE_FILTER",
    })
  }
