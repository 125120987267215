import React, { useState, useEffect } from "react"

import { Input, Select, Button, Card, Badge } from "antd"

import { useDispatch, useSelector } from "react-redux"

import AdvancedFilters from "./AdvancedFilters"
import {
  caretakerPatientAction,
  getCaretakerFilterPatientsAction,
  resetCaretakerPatientAdvanceFilter
} from "../../redux/actions/caretakerActions"
import { clearFilterModalValuesAction } from "../../redux/actions/utilsActions"
import { CloseOutlined } from "@ant-design/icons"
//import AdvancedFilters from "../Patient/AdvancedFilters"

export const sortBy = Object.freeze({
  AtoZ: 0,
  ZtoA: 1,
  DailyReadingCount: 2,
  LastActiveCount: 3,
  CriticalSortCount: 4
})

const CaretakerPatientFilter = ({
  caretakerId,
  searchQuery,
  applyFilters,
  resetFilters,
  setSortedData,
  setCurrentPage,
  setSearchQuery,
  setFilteredData,
  caretakerPatient,
  sortReadingCount,
  sortLatestReading,
  filterCriticalReading
}) => {
  const [sortValue, setSortValue] = useState("title")
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [onBoardSelect, setOnBoardSelect] = useState(false)
  const dispatch = useDispatch()

  const { getUserSearchData } = useSelector(state => state.filterSearch)

  // console.log('from filter',getUserSearchData, loading)
  // console.log('sorteddata', sortedData)

  const { caretakerPatientAdvFilter : patientAdvFilter} = useSelector(state => state.caretakerPatientAdvFilter)
  const [filteredValues, setFilteredValues] = useState(patientAdvFilter)

  // const applyFilters = (type, data) => {
  //   // console.log(type, data)
  //   setSearchQuery("")
  //   setCurrentPage(1)
  //   if (type === "normal") {
  //     dispatch(getCaretakerFilterPatientsAction({ caretakerId, formData: data }))
  //   } else {
  //     getOnBoardingPatients()
  //   }
  // }

  const getOnBoardingPatients = async () => {
    dispatch(caretakerPatientAction(caretakerId))
    // dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
  }

  // const resetFilters = () => {
  //   setSearchQuery("")
  //   setSortValue("title")
  //   setSortedData(caretakerPatient)
  //   if (
  //     filteredValues?.userType ||
  //     filteredValues?.unit ||
  //     filteredValues?.room ||
  //     filteredValues?.bedLocation || 
  //     filteredValues?.facilityId
  //   ) {
  //     dispatch(caretakerPatientAction(caretakerId))
  //     setFilteredValues({})
  //   }
  // }
  const resetCallback = () => {
    dispatch(resetCaretakerPatientAdvanceFilter())
    setFilteredValues({})
    setOnBoardSelect(false)
  }

  useEffect(() => {
    if ( onBoardSelect !== true) {
      //setOnBoardSelect(true)
      dispatch(clearFilterModalValuesAction())
    }
  }, [])

  const handleSort = value => {
    setSortValue(value)
    if (value === "count") {
      sortReadingCount("desc")
    } else if(value === "Reading"){
      sortLatestReading("desc")
    }else if(value === "critical")
    {
      filterCriticalReading("desc")
    }
  }

  const handlesearch = (e) => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }

  return (
    <Card>
      <div>
        <div className="flex gap-4 flex-wrap items-center justify-between">
          <div>
            <Input.Search
              allowClear
              size="large"
              value={searchQuery}
              className="max-w-[300px]"
              placeholder="Search Patient..."
              onChange={e => handlesearch(e)}
            />
          </div>
          <div className="flex flex-wrap gap-4">
            <Select
              size="large"
              value={sortValue}
              onChange={handleSort}
              className="min-w-[200px]"
              options={[
                {
                  disabled: true,
                  value: "title",
                  label: "Filter Patient By"
                },
                {
                  value: "count",
                  label: "Reading Count"
                },
                {
                  value: "Reading",
                  label: "Recent Reading"
                },
                {
                  value: "critical",
                  label: "Critical Reading"
                }
              ]}
            />
            <Badge dot={patientAdvFilter} style={{dot:{width:'50px'}}}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setShowAdvancedFilters(true)
              }}>
              Advanced Filters
            </Button>
            </Badge>
            <Button
              danger
              size="large"
              onClick={() => {
                setSortValue("title")
                setFilteredValues({})
                resetFilters(resetCallback)
                dispatch(clearFilterModalValuesAction())
              }}>
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        {!patientAdvFilter?.facilityId && patientAdvFilter?.userType === "outpatient" && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">OutPatient</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {patientAdvFilter?.facilityName && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Facility: {patientAdvFilter.facilityName}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {patientAdvFilter?.unit && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Unit: {patientAdvFilter.unit}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {patientAdvFilter?.room && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Room: {patientAdvFilter.room}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  room: undefined,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {patientAdvFilter?.bedLocation && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Bed Position: {patientAdvFilter.bedLocation?.split("_")?.[0]}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
      </div>
      <AdvancedFilters
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        facilities={getUserSearchData}
        filteredValues={filteredValues}
        resetCallback={resetCallback}
        setFilteredValues={setFilteredValues}
        showAdvancedFilters={showAdvancedFilters}
        setShowAdvancedFilters={setShowAdvancedFilters}
        onBoardSelect={onBoardSelect}
        setOnBoardSelect={setOnBoardSelect}
      />
    </Card>
  )
}

export default CaretakerPatientFilter
