import React, { useEffect, useState } from "react"

import Loading from "../layouts/Loading/Loading"
import { useDispatch, useSelector } from "react-redux"
import { getpcmDataActions } from "../../redux/actions/pcmActions"
import { Button, Table, Input, Tooltip, Card, Radio, Select } from "antd"
import {
    EyeOutlined,
    EditOutlined,
    LinkOutlined,
    PlusOutlined,
    HistoryOutlined
} from "@ant-design/icons"
import moment from "moment"
import { Link, useNavigate, useParams } from "react-router-dom"
import { getrpmDeviceActions as getMonitorTypes } from "../../redux/actions/rpmAction"
import { getAdvancedFiltersOptions } from "../../redux/actions/patientAction"
import { Mixpanel } from "../../utils/mixpanel"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const { Search } = Input

const isInCurrentMonth = date => moment(date).isSame(new Date(), "month")

const prevMonthStart = moment().subtract(1, "month").startOf("month")
const prevMonthEnd = moment().subtract(1, "month").endOf("month")
const isInPrevMonth = date => moment(date).isBetween(prevMonthStart, prevMonthEnd)

const FACICLITY = "facility"
const BOTH = "both"
const NEW_PLAN = "new-plan"
const ADDED_PLAN = "added-plan"
const FOLLOW_UP = "follow-up"

const getManupulatedData = data => {
    return data?.map(
        (
            {
                firstName,
                middleName,
                lastName,
                id,
                pcmActiveDate,
                email,
                userType,
                facilityId,
                providerFirstName,
                providerLastName,
                providerMiddleName,
                practitionerFirstName,
                practitionerrMiddleName,
                practitionerLastName,
                dob,
                createdByFirstName,
                createdByMiddleName,
                createdByLastName
            },
            index
        ) => ({
            key: index + 1,
            sNo: index + 1,
            id,
            pcmActiveDate,
            email,
            userType,
            facilityId,
            fullName: middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`,
            patientNameObj: { firstName, middleName, lastName },
            dob: dob,
            provider: providerFirstName && (providerFirstName !== "") ? [`${providerFirstName}${providerMiddleName && providerMiddleName !== "" ? " " + providerMiddleName : ""} ${providerLastName} `] : [],
            practitioner: practitionerFirstName && (practitionerFirstName !== "") ? [`${practitionerFirstName}${practitionerrMiddleName && practitionerrMiddleName !== "" ? " " + practitionerrMiddleName : ""} ${practitionerLastName} `] : [],
            isSameMonthPlanCreated: isInCurrentMonth(pcmActiveDate),
            createdBy : createdByFirstName && (createdByFirstName !== "") ? `${createdByFirstName}${createdByMiddleName && createdByMiddleName !== "" ? " " + createdByMiddleName : ""} ${createdByLastName} ` : "-",
        })
    )
}

const PCMIsPatientDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {orgId, orgName} = useParams()

    const [pcmMonitorId, setPcmMonitorId] = useState(null)
    const { userInfo } = useSelector(state => state.userInfo)
    const { loading: pcmLoading, getPcm } = useSelector(state => state.pcmList)
    const { loading: monitorLoading } = useSelector(state => state.rpmDevices)
    const { advancedFiltersOptions, loading } = useSelector(state => state.advancedFiltersOptions)
    const [pcmData, setPcmData] = useState([])
    // const [searchedData, setSearchedData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedFacility, setSelectedFacility] = useState("both")
    const [selectedPlan, setSelectedPlan] = useState("all-plan")
    const [facilityFilteredValues, setFacilityFilteredValues] = useState()
    let monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))
    const facilityOptions = advancedFiltersOptions?.filter(i => i.userType === "facility") || []

    const checkMonitorType = async () => {
        if (!monitorTypes?.length) {
            await dispatch(getMonitorTypes())
            monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))
        }
        const data = monitorTypes.find(value => value.name === "pcm")
        setPcmMonitorId(data?.programs[0]?.id)
    }

    useEffect(() => {
        checkMonitorType()
    }, [monitorTypes])

    useEffect(() => {
        if (!userInfo || !pcmMonitorId) return
        dispatch(getpcmDataActions({ orgId: orgId, pcmMonitorId }))
    }, [pcmMonitorId, dispatch, userInfo])

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sNo",
            key: "sNo"
        },
        {
            title: "Full Name",
            dataIndex: "fullName",
            key: "fullName",
            render: (fullName, { id, facilityId }) => (
                <div
                    className="cursor-pointer hover:text-current"
                    onClick={() => {
                        navigate(`/${orgId}/${orgName}/patient/${id}`, { state: { facilityId } })
                    }}>
                    <p className="text-capitalize wrap-text mb-1">{fullName}</p>
                </div>
            ),
            sorter: (a, b) => a?.fullName?.localeCompare(b?.fullName)
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a?.email?.localeCompare(b?.email)
        },
        {
            title: "User Type",
            dataIndex: "userType",
            key: "userType",
            render: userType => <p className="capitalize">{userType}</p>
        },
        {
            title: "Plan Active date",
            dataIndex: "pcmActiveDate",
            key: "pcmActiveDate",
            render: pcmActiveDate => (
                <p className="capitalize">
                    {pcmActiveDate ? `${new Date(pcmActiveDate).toDateString()}` : "N/A"}
                </p>
            ),
            sorter: (a, b) => a?.pcmActiveDate?.localeCompare(b?.pcmActiveDate)
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            render: createdBy => <p className="capitalize">{createdBy}</p>,
            sorter: (a, b) => a?.createdBy?.localeCompare(b?.createdBy)
        },
        {
            title: "Action",
            render: ({ id, pcmActiveDate, isSameMonthPlanCreated, provider, practitioner, patientNameObj, dob }) => (
                <div className="flex gap-3 items-center">
                    {!isSameMonthPlanCreated && (
                        <Tooltip title="Create">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={() =>
                                    navigate(`/${orgId}/${orgName}/pcm-questions`, {
                                        state: { patientNameObj, id, provider, practitioner, pcmMonitorId, dob }
                                    })
                                }
                            />
                        </Tooltip>
                    )}
                    {pcmActiveDate && (
                        <>
                            <Tooltip title="View">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<EyeOutlined />}
                                    onClick={() => {
                                        Mixpanel.track("Principal Careplan Clicked", { Id: id })
                                        navigate(`/${orgId}/${orgName}/pcm-view-care`, {
                                            state: { patientNameObj, id, dob }
                                        })
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Plan History">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<HistoryOutlined />}
                                    onClick={() => navigate(`/${orgId}/${orgName}/pcm-plan-history/${id}`)}
                                />
                            </Tooltip>
                            {isInPrevMonth(pcmActiveDate) && (
                                <Tooltip title="Follow Up">
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<LinkOutlined />}
                                        onClick={() => navigate(`/${orgId}/${orgName}/pcm-follow-up/${id}`)}
                                    />
                                </Tooltip>
                            )}
                            {isSameMonthPlanCreated && (
                                <Tooltip title="Edit">
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        disabled={!pcmActiveDate}
                                        icon={<EditOutlined />}
                                        onClick={() => navigate(`/${orgId}/${orgName}/pcm-edit-care/${id}`)}
                                    />
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            )
        }
    ]

    useEffect(() => {
        if (!getPcm?.length) return
        const sortedData = getPcm?.sort((a, b) => b.pcmActiveDate.localeCompare(a.pcmActiveDate))
        setPcmData(getManupulatedData(sortedData))
    }, [getPcm])

    const handleSearch = (e) => {
        const query = e?.target?.value
        let queryWithoutSpace = query?.replace(/^\s+/g, "")
        queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
        setSearchTerm(queryWithoutSpace)
    }

    const onFacilityChange = ({ target: { value } }) => {
        setSelectedFacility(value)
        setSearchTerm("")
    }

    const onPlanChange = ({ target: { value } }) => {
        setSelectedPlan(value)
        setSearchTerm("")
    }

    useEffect(() => {
        if (selectedFacility === FACICLITY && !advancedFiltersOptions?.length)
            dispatch(getAdvancedFiltersOptions(orgId))
    }, [selectedFacility])

    useEffect(() => {
        let filterData = getPcm

        if (searchTerm) {
            const trimmedSearchValue = searchTerm.replace(/\s+/g, "").toLowerCase()
            filterData = filterData?.filter(
                item =>
                    (item?.middleName
                        ? `${item?.firstName}${item?.middleName}${item?.lastName}`
                        : `${item?.firstName}${item?.lastName}`
                    )
                        ?.toLowerCase()
                        ?.includes(trimmedSearchValue) ||
                    item?.email?.toLowerCase().includes(trimmedSearchValue)
            )
            if (!filterData?.length) {
                return setPcmData([])
            }
        }

        if (selectedFacility !== BOTH) {
            if (selectedFacility === FACICLITY && facilityFilteredValues) {
                filterData = filterData?.filter(
                    ({ userType, facilityId }) =>
                        userType === selectedFacility && facilityFilteredValues === facilityId
                )
            } else filterData = filterData?.filter(({ userType }) => userType === selectedFacility)
        }

        if (selectedPlan === NEW_PLAN) {
            filterData = filterData?.filter(
                ({ pcmActiveDate }) =>
                    ["", undefined, null].includes(pcmActiveDate) || !isInCurrentMonth(pcmActiveDate)
            )
        } else if (selectedPlan === ADDED_PLAN) {
            filterData = filterData?.filter(
                ({ pcmActiveDate }) =>
                    !["", undefined, null].includes(pcmActiveDate) && isInCurrentMonth(pcmActiveDate)
            )
        } else if (selectedPlan === FOLLOW_UP) {
            filterData = filterData?.filter(
                ({ pcmActiveDate }) =>
                    !["", undefined, null].includes(pcmActiveDate) && isInPrevMonth(pcmActiveDate)
            )
        }

        setPcmData(getManupulatedData(filterData))
    }, [searchTerm, selectedFacility, selectedPlan, facilityFilteredValues])

    return pcmLoading || monitorLoading ? (
        <div className="loaderLoading">
            <Loading />
        </div>
    ) : (
        <>
            <OrgBackNavigation />
            <Card className="mt-2" title={<h4 className="text-xl">Filters</h4>}>
                <div className="flex justify-between items-center gap-3 flex-wrap">
                    <Search
                        allowClear
                        size="large"
                        placeholder="Search"
                        className="max-w-[300px]"
                        // onSearch={handleSearch}
                        value={searchTerm}
                        onChange={e => handleSearch(e)}
                    />
                    <Radio.Group
                        optionType="button"
                        onChange={onPlanChange}
                        value={selectedPlan}
                        options={[
                            {
                                label: "All Plans",
                                value: "all-plan"
                            },
                            {
                                label: "New Plan (Pending)",
                                value: "new-plan"
                            },
                            {
                                label: "Added Plan (Done)",
                                value: "added-plan"
                            },
                            {
                                label: "Follow Up (Inactive)",
                                value: "follow-up"
                            }
                        ]}
                    />
                    <Radio.Group
                        optionType="button"
                        value={selectedFacility}
                        onChange={onFacilityChange}
                        options={[
                            {
                                label: "Both",
                                value: "both"
                            },
                            {
                                label: "Facility",
                                value: "facility"
                            },
                            {
                                label: "Outpatient",
                                value: "outpatient"
                            }
                        ]}
                    />
                    {selectedFacility === "facility" && (
                        <Select
                            className="md:ml-auto w-56"
                            placeholder="Select Facility"
                            options={facilityOptions
                                ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort the options alphabetically by name
                                ?.map(address => ({
                                    value: address.facilityId,
                                    label: (
                                        <Tooltip title={<span className="capitalize">{address.name}</span>}>
                                            <span className="capitalize">{address.name}</span>
                                        </Tooltip>
                                    )
                                }))}
                            value={facilityFilteredValues || null}
                            allowClear
                            onChange={val => {
                                setFacilityFilteredValues(val)
                            }}
                        />
                    )}
                </div>
            </Card>
            <Card className="rounded-b-none mt-4">
                <h3 className="text-xl">Patients Principal Careplan Details</h3>
            </Card>
            <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={pcmData}
            />
        </>
    )
}

export default PCMIsPatientDetails
