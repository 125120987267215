import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Switch, Card, Button } from "antd"
import { useToasts } from "react-toast-notifications"

import { useSelector, useDispatch } from "react-redux"
import { getPatientInfo, criticalStatusAction } from "../../redux/actions/patientAction"

import CriticalDeviceSelctor from "./CriticalDeviceSelctor"
import AddConcern from "../PatientDetails/PatientModal/AddConcern"
import ViewConcern from "../PatientDetails/PatientModal/ViewConcern"
import ManualTimeList from "../PatientDetails/PatientModal/ManualTimeList"
import AddManualTime from "../PatientDetails/PatientModal/AddManualTime"
import UpdateManualTime from "../PatientDetails/PatientModal/UpdateManualTime"

import "./patientMoreDetails.css"
import SpecialInstructions from "./SpecialInstructions"
import { GetSpecialInstruction } from "../../redux/actions/specialInstructionsActions"

const EDIT_SPL_INS = "Edit Special Instructions"
const ADD_SPL_INS = "Add Special Instructions"

const PatientMoreDetail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { id , orgId, orgName} = useParams()

  let patientInfo = location?.state?.patientInfo
  const addCChighlight = location?.state?.addCChighlight
  const patientLoad = location?.state?.patientLoad
  const addMtHighlight = location?.state?.addMThighlight
  const programType = location?.state?.programType
  const monitoringData = location?.state?.monitoringData
  // console.log(addCChighlight)
  const { patientInfoDetail, loading: patientLoading } = useSelector(store => store.patientInfo)

  const { specialInstructions, loading: specialInstLoading } = useSelector(store => store.getSpecialInstructions)

  useEffect(() => {
    if (!id) return
    if(specialInstructions?.length===0) dispatch(GetSpecialInstruction({ patientId: id }))
  }, [id])

  useEffect(() => {
    // if (patientInfo === undefined || patientInfo === null) {
    //   if (patientLoad !== undefined || patientLoad !== null) {
    //     dispatch(getPatientInfo({ patientId: id }))
    //   } else {
    //     window.location.href = "/patient"
    //   }
    // }
    if (!patientInfo)
      dispatch(getPatientInfo({ patientId: id }))
  }, [patientInfo, dispatch, id, patientLoad])

  useEffect(() => {
    if (patientLoad !== undefined || patientLoad !== null) {
      //patientInfo = patientInfoDetail //eslint-disable-line
    } else {
      window.location.href = `/${orgId}/${orgName}/patient`
    }
  }, [patientInfo, patientLoading])

  // console.log(patientInfo)
  const [input, setInput] = useState(
    patientInfo?.criticalStatus === null ? false : patientInfo?.criticalStatus?.status
  )

  // const [criticalStatus, setCriticalStatus] = useState(patientInfo?.criticalStatus !== null && patientInfo?.criticalStatus?.status);

  // moodal states
  const [showAddManualTime, setShowAddManualTime] = useState(false)
  const [addManualApiCalls, setAddManualApiCalls] = useState(false)
  const [showAddManualTimeList, setShowAddManualTimeList] = useState(false)
  const [manualListApiCalls, setManualListApiCalls] = useState(false)
  const [showChronicCondition, setShowChronicCondition] = useState(false)
  const [showAddChronicCondition, setShowAddChronicCondition] = useState(false)
  const [showAddConcern, setShowAddConcern] = useState(false)
  const [showConcern, setShowConcern] = useState(false)
  const [concernApiCalls, setConcernApiCalls] = useState(false)
  const [addCCApiCalls, setAddCCApiCalls] = useState(false)
  const [showSplInstruction, setShowSplInstruction] = useState(false)

  const [selectedLog, setSelectedLog] = useState(null)
  const [showUpdateManualTime, setShowUpdateManualTime] = useState(false)

  // for critical device selection
  const [showCritialDeviceModal, setShowCriticalDeviceModal] = useState(false)

  //
  const [ccHighlight, setCcHighlight] = useState(addCChighlight !== undefined)
  const [mtHighlight, setMtHighlight] = useState(addMtHighlight !== undefined)

  // modal close/open handlers

  const handleSplInstructionShow = () => {
    if(!specialInstLoading) setShowSplInstruction(true)
  }
  const handleSplInstructionClose = () => setShowSplInstruction(false)

  const handleAddManualListClose = () => {
    setMtHighlight(false)
    setShowAddManualTimeList(false)
  }
  const handleAddManualListShow = () => {
    setMtHighlight(false)
    setShowAddManualTimeList(true)
    setManualListApiCalls(true)
  }

  const handleAddManualTimeClose = () => setShowAddManualTime(false)

  const handleAddManualTimeShow = () => {
    setMtHighlight(false)
    setShowAddManualTime(true)
    setAddManualApiCalls(true)
  }

  const handleChronicConditionClose = () => setShowChronicCondition(false)
  const handleChronicConditionShow = () => {
    setShowChronicCondition(true)
  }

  const handleAddChronicConditionClose = () => {
    setShowAddChronicCondition(false)
    setCcHighlight(false)
  }

  const handleAddChronicConditionShow = () => {
    setCcHighlight(false)
    setShowAddChronicCondition(true)
    setAddCCApiCalls(true)
  }

  const handleAddConcernShow = () => {
    setShowAddConcern(true)
  }

  const handleViewConcern = () => {
    setShowConcern(true)
    setConcernApiCalls(true)
  }

  // console.log('input',input)

  // critical status
  const handlePatientCriticalStatus = async (device, v1) => {
    // e.preventDefault();
    const result = await dispatch(criticalStatusAction(input, id, device, v1 && v1))
    if (result?.success) {
      addToast("Critical status of patient updated successfully.", {
        appearance: "success",
        autoDismiss: true
      })
      navigate(`/${orgId}/${orgName}/patient/${id}`)
      // dispatch(getPatientInfo({patientId: patientDetails?.userId}));
    } else {
      addToast("Unable to update critical status of patient.", {
        appearance: "error",
        autoDismiss: true
      })
    }
    // setShowAllAction(false);
  }

  return (
    <div id="content">
      <div className="main-container">
        <Card>
          <div className="flex justify-between items-between gap-4">
            <h4 className="capitalize text-xl">
              {patientInfo?.firstName} {patientInfo?.middleName} {patientInfo?.lastName}
            </h4>
            <div>
              <Button danger onClick={() => navigate(`/${orgId}/${orgName}/patient/${id}`, { state:location?.state})}>
                Back to profile
              </Button>
            </div>
          </div>
        </Card>

        <div className="flex justify-content-center mt-4 flex-wrap gap-4">
          <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={handleAddManualListShow}>
            <h6>Show Manual Time</h6>
          </div>
          <div
            className={`border-2 border-current text-center more-details-card mb-4 ${mtHighlight && "cc-highlight"
              }`}
            onClick={handleAddManualTimeShow}>
            <h6>Add Manual Time</h6>
          </div>
          {/* <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={handleChronicConditionShow}>
            <h6>View Added Diagnostic Codes</h6>
          </div>
          <div
            className={`border-2 border-current text-center more-details-card mb-4 ${
              ccHighlight && "cc-highlight"
            }`}
            onClick={handleAddChronicConditionShow}>
            <h6>Add Chronic Condition/Diagnostic Codes</h6>
          </div> 
          <div className="border-2 border-current text-center more-details-card mb-4">
            <Switch
              checked={input}
              checkedChildren="Critical"
              unCheckedChildren={<span className="text-black">Not Critical</span>}
              onChange={() => {
                setInput(!input)
                !input && setShowCriticalDeviceModal(true)
              }}
            />
            <h6 className="my-3">Critical Status</h6>
            <Button
              type="primary"
              disabled={input}
              onClick={() => handlePatientCriticalStatus("dummy", true)}>
              Save
            </Button>
          </div>
          */}
          <CriticalDeviceSelctor
            patientInfo={patientInfo}
            show={showCritialDeviceModal}
            setShow={setShowCriticalDeviceModal}
            handlePatientCriticalStatus={handlePatientCriticalStatus}
            setInput={setInput}
          />
          <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={() => navigate(`/${orgId}/${orgName}/patient-plans/${id}`,  {state:{patientInfo, monitoringData}})}>
            <h6>View Careplan &amp; Treatment Plan</h6>
          </div>
          <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={() => navigate(`/${orgId}/${orgName}/rpm-triage-view/${id}`, { state: { patientInfo } })}>
            <h6>View Triage Report</h6>
          </div>
          <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={() => navigate(`/${orgId}/${orgName}/internal-notes/${id}`)}>
            <h6>Internal Notes</h6>
          </div>
          <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={handleSplInstructionShow}>
            <h6>
              {specialInstLoading ? "Loading..." : specialInstructions ? EDIT_SPL_INS : ADD_SPL_INS}
            </h6>
          </div>
          {/* <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={handleAddConcernShow}>
            <h6>Add Concern</h6>
          </div>
          <div
            className="border-2 border-current text-center more-details-card mb-4"
            onClick={handleViewConcern}>
            <h6>View Concern</h6>
          </div> */}
        </div>
      </div>
      
      <SpecialInstructions
        patientId={id}
        specialInstData={specialInstructions}
        open={showSplInstruction}
        handleClose={handleSplInstructionClose}
      />

      <ManualTimeList
        setSelectedLog={setSelectedLog}
        manualListApiCalls={manualListApiCalls}
        showAddManualTimeList={showAddManualTimeList}
        setShowUpdateManualTime={setShowUpdateManualTime}
        handleAddManualListClose={handleAddManualListClose}
      />
      <AddManualTime
        showAddManualTime={showAddManualTime}
        addManualApiCalls={addManualApiCalls}
        handleAddManualTimeClose={handleAddManualTimeClose}
        programType={programType}
      />
      {/* <ChronicCondition
        handleChronicConditionClose={handleChronicConditionClose}
        showChronicCondition={showChronicCondition}
        patientInfo={patientInfo}
      />
      <AddChronicCondition
        setShowAddChronicCondition={setShowAddChronicCondition}
        showAddChronicCondition={showAddChronicCondition}
        handleAddChronicConditionClose={handleAddChronicConditionClose}
        addCCApiCalls={addCCApiCalls}
      /> 
      <AddConcern showAddConcern={showAddConcern} setShowAddConcern={setShowAddConcern} />
      <ViewConcern
        showConcern={showConcern}
        setShowConcern={setShowConcern}
        concernApiCalls={concernApiCalls}
        setConcernApiCalls={setConcernApiCalls}
      /> */}
      <UpdateManualTime
        selectedLog={selectedLog}
        setSelectedLog={setSelectedLog}
        showUpdateManualTime={showUpdateManualTime}
        setShowUpdateManualTime={setShowUpdateManualTime}
      />
    </div>
  )
}

export default PatientMoreDetail
