export const GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST = "GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST";
export const GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS = "GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS";
export const GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL = "GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL";

export const GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST = "GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST";
export const GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS = "GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS";
export const GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL = "GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL";

export const UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST = "UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST";
export const UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS = "UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS";
export const UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL = "UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL";

export const REMOVE_BULK_CARETAKER_FOR_PATIENT_REQUEST = "REMOVE_BULK_CARETAKER_FOR_PATIENT_REQUEST";
export const REMOVE_BULK_CARETAKER_FOR_PATIENT_SUCCESS = "REMOVE_BULK_CARETAKER_FOR_PATIENT_SUCCESS";
export const REMOVE_BULK_CARETAKER_FOR_PATIENT_FAIL = "REMOVE_BULK_CARETAKER_FOR_PATIENT_FAIL";

export const UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST = "UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST";
export const UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS = "UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS";
export const UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL = "UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL";

export const REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST = "REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST";
export const REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS = "REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS";
export const REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL = "REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL";

export const POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST = "POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST";
export const POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS = "POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS";
export const POST_BULK_MAIL_MESSAGE_PATIENT_FAIL = "POST_BULK_MAIL_MESSAGE_PATIENT_FAIL";

export const EDIT_ELIGIBLE_PATIENT_REQUEST = "EDIT_ELIGIBLE_PATIENT_REQUEST"
export const EDIT_ELIGIBLE_PATIENT_SUCCESS = "EDIT_ELIGIBLE_PATIENT_SUCCESS"
export const EDIT_ELIGIBLE_PATIENT_FAIL = "EDIT_ELIGIBLE_PATIENT_FAIL"
export const EDIT_ELIGIBLE_PATIENT_RESET = "EDIT_ELIGIBLE_PATIENT_RESET"

export const POST_REGISTER_OTP_REQUEST = "POST_REGISTER_OTP_REQUEST"
export const POST_REGISTER_OTP_SUCCESS = "POST_REGISTER_OTP_SUCCESS"
export const POST_REGISTER_OTP_FAIL = "POST_REGISTER_OTP_FAIL"
export const POST_REGISTER_OTP_RESET = "POST_REGISTER_OTP_RESET"
