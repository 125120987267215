import React, { useEffect, useState } from "react"
import PatientPlanHeader from "../../PatientDetails/PatientPlanHeader"
import { useSelector, useDispatch } from "react-redux"
import Loading from "../../layouts/Loading/Loading"
import { getPatientInfo, getTriageFalseReport } from "../../../redux/actions/patientAction"
import { useLocation, useParams } from "react-router-dom"
import TriageAlert from "./TriageAlert"
// import { getBpDetails } from '../../../redux/actions/patientAction'
import TriagePatientQuesView from "./TriagePatientQuesView"
import BpHighLowModal from "./BpHighLowModal"
import TriagePatientLowBpQuesView from "./TriagePatientLowBpQuesView"
import MonitorTypeModal from "./MonitorTypeModal"
import TriageOximeterQuesView from "./TriageOximeterQuesView"
import TriageWeightScaleQuesView from "./TriageWeightScaleQuesView"
import HandleApiFail from "../../layouts/APIFail/ApiFail"
import TriageGlucometerQuesView from "./TriageGlucometerQuesView"

const TriagePatient = () => {
  const { id } = useParams()
  const [showAlert, setShowAlert] = useState(false)
  const [isHighBp, setIsHighBp] = useState("")
  const [showHighLowAlert, setShowHighLowAlert] = useState(false)
  const [showOximeterModel, setShowOximeterModel] = useState(false)
  const [showGlucometerModel, setShowGlucometerModel] = useState(false)

  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector(state => state.userInfo)

  const { type, readingIds } = location.state
  const triageStartedAt = location.state?.triageStartedAt

  useEffect(() => {
    dispatch(getTriageFalseReport(id));
  }, [])

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getPatientInfo({ patientId: id }))
      setShowAlert(true)
    }
  }, [dispatch])

  // selectors
  const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  // const { patientCriticalDevices: criticalDevices, loading: criticalDevicesLoading } = useSelector(state => state.patientCriticalDevices)

  return (
    <div className="">
      <div id="content">
        <div className="main-container">
          {patientLoading ? (
            <Loading minHeight="80vh" />
          ) : !patientInfoDetail ? (
            <HandleApiFail
              message={"Unable To Fetch Patient Details"}
              route={"/rpm-critical-time-passed"}
            />
          ) : (
            <>
              <TriageAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                setShowHighLowAlert={setShowHighLowAlert}
                setShowOximeterModel={setShowOximeterModel}
                setShowGlucometerModel={setShowGlucometerModel}
              />
              {/* <MonitorTypeModal
                showDeviceSelect={showDeviceSelect}
                setShowDeviceSelect={setShowDeviceSelect}
                setShowHighLowAlert={setShowHighLowAlert}
                setDevice={setDevice}
                // setReadingId={setReadingId}
                type={type}
              /> */}
              <BpHighLowModal
                setIsHighBp={setIsHighBp}
                showHighLowAlert={showHighLowAlert}
                setShowHighLowAlert={setShowHighLowAlert}
                patientInfoDetail={patientInfoDetail}
              />
              <PatientPlanHeader title="Patient Details" patientInfoDetail={patientInfoDetail} id={id} />
              {!showHighLowAlert && isHighBp === "high" && <TriagePatientQuesView readingDetails={location?.state?.readingDetails} patientInfoDetail={patientInfoDetail} triageStartedAt={triageStartedAt} />}
              {!showHighLowAlert && isHighBp === "low" && <TriagePatientLowBpQuesView readingDetails={location?.state?.readingDetails} patientInfoDetail={patientInfoDetail} triageStartedAt={triageStartedAt} />}
              {/* this module added later so going by device name */}
              {showOximeterModel && <TriageOximeterQuesView readingDetails={location?.state?.readingDetails} patientInfoDetail={patientInfoDetail} triageStartedAt={triageStartedAt} />}
              {/* {!showDeviceSelect && device === "ws" && <TriageWeightScaleQuesView />} */}
              {showGlucometerModel && <TriageGlucometerQuesView setShowGlucometerModel={setShowGlucometerModel} readingDetails={location?.state?.readingDetails} patientInfoDetail={patientInfoDetail} triageStartedAt={triageStartedAt} />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TriagePatient
