import { axiosGet, axiosPost } from "../axios"
import store from "../store"
import {
  EDIT_ELIGIBLE_PATIENT_FAIL,
  EDIT_ELIGIBLE_PATIENT_REQUEST,
  EDIT_ELIGIBLE_PATIENT_RESET,
  EDIT_ELIGIBLE_PATIENT_SUCCESS,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS,
  GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
  GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST,
  GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
  POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
  POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
  POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
  POST_REGISTER_OTP_FAIL,
  POST_REGISTER_OTP_REQUEST,
  POST_REGISTER_OTP_RESET,
  POST_REGISTER_OTP_SUCCESS,
  REMOVE_BULK_CARETAKER_FOR_PATIENT_FAIL,
  REMOVE_BULK_CARETAKER_FOR_PATIENT_REQUEST,
  REMOVE_BULK_CARETAKER_FOR_PATIENT_SUCCESS,
  REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
  REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST,
  REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS
} from "../types/selfOnboardingConstant"
import { userLogoutAction } from "./userActions"

export const getSelfOnboardingPendingPatientAction = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST
    })
    if (orgId) {
      const { data } = await axiosGet(
        `/api/cs/bulk-upload/onboardingStatus/profiles/${orgId}/fetch`
      )
      dispatch({
        type: GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
        payload: data?.details
      })
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
      payload: error.response?.data?.details
    })
  }
}

export const editEligiblePatientAction =
  ({ patientId, email, mobileNumber }) =>
  async dispatch => {
    try {
      dispatch({
        type: EDIT_ELIGIBLE_PATIENT_REQUEST
      })

      const { data } = await axiosPost(
        `/api/cs/bulk-upload/onboardingStatus/enrollment/profiles/${patientId}/update`,
        { email, mobileNumber }
      )

      dispatch({
        type: EDIT_ELIGIBLE_PATIENT_SUCCESS
      })

      // modify patient detail in patient list also
      const existingPatients = store?.getState()?.selfOnboardingPendingPatient?.selfOnboardingPendingPatient
      const index = existingPatients?.findIndex(item => item?.id === patientId)

      const updatedPatientList =
        index !== -1
          ? [
              ...existingPatients.slice(0, index),
              { ...existingPatients[index], email, mobileNumber },
              ...existingPatients.slice(index + 1)
            ]
          : existingPatients

      dispatch({
        type: GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
        payload: updatedPatientList
      })

      return { data, isUpdated:true }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: EDIT_ELIGIBLE_PATIENT_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

export const resetEditEligiblePatientAction = () => async dispatch => {
  dispatch({
    type: EDIT_ELIGIBLE_PATIENT_RESET
  })
}

export const registerOTP = (patientID, code) => async dispatch => {
  try {
    dispatch({
      type: POST_REGISTER_OTP_REQUEST
    })
    const { data } = await axiosPost(`/api/cs/patients/opt/${patientID}/create`, code)

    dispatch({
      type: POST_REGISTER_OTP_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    dispatch({
      type: POST_REGISTER_OTP_FAIL,
      payload: error.response?.data?.details
    })
  }
}

export const resetRegisterOTPErr = () => async dispatch => {
  dispatch({
    type: POST_REGISTER_OTP_RESET
  })
}

export const getOrgOnboardPatientStatistics = id => async dispatch => {
  try {
    dispatch({
      type: GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/bulk-upload/onboardingStatus/${id}/stats`)

    dispatch({
      type: GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS,
      payload: data.details
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL,
      payload: error.response?.data?.detail
    })
  }
}

// UPDATE action for bulk caretaker for Active patient
export const updateBulkCaretakerActivePatientAction = (formData, orgId) => async dispatch => {
  try {
    dispatch({ type: UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST })
    if (orgId) {
      const { data } = await axiosPost(
        `/api/cs/patients/bulkMapCaretakers/${orgId}/update`,
        formData
      )
      dispatch({
        type: UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS,
        payload: data.details
      })
      return data
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    const errMsg = error.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : "Something went wrong!"

    dispatch({
      type: UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL,
      payload: errMsg
    })
    throw new Error(errMsg)
  }
}

// UPDATE action for bulk remove caretaker
export const removeBulkCaretakerPatientAction = (organizationId, formData) => async dispatch => {
  try {
    dispatch({ type: REMOVE_BULK_CARETAKER_FOR_PATIENT_REQUEST })

    const { data } = await axiosPost(
      `/api/cs/patients/bulkMapCaretakers/${organizationId}/remove`,
      formData
    )
    dispatch({
      type: REMOVE_BULK_CARETAKER_FOR_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    const errMsg = error.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : "Something went wrong!"
    dispatch({
      type: REMOVE_BULK_CARETAKER_FOR_PATIENT_FAIL,
      payload: errMsg
    })
    throw new Error(errMsg)
  }
}

// UPDATE action for bulk caretaker
export const updateSelfOnboardingPatientCaretakerAction = (formData, orgId) => async dispatch => {
  try {
    dispatch({ type: UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST })
    if (orgId) {
      const { data } = await axiosPost(
        `/api/cs/bulk-upload/onboardingStatus/caretakers/${orgId}/update`,
        formData
      )
      dispatch({
        type: UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS,
        payload: data.details
      })
      return data
    }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    const errMsg = error.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : "Something went wrong!"

    dispatch({
      type: UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
      payload: errMsg
    })
    throw new Error(errMsg)
  }
}

// UPDATE action for bulk remove caretaker
export const removeSelfOnboardingPatientCaretakerAction =
  (organizationId, formData) => async dispatch => {
    try {
      dispatch({ type: REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST })

      const { data } = await axiosPost(
        `/api/cs/bulk-upload/onboardingStatus/caretakers/${organizationId}/remove`,
        formData
      )
      dispatch({
        type: REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      const errMsg = error.response?.data?.details
        ? Object.values(error.response?.data?.details)[0]
        : "Something went wrong!"

      dispatch({
        type: REMOVE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
        payload: errMsg
      })
      throw new Error(errMsg)
    }
  }

// POST action for bulk mail/messages to the patients
export const postBulkMailMessagePatientAction = formData => async dispatch => {
  try {
    dispatch({ type: POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST })

    const { data } = await axiosPost(`/api/cs/bulk-upload/messages`, formData)
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
      payload:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    })
  }
}
