import React, { useState } from "react"

import { Button } from "antd"
import { InputOTP } from "antd-input-otp"
import { useToasts } from "react-toast-notifications"

import { useDispatch } from "react-redux"
import { forgetPasswordLoginAction } from "../../redux/actions/utilsActions"
import { useNavigate } from "react-router-dom"
import "./forgetPassword.css"
import { USER_LOGIN_RESET } from "../../redux/types/userConstant"
import { Mixpanel } from "../../utils/mixpanel"

const CODE_VALIDATION_MSG = "Code must be of 6 characters"
const CODE_SENT_SUCCESS_MSG = "Code Sent Successfully!"
const CODE_SENT_FAIL_MSG = "Oops! Unable to send Code, Try later"

const OTPForm = ({ credential, setShowNext, setOtpValue, setChangePasswordShow }) => {
  const [otp, setOtp] = useState("")
  const [errors, setErrors] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const handleSubmit = async () => {
    setErrors("")
    Mixpanel.track("Validate OTP")
    if (!otp.length || otp.some(o => o === "") || otp.length < 6) {
      setErrors(CODE_VALIDATION_MSG)
    } else {
      setErrors("")

      setLoading(true)
      const result = await dispatch(forgetPasswordLoginAction(credential, otp.join("")))
      if (result?.message === "Success" && result?.details?.setPassword === true) {
        setShowNext(false)
        setChangePasswordShow(true)
        setOtpValue(otp.join(""))
        addToast(CODE_SENT_SUCCESS_MSG, {
          appearance: "success",
          autoDismiss: true
        })
      } else {
        setErrors(result)
        addToast(CODE_SENT_FAIL_MSG, {
          appearance: "error",
          autoDismiss: true
        })
      }
      setLoading(false)
    }
  }

  const DISPLAY_TITLE = credential ? `Enter code sent to ${credential}` : "Enter code sent to your Email / Mobile"

  return (
    <>
      <div className="mb-4">
        <p className="text-center my-3">{DISPLAY_TITLE}</p>

        <InputOTP
          onChange={setOtp}
          value={otp}
          inputType="numeric"
          length={6}
          inputClassName={`h-[40px] text-lg ${errors ? "border-danger" : ""}`}
        />
      </div>
      <Button
        block
        size="large"
        type="primary"
        onClick={handleSubmit}
        disabled={loading}
      >
        Submit
      </Button>
      <div className="mt-4">
        <small
          className="text-center cursor-pointer text-secondary"
          onClick={() => {
            dispatch({ type: USER_LOGIN_RESET })
            navigate("/login")
          }}>
          Back to Login
        </small>
      </div>

      {errors && typeof errors === "string" ? (
        <p className="text-danger block text-center font-medium mt-2">{errors}</p>
      ) : null}

      {errors &&
        typeof errors === "object" &&
        Object.values(errors)?.map((value, index) => (
          <p key={index} className="font-bold text-danger text-center mt-4">
            <small>{value}</small>
          </p>
        ))}
    </>
  )
}

export default OTPForm
