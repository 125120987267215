import React, { useEffect, useState } from "react"
import { Button, Tabs } from "antd"
import { useParams } from "react-router-dom"
import "./EligiblePatient.css"

import { useSelector, useDispatch } from "react-redux"
import OnboardedPatient from "./OnboardedPatient"
import OnboardingPendingPatient from "./OnboardingPendingPatient"
import { getSelfOnboardingPendingPatientAction } from "../../../redux/actions/selfOnboardingAction"
import { getCaretakerListAction } from "../../../redux/actions/caretakerActions"
import { OnBoardedStage, OnBoardingPendingStage } from "./Types"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import { TableDropdown } from "@ant-design/pro-components"
import { DownOutlined } from "@ant-design/icons"
import UpdateEmail from "./UpdateEmail"
import UpdatePhoneNumber from "./UpdatePhoneNumber"
import SetCodeModal from "./SetCodeModal"
import EnrollByCodeModal from "./EnrollByCodeModal"

const EligiblePatient = () => {
  const [onboardPendingPatient, setOnboardPendingPatient] = useState()

  const [selfOnboardedPatients, setSelfOnboardedPatients] = useState()
  const [patientLoading, setPatientLoading] = useState(true)
  const [updateEmail, setUpdateEmail] = useState(null)
  const [updatePhoneNum, setUpdatePhoneNum] = useState(null)
  const [manualPatientEnroll, setManualPatientEnroll] = useState(null)
  const [setCodeModal, setSetCodeModal] = useState(null)

  const dispatch = useDispatch()
  const { orgId } = useParams()
  const { selfOnboardingPendingPatient, loading: selfOnboardLoading } = useSelector(
    state => state.selfOnboardingPendingPatient
  )
  const { loading: caretakerLoading, caretakerLists } = useSelector(state => state.caretakerList)

  const { onboardpatientStatistics, loading: onboardPatientStatisticLoading } = useSelector(
    state => state.getOrgOnboardPatientStatistics
  )

  const tableMenuItems = record => {
    const items = []
    if (record?.signupPending) {
      items.push(
        {
          key: "changeEmail",
          name: "Change Email",
          onClick: () => setUpdateEmail(record)
        },
        {
          key: "changePhoneNumber",
          name: "Change Phone Number",
          onClick: () => setUpdatePhoneNum(record)
        },
        {
          key: "enrollByCode",
          name: "Enroll to Program",
          onClick: () => setManualPatientEnroll(record)
        }
      )
    } else if (record?.signupInitiated) {
      items.push(
        { key: "setCode", name: "Generate Code", onClick: () => setSetCodeModal(record) },
        {
          key: "enrollByCode",
          name: "Enroll to Program",
          onClick: () => setManualPatientEnroll(record)
        }
      )
    } else if (record?.signup) {
      items.push(
        { key: "setCode", name: "Generate Code", onClick: () => setSetCodeModal(record) }
      )
    }
    return items
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, patientDeatils) => (
        <p
          className="capitalize wrap-text mb-0"
          // style={{ cursor: "pointer" }}
          // onClick={() => {
          //   setPatientDetail(patientDeatils)
          //   setShowPatientProfileStatges(true)
          // }}
        >
          {name?.toLowerCase()}
        </p>
      )
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      copyable: true,
      render: (_, { phone }) => <p>{phone ? formatPhoneNumber(phone) : "-"}</p>
    },
    { title: "Email", dataIndex: "email", key: "email", copyable: true },
    {
      title: "Onboarding Stage",
      dataIndex: "onBoardingStage",
      key: "onBoardingStage",
      sorter: (a, b) => a.onBoardingStage.localeCompare(b.onBoardingStage)
    },
    {
      title: "Caretaker",
      dataIndex: "caretaker",
      key: "caretaker",
      width: "18vw",
      render: caretaker => (
        <span>
          {Array.isArray(caretaker)
            ? caretaker.map((item, index) => {
                const [name, email] = item.split("<")
                return (
                  <span key={index}>
                    <span className="font-semibold capitalize">{name?.toLowerCase()}</span>&lt;
                    {email}
                    {index !== caretaker.length - 1 ? ", " : ""}
                  </span>
                )
              })
            : caretaker}
        </span>
      )
    },
    {
      title: "Patient Type",
      dataIndex: "userType",
      key: "userType ",
      sorter: (a, b) => a.userType.localeCompare(b.userType)
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        [
          OnBoardingPendingStage?.SignUpPending,
          OnBoardingPendingStage?.SignUpInitiated,
          OnBoardingPendingStage?.DeniedService,
          OnBoardedStage?.SignedUp // for eligible->onboarded tab added generate code
        ].includes(record?.onBoardingStage) ? (
          <Button key="actionGroup">
            <TableDropdown menus={tableMenuItems(record)}>
              More <DownOutlined />
            </TableDropdown>
          </Button>
        ) : (
          <p className="text-center">-</p>
        )
    }
    // { title: "Address", dataIndex: "address", key: "address" },
  ]

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("onboardComplete")
  // Function to handle tab change
  const handleTabChange = key => {
    setActiveTab(key)
  }

  const getCaretakerById = caretakerIds => {
    if (!caretakerIds) return
    const caretakerIdArray = caretakerIds?.split("~") // Splitting multiple IDs if present
    const caretakerNames = []
    for (const id of caretakerIdArray) {
      // @ts-expect-error
      const caretaker = caretakerLists?.find(c => c.id === id)
      if (caretaker) {
        caretakerNames.push(
          `${caretaker?.firstName} ${caretaker?.middleName ? caretaker?.middleName + " " : ""} ${
            caretaker?.lastName
          }  <${caretaker?.email}>`
        )
      }
    }
    return caretakerNames
  }

  useEffect(() => {
    if (!caretakerLists?.length) dispatch(getCaretakerListAction(orgId))
  }, [])

  useEffect(() => {
    if (!caretakerLists) return

    if (selfOnboardingPendingPatient) {
      const pendingSignup = selfOnboardingPendingPatient?.filter(patient => !patient.signup)
      const completeSignup = selfOnboardingPendingPatient?.filter(patient => patient.signup)

      const mapPatient = p => ({
        id: p.id,
        dob: p.dob,
        email: p.email,
        phone: p.mobileNumber,
        name: `${p.firstName} ${p.middleName || ""} ${p.lastName}`,
        firstName: p.firstName,
        lastName: p.lastName,
        middleName: p.middleName,
        gender: p.gender,
        telephone: p.telephone,
        extension: p.extension,
        caretaker: getCaretakerById(p.caretakerIds),
        caretakerIds: p.caretakerIds,
        userType: p.typeOfPatient,
        deniedService: p?.deniedService,
        signupPending: p?.signupPending,
        signupInitiated: p?.signupInitiated,
        signup: p?.signup,
        onBoardingStage: p?.deniedService
          ? OnBoardingPendingStage.DeniedService
          : p.signup
          ? OnBoardedStage.SignedUp
          : p.signupPending
          ? OnBoardingPendingStage.SignUpPending
          : OnBoardingPendingStage.SignUpInitiated
      })

      const pendingPatients = pendingSignup.map(mapPatient)
      const onboardedPatients = completeSignup.map(mapPatient)

      setOnboardPendingPatient(pendingPatients)
      //  setFilteredPatients(pendingPatients)
      setSelfOnboardedPatients(onboardedPatients)
      //  setFilteredOnboaredPatients(onboardedPatients)
    }
  }, [selfOnboardLoading, selfOnboardingPendingPatient, caretakerLists])

  useEffect(() => {
    if (selfOnboardedPatients) setPatientLoading(false)
  }, [selfOnboardedPatients, onboardPendingPatient])

  useEffect(() => {
    dispatch(getSelfOnboardingPendingPatientAction(orgId))
  }, [dispatch])

  const patientTabItems = [
    {
      key: "onboardComplete",
      // @ts-expect-error
      label: `Onboarded Patients (${onboardpatientStatistics?.singedUpCount ?? "-"})`,
      children: (
        <OnboardedPatient
          columns={columns}
          selfOnboardedPatients={selfOnboardedPatients}
          patientLoading={patientLoading}
        />
      )
    },
    {
      key: "onboardPending",
      label: `Onboarding Pending Patients (${onboardpatientStatistics?.pendingCount ?? "-"})`,
      children: (
        <OnboardingPendingPatient columns={columns} onboardPendingPatient={onboardPendingPatient} />
      )
    }
  ]

  return (
    <>
      <div>
        <Tabs
          activeKey={activeTab}
          type="card"
          defaultActiveKey="onboardComplete"
          items={patientTabItems}
          onChange={handleTabChange}
        />
        {/* {showPatientProfileStatges && (
        <PatientProfileStages
          patientDeatil={patientDeatil}
          showPatientProfileStatges={showPatientProfileStatges}
          setShowPatientProfileStatges={setShowPatientProfileStatges}
        />
      )} */}
        <UpdateEmail selectedPatient={updateEmail} closeModal={() => setUpdateEmail(null)} />
        <UpdatePhoneNumber
          selectedPatient={updatePhoneNum}
          closeModal={() => setUpdatePhoneNum(null)}
        />
        <SetCodeModal selectedPatient={setCodeModal} closeModal={() => setSetCodeModal(null)} />
        <EnrollByCodeModal
          setManualPatientEnroll={setManualPatientEnroll}
          selectedPatient={manualPatientEnroll}
        />
      </div>
    </>
  )
}
export default EligiblePatient
