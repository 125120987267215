import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Card, Typography } from "antd"

import PatientTable from "../Dashboard/PatientTable"

import { sortBy } from "../../utils/sortBy"
import { useDispatch, useSelector } from "react-redux"
import {
    getFilterUsersAction,
    getOnboardingPatientAction,
    resetFilterUsersAction
} from "../../redux/actions/ccmActions"
import moment from "moment"
import Loading from "../layouts/Loading/Loading"
import { PATIENT_DIAGNOSTIC_RESET } from "../../redux/types/PatientConstant"
import { LeftOutlined } from "@ant-design/icons"
import { GetPatientsForGroup, resetGroupPatientAdvanceFilter, saveGroupPatientAdvanceFilter } from "../../redux/actions/groupsActions"
import GroupPatientFilter from "./GroupPatientFilter"
import GroupPatientTable from "./GroupPatientTable"

const GroupDetails = () => {
    const location = useLocation()
    const [sortColumn] = useState({
        path: sortBy.AtoZ,
        order: 1
    })

    const [searchQuery, setSearchQuery] = useState("")
    const [currentPage, setCurrentPage] = useState(1) // eslint-disable-line
    const [sortValue, setSortValue] = useState("title")
    const [groupIds, setGroupIds] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {orgId, orgName} = useParams()
    const filter = useSelector(state => state.filter)
    const userInfo = useSelector(state => state.userInfo)

    const { loading, patientsForGroup } = useSelector(state => state.getPatientsForGroup)
    const { groupPatientAdvFilter } = useSelector(state => state.groupPatientAdvFilter)
    const [sortedData, setSortedData] = useState([])
    const [filterUserType, setFilterUserType] = useState([])
    const [tempLoading, setTempLoading] = useState(false) // eslint-disable-line
    const [allPatientFiltered, setAllPatientFiltered] = useState([])
    const [isFilteredPatient, setIsFilteredPatient] = useState(false)
    const [filteredWithoutSearch, setFilteredWithoutSearch] = useState([])
    const [slectedFacility, setSelectedFacility] = useState()

    const groupDetails = location?.state?.groupDetails

    useEffect(() => {
        let newGroupIds = []
        if (groupDetails?.id) {
            newGroupIds = [...groupIds, groupDetails?.id]
            setGroupIds(newGroupIds)
        }
    }, [location])

    useEffect(() => {
        if (userInfo && loading === false && groupIds?.length > 0) {
            const data = {
                groupIds: groupIds,
                filters: slectedFacility?.facilityId ? {
                    facilityId: slectedFacility?.facilityId,
                    userType: slectedFacility?.userType,
                } : null
            }
            dispatch(GetPatientsForGroup(data))
        }
    }, [userInfo, groupIds, slectedFacility])

    const handleSearch = query => {
        setSearchQuery(query)
        setCurrentPage(1)
    }
    const showSearchedData = () => {
        let allPatients = []
        const query = searchQuery?.toLowerCase()
        if (patientsForGroup && groupPatientAdvFilter?.userType !== "outpatient") {
            if (searchQuery) {
                allPatients = patientsForGroup?.filter(m => {
                    const fullName = `${m?.firstName}${m?.middleName || ""}${m?.lastName}`.toLowerCase()
                    const reversedFullName = `${m?.lastName}${m?.middleName || ""}${m?.firstName}`.toLowerCase()
                    const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
                    return (
                        m?.firstName?.toLowerCase().includes(query) ||
                        m?.middleName?.toLowerCase().includes(query) ||
                        m?.lastName?.toLowerCase().includes(query) ||
                        m?.email?.toLowerCase().includes(query) ||
                        String(m?.mobileNumber)?.toLowerCase().includes(query) ||
                        m?.userName?.toLowerCase().includes(query) ||
                        m?.id?.toLowerCase().includes(query) ||
                        fullName.includes(trimmedSearchValue) ||
                        reversedFullName.includes(trimmedSearchValue)
                    )
                })
            }
            else {
                allPatients = patientsForGroup
            }
            setSortValue("title")
        }
        else if (patientsForGroup !== undefined) {
            if (searchQuery) {
                let tempAllPatient = []
                if (sortedData?.length === 0) {
                    tempAllPatient = isFilteredPatient ? filteredWithoutSearch : patientsForGroup
                } else {
                    tempAllPatient = sortedData
                }
                allPatients = tempAllPatient.filter(m => {
                    const fullName = `${m.firstName}${m?.middleName || ""}${m.lastName}`.toLowerCase()
                    const reversedFullName = `${m.lastName}${m?.middleName || ""}${m.firstName}`.toLowerCase()
                    const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
                    return (
                        m?.firstName?.toLowerCase().includes(query) ||
                        m?.middleName?.toLowerCase().includes(query) ||
                        m?.lastName?.toLowerCase().includes(query) ||
                        m?.email?.toLowerCase().includes(query) ||
                        String(m?.mobileNumber)?.toLowerCase().includes(query) ||
                        m?.userName?.toLowerCase().includes(query) ||
                        m?.id?.toLowerCase().includes(query) ||
                        fullName.includes(trimmedSearchValue) ||
                        reversedFullName.includes(trimmedSearchValue)
                    )
                })
            }
            else if (groupPatientAdvFilter?.userType) {
                allPatients = (isFilteredPatient ? filteredWithoutSearch : patientsForGroup)?.filter(
                    patient => patient?.userType === groupPatientAdvFilter?.userType
                )
            } else if (isFilteredPatient) {
                allPatients = filteredWithoutSearch
            } else {
                allPatients = patientsForGroup
            }
        }
        allPatients = allPatients.map((patient) => {
            if (patient?.manualLog?.length) {
                let ccmLogCount = 0
                let rpmLogCount = 0
                let manualLogCount = 0
                if (moment(patient?.manualLogActiveTime).month() === moment().month() && moment(patient?.manualLogActiveTime).year() === moment().year()) {
                    patient?.manualLog?.map((manual) => {
                        if (manual?.careType === 'ccm' && moment(manual?.careDate).month() === moment().month() && moment(manual?.careDate).year() === moment().year()) {
                            ccmLogCount += manual?.count
                        }
                        else if (manual?.careType === 'rpm' && moment(manual?.careDate).month() === moment().month() && moment(manual?.careDate).year() === moment().year()) {
                            rpmLogCount += manual?.count
                        }
                    })
                    patient = { ...patient, "rpmManualLogCount": rpmLogCount, "ccmManualLogCount": ccmLogCount }
                } else {
                    patient = { ...patient, "rpmManualLogCount": rpmLogCount, "ccmManualLogCount": ccmLogCount, "manualLogCount": manualLogCount }
                }
            }
            if (patient?.rpmActiveDate?.length !== 0 && moment(patient?.rpmActiveDate).month() === moment().month()
                && moment(patient?.rpmActiveDate).year() === moment().year()) {
                return { ...patient, "monthlyLastestTotalReadingRPM": patient?.rpmReadingCount }
            } else {
                return { ...patient, "monthlyLastestTotalReadingRPM": 0 }
            }
        })
        let temp = []
        if (sortColumn.path === sortBy.AtoZ) {
            temp = allPatients?.sort((a, b) =>
                a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
            )
        }
        if (sortColumn.path === sortBy.ZtoA) {
            temp = allPatients?.sort((a, b) =>
                a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
            )
        }
        setSortedData(temp)
        setAllPatientFiltered(temp)
        if (temp?.length === patientsForGroup?.length)
            setFilterUserType(temp) // this is added to handle the advancce filter condition 
    }

    useEffect(() => {
        showSearchedData()
    }, [patientsForGroup, searchQuery, isFilteredPatient]) //eslint-disable-line

    const applyFilters = (type, data) => {
        dispatch(saveGroupPatientAdvanceFilter(data))
        setIsFilteredPatient(true)
        setCurrentPage(1)
        if (type === "normal" && data?.facilityId) {
            const filterData = {
                groupIds: groupIds,
                filters: data?.facilityId ? {
                    facilityId: data?.facilityId,
                    unit: data?.unit,
                    room: data?.room,
                    bedLocation: data?.bedLocation,
                    userType: data?.userType,
                } : null
            }
            dispatch(GetPatientsForGroup(filterData))
        } else if (type === "normal" && !data?.facilityId) {
            setTimeout(() => {
                setSortedData(allPatientFiltered?.filter((patient) => patient?.userType === data?.userType))
            }, 700)
        } else {
            getOnBoardingPatients()
        }
    }
    useEffect(() => {
        dispatch({ type: PATIENT_DIAGNOSTIC_RESET })
    }, [])

    const getOnBoardingPatients = async () => {
        await dispatch(resetFilterUsersAction()).then(() => {
        })
    }

    const resetFilters = async cb => {
        setAllPatientFiltered([])
        const data = {
            groupIds: groupIds,
            filters: null
        }
        if (isFilteredPatient) {
            dispatch(GetPatientsForGroup(data))
        }
        dispatch(resetGroupPatientAdvanceFilter())
        setIsFilteredPatient(false)
        await dispatch(resetFilterUsersAction())
        if (cb) {
            cb()
        }
    }

    const sortReadingCount = val => {
        const tempData = []
        setIsFilteredPatient(true)
        const countExistsArr = allPatientFiltered.filter(
            x => x?.rpmReadingCount !== null && x?.rpmReadingCount !== undefined && x?.rpmActiveDate && moment(x?.rpmActiveDate).month() === moment().month()
                && moment(x?.rpmActiveDate).year() === moment().year()
        )
        const noCountArr = allPatientFiltered.filter(
            x => x?.rpmReadingCount === undefined || x?.rpmReadingCount === null
        )
        const ascCountArr = countExistsArr.sort((a, b) => a?.rpmReadingCount - b?.rpmReadingCount)
        const descCountArr = countExistsArr.sort((a, b) => b?.rpmReadingCount - a?.rpmReadingCount)
        if (val === "asc") {
            const sd = noCountArr.concat(ascCountArr)
            setTempLoading(true)
            setSortedData([...tempData])
            setFilteredWithoutSearch([...sd])
            setTimeout(() => {
                setTempLoading(false)
                setSortedData([...sd])
            }, 1500)
        } else {
            const sd = descCountArr.concat(noCountArr)
            setTempLoading(true)
            setSortedData([...tempData])
            setFilteredWithoutSearch([...sd])
            setTimeout(() => {
                setTempLoading(false)
                setSortedData([...sd])
            }, 1500)
        }
    }
    const sortLatestReading = val => {
        setIsFilteredPatient(true)
        const tempData = []
        const dateExistsArr = allPatientFiltered?.filter(
            x => x?.rpmActiveDate !== null && x?.rpmActiveDate !== undefined && x?.rpmActiveDate !== "" && x?.rpmActiveDate && moment(x?.rpmActiveDate).month() === moment().month()
                && moment(x?.rpmActiveDate).year() === moment().year()
        )
        const noDateArr = allPatientFiltered?.filter(
            x => x?.rpmActiveDate === undefined && x?.rpmActiveDate === null && x?.rpmActiveDate === ""
        )
        const ascDateArr = dateExistsArr.sort(
            (a, b) => new Date(a?.rpmActiveDate) - new Date(b?.rpmActiveDate)
        )
        const descDateArr = dateExistsArr.sort(
            (a, b) => new Date(b?.rpmActiveDate) - new Date(a?.rpmActiveDate)
        )
        if (val === "asc") {
            const sd = noDateArr.concat(ascDateArr)
            setTempLoading(true)
            setSortedData([...tempData])
            setFilteredWithoutSearch([...sd])
            setTimeout(() => {
                setTempLoading(false)
                setSortedData([...sd])
            }, 1500)
        } else {
            const sd = descDateArr.concat(noDateArr)
            setTempLoading(true)
            setSortedData([...tempData])
            setFilteredWithoutSearch([...sd])
            setTimeout(() => {
                setTempLoading(false)
                setSortedData([...sd])
            }, 1500)
        }
    }
    const filterCriticalReading = val => {
        setIsFilteredPatient(true)
        const countExistsArr = allPatientFiltered.filter(
            x => x?.criticalStatus === true
        )
        setTempLoading(true)
        setTimeout(() => {
            setTempLoading(false)
            setSortedData([...countExistsArr])
            setFilteredWithoutSearch([...countExistsArr])
        }, 1500)

    }

    useEffect(() => {
        if (location?.state?.mark === undefined) {
            // dispatch(resetFilterUsersAction())
        }
        dispatch(resetGroupPatientAdvanceFilter())
    }, [])

    return (
        <>
            <div>
                <div
                    className="text-lg font-semibold p-1 w-min cursor-pointer flex justify-start items-center"
                    onClick={() => {
                        navigate(`/${orgId}/${orgName}/groups`)
                    }}
                >
                    <LeftOutlined />
                    &nbsp; <span className="capitalize">{groupDetails.name}</span>
                </div>
                <h4 className="my-3 text-xl font-semibold">Patients</h4>
            </div>
            <Card
                headStyle={{ paddingTop: "16px", paddingBottom: "16px" }}
            >
                <GroupPatientFilter
                    groupId={groupIds}
                    setCurrentPage={setCurrentPage}
                    handleSearch={handleSearch}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setSortedData={setSortedData}
                    sortReadingCount={sortReadingCount}
                    applyFilters={applyFilters}
                    resetFilters={resetFilters}
                    sortLatestReading={sortLatestReading}
                    filterCriticalReading={filterCriticalReading}
                    from={"group"}
                />
                {!loading ? <GroupPatientTable loading={loading || tempLoading || filter?.loading} sortedData={sortedData} /> : <Loading />}
            </Card>
        </>
    )
}

export default GroupDetails